import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

export type AccommodationOffersTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const AccommodationOffersTable: React.FunctionComponent<AccommodationOffersTableProps> = memo(props => {
  console.debug('AccommodationOffersTable');

  return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default AccommodationOffersTable;
