import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import PriceListItemDetail, {
  PriceListItemDetailStateProps,
  PriceListItemDetailDispatchProps,
  PriceListItemDetailProps
} from './priceListItemsDetail';
import * as actions from './actions';
import { priceListItemTypeAsSelectOptions } from './enums';
import { PriceListItemModel, PriceListItemType } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectPriceListsSelectItems } from '../priceLists';
import { selectVatsSelectItems } from '../vats';
import { getAccommodationIdByPriceListId } from '../priceLists';
import { selectRoomTypesSelectItemsByAccomodationId } from '../roomTypes';

type PriceListItemCreateContainerStateProps = {} & PriceListItemDetailStateProps;

type PriceListItemCreateContainerDispatchProps = {} & PriceListItemDetailDispatchProps;

type PriceListItemCreateContainerProps = {} & PriceListItemDetailProps &
  PriceListItemCreateContainerStateProps &
  PriceListItemCreateContainerDispatchProps;

const createInitialValues = { description: '', name: '', price: 0, type: PriceListItemType.Default } as PriceListItemModel;

const PriceListItemCreateContainer: React.FunctionComponent<PriceListItemCreateContainerProps> = memo(props => {
  console.debug('PriceListItemCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    priceTypeSelectItems,
    priceListsSelectItems,
    vatsSelectItems,
    priceLists,
    roomTypes,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    getAccommodationIdByPriceListId,
    selectRoomTypesSelectItemsByAccomodationId
  } = props;

  return (
    <PriceListItemDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      priceTypeSelectItems={priceTypeSelectItems}
      priceListsSelectItems={priceListsSelectItems}
      vatsSelectItems={vatsSelectItems}
      priceLists={priceLists}
      getAccommodationIdByPriceListId={getAccommodationIdByPriceListId}
      roomTypes={roomTypes}
      selectRoomTypesSelectItemsByAccomodationId={selectRoomTypesSelectItemsByAccomodationId}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): PriceListItemCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.priceListItems.errorMessage,
    requestInProgress: state.priceListItems.requestInProgress,
    priceTypeSelectItems: priceListItemTypeAsSelectOptions,
    priceListsSelectItems: selectPriceListsSelectItems(state),
    vatsSelectItems: selectVatsSelectItems(state),
    priceLists: state.priceLists.itemList.byId,
    roomTypes: state.roomTypes.itemList.byId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PriceListItemCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    ),
    getAccommodationIdByPriceListId,
    selectRoomTypesSelectItemsByAccomodationId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListItemCreateContainer);
