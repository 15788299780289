import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type AccommodationsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const AccommodationsTable: React.FunctionComponent<AccommodationsTableProps> = memo(props => {
    console.debug('AccommodationsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default AccommodationsTable;
