import React, { memo } from 'react';

import { BaseTableFilterComponentModel } from '../../components/table';
import { default as CompaniesFilter } from './companiesFilter';

type CompaniesFilterContainerProps = {} & BaseTableFilterComponentModel;

const CompaniesFilterContainer: React.FunctionComponent<CompaniesFilterContainerProps> = memo(props => {
  console.debug('CompaniesFilterContainer');

  return <CompaniesFilter {...props} />;
});

export default CompaniesFilterContainer;
