import React, { ReactNode, RefObject } from 'react';
import {
  Theme,
  InputAdornment,
  Input as MuiInput,
  FormControl,
  FormHelperText,
  InputProps as MuiInputProps,
  InputLabel
} from '@material-ui/core';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import InfiniteScroller from 'react-infinite-scroll-component';

import { VirtualComponentTypeEnum, VirtualSelectItem } from '.';
import { Icon, IconName } from '../../icons';
import { BaseComponentWithModalSupport } from '../baseDetail';
import { withOpenModal, WithOpenModalProps } from '../modalDialogManager';
import { ROUTES } from '../../navigation';
import { Condition } from '../conditions';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.background.paper,
    '& fieldset': {
      borderColor: palette.primary.main
    }
  },
  labelFormControl: {
    transform: 'translate(44px, 24px) scale(1)'
  },
  iconRoot: {
    width: '1.2em',
    color: palette.text.secondary
  },
  inputInput: {
    fontWeight: 'bold'
  },
  selectIcon: { color: palette.primary.main },
  inputStartAdornment: {
    marginRight: spacing(2)
  },
  inputEndAdornment: {
    cursor: 'pointer'
  },
  autocomplete: {
    position: 'relative',
    display: 'inline-block',
    width: '100%'
  },
  autocompleteItems: {
    position: 'absolute',
    backgroundColor: palette.background.paper,
    border: '1px solid #eee',
    boxShadow: '0px 1px 1px #aaa',
    borderTop: 'none',
    borderRadius: '0 0 5px 5px',
    paddingBottom: '10px',
    zIndex: 99,
    top: '100%',
    left: 0,
    right: 0
  },
  autocompleteItemsDiv: {
    padding: '10px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    "&:hover": {
      color: palette.common.white,
      backgroundColor: palette.primary.main
    },
    '&[data-selected="true"]': {
      color: palette.common.white,
      backgroundColor: palette.primary.main
    },
  }
}));

export type VirtualSelectClassKey =
  | 'root'
  | 'labelFormControl'
  | 'iconRoot'
  | 'selectIcon'
  | 'inputInput'
  | 'inputStartAdornment'
  | 'inputEndAdornment'
  | 'autocomplete'
  | 'autocompleteItems'
  | 'autocompleteItemsDiv';

export type VirtualComponentSelectProps = {
  type: VirtualComponentTypeEnum;
  filter: number | string | undefined;
  search: (value: string) => void;
  load: (page: number) => void;
  total: number;
  hasMore: boolean;
  items: VirtualSelectItem[] | VirtualFilterSelectItem[];
  value?: number;
  errorMessage?: string;
  disabled?: boolean;
  iconName?: IconName;
  labelText?: string;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  classes: Partial<ClassNameMap<VirtualSelectClassKey>>;
  helperText?: ReactNode;
  error?: boolean;
  disableUnselect?: boolean;
  variant?: 'standard' | 'outlined';
  hideErrorComponent?: boolean;
  createComponent?: {
    iconName?: IconName;
    component: React.ComponentType<BaseComponentWithModalSupport>;
    route: ROUTES;
  };
  onChange?: (value: number) => void;
  onRemoveRelation?: () => void;
};

export type VirtualSelectProps = { items: VirtualSelectItem[] } & VirtualComponentSelectProps & WithOpenModalProps;

export type VirtualFilterSelectItem = { filterId: number } & VirtualSelectItem;

type VirtualSelectState = {
  open: boolean;
  value: string;
  page: number;
};

class VirtualSelect extends React.PureComponent<VirtualSelectProps, VirtualSelectState> {
  selectRef: RefObject<any>;

  constructor(props: VirtualSelectProps) {
    super(props);

    this.state = { open: false, value: 'Nevybráno', page: 0 };
    this.selectRef = React.createRef<any>();
  }

  componentDidUpdate(prevProps: VirtualSelectProps, prevState: VirtualSelectState): void {
    const { value, items, filter } = this.props;
    if (prevProps.items !== items) {
      const index = items?.findIndex(item => item.value === value);
      if (index !== -1) {
        this.setState({ value: items[index].text });
      }
    }

    if (prevProps.value !== value) {
      if (value === 0) {
        this.setState({ value: 'Nevybráno' });
      }
    }

    if (prevProps.filter !== filter) {
      this.props.load(0);
    }
  }

  onTextChange = (event: any): void => {
    event.preventDefault();
    const value = event.target.value;
    this.props.search(value);

    this.setState({ value: value, open: true });
    if (this.props.onChange) {
      this.props.onChange(-1);
    }
  }

  onValueChange = (event: React.MouseEvent): void => {
    console.debug('VirtualSelect.onValueChange');
    event.preventDefault();

    const value = Number(event.currentTarget.getAttribute('data-value')) ?? 0;
    const text = event.currentTarget.getAttribute('data-text') ?? '';

    this.setState({ value: text, open: false });
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  handleOpen = (): void => {
    if (this.props.disabled) {
      return;
    }
    console.debug('RelationSelect.handleOpen');

    this.setState({ open: true });
  };

  handleRemoveRelation = (event: React.MouseEvent<HTMLInputElement>): void => {
    if (this.props.disabled) {
      return;
    }

    console.debug('RelationSelect.handleRemoveRelation');
    event.preventDefault();

    if (this.props.onRemoveRelation) {
      this.props.onRemoveRelation();
    } else {
      console.error(`Je povolena funkce zrušení relace, ale není nastavena metoda na nastavení prázdné hodnoty - "props.onRemoveRelation"`);
    }
  };

  openModal = (event: React.MouseEvent<HTMLSpanElement>): void => {
    event.preventDefault();
    if (this.props.disabled) {
      return;
    }

    if (this.props.createComponent) {
      this.props.openModal(this.props.createComponent.component, this.props.createComponent.route);
    }
  };

  render(): React.ReactElement {
    console.debug('VirtualSelect.render');

    const errorComponent = !this.props.hideErrorComponent ? (
      <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>
    ) : null;

    const openModalIconName = this.props.createComponent
      ? this.props.createComponent.iconName
        ? this.props.createComponent.iconName
        : 'plus'
      : 'plus';

    const openIcon = (
      <Icon iconName="chevron-down" IconProps={{ className: this.props.classes.selectIcon, onClick: this.handleOpen }} fixedWidth />
    );
    const openModalIcon = (
      <Icon iconName={openModalIconName} IconProps={{ className: this.props.classes.selectIcon, onClick: this.openModal }} fixedWidth />
    );
    const removeRelationIcon = (
      <Icon iconName="times" IconProps={{ className: this.props.classes.selectIcon, onClick: this.handleRemoveRelation }} fixedWidth />
    );
    const startAdormentComponent = this.props.iconName ? (
      <InputAdornment position="start" classes={{ root: this.props.classes.inputStartAdornment }}>
        <Icon iconName={this.props.iconName} IconProps={{ className: this.props.classes.iconRoot }} fixedWidth />
      </InputAdornment>
    ) : null;
    const endAdormentComponent = (
      <InputAdornment position="end" classes={{ root: this.props.classes.inputEndAdornment }}>
        <Condition is={!this.props.disableUnselect}>{removeRelationIcon}</Condition>
        <Condition is={!!this.props.createComponent}>{openModalIcon}</Condition>
        {openIcon}
      </InputAdornment>
    );

    const inputLabelComponent = this.props.labelText ? (
      <InputLabel
        shrink={true}
        {...this.props.InputLabelProps}
      >
        {this.props.labelText}
      </InputLabel>
    ) : null;

    const inputProps = {
      classes: {
        root: this.props.classes.inputInput,
        input: this.props.classes.inputInput
      },
      startAdornment: startAdormentComponent,
      endAdornment: endAdormentComponent,
      ref: this.props.InputProps?.ref
    };
    const { value, items } = this.props;
    const _items = [{ text: 'Nevybráno', value: 0 }, ...items];
    const _value = value ?? 0;
    return (
      <div className={this.props.classes.autocomplete}>
        <FormControl fullWidth classes={{ root: this.props.classes.root }} >
          {inputLabelComponent}
          <MuiInput
            type="text"
            value={this.state.value}
            onChange={this.onTextChange}
            onFocus={this.props.InputProps?.onFocus}
            onBlur={this.props.InputProps?.onBlur}
            onClick={(): void => { this.setState({ open: true }) }}
            name={this.props.InputProps?.name}
            placeholder={this.props.labelText}
            className={this.props.classes.inputInput}
            {...inputProps}
          />
          {this.state.open && <div className={this.props.classes.autocompleteItems} id='autocomplete-list' onMouseLeave={(): void => { this.setState({ open: false }) }}>
            <InfiniteScroller
              dataLength={this.props.total}
              next={(): void => {
                const { page } = this.state;
                this.setState({ page: page + 1 })
                this.props.load(page + 1);
              }}
              hasMore={this.props.hasMore}
              height={200}
              loader={<div>Načítám ...</div>}>
              {_items.map(item => {
                return (<div className={this.props.classes.autocompleteItemsDiv}
                  data-value={item.value}
                  data-text={item.text}
                  data-selected={item.value === _value}
                  onClick={this.onValueChange}>{item.text}</div>);
              })}
            </InfiniteScroller>
          </div>}
          {errorComponent}
        </FormControl>
      </div>
    );
  }
}

export default withOpenModal(withStylesFunction(VirtualSelect));
