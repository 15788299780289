import { AxiosInstance } from 'axios';

import { ExtendedApiModelClient, BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { BaseModel, LogModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class LogsClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<LogModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'log');
  }

  getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    throw new Error('Method not implemented.');
  }
  
  getAsync(id: number): Promise<BaseDataResponse<LogModel>> {
    throw new Error('Method not implemented.');
  }
  
  updateAsync(model: BaseModel): Promise<BaseDataResponse<LogModel>> {
    throw new Error('Method not implemented.');
  }

  createAsync(model: BaseModel): Promise<BaseDataResponse<LogModel>> {
    throw new Error('Method not implemented.');
  }

  deleteAsync(id: number): Promise<BaseResponse> {
    throw new Error('Method not implemented.');
  }

  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<LogModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<LogModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAllAsync(): Promise<BaseDataResponse<LogModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<LogModel[]>>('', '');
  }
}