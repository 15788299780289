import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Checkbox as MuiCheckbox, TableCell } from '@material-ui/core';
import { Field, FormikTouched } from 'formik';

import { AccommodationOfferFloorCalculationModel, AccommodationOfferModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { TableContainer, GenericColumn, BaseTableRowModel, LinearProgressWithLabel } from '../../components';
import { mergeWithIdColumn } from '../../components/table';
import * as actions from '../accommodationOfferFloors/actions';
import { columnValueChangedAction, getSelectedFloorCapacity } from '../accommodationOfferCalculations';

type AccommodationOfferFloorsContainerStateProps = {
  floors: AccommodationOfferFloorCalculationModel[];
  progress: number;
  total: number;
  selected: number;
}

type AccommodationOfferFloorsContainerDispatchProps = {
  toggleFloor: typeof actions.toggleFloor;
  columnValueChangedAction: typeof columnValueChangedAction;
};

type AccommodationOfferFloorsContainerProps = {
  showProgress: boolean;
} & AccommodationOfferFloorsContainerStateProps &
  AccommodationOfferFloorsContainerDispatchProps;

const columns = (toggleFloor: typeof actions.toggleFloor): GenericColumn<AccommodationOfferFloorCalculationModel>[] => {
  return mergeWithIdColumn<AccommodationOfferFloorCalculationModel>([
    {
      id: 'id', label: '', minWidth: 25, align: 'left', isVisible: true, isTableActions: false, customComponent: (row: AccommodationOfferFloorCalculationModel): any => {
        return <Field type="checkbox" component={MuiCheckbox} style={{ padding: '2px' }} name="id" value={row.id} checked={row.isSelected} onChange={(e: any) => {
          toggleFloor({
            floorId: row.id,
            checked: e.target.checked
          });
        }} />
      }
    },
    { id: 'name', label: 'Název patra', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
    { id: 'number', label: 'Patro', minWidth: 100, align: 'center', isVisible: true, isTableActions: false },
    { id: 'totalCapacity', label: 'Celková kapacita', minWidth: 100, align: 'center', isVisible: true, isTableActions: false },
    { id: 'freeCapacity', label: 'Volná kapacita', minWidth: 100, align: 'center', isVisible: true, isTableActions: false },
    { id: 'offerCapacity', label: 'Nabídnutá kapacita', minWidth: 100, align: 'center', isVisible: true, isTableActions: false },
    {
      id: 'id', label: 'Možná kapacita', minWidth: 100, align: 'center', isVisible: true, isTableActions: false,
      customComponent: (row: AccommodationOfferFloorCalculationModel): any => {
        return Number(row.freeCapacity - row.offerCapacity);
      }
    },
  ])
};

class AccommodationOfferFloorsContainer extends React.PureComponent<AccommodationOfferFloorsContainerProps> {
  render(): React.ReactElement {
    console.debug('AccommodationOfferFloorsContainer.render');
    const { floors, progress, toggleFloor, columnValueChangedAction, total, selected } = this.props;
    return (<Grid container direction="row" alignItems="center">
      <Typography>
      </Typography>
      <Grid>
        {this.props.showProgress && <LinearProgressWithLabel value={progress} label={`${selected} z ${total}`} />}
        <TableContainer
          rows={floors}
          rowNoClick={true}
          columns={columns(toggleFloor) as GenericColumn<BaseTableRowModel>[]}
          dataSourcePath=""
          editAction={() => {
            console.log('editAction');
          }}
          deleteAction={(id) => {
            console.log('deleteAction')
          }}
          customCellClasses={{}}
          columnValueChangedAction={columnValueChangedAction}
        />
      </Grid>
      <Box pt={3} width="100%" />

    </Grid>);
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferFloorsContainerStateProps => {
  const selected = getSelectedFloorCapacity(state);
  let total = state.accommodationOfferCalculations.offerHeader.numberOfPersons;
  if (total <= 0) {
    total = 1;
  }
  const onePercent = 100 / total;
  let progress = selected > 0 ? onePercent * selected : 0;
  if (progress > 100) {
    progress = 100;
  }

  return {
    floors: state.accommodationOfferCalculations.floors.allIds.map<AccommodationOfferFloorCalculationModel>(id => {
      return state.accommodationOfferCalculations.floors.byId[id];
    }),
    progress: progress,
    total: state.accommodationOfferCalculations.offerHeader.numberOfPersons,
    selected: selected
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferFloorsContainerDispatchProps => {
  return bindActionCreators(
    {
      toggleFloor: actions.toggleFloor,
      columnValueChangedAction: columnValueChangedAction
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferFloorsContainer);