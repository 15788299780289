import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { SignUpState, SignUpAction } from './types';
import { signUpAsync } from './actions';

export const initialState: SignUpState = {
    errorMessage: '',
    requestInProgress: false
};

const errorMessageReducer = createReducer<DeepReadonly<string>, SignUpAction>(initialState.errorMessage)
    .handleAction([signUpAsync.request, signUpAsync.success], (state, action) => initialState.errorMessage)
    .handleAction([signUpAsync.failure], (state, action) => action.payload.message);

const requestInProgressReducer = createReducer<DeepReadonly<boolean>, SignUpAction>(initialState.requestInProgress)
    .handleAction([signUpAsync.request], (state, action) => true)
    .handleAction([signUpAsync.success, signUpAsync.failure], (state, action) => initialState.requestInProgress);

export default combineReducers<SignUpState, SignUpAction>({
    errorMessage: errorMessageReducer,
    requestInProgress: requestInProgressReducer
});
