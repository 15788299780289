import React from 'react';

import Table from './table';
import { BaseTableProps } from './types';

type TableContainerProps = {
  dataSourcePath: string;
} & BaseTableProps;

const TableContainer: React.FunctionComponent<TableContainerProps> = props => {
  console.debug('TableContainer');
  const { dataSourcePath, ...othreProps } = props;

  return <Table {...othreProps} />;
};

export default TableContainer;
