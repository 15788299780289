import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import * as actions from './actions';
import { default as StickyAlert } from './stickyAlert';
import { StickyAlertModel } from './types';
import { alertAutoCloseConfig } from '../../configuration/alerts';
import { ApplicationState } from '../../store/rootReducer';
import { v4 as uuidv4 } from 'uuid';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.primary.main,
    position: 'absolute',
    top: '64px',
    right: '0',
    width: '450px',
    zIndex: 1299,

    '& .sticky-alert': {
      margin: spacing(1),
      backgroundColor: '#fff',
      color: '#611a15 !important'
    },
    '& .sticky-alert .MuiAlert-icon': {
      alignItems: 'center',
      color: '#f44336'
    }
  },
  empty: {
    display: 'none'
  }
}));

type AlertsContainerClassKey = 'root' | 'empty';

type AlertsContainerProps = {
  autoCloseConfig: typeof alertAutoCloseConfig;
  classes: ClassNameMap<AlertsContainerClassKey>;
} & AlertsContainerStateProps &
  AlertsContainerDispatchProps;

type AlertsContainerStateProps = {
  stickyAlerts: StickyAlertModel[];
};

type AlertsContainerDispatchProps = {
  removeStickyAlert(id: string): void;
  clearStickyAlerts(): void;
};

class AlertsContainer extends React.Component<AlertsContainerProps, { id: string }> {
  constructor(props: AlertsContainerProps) {
    super(props);
    const id = uuidv4();
    this.state = { id: id };
  }

  static defaultProps = {
    autoCloseConfig: alertAutoCloseConfig
  };

  componentWillUnmount(): void {
    this.clearAlerts();
  }

  removeAlert = (alertId: string): void => {
    this.props.removeStickyAlert(alertId);
  };

  clearAlerts = (): void => {
    this.props.clearStickyAlerts();
  };

  render(): React.ReactNode {
    const className = classNames(this.props.classes.root, { [this.props.classes.empty]: this.props.stickyAlerts.length === 0 });

    const alerts = this.props.stickyAlerts.map(alert => (
      <StickyAlert key={alert.id} alert={alert} onClose={this.removeAlert} autoCloseConfig={this.props.autoCloseConfig} />
    ));

    return <div className={className}>{alerts}</div>;
  }
}

const mapStateToProps = ({ stickyAlerts }: ApplicationState): AlertsContainerStateProps => {
  return {
    stickyAlerts: stickyAlerts.stickyAlerts
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AlertsContainerDispatchProps => {
  return {
    removeStickyAlert: bindActionCreators(actions.removeStickyAlert, dispatch),
    clearStickyAlerts: bindActionCreators(actions.clearStickyAlerts, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(AlertsContainer));
