import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient, ExtendedApiModelClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { PaymentModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';
import { PaymentCalculationModel } from '../interfaces/paymentCalculationModel';

export default class PaymentClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<PaymentModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'payment');
  }
 
  public async getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    throw new Error('Method not implemented.');
  }

  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<PaymentModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<PaymentModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAsync(id: number): Promise<BaseDataResponse<PaymentModel>> {
    return await this.getHttpAsync<BaseDataResponse<PaymentModel>>(`${id}`, '');
  }

  public async getAllAsync(): Promise<BaseDataResponse<PaymentModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<PaymentModel[]>>(``, '');
  }

  public async getAllByReservationAsync(id: number): Promise<BaseDataResponse<PaymentModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<PaymentModel[]>>(`${id}`, '');
  }

  public async createAsync(model: PaymentModel): Promise<BaseDataResponse<PaymentModel>> {
    return await this.postHttpAsync<BaseDataResponse<PaymentModel>, PaymentModel>('', model);
  }

  public async updateAsync(model: PaymentModel): Promise<BaseDataResponse<PaymentModel>> {
    return await this.putHttpAsync<BaseDataResponse<PaymentModel>, PaymentModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }

  public async calculateAsync(id: number): Promise<BaseDataResponse<PaymentCalculationModel>> {
    return await this.getHttpAsync<BaseDataResponse<PaymentCalculationModel>>(`calculate/${id}`, '');
  }
}
