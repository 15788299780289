import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import * as actions from './actions';
import { default as Alert } from './alert';
import { AlertModel } from './types';
import { alertAutoCloseConfig } from '../../configuration/alerts';
import { ApplicationState } from '../../store/rootReducer';
import { v4 as uuidv4 } from 'uuid';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {
    display: 'inline-flex',
    flexFlow: 'column-reverse wrap',
    maxHeight: '100%',
    position: 'fixed',
    zIndex: 1000,
    alignItems: 'flex-start',
    flexWrap: 'wrap-reverse',
    right: '0px',
    bottom: '30px',
    margin: '15px',
    '& > * + *': {
      marginBottom: spacing(1)
    }
  },
  empty: {
    display: 'none'
  }
}));

type AlertsContainerClassKey = 'root' | 'empty';

type AlertsContainerProps = {
  autoCloseConfig: typeof alertAutoCloseConfig;
  classes: ClassNameMap<AlertsContainerClassKey>;
} & AlertsContainerStateProps &
  AlertsContainerDispatchProps;

type AlertsContainerStateProps = {
  alerts: AlertModel[];
  lastContainer: string | undefined;
};

type AlertsContainerDispatchProps = {
  removeAlert(id: string): void;
  clearAlerts(): void;
  register(id: string): void;
  unRegister(id: string): void;
};

class AlertsContainer extends React.Component<AlertsContainerProps, { id: string }> {
  constructor(props: AlertsContainerProps) {
    super(props);
    const id = uuidv4();
    this.state = { id: id };
    this.props.register(id);
  }

  static defaultProps = {
    autoCloseConfig: alertAutoCloseConfig
  };

  componentWillUnmount(): void {
    this.clearAlerts();
    this.props.unRegister(this.state.id);
  }

  removeAlert = (alertId: string): void => {
    this.props.removeAlert(alertId);
  };

  clearAlerts = (): void => {
    this.props.clearAlerts();
  };

  render(): React.ReactNode {
    if (this.state.id !== this.props.lastContainer) {
      return <React.Fragment></React.Fragment>
    }

    const className = classNames(this.props.classes.root, { [this.props.classes.empty]: this.props.alerts.length === 0 });

    const alerts = this.props.alerts.map(alert => (
      <Alert key={alert.id} alert={alert} onClose={this.removeAlert} autoCloseConfig={this.props.autoCloseConfig} />
    ));

    return <div className={className}>{alerts}</div>;
  }
}

const mapStateToProps = ({ alerts }: ApplicationState): AlertsContainerStateProps => {
  return {
    alerts: alerts.alerts,
    lastContainer: alerts.containers.slice(-1)[0]
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AlertsContainerDispatchProps => {
  return {
    removeAlert: bindActionCreators(actions.removeAlert, dispatch),
    clearAlerts: bindActionCreators(actions.clearAlerts, dispatch),
    register: bindActionCreators(actions.registerAlertContainer, dispatch),
    unRegister: bindActionCreators(actions.unRegisterAlertContainer, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(AlertsContainer));
