import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SingInContainer } from '../signIn';
import { SingUpContainer } from '../signUp';
import { ROUTES } from '../../navigation';

type UnauthenticatedAppRouterProps = {};

const UnauthenticatedAppRouter: React.FunctionComponent<UnauthenticatedAppRouterProps> = (): React.ReactElement => {
  console.debug('AuthenticatedAppRouter');

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={ROUTES.SINGIN} />
      </Route>
      <Route path={ROUTES.SINGIN}>
        <SingInContainer />
      </Route>
      <Route path={ROUTES.SINGGUP}>
        <SingUpContainer />
      </Route>
      <Route
        path="*"
        render={({ location }): React.ReactElement => {
          if (location.pathname !== ROUTES.SINGOUT) {
            return <Redirect to={{ pathname: ROUTES.SINGIN, state: { referer: location } }} />;
          }

          return <Redirect to={ROUTES.SINGIN} />;
        }}
      />
    </Switch>
  );
};

export default UnauthenticatedAppRouter;
