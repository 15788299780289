import React, { memo, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik, useField } from 'formik';
import { DeepReadonly } from 'utility-types';

import {
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  RelationFilterSelectItem
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { accommodationOfferFeeValidationSchema } from './validations';
import { AccommodationOfferFeeModel, PriceListItemModel } from '../../api/interfaces';
import * as actions from './actions';
import { NormalizedListById } from '../../core';
import _ from 'lodash';

export type AccommodationOfferFeeDetailStateProps = {
  priceListsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListItemsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListItems: NormalizedListById<PriceListItemModel>;
} & BaseDetailStateProps<AccommodationOfferFeeModel>;

export type AccommodationOfferFeeDetailDispatchProps = {
  addFee: typeof actions.addFeeAction;
  selectPriceListItem: typeof actions.selectPriceListItemAction;
} & BaseDetailDispatchProps<AccommodationOfferFeeModel>;

export type AccommodationOfferFeeDetailProps = {
} & BaseDetailProps<AccommodationOfferFeeModel> & AccommodationOfferFeeDetailStateProps & AccommodationOfferFeeDetailDispatchProps;

type RenderFormProps = {
  priceListsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListItemsSelectItems: DeepReadonly<RelationFilterSelectItem[]>;
  filterId: number;
  setFilterId: any;
  addFee: typeof actions.addFeeAction;
  selectPriceListItem: typeof actions.selectPriceListItemAction;
  priceListItems: NormalizedListById<PriceListItemModel>;
} & BaseDetailContainerWrappedComponentProps<AccommodationOfferFeeModel>;

const nameof = nameofFactory<AccommodationOfferFeeModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {

  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    priceListsSelectItems,
    priceListItemsSelectItems,
    filterId,
    setFilterId,
    selectPriceListItem,
    cancelActionRequest,
    addFee,
    priceListItems
  } = props;


  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    cancelActionRequest();
  };

  return (
    <Formik<AccommodationOfferFeeModel>
      initialValues={initialValues}
      onSubmit={(values: AccommodationOfferFeeModel, { setSubmitting }): void => {
        setSubmitting(true);

        addFee(values);
      }}
      validationSchema={accommodationOfferFeeValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, setFieldValue, values } = formProps;

        return (
          <form onSubmit={handleSubmit} className='form-spacing-3' autoComplete="off">
            <Grid container className={classes.grid} spacing={3}>
              <Grid item xs={12}>
                <FormikRelationSelect
                  iconName="hotel"
                  labelText="Ceník"
                  name={nameof('priceListId')}
                  showErrorWhenNotTouched={true}
                  errorMessage={errorMessage}
                  items={priceListsSelectItems as RelationSelectItem[]}
                  onChange={(value): void => { setFilterId(value) }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikRelationSelect
                  iconName="hotel"
                  labelText="Položka ceníku"
                  name={nameof('priceListItemId')}
                  filterId={filterId}
                  showErrorWhenNotTouched={true}
                  errorMessage={errorMessage}
                  items={priceListItemsSelectItems as RelationFilterSelectItem[]}
                  onChange={(value): void => {
                    setFieldValue('price', priceListItems[value].price);
                    setFieldValue('name', priceListItems[value].name);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  iconName="inventory"
                  labelText="Množství"
                  name={nameof('amount')}
                  errorMessage={errorMessage}
                  InputProps={{
                    type: 'number'
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  iconName="usd-circle"
                  labelText="Cena za noc"
                  name={nameof('price')}
                  errorMessage={errorMessage}
                  InputProps={{
                    type: 'number',
                    onChange: (e): void => {
                      setFieldValue('price', _.round(Number(e.target.value), 4));
                      setFieldValue('totalPrice', _.round(Number(e.target.value) * values.amount, 4));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  iconName="usd-circle"
                  labelText="Cena za pobyt"
                  name={nameof('totalPrice')}
                  errorMessage={errorMessage}
                  InputProps={{
                    type: 'number',
                    onChange: (e): void => {
                      setFieldValue('totalPrice', _.round(Number(e.target.value), 4));
                      setFieldValue('price', _.round(Number(e.target.value) / values.amount, 4));
                    }
                  }}
                />
              </Grid>

            </Grid>
            <Grid container className={classes.grid}>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const AccommodationOfferFeeDetail: React.FunctionComponent<AccommodationOfferFeeDetailProps> = memo(props => {
  console.debug('AccommodationOfferFeeDetail');
  const [filterId, setFilterId] = useState(0);
  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    priceListsSelectItems,
    priceListItemsSelectItems,
    addFee,
    selectPriceListItem,
    priceListItems
  } = props;

  return (
    <BaseDetailContainer<AccommodationOfferFeeModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        priceListsSelectItems: priceListsSelectItems,
        priceListItemsSelectItems: priceListItemsSelectItems,
        filterId: filterId,
        setFilterId: setFilterId,
        addFee: addFee,
        selectPriceListItem: selectPriceListItem,
        priceListItems: priceListItems
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default AccommodationOfferFeeDetail;
