import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { default as defaultPalette } from './palette.default';
import { default as cezepPalette } from './palette.cezep';
import { CEZEPTenantDomianKey } from '../../tenant';

export const DefaultColorPaletteKey = 'default';

const ColorPalettes = {
    [DefaultColorPaletteKey]: defaultPalette,
    [CEZEPTenantDomianKey]: cezepPalette,
} as Record<string, PaletteOptions>;

export default ColorPalettes;

export { default as PaletteProvider } from './paletteProvider';
