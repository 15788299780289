export class Stack<T> {
  private stack: T[];

  constructor(stack?: T[]) {
    this.stack = stack || [];
  }

  push(item: T): void {
    this.stack.push(item);
  }

  pop(): T | undefined {
    return this.stack.pop();
  }

  clear(): void {
    this.stack = [];
  }

  clone(): Stack<T> {
    return new Stack<T>([...this.stack]);
  }

  isEmpty(): boolean {
    return this.stack.isEmpty();
  }
}
