import { all, put, takeEvery, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, cancelItemAsync, setPagination, loadDependenciesAsync } from './actions';
import apiClient from '../../api/apiClientInstance';
import { LogModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';
import { getLogsPagination, getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, registerCancelEntityRequest, registerGetEntitiesByPageRequest } from '../../core';
import { BaseDataResponse, BasePageDataResponse } from '../../api/responses';
import { TablePageChangeModel } from '../../components/table';
import pagination from '../../utils/pagination';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getLogsRequest),
    yield takeEvery(cancelItemAsync.request, cancelLogsRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

const getLogsRequest = registerGetEntitiesByPageRequest<LogModel>(
  getItemsAsync.success,
  getItemsAsync.failure,
  getItemsAsync.cancel,
  apiClient.LogsClient,
  setPagination,
  getLogsPagination
);

const cancelLogsRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_ACCOMMODATIONS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
