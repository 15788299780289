import React, { useEffect, useRef, useState } from 'react';
import Timeline, { TimelineItemBase, TimelineHeaders, SidebarHeader, DateHeader, Id } from 'react-calendar-timeline';
import { default as moment } from 'moment';
import classNames from 'classnames';

import 'react-calendar-timeline/lib/Timeline.css';
import { StyleRules, Theme, Grid, Typography } from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { TimeLineRange } from '../../features/timeLine';
import { TimeLineGroup, TimeLineItem } from './types';

const styles = (theme: Theme): StyleRules<TimeLineComponentKey> => ({
  root: {
    '& .react-calendar-timeline .rct-header-root': {
      border: 'none'
    },
    '& .react-calendar-timeline .rct-calendar-header': {
      border: 'none',
      borderLeft: `1px solid ${theme.palette.grey[400]}`
    },
    '& .react-calendar-timeline .rct-header-root$headersRoot': {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even$horizontalLine': {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd$horizontalLine': {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.grey[400]}`
    },
    '& .react-calendar-timeline .rct-vertical-lines .rct-vl$verticalLine': {
      borderLeft: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.common.transparent
    }
  },
  headersRoot: {
    // Pseudo class
  },
  calendarHeaderRoot: {
    backgroundColor: theme.palette.background.paper
  },
  dateHeaderRoot: { backgroundColor: theme.palette.background.paper },
  dateHeaderItemRoot: {
    display: 'flex'
  },
  dateHeaderRootLabel: { fontWeight: 'bold' },
  dayHeaderRoot: {
    minHeight: '48px',
    paddingTop: '3px'
  },
  dayHeaderDayRoot: {
    minHeight: '48px',
    borderTop: `1px solid ${theme.palette.grey[400]}`,
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    paddingTop: '3px'
  },
  dayHeaderDayOfWeekRoot: {},
  dayHeaderDayOfMonthRoot: {},
  dayHeaderDayOfWeekText: { color: theme.palette.grey[400] },
  dayHeaderDayOfMonthText: { fontWeight: 'bold' },
  groupHeader: {
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  groupRowRoot: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  groupRowOdd: {
    backgroundColor: theme.palette.primary.light
  },
  groupRowEven: {},
  itemRoot: {
    backgroundColor: 'yellow',
    cursor: 'pointer',
    border: '1px solid #1a6fb3',
    borderRadius: '5px',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    fontSize: 12,
    textAlign: 'center'
  },
  itemRootAlt: {
    backgroundColor: '#efefef',
    cursor: 'pointer',
    border: '1px solid #1a6fb3',
    borderRadius: '5px',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    fontSize: 12,
    textAlign: 'center'
  },
  itemContent: {
    overflow: 'auto',
    paddingLeft: 3,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  horizontalLine: {
    // Pseudo class
  },
  verticalLine: {
    // Pseudo class
  },
  selectedItemRoot: {
    backgroundColor: theme.palette.secondary.main
  },
  selectedItemContent: {}
});

type TimeLineComponentKey =
  | 'root'
  | 'headersRoot'
  | 'calendarHeaderRoot'
  | 'dateHeaderRoot'
  | 'dateHeaderItemRoot'
  | 'dateHeaderRootLabel'
  | 'groupHeader'
  | 'groupRowRoot'
  | 'groupRowOdd'
  | 'groupRowEven'
  | 'dayHeaderRoot'
  | 'dayHeaderDayRoot'
  | 'dayHeaderDayOfWeekRoot'
  | 'dayHeaderDayOfMonthRoot'
  | 'dayHeaderDayOfWeekText'
  | 'dayHeaderDayOfMonthText'
  | 'itemRoot'
  | 'itemContent'
  | 'horizontalLine'
  | 'verticalLine'
  | 'selectedItemRoot'
  | 'selectedItemContent'
  | 'itemRootAlt';

export interface TimeLineComponentProps {
  classes: ClassNameMap<TimeLineComponentKey>;
  canResize: false | true | 'left' | 'right' | 'both';

  items: TimeLineItem<moment.Moment>[];
  groups: TimeLineGroup[];
  defaultTimeStart: moment.Moment;
  defaultTimeEnd: moment.Moment;
  visibleTimeStart: moment.Moment;
  visibleTimeEnd: moment.Moment;
  daysToVisible: number;
  onTimeChanged(timeLineTimeScope: TimeLineRange): void;
  onClick(id: number): void;
  dateRange: boolean;
}

const TimeLineComponent: React.FunctionComponent<TimeLineComponentProps> = (props: TimeLineComponentProps) => {
  const timeLineRef = useRef<Timeline<TimeLineItem<moment.Moment>, TimeLineGroup>>(null);
  const maxZoom = props.daysToVisible * 24 * 60 * 60 * 1000;

  const visibleTimeStart = useState(props.visibleTimeStart);
  const visibleTimeEnd = useState(props.visibleTimeEnd);

  useEffect(() => {
    if (visibleTimeStart && visibleTimeEnd && props.dateRange) {
      timeLineRef?.current?.setState({visibleTimeStart: props.visibleTimeStart, visibleTimeEnd: props.visibleTimeEnd });
    } 
  }, [props.visibleTimeStart, props.visibleTimeEnd]);

  if (props.items.length === 0 || props.groups.length === 0) {
    return <div></div>;
  }

  return (
    <div className={props.classes.root}>
      <Timeline<TimeLineItem<moment.Moment>, TimeLineGroup>
        groups={props.groups}
        ref={timeLineRef}
        items={props.items}
        canResize={props.canResize}
        stackItems={true}
        defaultTimeStart={props.defaultTimeStart}
        defaultTimeEnd={props.defaultTimeEnd}
        maxZoom={maxZoom}
        lineHeight={45}
        itemHeightRatio={0.8}
        onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas): void => {
          const zoom = visibleTimeEnd - visibleTimeStart;
          console.debug(`Zomm ${zoom}`);
          console.debug(`MaxZomm ${maxZoom}`);

          console.debug(`visibleTimeStart ${visibleTimeStart}`);
          console.debug(`visibleTimeEnd ${visibleTimeEnd}`);

          updateScrollCanvas(visibleTimeStart, visibleTimeEnd);

          props.onTimeChanged({ start: moment(visibleTimeStart), end: moment(visibleTimeEnd) });
        }}
        onBoundsChange={(canvasTimeStart, canvasTimeEnd): void => {
          console.debug(`canvasTimeStart ${moment(canvasTimeStart)}`);
          console.debug(`canvasTimeEnd ${moment(canvasTimeEnd)}`);
        }}
        itemRenderer={({ item, itemContext, getItemProps, getResizeProps }): React.ReactNode => {
          const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
          const { style: itemStyle, ...otherItemProps } = getItemProps({
            style: {
              borderLeftWidth: itemContext.selected ? 3 : 1,
              borderRightWidth: itemContext.selected ? 3 : 1
            },
            onMouseDown: () => {
              console.log('on item click', item);

              if (typeof (item.refId) === 'number') {
                props.onClick(item.refId);
              }
            }
          });

          const itemRootClassName = classNames(props.classes.itemRoot, { [props.classes.selectedItemRoot]: itemContext.selected });
          const itemRootAltClassName = classNames(props.classes.itemRootAlt, { [props.classes.selectedItemRoot]: itemContext.selected });
          const itemContentClassName = classNames(props.classes.itemContent, { [props.classes.selectedItemContent]: itemContext.selected });
          const { left, top, zIndex, position, width, height, lineHeight } = itemStyle;
          const itemClassName = item.type > 1 ? itemRootClassName : itemRootAltClassName;

          // Fixing top position
          let topPx = top;
          if (top === "nullpx") {
            topPx = "4px";
          }

          console.debug(item);
          return (
            <div
              {...otherItemProps}
              style={{ left: left, top: topPx, zIndex: zIndex, position: position, width: width, height: height, lineHeight: lineHeight,
                display: item.refId !== -1 ? "block" : "none" }}
              className={itemClassName}
            >
              {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
              <div
                style={{
                  height: itemContext.dimensions.height
                }}
                className={itemContentClassName}
              >
                {item.title}
              </div>

              {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </div>
          );
        }}
        groupRenderer={({ group }): React.ReactNode => {
          const groupClassName = classNames(props.classes.groupRowRoot, {
            [props.classes.groupRowOdd]: !group.isEvenRow,
            [props.classes.groupRowEven]: !group.isEvenRow
          });

          return (
            <Grid container className={groupClassName} direction="column" justify="center">
              <Typography>{group.title}</Typography>
            </Grid>
          );
        }}
        onItemResize={(itemId: Id, endTimeOrStartTime: number, edge: 'left' | 'right'): void => {
          console.debug(itemId);
        }}
        horizontalLineClassNamesForGroup={(group): string[] => [props.classes.horizontalLine]}
        verticalLineClassNamesForTime={(lineProps): string[] => [props.classes.verticalLine]}
      >
        <TimelineHeaders
          className={props.classes.headersRoot}
          calendarHeaderClassName={props.classes.calendarHeaderRoot}
        >
          <SidebarHeader>
            {({ getRootProps }): React.ReactElement => {
              return <div {...getRootProps()} className={props.classes.groupHeader}></div>;
            }}
          </SidebarHeader>
          <DateHeader
            unit="primaryHeader"
            className={props.classes.dateHeaderRoot}
            intervalRenderer={(intervalRendererProps): React.ReactNode => {
              if (!intervalRendererProps) {
                return null;
              }

              const { getIntervalProps, intervalContext } = intervalRendererProps;
              console.warn(intervalContext);
              console.warn(getIntervalProps());

              const intervalProps = {...getIntervalProps(), onClick: undefined };
              return (
                <div {...intervalProps}>
                  <div>
                    <Typography variant="subtitle1" className={props.classes.dateHeaderRootLabel}>
                      {intervalContext.intervalText}
                    </Typography>
                  </div>
                </div>
              );
            }}
          ></DateHeader>
          {/*  <DateHeader unit="day" /> */}
          <DateHeader<{ someData: string }>
            unit="day"
            className={props.classes.dayHeaderRoot}
            /* labelFormat="MM/DD" */
            intervalRenderer={(intervalRendererProps): React.ReactNode => {
              if (!intervalRendererProps) {
                return null;
              }

              const { getIntervalProps, intervalContext } = intervalRendererProps;
              const day = moment(intervalContext.interval.startTime);

              return (
                <Grid
                  container
                  {...getIntervalProps()}
                  className={props.classes.dayHeaderDayRoot}
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} className={props.classes.dayHeaderDayOfWeekRoot}>
                    <Typography variant="subtitle1" className={props.classes.dayHeaderDayOfWeekText}>
                      {day.format('ddd').capitalize()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={props.classes.dayHeaderDayOfMonthRoot}>
                    <Typography variant="button" className={props.classes.dayHeaderDayOfMonthText}>
                      {day.format('D')}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }}
          />
        </TimelineHeaders>
      </Timeline>
    </div>
  );
};

export default withStyles(styles)(TimeLineComponent);
