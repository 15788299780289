import * as Yup from 'yup';

export const myCompanyValidationSchema = Yup.object().shape({
  stateId: Yup.number()
    .required('Stát musí být vybrán')
    .min(1, 'Stát musí být vybrán'),
  numberOfDueDays: Yup.number()
    .required('Splatnost musí být zadána')
    .min(0, 'Splatnost musí být zadána'),
});
