import * as Yup from 'yup';

export const userValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Jméno je povinné'),
  lastName: Yup.string().required('Příjmení je povinné')
});

export const inviteUserValidationSchema = Yup.object().shape({
    email: Yup.string().required('Email je povinný')
});
