import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem, TablePaginationModel } from '../../components';
import { NormalizedListById } from '../../core';
import { AccommodationOfferModel, ReservationPaymentStatusEnum } from '../../api/interfaces';
import { TableSelectionModel } from '../../components/table';

export const getAccommodationOffersById = (state: ApplicationState): NormalizedListById<AccommodationOfferModel> => {
  return state.accommodationOffers.itemList.byId;
};

export const getAccommodationOffersAllIds = (state: ApplicationState): number[] => {
  return state.accommodationOffers.itemList.allIds;
};

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.accommodationOffers.itemList.loaded;
};

export const getAccommodationOffersPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.accommodationOffers.itemList.page ?? 0,
    skip: state.accommodationOffers.itemList.skip ?? 0,
    take: state.accommodationOffers.itemList.take ?? 100,
    total: state.accommodationOffers.itemList.total ?? 0,
    filter: state.accommodationOffers.itemList.filter ?? ""
  } as TablePaginationModel;
};

export const getAccommodationOfferStateSelectItems = (): RelationSelectItem[] => {
  return [{ text: 'Nabídka', value: 1 }, { text: 'Rezervace', value: 2 }] as RelationSelectItem[];
};

export const getRoute = (state: ApplicationState): string => {
  return state.router.location.pathname;
};

export const getAccommodationOfferPaymentStatusSelectItems = (): RelationSelectItem[] => {
  return [
    { text: 'Nezaplaceno', value: ReservationPaymentStatusEnum.No },
    { text: 'Částečně zaplaceno', value: ReservationPaymentStatusEnum.NotPaidTotally },
    { text: 'Přeplaceno', value: ReservationPaymentStatusEnum.OverPaid },
    { text: 'Zaplaceno', value: ReservationPaymentStatusEnum.Paid }
  ] as RelationSelectItem[];
};

export const getAccommodationOffersSelection = (state: ApplicationState): TableSelectionModel => {
  return {
    ids: state.accommodationOffers.itemList.selection?.ids ?? [],
    isOpen: state.accommodationOffers.itemList.selection?.isOpen ?? false,
    items: state.accommodationOffers.itemList.selection?.items,
  } as TableSelectionModel;
};
