import { createAction, createAsyncAction } from 'typesafe-actions';
import { AccommodationModel } from '../../api/interfaces';
import {
    createGetItemsAsyncAction,
    createNewItemAsyncAction,
    createEditItemAsyncAction,
    createDeleteItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createLoadDependenciesAsyncAction
} from '../../core';

const modelName = 'accommodations';

export const getItemsAsync = createGetItemsAsyncAction<AccommodationModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<AccommodationModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);
