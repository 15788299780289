import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';

import { nameofFactory } from '../../utils/nameofFactory';
import { userValidationSchema } from './validations';
import { UserModel } from '../../api/interfaces';
import {
  FormikInput,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';

export type UserDetailStateProps = {
} & BaseDetailStateProps<UserModel>;

export type UserDetailDispatchProps = {
} & BaseDetailDispatchProps<UserModel>;

export type UserDetailProps = {} & BaseDetailProps<UserModel> & UserDetailStateProps & UserDetailDispatchProps;

type RenderFormProps = {
} & BaseDetailContainerWrappedComponentProps<UserModel>;

const nameof = nameofFactory<UserModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
  } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<UserModel>
      initialValues={initialValues}
      onSubmit={(values: UserModel, { setSubmitting }): void => {
        console.log("Submit");
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={userValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid} spacing={3}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="address-card"
                    labelText="Jméno"
                    name={nameof('firstName')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="address-card"
                    labelText="Příjmení"
                    name={nameof('lastName')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="address-card"
                    labelText="Heslo"
                    name={nameof('password')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'password'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const UserDetail: React.FunctionComponent<UserDetailProps> = memo(props => {
  console.debug('UserDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<UserModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default UserDetail;
