import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import VirtualSelect, { VirtualComponentSelectProps, VirtualFilterSelectItem } from './virtualSelect';
import { VirtualQueryModel, VirtualScrollModel, VirtualSearchModel, VirtualSelectItem } from './types';

export type VirtualProps = { defaultValue: number } & VirtualComponentSelectProps & VirtualStateProps & VirtualDispatchProps;

export type VirtualStateProps = {
  items: VirtualSelectItem[] | VirtualFilterSelectItem[];
  total: number;
  hasMore: boolean;
};

export type VirtualDispatchProps = {
  setVirtualValue: typeof actions.setVirtualValue;
  setVirtualSearch: typeof actions.setVirtualSearch;
  setVirtualScroll: typeof actions.setVirtualScroll;
  fetch: typeof actions.getVirtualData.request;
};

class VirtualContainer extends React.PureComponent<VirtualProps> {
  static defaultProps = {};

  constructor(props: VirtualProps) {
    super(props);

    props.fetch({ page: 0, search: '', type: props.type, id: props.value ?? 0, filter: `${props.filter}` } as VirtualQueryModel);
  }

  onChange = (value: number): void => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }

    this.props.setVirtualValue({ value: value, type: this.props.type });
  }

  render(): React.ReactElement {
    const { props } = this;

    return (
      <React.Fragment>
        <VirtualSelect
          items={props.items}
          value={props.value}
          iconName={props.iconName}
          labelText={props.labelText}
          helperText={props.helperText}
          error={props.error}
          classes={{ ...props.classes }}
          disabled={props.disabled}
          createComponent={props.createComponent}
          InputProps={{
            ...props.InputProps
          }}
          onChange={this.onChange}
          search={(value: string): void => {
            props.setVirtualSearch({ search: value, type: props.type, filter: `${props.filter}` } as VirtualSearchModel);
          }}
          load={(page: number): void => {
            props.setVirtualScroll({ page: page, type: props.type, filter: `${props.filter}` } as VirtualScrollModel);
          }}
          filter={props.filter}
          type={props.type}
          total={props.total}
          hasMore={props.hasMore}
          onRemoveRelation={props.onRemoveRelation}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ virtual }: ApplicationState, ownProps: any): VirtualStateProps => {
  return {
    items: virtual.virtuals[ownProps.type]?.items ?? [],
    total: virtual.virtuals[ownProps.type]?.total ?? 10,
    hasMore: virtual.virtuals[ownProps.type]?.items.length < virtual.virtuals[ownProps.type]?.total
  };
};

const mapDispatchToProps = (dispatch: any): VirtualDispatchProps => {
  return bindActionCreators(
    {
      setVirtualValue: actions.setVirtualValue,
      setVirtualSearch: actions.setVirtualSearch,
      setVirtualScroll: actions.setVirtualScroll,
      fetch: actions.getVirtualData.request
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualContainer);
