import React, { ReactNode, RefObject } from 'react';
import {
  Theme,
  InputAdornment,
  Input as MuiInput,
  OutlinedInput as MuiOutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  InputProps as MuiInputProps,
} from '@material-ui/core';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { IconName, Icon } from '../../icons';
import { BaseComponentWithModalSupport } from '../baseDetail';
import { withOpenModal, WithOpenModalProps } from '../modalDialogManager';
import { ROUTES } from '../../navigation';
import { Condition } from '../conditions';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.background.paper,
    '& fieldset': {
      borderColor: palette.primary.main
    }
  },
  labelFormControl: {
    transform: 'translate(44px, 24px) scale(1)'
  },
  iconRoot: {
    width: '1.3rem',
    color: palette.text.secondary
  },
  inputInput: {
    fontWeight: 'bold'
  },
  selectOption: {
    '&[aria-selected="true"]': {
      color: palette.common.white
    },
    '&[data-focus="true"]': {
      color: palette.common.white,
      backgroundColor: palette.primary.main
    }
  },
  selectIcon: { color: palette.primary.main },
  selectOutlined: {
    backgroundColor: palette.background.paper,
    paddingBottom: '13px',
    paddingTop: '13px'
  },
  inputStartAdornment: {
    marginRight: spacing(2)
  },
  inputEndAdornment: {
    /*  marginRight: spacing(1.75), */
    cursor: 'pointer'
  }
}));

export type RelationSelectClassKey =
  | 'root'
  | 'labelFormControl'
  | 'iconRoot'
  | 'selectOption'
  | 'selectIcon'
  | 'selectOutlined'
  | 'inputInput'
  | 'inputStartAdornment'
  | 'inputEndAdornment';

export type RelationSelectProps = {
  items: RelationSelectItem[] | RelationFilterSelectItem[];
  getItemDisabled?: (option: RelationSelectItem) => boolean;
  value?: RelationSelectItem;
  errorMessage?: string;
  disabled?: boolean;
  iconName?: IconName;
  labelText?: string;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  classes: Partial<ClassNameMap<RelationSelectClassKey>>;
  helperText?: ReactNode;
  error?: boolean;
  disableUnselect?: boolean;
  variant?: 'standard' | 'outlined';
  hideErrorComponent?: boolean;
  createComponent?: {
    iconName?: IconName;
    component: React.ComponentType<BaseComponentWithModalSupport>;
    route: ROUTES;
  };
  onRemoveRelation?: () => void;
  onChange?: (value: number) => void;
  filterId?: number;
} & WithOpenModalProps;

export type RelationSelectItem = {
  value: number;
  text: string;
};

export type RelationFilterSelectItem = { filterId: number; isDefault: boolean } & RelationSelectItem;

type RelationSelectState = {
  shrink: boolean;
  open: boolean;
};

class RelationSelect extends React.PureComponent<RelationSelectProps, RelationSelectState> {
  selectRef: RefObject<any>;

  constructor(props: RelationSelectProps) {
    super(props);

    const hasValue = !!props.value;
    this.state = { open: false, shrink: !!hasValue };
    this.selectRef = React.createRef<any>();
  }

  shrinkLabel = (): void => {
    console.debug('RelationSelect.shrinkLabel');

    this.setState({ shrink: true });
  };

  unShrinkLabel = (): void => {
    const hasValue = !!this.props.value;
    this.setState({ shrink: hasValue });
  };

  openModal = (event: React.MouseEvent<HTMLSpanElement>): void => {
    event.preventDefault();
    if (this.props.disabled) {
      return;
    }

    if (this.props.createComponent) {
      this.props.openModal(this.props.createComponent.component, this.props.createComponent.route);
    }
  };

  handleClose = (): void => {
    if (this.props.disabled) {
      return;
    }

    this.setState({ open: false });
  };

  handleOpen = (): void => {
    if (this.props.disabled) {
      return;
    }
    console.debug('RelationSelect.handleOpen');

    this.setState({ open: true });
  };

  handleMouseDown = (event: React.MouseEvent<HTMLInputElement>): void => {
    if (this.props.disabled) {
      return;
    }

    console.debug('RelationSelect.dumyMouseDown');
    event.preventDefault();
  };

  handleRemoveRelation = (event: React.MouseEvent<HTMLInputElement>): void => {
    if (this.props.disabled) {
      return;
    }

    console.debug('RelationSelect.handleRemoveRelation');
    event.preventDefault();

    if (this.props.onRemoveRelation) {
      this.props.onRemoveRelation();
    } else {
      console.error(`Je povolena funkce zrušení relace, ale není nastavena metoda na nastavení prázdné hodnoty - "props.onRemoveRelation"`);
    }
  };

  onChangeHandler = (event: React.ChangeEvent<{}>, value: RelationSelectItem): void => {
    console.debug('RelationSelect.onChangeHandler');
    event.preventDefault();

    if (this.props.onChange) {
      this.props.onChange(value.value);
    }
  };

  getItems = (): RelationSelectItem[] => {
    if (this.props.filterId) {
      return (this.props.items as RelationFilterSelectItem[]).filter(i => i.filterId === this.props.filterId);
    }

    return this.props.items;
  }

  render(): React.ReactElement {
    console.debug('RelationSelect.render');

    const openModalIconName = this.props.createComponent
      ? this.props.createComponent.iconName
        ? this.props.createComponent.iconName
        : 'plus'
      : 'plus';
    const inputLabelComponent = this.props.labelText ? (
      <InputLabel
        shrink={this.state.shrink}
        classes={{
          formControl: !this.state.shrink ? this.props.classes.labelFormControl : undefined
        }}
        {...this.props.InputLabelProps}
      >
        {this.props.labelText}
      </InputLabel>
    ) : null;
    const openIcon = (
      <Icon iconName="chevron-down" IconProps={{ className: this.props.classes.selectIcon, onClick: this.handleOpen }} fixedWidth />
    );
    const openModalIcon = (
      <Icon iconName={openModalIconName} IconProps={{ className: this.props.classes.selectIcon, onClick: this.openModal }} fixedWidth />
    );
    const removeRelationIcon = (
      <Icon iconName="times" IconProps={{ className: this.props.classes.selectIcon, onClick: this.handleRemoveRelation }} fixedWidth />
    );

    const startAdormentComponent = this.props.iconName ? (
      <InputAdornment position="start" classes={{ root: this.props.classes.inputStartAdornment }}>
        <Icon iconName={this.props.iconName} IconProps={{ className: this.props.classes.iconRoot }} fixedWidth />
      </InputAdornment>
    ) : null;

    const endAdormentComponent = (
      <InputAdornment position="end" classes={{ root: this.props.classes.inputEndAdornment }} onMouseDown={this.handleMouseDown}>
        <Condition is={!this.props.disableUnselect}>{removeRelationIcon}</Condition>
        <Condition is={!!this.props.createComponent}>{openModalIcon}</Condition>
        {openIcon}
      </InputAdornment>
    );

    const errorComponent = !this.props.hideErrorComponent ? (
      <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>
    ) : null;

    return (
      <React.Fragment>
        <FormControl fullWidth classes={{ root: this.props.classes.root }}>
          <Autocomplete
            id="checkboxes-tags-demo"
            open={this.state.open}
            value={this.props.value}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            onFocus={this.shrinkLabel}
            onBlur={this.unShrinkLabel}
            onChange={this.onChangeHandler}
            disabled={this.props.disabled}
            forcePopupIcon={false}
            disableClearable={true}
            options={this.getItems()}
            getOptionDisabled={this.props.getItemDisabled}
            getOptionLabel={(option: RelationSelectItem): string => option.text}
            getOptionSelected={(option: RelationSelectItem, value: RelationSelectItem): boolean => option === value}
            renderOption={(option: any): React.ReactNode => (
              /*  <MenuItem key={`${name}-${option.value}`} value={option.value}> */
              <React.Fragment> {option.text}</React.Fragment>
              /*  </MenuItem> */
            )}
            renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => {
              const { InputProps, InputLabelProps, ...otherParams } = params;
              const composedProps = {
                ...otherParams,
                classes: {
                  root: this.props.classes.inputInput,
                  input: this.props.classes.inputInput
                },
                startAdornment: startAdormentComponent,
                endAdornment: endAdormentComponent,
                ref: InputProps.ref
              };
              return (
                <React.Fragment>
                  {inputLabelComponent}
                  {this.props.variant === 'outlined' ? <MuiOutlinedInput {...composedProps} /> : <MuiInput {...composedProps} />}
                </React.Fragment>
              );
            }}
            classes={{ option: this.props.classes.selectOption }}
          />
          {errorComponent}
        </FormControl>
      </React.Fragment>
    );
  }
}

export default withOpenModal(withStylesFunction(RelationSelect));
