import React, { memo, useRef } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Box,
  withStyles,
  Typography,
  Input,
  Checkbox,
} from '@material-ui/core';
import { TableCellProps } from '@material-ui/core/TableCell';
import { WithStyles, StyleRules, ClassNameMap } from '@material-ui/core/styles/withStyles';
import { DeepReadonly } from 'utility-types';

import { Icon } from '../../icons';
import {
  BaseTableRowModel,
  GenericColumn,
  TableProps,
  TableRelations,
  ColumnRelation,
  EditableColumn,
  BaseTableColumnId,
  ColumnValueChangedEvent,
  CustomTableRowClassKey,
  TableRowClassKey
} from './types';
import { relationConfiguration } from '../../configuration/relations';
import { ConfirmDialogContainer } from '..';
import TablePagination from './tablePagination';
import { TablePaginationModel } from '.';
import TableHeader from './tableHeader';

const useStyles = makeStyles(
  (theme: Theme): StyleRules<TableRowClassKey> =>
    createStyles({
      root: {},
      listRoot: {
        borderRadius: '0px',
        boxShadow: 'none'
      },
      table: {},
      tableHeadRoot: {
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        borderWidth: '1px',
        borderStyle: 'solid'
      },
      tableCellBody: {
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing(1),
      },
      tableRowAnyItem: { padding: '16px', paddingLeft: theme.spacing(1) },
      actionIconRoot: {
        '&:not(:last-of-type)': {
          marginRight: theme.spacing(1)
        }
      },
      actionIcon: {
        fontSize: '1.2rem'
      }
    })
);

const useTableCellHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      padding: theme.spacing(1)
    }
  })
);

const useExtendedTableCellHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      textTransform: 'uppercase',
      fontWeight: 'bold'
    }
  })
);

const extendedTableCellStyles = (theme: Theme): StyleRules<ExtendedTableCellClassKey, ExtendedTableCellStyleProps> =>
  createStyles({
    root: { padding: theme.spacing(1) },
    wrapper: { display: 'flex' },
    body: {
    },
    bodyActive: {
      color: theme.palette.text.primary
    },
    indicatorActive: {
      width: '4px',
      backgroundColor: theme.palette.primary.main
    },
    indicatorInactive: {
      width: '4px',
      backgroundColor: 'transparent'
    },
    footer: {

    }
  });

const useTableCellStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { padding: theme.spacing(1), borderColor: 'rgba(224, 224, 224, 1)', borderWidth: '1px', borderStyle: 'solid', position: 'relative' }
  })
);

type ExtendedTableCellClassKey = 'root' | 'wrapper' | 'body' | 'bodyActive' | 'indicatorActive' | 'indicatorInactive';
type ExtendedTableCellStyleProps = {};
type ExtendedTableCellProps = {
  selected?: boolean;
  children: React.ReactNode;
} & TableCellProps &
  ExtendedTableCellStyleProps &
  WithStyles<ExtendedTableCellClassKey>;

const ExtendedTableCell: React.FunctionComponent<ExtendedTableCellProps> = props => {
  console.debug('ExtendedTableCell');

  const { selected, children, classes, ...muiTableCellProps } = props;
  const { body, bodyActive, wrapper, indicatorActive, indicatorInactive, ...muiTableCellClasses } = classes;

  return (
    <TableCell className={classes.root} {...muiTableCellProps} classes={{ ...muiTableCellClasses }}>
      <div className={classes.wrapper}>
        <Divider className={selected ? classes.indicatorActive : classes.indicatorInactive} orientation="vertical" />
        <span className={selected ? classes.bodyActive : classes.body}>{children}</span>
      </div>
    </TableCell>
  );
};

type RelationTableCellProps = {
  row: BaseTableRowModel;
  columnId: BaseTableColumnId;
  columnRelation: ColumnRelation;
  relations: TableRelations;
} & TableCellProps;

const RelationTableCell = (props: RelationTableCellProps): React.ReactElement<RelationTableCellProps> => {
  console.debug('RelationTableCell');

  const { classes, row, columnRelation, relations, columnId, ...muiTableCellProps } = props;
  const relationKey: string = columnRelation.key;
  const existRelation = !!relations[relationKey];
  const relatedEntities = relations[relationKey];
  const areItemsLoaded = Object.keys(relatedEntities.items).some(item => item);
  const relationItemId: number = row[columnId];

  let component = (
    <TableCell {...muiTableCellProps} classes={classes}>
      {'Relace nenastavena'}
    </TableCell>
  );

  if (existRelation && areItemsLoaded && relationItemId > relationConfiguration.relationNotSetId) {
    const value = relatedEntities.items[relationItemId][columnRelation.displayProperty];

    component = (
      <TableCell {...muiTableCellProps} classes={classes}>
        {value}
      </TableCell>
    );
  }

  return component;
};

type CheckInputTableCellProps = {
  row: BaseTableRowModel;
  columnId: BaseTableColumnId;
  editable: EditableColumn;
  stateId: string;
  onColumnValueChanged: (event: ColumnValueChangedEvent) => void;
} & TableCellProps;

const CheckInputTableCell = (props: CheckInputTableCellProps): React.ReactElement<CheckInputTableCellProps> => {
  console.debug('CheckInputTableCell');

  const { classes, row, editable, columnId, stateId, onColumnValueChanged, ...muiTableCellProps } = props;
  const value = row[columnId] ? row[columnId] : false;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    onColumnValueChanged({ newValue: event.target.value, rowId: row.id, columnId: columnId, stateId: stateId });
  };

  return (
    <TableCell {...muiTableCellProps} classes={classes}>
      <Checkbox onChange={handleChange} value={value} />
    </TableCell>
  );
};

type NumberInputTableCellProps = {
  row: BaseTableRowModel;
  columnId: BaseTableColumnId;
  editable: EditableColumn;
  stateId: string;
  onColumnValueChanged: (event: ColumnValueChangedEvent) => void;
  textAlign?: 'left' | 'center' | 'right';
} & TableCellProps;

const NumberInputTableCell = (props: NumberInputTableCellProps): React.ReactElement<NumberInputTableCellProps> => {
  console.debug('NumberInputTableCell');

  const { classes, row, editable, columnId, stateId, onColumnValueChanged, ...muiTableCellProps } = props;
  const value = row[columnId] ? row[columnId] : 0;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    onColumnValueChanged({ newValue: event.target.value, rowId: row.id, columnId: columnId, stateId: stateId });
  };

  return (
    <TableCell {...muiTableCellProps} classes={classes}>
      <Input type="number" onChange={handleChange} value={value} inputProps={{ style: { textAlign: props.textAlign } }} />
    </TableCell>
  );
};

type StringInputTableCellProps = {
  row: BaseTableRowModel;
  columnId: BaseTableColumnId;
  editable: EditableColumn;
  stateId: string;
  onColumnValueChanged: (event: ColumnValueChangedEvent) => void;
} & TableCellProps;

const StringInputTableCell = (props: StringInputTableCellProps): React.ReactElement<StringInputTableCellProps> => {
  console.debug('StringInputTableCell');

  const { classes, row, editable, columnId, stateId, onColumnValueChanged, ...muiTableCellProps } = props;
  const value = row[columnId] ? row[columnId] : '';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    onColumnValueChanged({ newValue: event.target.value, rowId: row.id, columnId: columnId, stateId: stateId });
  };

  return (
    <TableCell {...muiTableCellProps} classes={classes}>
      <Input type="text" onChange={handleChange} value={value} />
    </TableCell>
  );
};

const ExtendedTableCellWithStyles = withStyles(extendedTableCellStyles)(ExtendedTableCell);

const NoRows = (rows: DeepReadonly<BaseTableRowModel[]>): boolean => rows.length === 0;

const getTableRows = (
  rows: DeepReadonly<BaseTableRowModel[]>,
  columns: DeepReadonly<GenericColumn<BaseTableRowModel>[]>,
  rowNoClick: boolean,
  stateId: string,
  relations: TableRelations,
  selected: number,
  classes: ClassNameMap<TableRowClassKey>,
  tableCellClasses: Partial<ClassNameMap<TableRowClassKey>>,
  handleClick: (event: React.MouseEvent<unknown>, id: number) => void,
  handleActionEdit: (event: React.MouseEvent<unknown>, id: number) => void,
  handleActionDelete: (event: React.MouseEvent<unknown>, id: number) => void,
  onColumnValueChanged: (event: ColumnValueChangedEvent) => void,
  confirmDialog: React.RefObject<ConfirmDialogContainer>,
  customCellClasses?: Partial<ClassNameMap<CustomTableRowClassKey>>,
): React.ReactElement[] | React.ReactElement => {
  if (NoRows(rows)) {
    return (
      <TableRow key="emptyRow" className={classes.tableRowAnyItem}>
        <TableCell classes={{ body: classes.tableCellBody }} align="left" variant="head" colSpan={columns.length}>
          <Typography align="center">Nejsou k dispozici žádné záznamy.</Typography>
        </TableCell>
      </TableRow>
    );
  }

  return rows.map(row => {
    const tableRowProps = {
      hover: true,
      onClick: (event: React.MouseEvent<unknown>): void => { console.debug("Click"); }
    };

    return (
      <TableRow key={row.id} {...tableRowProps}>
        {columns.map((column, index) => {
          if (!column.isVisible) {
            return null;
          }

          if (column.id === 'tableActions') {
            return (
              <TableCell key={column.id} classes={{ ...tableCellClasses, body: classes.tableCellBody }} align="left" variant="body" >
                <Box display="flex" flexDirection="row" alignItems="center">
                  {!column.editComponent && (column.isEditable !== false) && <Icon
                    iconName="edit"
                    className={classes.actionIcon}
                    IconProps={{
                      classes: { root: classes.actionIconRoot },
                      color: 'secondary',
                      title: 'Editovat',
                      style: { cursor: 'pointer' },
                      onClick: (event): void => handleActionEdit(event, row.id)
                    }}
                  />}
                  {column.editComponent && column.editComponent(row.id, row)}
                  {((column.deletable !== undefined && column.deletable) || (column.deletable === undefined)) &&
                    <Icon
                      iconName="trash"
                      className={classes.actionIcon}
                      IconProps={{
                        classes: { root: classes.actionIconRoot },
                        color: 'secondary',
                        title: 'Odstranit',
                        style: { cursor: 'pointer' },
                        onClick: (event): void => {
                          event.preventDefault();
                          if (confirmDialog) {
                            confirmDialog?.current?.open(row.id);
                          } else {
                            handleActionDelete(event, row.id)
                          }
                        }
                      }}
                    />}
                  {column.actionComponent && column.actionComponent(row.id, row, classes)}
                </Box>
              </TableCell>
            );
          } else if (column.relation) {
            return (
              <RelationTableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                classes={{ ...tableCellClasses }}
                row={row}
                columnId={column.id}
                columnRelation={column.relation}
                relations={relations}
              />
            );
          } else if (column.editable) {
            if (column.editable.type === 'number') {
              return (
                <NumberInputTableCell
                  key={column.id}
                  align={column.align}
                  textAlign={column.textAlign}
                  style={{ minWidth: column.minWidth }}
                  classes={{ ...tableCellClasses }}
                  row={row}
                  stateId={stateId}
                  columnId={column.id}
                  editable={column.editable}
                  onColumnValueChanged={onColumnValueChanged}
                />
              );
            } else if (column.editable.type === 'check') {
              return (<CheckInputTableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                classes={{ ...tableCellClasses }}
                row={row}
                stateId={stateId}
                columnId={column.id}
                editable={column.editable}
                onColumnValueChanged={onColumnValueChanged}
              />);
            } else {
              return (
                <StringInputTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  classes={{ ...tableCellClasses }}
                  row={row}
                  stateId={stateId}
                  columnId={column.id}
                  editable={column.editable}
                  onColumnValueChanged={onColumnValueChanged}
                />
              )
            }
          } else if (column.customComponent) {
            return <TableCell
              classes={{ ...tableCellClasses }}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >{column.customComponent(row, onColumnValueChanged, column.id, stateId)}</TableCell>
          }

          const value = row[column.id];
          if (index === 0) {
            return (
              <ExtendedTableCellWithStyles
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                classes={{ ...tableCellClasses, ...customCellClasses }}
                onClick={(event): void => {
                  if (!rowNoClick) {
                    handleClick(event, row.id)
                  }
                }}>
                {value}
              </ExtendedTableCellWithStyles>
            );
          }

          return <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth }}
            classes={{ ...tableCellClasses, ...customCellClasses }}
            onClick={(event): void => {
              if (!rowNoClick) {
                handleClick(event, row.id)
              }
            }}>
            {value}
          </TableCell>
        })}
      </TableRow>
    );
  });
}

const Table: React.FunctionComponent<TableProps> = memo(props => {
  console.debug('Table');
  const classes = useStyles();
  const tableCellHeaderClasses = useTableCellHeaderStyles();
  const tableCellClasses = useTableCellStyles();
  const extendedTableCellHeaderClasses = useExtendedTableCellHeaderStyles();
  const customCellClasses = props.customCellClasses;
  const defaultSelectedValue = 0;
  const [selected, setSelected] = React.useState<number>(defaultSelectedValue);
  const confirmDialog = useRef<ConfirmDialogContainer>(null);
  const handleClick = (event: React.MouseEvent<unknown>, id: number): void => {
    event.preventDefault();
    setSelected(id);

    props.editAction(id);
  };

  const handleActionEdit = (event: React.MouseEvent<unknown>, id: number): void => {
    event.preventDefault();
    console.debug('handleActionEdit');

    props.editAction(id);
  };

  const handleActionDelete = (event: React.MouseEvent<unknown>, id: number): void => {
    event.preventDefault();
    console.debug('handleActionDelete');

    props.deleteAction(id);
  };

  const onColumnValueChanged = (event: ColumnValueChangedEvent): void => {
    console.debug('onColumnValueChanged');

    if (props.columnValueChangedAction !== undefined) {
      props.columnValueChangedAction(event);
    }
  };

  const tableRelations = props.relations ? props.relations : {};
  const rows = getTableRows(
    props.rows,
    props.columns,
    props.rowNoClick ?? false,
    props.stateId ?? '',
    tableRelations,
    selected,
    classes,
    tableCellClasses,
    handleClick,
    handleActionEdit,
    handleActionDelete,
    onColumnValueChanged,
    confirmDialog,
    customCellClasses
  );

  return (
    <MuiTable className={classes.table} aria-label="table">
      <TableHead classes={{ root: classes.tableHeadRoot }}>
        <ConfirmDialogContainer
          ref={confirmDialog}
          approveAction={handleActionDelete}
          text="Opravdu si přejete smazat záznam"
        />
        {(props.filter || props.selectionHeader || props.toolbar) &&
          <TableHeader load={props.loadAction}
            colspan={props.columns.length}
            filter={props.filter}
            selectionHeader={props.selectionHeader}
            toolBar={props.toolbar}
          />}
        <TableRow key="rowHeader">
          {props.columns.map((column, index) => {
            if (!column.isVisible) {
              return null;
            }

            if (index === 0) {
              return (
                <ExtendedTableCellWithStyles
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  classes={{ ...extendedTableCellHeaderClasses }}
                >
                  {column.label}
                </ExtendedTableCellWithStyles>
              );
            }

            return (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
                classes={{ ...tableCellHeaderClasses }}
              >
                {column.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
      {props.paging && <TablePagination
        pagination={props.pagination ?? { skip: 0, take: 100, total: 0, page: 0 } as TablePaginationModel}
        loadAction={props.loadAction}
      />}
    </MuiTable>
  );
});

export default Table;
