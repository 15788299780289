import React, { useState, ReactNode } from 'react';
import { Theme, Switch as MuiSwitch, FormControl, FormHelperText } from '@material-ui/core';
import { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {

  },
  header: {
    fontSize: '1.2rem'
  },
  body: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  status: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
}));

export type SwitchClassKey = 'root' | 'body' | 'header' | 'status';

export type SwitchProps = {
  classes: Partial<ClassNameMap<SwitchClassKey>>;
  labelText: string;
  helperText: ReactNode;
  error: boolean;
  switchProps?: MuiSwitchProps;
};

const Switch: React.FunctionComponent<SwitchProps> = props => {
  const [enabled, setEnabled] = useState<boolean>(props.switchProps?.value as boolean);

  const renderStatus = () => {
    return enabled ? 'Aktivní': 'Neaktivní';
  }

  return (
    <React.Fragment>
      <FormControl fullWidth classes={{ root: props.classes.root }}>
        <div className={props.classes.header}>{props.labelText}</div>
        <div className={props.classes.body}>
          <span className={props.classes.status}>{renderStatus()}</span>
          <MuiSwitch
            {...{ ...props.switchProps, error: props.error }}
            checked={enabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
              setEnabled(checked);

              if (props.switchProps?.onChange) {
                props.switchProps?.onChange(event, checked);
              }
            }}
          />
        </div>
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      </FormControl>
    </React.Fragment>
  );
};

export default withStylesFunction(Switch);