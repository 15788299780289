import { createAction } from 'typesafe-actions';
import { AlertModel, AlertMessage } from './types';

export const addAlert = createAction('alert/ADD_ALERT')<AlertModel>();

export const removeAlert = createAction('alert/REMOVE_ERROR')<string>();

export const clearAlerts = createAction('alert/CLEAR_ALERTS')();

export const addErrorAlert = (message: string) => {
  const alert = new AlertModel(new AlertMessage({ message, type: 'error' }));

  return addAlert(alert);
};

export const addSuccessAlert = (message: string) => {
  const alert = new AlertModel(new AlertMessage({ message, type: 'success' }));

  return addAlert(alert);
};

export const registerAlertContainer = createAction('alert/REGISTER_CONTAINER')<string>();

export const unRegisterAlertContainer = createAction('alert/UNREGISTER_CONTAINER')<string>();