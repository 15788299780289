import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { VatModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class VatClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'vat');
  }

  public async getAllAsync(): Promise<BaseDataResponse<VatModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<VatModel[]>>('', '');
  }

  public async createAsync(model: VatModel): Promise<BaseDataResponse<VatModel>> {
    return await this.postHttpAsync<BaseDataResponse<VatModel>, VatModel>('', model);
  }

  public async updateAsync(model: VatModel): Promise<BaseDataResponse<VatModel>> {
    return await this.putHttpAsync<BaseDataResponse<VatModel>, VatModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
