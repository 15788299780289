import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { makeStyles, createStyles, Theme, Grid, Button } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles/withStyles';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import { AlertsContainer, FormikInput, DynamicToolbar } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { inviteUserValidationSchema } from './validations';
import { UserInvitationModel } from '../../api/interfaces';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';

const useStyles = makeStyles(
  (theme: Theme): StyleRules<DetailClassKey> =>
    createStyles({
      root: {},
      infoText: {
        margin: '20px 0px 0px 0px',
        lineHeight: 1.4,
        fontSize: '1.12rem'
      },
      grid: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
      },
      gridButtonsRoot: {
        '& > button:not(:first-of-type)': {
          marginLeft: theme.spacing(2)
        }
      }
    })
);

type DetailClassKey = 'root' | 'grid' | 'gridButtonsRoot';

type InviteUserContainerStateProps = DeepReadonly<{
  initialValues: UserInvitationModel;
  errorMessage?: string;
  requestInProgress: boolean;
}>;

type InviteUserContainerDispatchProps = {
  sendInvitationActionRequest: typeof actions.sendUserInvitationAsync.request;
  cancelActionRequest: typeof actions.cancelItemAsync.request;
};

type InviteUserProps = {} & InviteUserContainerStateProps & InviteUserContainerDispatchProps;

const nameof = nameofFactory<UserInvitationModel>();

const InviteUserContainer: React.FunctionComponent<InviteUserProps> = memo(props => {
  console.debug('InviteUserContainer');

  const { errorMessage, requestInProgress, initialValues } = props;
  const classes = useStyles(props);
  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <div className={classes.root}>
      <DynamicToolbar />
      <Formik<UserInvitationModel>
        initialValues={initialValues}
        onSubmit={(values: UserInvitationModel, { setSubmitting }): void => {
          setSubmitting(true);

          props.sendInvitationActionRequest(values);
        }}
        validationSchema={inviteUserValidationSchema}
      >
        {(formProps): React.ReactElement => {
          const { /* isSubmitting, */ handleSubmit } = formProps;
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container className={classes.grid} spacing={3}>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <FormikInput
                      iconName="id-card-alt"
                      labelText="Email"
                      name={nameof('email')}
                      errorMessage={errorMessage}
                      InputProps={{
                        autoFocus: true
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                  <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                    Odeslat pozvánku
                  </Button>

                  <Button
                    variant="contained"
                    title="Zavřít"
                    color="secondary"
                    disabled={requestInProgress}
                    onClick={handleActionCancel}
                  >
                    Zavřít
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </div>
  );
});

const mapStateToProps = (state: ApplicationState): InviteUserContainerStateProps => {
  return {
    initialValues: { email: '' },
    errorMessage: state.users.errorMessage,
    requestInProgress: state.users.requestInProgress
  };
};

const mapDispatchToProps = (dispatch: Dispatch): InviteUserContainerDispatchProps =>
  bindActionCreators(
    {
      sendInvitationActionRequest: actions.sendUserInvitationAsync.request,
      cancelActionRequest: actions.cancelItemAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserContainer);
