import { AxiosInstance } from 'axios';
import { BaseResponse } from '../responses';

export default class BaseJsonClient {
  protected readonly axiosInstance: AxiosInstance;

  protected readonly modelPath: string;

  constructor(axiosInstance: AxiosInstance, modelPath: string) {
    this.axiosInstance = axiosInstance;
    this.modelPath = modelPath;
  }

  protected async getHttpAsync<TResponse extends BaseResponse>(route: string, query: string): Promise<TResponse> {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const axiosResponse = await this.axiosInstance.get(this.composeUrl(route, query), config);
    return axiosResponse.data as TResponse;
  }

  protected async putHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const axiosResponse = await this.axiosInstance.put(this.composeUrl(route), data, config);
    return axiosResponse.data as TResponse;
  }

  protected async postHttpAsync<TResponse extends BaseResponse, TData>(route: string, data: TData): Promise<TResponse> {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const axiosResponse = await this.axiosInstance.post(this.composeUrl(route), data, config);
    return axiosResponse.data as TResponse;
  }

  protected async deleteHttpAsync<TResponse extends BaseResponse>(route: string): Promise<TResponse> {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    const axiosResponse = await this.axiosInstance.delete(this.composeUrl(route), config);
    return axiosResponse.data as TResponse;
  }

  public composeUrl(route: string, query = ''): string {
    let urlBuilder: string;

    urlBuilder = this.modelPath;
    if (route) {
      urlBuilder += '/' + route;
    }

    if (query) {
      urlBuilder += '?' + query;
    }

    return urlBuilder;
  }
}
