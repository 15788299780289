import {
    createGetItemsAsyncAction,
    createNewItemAsyncAction,
    createEditItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createDeleteItemAsyncAction,
    createLoadDependenciesAsyncAction
} from '../../core';

import { StateModel } from '../../api/interfaces';

const modelName = 'state';

export const getItemsAsync = createGetItemsAsyncAction<StateModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<StateModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);