import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import StatesTable from './statesTable';
import * as actions from './actions';

import { StateModel } from '../../api/interfaces';
import {
  DynamicToolbar,
  DynamicToolbarButton,
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps
} from '../../components';

type StatesContainerStateProps = {} & BaseListStateProps<StateModel>;

type StatesContainerDispatchProps = {} & BaseListDispatchProps<StateModel> & BaseListContainerDispatchProps<StateModel>;

type StatesContainerProps = {} & StatesContainerStateProps & StatesContainerDispatchProps;

const columns = mergeWithDefaultColumns<StateModel>([
  /* Example
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  { id: 'description', label: 'Popis', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'personCount', label: 'Počet osob', minWidth: 150, align: 'left', isVisible: true, isTableActions: false }
  */
]);

const StatesContainer: React.FunctionComponent<StatesContainerProps> = props => {
  console.debug('StatesContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <StatesTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): StatesContainerStateProps => {
  return {
    items: state.states.itemList.allIds.map<StateModel>(id => {
      return state.states.itemList.byId[id];
    }),
    requestInProgress: state.states.requestInProgress
  };
};

const mapDispatchToProps = (dispatch: Dispatch): StatesContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StatesContainer);
