import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type AccommodationOffersEvidenceListTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const AccommodationOffersEvidenceListTable: React.FunctionComponent<AccommodationOffersEvidenceListTableProps> = memo(props => {
    console.debug('AccommodationOffersEvidenceListTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default AccommodationOffersEvidenceListTable;
