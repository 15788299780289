import { tenantProvider, CEZEPTenantDomianKey } from './';

const RegistrationEmails = {
  [CEZEPTenantDomianKey]: 'support@cez.cz'
} as Record<string, string>;

const DefaultRegistrationEmail = 'dev@hotelios.cz';

class TenantConfigurationService {
  getRegistrationEmail = (): string => {
    const tenantKey = tenantProvider.getTenant();

    for (const emailKey in RegistrationEmails) {
      if (emailKey === tenantKey) {
        return RegistrationEmails[emailKey];
      }
    }

    return DefaultRegistrationEmail;
  };
}

export default new TenantConfigurationService();
