import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import UserDetail, { UserDetailStateProps, UserDetailDispatchProps, UserDetailProps } from './usersDetail';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';

type UserEditContainerStateProps = {} & UserDetailStateProps;

type UserEditContainerDispatchProps = {} & UserDetailDispatchProps;

type UserEditContainerProps = {} & UserDetailProps & UserEditContainerStateProps & UserEditContainerDispatchProps;

const UserEditContainer: React.FunctionComponent<UserEditContainerProps> = memo(props => {
  console.debug('UserEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <UserDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): UserEditContainerStateProps => {
  return {
    initialValues: state.users.itemList.byId[ownProps.match.params.id],
    errorMessage: state.users.errorMessage,
    requestInProgress: state.users.requestInProgress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): UserEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);