import { add } from 'lodash';
import { createAction } from 'typesafe-actions';
import { StickyAlertModel, StickyAlertMessage } from './types';

export const addStickyAlert = createAction('stickyAlert/ADD_ALERT')<StickyAlertModel>();

export const removeStickyAlert = createAction('stickyAlert/REMOVE_ALERT')<string>();

export const clearStickyAlerts = createAction('stickyAlert/CLEAR_ALERTS')();

export const addUnauthorizedStickyAlert = (message: string) => {
  const alert = new StickyAlertModel(new StickyAlertMessage({ message, type: 'error', errorType: 'unauthorized' }));

  return addStickyAlert(alert);
};
