import { ThemeOptions } from '@material-ui/core';

import IstokWebTtf from '../../fonts/IstokWeb/IstokWeb-Regular.ttf';

/*const MuiInputBaseInputPaddings: Padding = {
    Bottom: 7,
    Left: 0,
    Right: 0,
    Top: 6,
    Padding: (): string => `${6}px ${0}px ${7}px ${0}px`
};*/

const IstokWeb = {
  fontFamily: 'Istok Web',
  fontStyle: 'normal',
  fontDisplay: 'swap' as 'swap',
  fontWeight: 400,
  src: `
    local('IstokWeb'),
    local('IstokWeb-Regular'),
    url(${IstokWebTtf}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

// A custom theme for this app
const defaultThemeOptions = {
  breakpoints: {
    values: { xs: 0, sm: 600, md: 750, lg: 1280, xl: 1920 }
  },
  typography: {
    fontFamily: 'Istok Web, Arial',
    button: {
      lineHeight: 1.43
    },
    subtitle1: {
      lineHeight: 1.43
    }
  },
  paddings: {
    // MuiInputBaseInput: MuiInputBaseInputPaddings
  },
  palette: {
    text: {
      appBar: 'white'
    },
    listLine: '#D5D3D3',
    background: {
      default: 'white'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [IstokWeb]
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: 'transparent'
      },
      input: {
        // padding: MuiInputBaseInputPaddings.Padding()
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none'
      },
      containedPrimary: {
        color: 'white',
        textTransform: 'uppercase'
      },
      containedSecondary: {
        color: 'white',
        textTransform: 'uppercase'
      }
    },
    MuiIcon: {
      root: {
        width: 'unset',
        height: 'unset',
        fontSize: 'unset'
      },
      fontSizeLarge: {
        fontSize: '1.6rem'
      }
    },
    MuiDivider: {
      vertical: {
        alignSelf: 'stretch',
        height: 'auto'
      }
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      },
      colorPrimary: {
        backgroundColor: '#C6A530'
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: { color: 'white' },
      toolbarBtnSelected: { color: 'white' }
    },
    MuiPickersDay: { daySelected: { color: 'white' } }
  }
} as ThemeOptions;

export default defaultThemeOptions;
