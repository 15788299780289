import React, { memo, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { roomValidationSchema } from './validations';
import { RoomModel, RoomTypeModel } from '../../api/interfaces';
import { selectRoomTypesSelectItemsByAccomodationId } from '../roomTypes/selectors';
import { ROUTES } from '../../navigation';
import { RoomTypesCreateContainer } from '../roomTypes';
import { FloorsCreateContainer } from '../floors';
import { AccommodationsCreateContainer } from '../accommodations';

export type RoomDetailStateProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  floorsSelectItems: DeepReadonly<RelationSelectItem[]>;
  roomTypes: { [itemId: number]: DeepReadonly<RoomTypeModel> };
} & BaseDetailStateProps<RoomModel>;

export type RoomDetailDispatchProps = {
  selectRoomTypesSelectItemsByAccomodationId: typeof selectRoomTypesSelectItemsByAccomodationId;
} & BaseDetailDispatchProps<RoomModel>;

export type RoomDetailProps = {} & BaseDetailProps<RoomModel> & RoomDetailStateProps & RoomDetailDispatchProps;

type RenderFormProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  floorsSelectItems: DeepReadonly<RelationSelectItem[]>;
  roomTypes: { [itemId: number]: DeepReadonly<RoomTypeModel> };
  setRoomTypesSelectItems: () => void;
} & BaseDetailContainerWrappedComponentProps<RoomModel>;

const nameof = nameofFactory<RoomModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues, accommodationsSelectItems, floorsSelectItems, roomTypes } = props;

  const handleActionCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<RoomModel>
      initialValues={initialValues}
      onSubmit={(values: RoomModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={roomValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, values } = formProps;
        const filteredRoomTypesSelectItems =
          values.accommodationId > 0
            ? selectRoomTypesSelectItemsByAccomodationId(values.accommodationId, roomTypes)
            : initialRoomTypesSelectItems;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Název"
                    name={nameof('name')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="sticky-note"
                    labelText="Popisek"
                    name={nameof('description')}
                    errorMessage={errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="user"
                    labelText="Patro"
                    name={nameof('floorId')}
                    errorMessage={errorMessage}
                    items={floorsSelectItems as RelationSelectItem[]}
                    createComponent={{
                      component: FloorsCreateContainer,
                      route: ROUTES.SETTINGS_FLOORS_CREATE
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Ubytovací zařízení"
                    name={nameof('accommodationId')}
                    errorMessage={errorMessage}
                    items={accommodationsSelectItems as RelationSelectItem[]}
                    createComponent={{
                      component: AccommodationsCreateContainer,
                      route: ROUTES.SETTINGS_ACCOMMODATIONS_CREATE
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Typ pokoje"
                    name={nameof('roomTypeId')}
                    errorMessage={errorMessage}
                    items={filteredRoomTypesSelectItems as RelationSelectItem[]}
                    createComponent={{
                      component: RoomTypesCreateContainer,
                      route: ROUTES.SETTINGS_ROOM_TYPES_CREATE
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const initialRoomTypesSelectItems: RelationSelectItem[] = [];

const RoomDetail: React.FunctionComponent<RoomDetailProps> = memo(props => {
  console.debug('RoomDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    accommodationsSelectItems,
    floorsSelectItems,
    roomTypes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  const afterInitialize = (): void => {
    console.warn(initialValues.accommodationId);
  };

  return (
    <BaseDetailContainer<RoomModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      afterInitialize={afterInitialize}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      {...{
        roomTypes: roomTypes,
        accommodationsSelectItems: accommodationsSelectItems,
        floorsSelectItems: floorsSelectItems
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default RoomDetail;
