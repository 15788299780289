import { ReservationGuestCheckOutModel, ReservationModel } from '../../api/interfaces';

export type ReservationsState = { 
  current: ReservationModel;
  checkOuts: ReservationGuestCheckOutModel[];
  info: string;
 };

export enum ReservationGuestCheckOutEditType {
  Logon = 1,
  Logoff = 2
}