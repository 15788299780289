import { put, all, takeEvery, select, PutEffect, SelectEffect, ForkEffect, AllEffect, call } from 'redux-saga/effects';
import { push, CallHistoryMethodAction } from 'connected-react-router';
import { Location } from 'history';
import { ActionType } from 'typesafe-actions';
import sha1 from 'sha1';

import apiClient from '../../api/apiClientInstance';
import { signInRequestOptAsync, signInValidateOptAsync, signInAsync, signInSilentAsync } from './actions';
import { getPersonalId } from './selectors';
import { BaseResponse } from '../../api/responses';
import { Path, LocationState } from 'history';
import { ApplicationState } from '../../store/rootReducer';
import { clearAlerts, getIsSomeModalOpened, closeTopModal } from '../../components';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([
    yield takeEvery(signInRequestOptAsync.request, signInRequestOpt),
    yield takeEvery(signInValidateOptAsync.request, signInValidateOpt),
    yield takeEvery(signInAsync.request, signInRequest),
    yield takeEvery(signInSilentAsync.request, signInSilentRequest),
  ]);
}

const getLocationSelector = (state: ApplicationState): Location<unknown> => state.router.location;

type SignInRequestOptGeneratorType =
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof signInRequestOptAsync.success>>
  | PutEffect<ActionType<typeof signInRequestOptAsync.failure>>;

function* signInRequestOpt(action: ReturnType<typeof signInRequestOptAsync.request>): Generator<SignInRequestOptGeneratorType, void, BaseResponse> {
  // TODO: AsyncGenerator {
  try {
    console.debug(action.payload);

    const response: BaseResponse = yield apiClient.Authentication.sendOtpAsync(action.payload);
    if (response.resultCode === 0) {
      yield put(signInRequestOptAsync.success(action.payload));
    } else {
      yield put(signInRequestOptAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(signInRequestOptAsync.failure(error));
    }
  }
}

type SignInValidateOptGeneratorType =
  | SelectEffect
  | Promise<BaseResponse>
  | PutEffect<CallHistoryMethodAction<[Path, LocationState?]>>
  | PutEffect<ActionType<typeof signInValidateOptAsync.success>>
  | PutEffect<ActionType<typeof clearAlerts>>
  | PutEffect<ActionType<typeof signInValidateOptAsync.failure>>;

type SignInValidateOptGeneratorNextType = any | BaseResponse;
type SignInValidateOptGeneratorReturnType = PutEffect<CallHistoryMethodAction<[Path, LocationState?]>> | void;

function* signInValidateOpt(
  action: ReturnType<typeof signInValidateOptAsync.request>
): Generator<SignInValidateOptGeneratorType, SignInValidateOptGeneratorReturnType, SignInValidateOptGeneratorNextType> {
  // TODO: AsyncGenerator {
  try {
    console.debug(action.payload);

    const personalId = yield select(getPersonalId);
    const response: BaseResponse = yield apiClient.Authentication.loginAsync(personalId, action.payload);
    if (response.resultCode === 0) {
      yield put(signInValidateOptAsync.success());
      yield put(clearAlerts());

      const location = yield select(getLocationSelector);
      console.debug(location);

      if (location.state && location.state.referer) {
        return yield put(push(location.state.referer.pathname));
      }

      yield put(push('/'));
    } else {
      yield put(signInValidateOptAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(signInValidateOptAsync.failure(error));
  }
}

type SignInRequestGeneratorType =
  | Promise<BaseResponse>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof signInAsync.success>>
  | PutEffect<ActionType<typeof signInAsync.failure>>
  | PutEffect<CallHistoryMethodAction<[Path, LocationState?]>>
  | PutEffect<ActionType<typeof clearAlerts>>;

 
function* signInRequest(action: ReturnType<typeof signInAsync.request>): 
  Generator<SignInRequestGeneratorType, void, any | BaseResponse> {
  // TODO: AsyncGenerator {
  try {
    console.debug(action.payload);

    const passwordHash: string = sha1(action.payload.password);
    const response: BaseResponse = yield apiClient.apiCallHandler(
      { context: apiClient.Authentication, apiCallFnName: 'loginAsync' },
      action.payload.userName,
      passwordHash
    );

    if (response.resultCode === 0) {
      const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
      if (isSomeModalOpend) {
        yield put(closeTopModal());
      }

      yield put(signInAsync.success());

      yield put(clearAlerts());

      const location = yield select(getLocationSelector);
      console.debug(location);

      if (location.state && location.state.referer) {
        return yield put(push(location.state.referer.pathname));
      }

      yield put(push('/'));
    } else {
      yield put(signInAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(signInAsync.failure(error));
    }
  }
}


type SignInSilentRequestGeneratorType =
  | Promise<BaseResponse>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof signInSilentAsync.success>>
  | PutEffect<ActionType<typeof signInSilentAsync.failure>>
  | PutEffect<CallHistoryMethodAction<[Path, LocationState?]>>
  | PutEffect<ActionType<typeof clearAlerts>>;

function* signInSilentRequest(action: ReturnType<typeof signInSilentAsync.request>): 
  Generator<SignInSilentRequestGeneratorType, void, any | BaseResponse> {
  try {
    console.debug(action.payload);

    const passwordHash: string = sha1(action.payload.password);
    const response: BaseResponse = yield apiClient.apiCallHandler(
      { context: apiClient.Authentication, apiCallFnName: 'loginAsync' },
      action.payload.userName,
      passwordHash
    );

    if (response.resultCode === 0) {
      const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
      if (isSomeModalOpend) {
        yield put(closeTopModal());
      }

      yield put(signInSilentAsync.success());

      yield put(clearAlerts());
    } else {
      yield put(signInSilentAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(signInSilentAsync.failure(error));
    }
  }
}

