import { createAsyncAction, createAction } from 'typesafe-actions';
import { AccommodationOfferWizard } from '.';
import { AccommodationOfferCalculationHeaderModel, AccommodationOfferCalculationModel, AccommodationOfferModel, AccommodationOfferRoomTypeModel } from '../../api/interfaces';
import { ColumnValueChangedEvent } from '../../components/table';

const modelName = 'accommodationOfferCalculation';

export const calculateAsyncAction = createAsyncAction<'CANCEL_REQUEST', 'CANCEL_SUCCESS', 'CANCEL_FAILURE'>(
    `${modelName}/CALCULATE_REQUEST` as 'CANCEL_REQUEST',
    `${modelName}/CALCULATE_SUCCESS` as 'CANCEL_SUCCESS',
    `${modelName}/CALCULATE_FAILURE` as 'CANCEL_FAILURE'
)<AccommodationOfferModel, AccommodationOfferCalculationModel, Error>();

export const setWizardAction = createAction(`${modelName}/WIZARD_SET_STEP`)<AccommodationOfferWizard>();

export const capacityIsNotLoadedAction = createAction(`${modelName}/CAPACITY_IS_NOT_LOADED`)<void>();

export const clearAction = createAction(`${modelName}/CLEAR`)<void>();

export const columnValueChangedAction = createAction(`${modelName}/COLUMN_VALUE_CHANGED`)<ColumnValueChangedEvent>();

export const setCompanyFilterAction = createAction(`${modelName}/SET_COMPANY_FILTER`)<number>();

export const setOfferHeaderAction = createAction(`${modelName}/SET_OFFER_HEADER`)<AccommodationOfferCalculationHeaderModel>();

export const editOfferHeaderAction = createAction(`${modelName}/EDIT_OFFER_HEADER`)<AccommodationOfferCalculationHeaderModel>();

export const editRoomTypeAction = createAction(`${modelName}/EDIT_ROOM_TYPE`)<AccommodationOfferRoomTypeModel>();
