import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { TimeLineRange } from './types';
import { getAllById as getAllRoomsById } from '../rooms';
import { TimeLineGroup } from '../../components/timeLine';

export const getLoadedDataRangeSelector = (state: ApplicationState): TimeLineRange => {
  return { start: state.timeLine.loadedDataStartDate, end: state.timeLine.loadedDataEndDate };
};

export const getVisibleDataRangeSelector = (state: ApplicationState): TimeLineRange => {
  return { start: state.timeLine.visibleDataStartDate, end: state.timeLine.visibleDataEndDate };
};

export const getIsAccommodationSelected = (state: ApplicationState): boolean => {
  return state.timeLine.selectedAccommodation !== -1;
};

const allRooms = getAllRoomsById;

const getSelectedAccommodationId = (state: ApplicationState): number => state.timeLine.selectedAccommodation;
const getSelectedFloorId = (state: ApplicationState): number => state.timeLine.selectedFloor;

export const selectRoomsBySelectedAccommodationAndFloor = createSelector(allRooms, getSelectedFloorId, getSelectedAccommodationId,
  (roomsById, selectedFloorId, selectedAccommodationId) => {
    let items = Object.values(roomsById).filter(room => {
      return room.accommodationId === selectedAccommodationId;
    });

    if (selectedFloorId >= 0) {
      items = items.filter(room => {
        return room.floorId === selectedFloorId;
      })
    }

    return items;
  });

export const transformRoomsAsTimeLineGroupItems = createSelector(selectRoomsBySelectedAccommodationAndFloor, rooms => {
  return rooms.map<TimeLineGroup>((room, index) => {
    return { id: room.id, title: room.name, isEvenRow: index % 2 === 0 } as TimeLineGroup;
  });
});
