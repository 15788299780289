import React, { } from 'react';
import { Paper, Theme, withStyles, StyleRules, WithStyles } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';

import { DynamicToolbar, DynamicToolbarButton, AlertsContainer } from '..';
import { BaseActions, ListItemState, BaseActionOperation, BaseAction } from '../../core';

const withStylesFunction = withStyles(
  (theme: Theme): StyleRules<BaseListContainerClassKey> => ({
    root: {
      flex: '1'
    },
    listRoot: {
      borderRadius: '0px',
      boxShadow: 'none'
    }
  })
);

type BaseListContainerClassKey = 'root' | 'listRoot';

type BaseListContainerStateProps<TModel extends ListItemState> = DeepReadonly<{}>;

export type BaseListContainerDispatchProps<TModel extends ListItemState> = {
  getItemsRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'getItemsAsync'>, 'request'>;
  newItemRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'newItemAsync'>, 'request'>;
  loadDependenciesRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'loadDependenciesAsync'>, 'request'>;
};

export type BaseListStateProps<TModel extends ListItemState> = {
  items: TModel[];
  requestInProgress: boolean;
};

export type BaseListDispatchProps<TModel extends ListItemState> = {
  deleteItemRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'deleteItemAsync'>, 'request'>;
  editItemRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'editItemAsync'>, 'request'>;
  loadDependenciesRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'loadDependenciesAsync'>, 'request'>;
  copyItemRequest?: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'copyItemAsync'>, 'request'>;
};

type BaseListContainerProps<TModel extends ListItemState> = {
  disableNew?: boolean;
  hideMenu?: boolean;
} & BaseListContainerStateProps<TModel> &
  BaseListContainerDispatchProps<TModel> &
  WithStyles<BaseListContainerClassKey>;

class BaseListContainer<TModel extends ListItemState> extends React.PureComponent<BaseListContainerProps<TModel>> {
  componentDidMount(): void {
    this.props.loadDependenciesRequest();
  }

  onNewButtonClickHandler = (): void => {
    this.props.newItemRequest();
  };

  onRefreshButtonClickHandler = (): void => {
    this.props.getItemsRequest({ forceReload: true });
  };

  render(): React.ReactNode {
    const disableNewBtn = this.props.disableNew !== undefined ? this.props.disableNew : false;
    const { hideMenu } = this.props;
    return (
      <div className={this.props.classes.root}>
        {(hideMenu !== true || hideMenu === undefined) && <DynamicToolbar>
          <DynamicToolbarButton icon="sync-alt" ariaLabel="refresh list action" onClick={this.onRefreshButtonClickHandler} />
          {!disableNewBtn && <DynamicToolbarButton icon="plus" ariaLabel="add action" onClick={this.onNewButtonClickHandler} />}
        </DynamicToolbar>}
        <Paper className={this.props.classes.listRoot} aria-label="list of items">
          {this.props.children}
        </Paper>
      </div>
    );
  }
}

export default withStylesFunction(BaseListContainer);
