import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DeepReadonly } from 'utility-types';

import { LogsState } from './types';
import * as actions from './actions';
import {
  createBaseListReducer,
  BaseActions,
  createBaseErrorMessageReducer,
  createBaseRequestInProgressReducer,
  GetAllRequestAsyncActionPayload
} from '../../core';
import { AccommodatedGuestModel, AccommodationModel, LogModel } from '../../api/interfaces';
import moment from 'moment';

export const initialState: LogsState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false,
    page: 0,
    skip: 0,
    take: 100,
    filter: `group=${moment(new Date()).format('MM/YYYY')}`,
  },
  errorMessage: '',
  requestInProgress: false
};

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync,
  setPagination: actions.setPagination
} as BaseActions<LogModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<LogsState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer
});
