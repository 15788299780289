import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, makeStyles, createStyles, Theme, Container } from '@material-ui/core';
import { withRouter } from "react-router";
import queryString from 'query-string';

import * as actions from './actions';
import SignUpView from './signUpView';
import { ApplicationState } from '../../store/rootReducer';
import { tenantConfiguratinService } from '../tenant';

type SignUpContainerProps = {} & SignUpContainerStateProps & SignUpContainerDispatchProps;

type SignUpContainerStateProps = {
  errorMessage: string;
  requestInProgress: boolean;
  inviteToken: string;
};

type SignUpContainerDispatchProps = {
  signUpRequest: typeof actions.signUpAsync.request;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}px`,
      minHeight: '100%'
    },
    loginText: {
      color: theme.palette.primary.main
      /* padding: `${theme.spacing(4)}px 0px 0px 0px` */
    }
  })
);

const SingUpContainer: React.FunctionComponent<SignUpContainerProps> = props => {
  const classes = useStyles(props);

  return (
    <Container maxWidth="sm" classes={{ root: classes.root }}>
      <Typography align="center" variant="h4" classes={{ root: classes.loginText }}>
        Dokončení registrace
      </Typography>
      <SignUpView
        requestInProgress={props.requestInProgress}
        errorMessage={props.errorMessage}
        inviteToken={props.inviteToken}
        signUp={props.signUpRequest}
      />
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: any): SignUpContainerStateProps => {
  return {
    errorMessage: state.signUp.errorMessage,
    requestInProgress: state.signUp.requestInProgress,
    inviteToken: queryString.parse(ownProps.location.search).token as string
  };
};

const mapDispatchToProps = (dispatch: Dispatch): SignUpContainerDispatchProps =>
  bindActionCreators(
    {
      signUpRequest: actions.signUpAsync.request
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingUpContainer));
