import { PaletteOptions } from '@material-ui/core/styles/createPalette';

import { default as ColorPalettes } from './index';

class PaletteProvider {
  getPallete = (theme: string): PaletteOptions => {
    for (const themeKey in ColorPalettes) {
      if (themeKey === theme) {
        return ColorPalettes[themeKey];
      }
    }

    throw new Error(`Unsupported theme ${theme}`);
  };
}

export default new PaletteProvider();
