import { createAction } from 'typesafe-actions';

import { ModalDialogModel } from './types';

const module = 'modal';

export const openModal = createAction(`${module}/OPEN_MODAL`)<ModalDialogModel>();

export const closeModal = createAction(`${module}/CLOSE_MODAL`)<string>();

export const closeTopModal = createAction(`${module}/CLOSE_TOP_MODAL`)<void>();

