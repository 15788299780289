import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { NumberSeriesModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class NumberSeriesClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'numberseries');
  }

  public async getAsync(): Promise<BaseDataResponse<NumberSeriesModel>> {
    return await this.getHttpAsync<BaseDataResponse<NumberSeriesModel>>('', '');
  }

  public async createAsync(model: NumberSeriesModel): Promise<BaseDataResponse<NumberSeriesModel>> {
    return await this.postHttpAsync<BaseDataResponse<NumberSeriesModel>, NumberSeriesModel>('', model);
  }

  public async updateAsync(model: NumberSeriesModel): Promise<BaseDataResponse<NumberSeriesModel>> {
    return await this.putHttpAsync<BaseDataResponse<NumberSeriesModel>, NumberSeriesModel>(`${model.id}`, model);
  }

  public async getUniqueReservationNumberAsync(): Promise<BaseDataResponse<string>> {
    return await this.getHttpAsync<BaseDataResponse<string>>('unique-number?numberSeriesType=0', '');
  }

  public async getUniqueOfferNumberAsync(): Promise<BaseDataResponse<string>> {
    return await this.getHttpAsync<BaseDataResponse<string>>('unique-number?numberSeriesType=1', '');
  }
}
