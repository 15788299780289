export const defaultTake = 100;

export const pagination = (
  payload: { skip: number; take?: number; page: number; filter: string },
  stored: { skip: number; take?: number; page: number; filter: string }
): { _take: number; _skip: number; _filter: string } => {
  let _skip = 0;
  let _take = undefined;
  let _page = 0;
  let _filter = "";
  if (payload) {
    _skip = payload.skip ?? 0;
    _take = payload.take ?? 100;
    _page = payload.page ?? 0;
    _filter = payload.filter ?? "";
  } else {
    const _payload = stored;
    _skip = _payload.skip;
    _take = _payload.take;
    _filter = _payload.filter;
  }

  if (_take === undefined) {
    const _payload = stored;
    if (_payload.take === undefined) {
      _take = defaultTake;
    } else {
      _take = _payload.take;
    }
  }

  _take = _take * (_page + 1);

  return { _take: _take, _skip: _skip, _filter: _filter };
};

export default pagination;