import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { AccommodationOfferCalculationModel, AccommodationOfferModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class AccommodationOfferCalculationClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'accommodationoffer');
  }

  public async createAsync(model: AccommodationOfferCalculationModel): Promise<BaseDataResponse<AccommodationOfferCalculationModel>> {
    return await this.postHttpAsync<BaseDataResponse<AccommodationOfferCalculationModel>, AccommodationOfferCalculationModel>('', model);
  }

  public async calculateAsync(model: AccommodationOfferModel): Promise<BaseDataResponse<AccommodationOfferCalculationModel>> {
    return await this.postHttpAsync<BaseDataResponse<AccommodationOfferCalculationModel>, AccommodationOfferModel>('calculate', model);
  }
}
