import React from 'react';
import {
  Theme,
  TableRow,
  TableCell,
  withStyles,
  Input,
  StyleRules,
  Grid,
  GridSize
} from '@material-ui/core';
import { GetAllRequestAsyncActionPayload } from '../../core';
import { Icon } from '../../icons';
import { BaseTableSelectionHeaderModel, BaseTableFilterModel, TablePageChangeModel, BaseTableToolbarModel } from '.';
import { ClassNameMap } from '@material-ui/styles';
import { DeepReadonly } from 'utility-types';
import _ from 'lodash';

const withStylesFunction = withStyles(
  (theme: Theme): StyleRules<TableHeaderClassKey> => ({
    overlay: {
      borderColor: theme.palette.primary.main,
      border: '1px',
      borderStyle: 'solid',
      width: 'auto',
      height: 'auto',
      position: 'fixed',
      background: '#fff',
      zIndex: 999
    }
  }));

export type TableHeaderClassKey = | 'overlay';

type TableHeaderProps = DeepReadonly<{
  load?(payload: GetAllRequestAsyncActionPayload | void | TablePageChangeModel): void;
  classes: Partial<ClassNameMap<TableHeaderClassKey>>;
  colspan: number;
}> & { 
  filter?: BaseTableFilterModel; 
  selectionHeader?: BaseTableSelectionHeaderModel; 
  toolBar?: BaseTableToolbarModel; 
};

type TableHeaderState = {
  open: boolean;
};

class TableHeader extends React.Component<TableHeaderProps, TableHeaderState> {
  search: _.DebouncedFunc<(e: string) => void>;

  constructor(props: TableHeaderProps) {
    super(props);
    this.state = {
      open: false
    }

    this.search = _.debounce(value => {
      const { load } = this.props;
      if (load) {
        let filter = "";
        if (value) {
          filter = `everywhere=${value}`
        }

        load({ page: 0, skip: 0, filter: filter } as TablePageChangeModel);
      }
    }, 1000);
  }

  componentDidMount(): void {
    console.debug('TableHeader.didMount');
  }

  render(): React.ReactFragment {
    const { classes, colspan } = this.props;
    const { open } = this.state;
    let filterSize: GridSize = 6;
    filterSize = this.props.toolBar !== undefined && this.props.selectionHeader !== undefined ? 6 : 8;

    let toolBarSize: GridSize = 4;
    toolBarSize = this.props.filter === undefined && this.props.selectionHeader == undefined ? 12 : 4;
    toolBarSize = this.props.filter === undefined && this.props.selectionHeader != undefined ? 8 : 12;

    const selectionHeaderSize: GridSize = 4;
    return (
      <TableRow>
        <TableCell colSpan={colspan} style={{ position: 'relative' }}>
          <Grid container spacing={4}>
            {this.props.filter && <Grid item xs={filterSize}>
              <Input placeholder='Hledat' style={{ width: '300px' }} onChange={(e): void => {
                this.search(e.target.value);
              }} />
              {!open && <Icon iconName="chevron-down" IconProps={{ onClick: (): void => { this.setState({ open: true }) } }} fixedWidth />}
              {open && <Icon iconName="chevron-up" IconProps={{ onClick: (): void => { this.setState({ open: false }) } }} fixedWidth />}
              <div className={classes.overlay} style={{ display: open ? 'block' : 'none' }}>
                {this.props.filter && <this.props.filter.component onClose={(): void => {
                  this.setState({ open: false });
                }} />}
              </div>
            </Grid>}
            {this.props.toolBar && <Grid item xs={toolBarSize}>
              {this.props.toolBar && <this.props.toolBar.component />}
            </Grid>}
            {this.props.selectionHeader && <Grid item xs={selectionHeaderSize}>
               <this.props.selectionHeader.component />
            </Grid>}
          </Grid>
        </TableCell>
      </TableRow>);
  }
}

export default withStylesFunction(TableHeader); 