import React, { memo } from 'react';

import { TableContainer, BaseTableProps } from '../../components';

type AccommodatedGuestsTableProps = {} & BaseTableProps;

const AccommodationsTable: React.FunctionComponent<AccommodatedGuestsTableProps> = memo(props => {
    console.debug('AccommodatedGuestsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default AccommodationsTable;
