import React, { } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Button, withStyles, StyleRules, Theme, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core';

import { ApplicationState } from '../../store/rootReducer';
import {
  withOpenModal,
  WithOpenModalProps,
  FormikRelationSelect,
  RelationSelectItem,
  FormikInput,
  FormikDatePicker,
  LinearProgressWithLabel,
  IconButton,
  FormikObserver
} from '../../components';
import { getPaymentMethodsSelectItems } from '.';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { cancelItemAsync, getItemsAsync, saveItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import { Formik } from 'formik';
import { PaymentMethodEnum, PaymentModel } from '../../api/interfaces';
import { nameofFactory } from '../../utils/nameofFactory';
import moment from 'moment';
import { selectGuestsSelectItems } from '../reservations';
import { DeepReadonly } from 'utility-types';
import _ from 'lodash';

const withStylesFunction = withStyles(
  (theme: Theme): StyleRules<PaymentContainerClassKey> => ({
    contentRoot: {
      '& > div:first-of-type': {
        paddingRight: theme.spacing(2)
      },
      '& > div:not(:first-of-type)': {
        paddingLeft: theme.spacing(2)
      }
    },
    gridButtonsRoot: {
      paddingTop: theme.spacing(3),
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(2)
      }
    },
    paymentData: {
      '& > div': {
        padding: theme.spacing(1)
      }
    },
    tableCell: {
      padding: theme.spacing(1)
    }
  }));

type PaymentContainerClassKey = 'gridButtonsRoot' | 'contentRoot' | 'paymentData' | 'tableCell';

type PaymentsDetailStateProps = {
  id: number;
  requestInProgress: boolean;
  documentNumber: string;
  reservationId: number;
  payments: PaymentModel[];
  paid: number;
  restToPay: number;
  toPay: number;
  percentPaid: number;
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  paymentMethodsSelectItems: DeepReadonly<RelationSelectItem[]>;
  allowPayByInvoice: boolean;
};

type PaymentsDetailDispatchProps = {
  load: typeof loadDependenciesAsync.request;
  cancel: typeof cancelItemAsync.request;
  save: typeof saveItemAsync.request;
  delete: typeof deleteItemAsync.request;
};

type PaymentsDetailProps = {
  classes: Partial<ClassNameMap<PaymentContainerClassKey>>;
} & PaymentsDetailStateProps
  & PaymentsDetailDispatchProps
  & WithOpenModalProps;

const nameof = nameofFactory<PaymentModel>();
class PaymentsDetail extends React.PureComponent<PaymentsDetailProps, { isTouched: boolean; value: number }> {

  constructor(props: PaymentsDetailProps) {
    super(props);

    props.load(props.id);

    this.state = {
      isTouched: false,
      value: props.restToPay
    };
  }

  componentDidUpdate(prevProps: Readonly<PaymentsDetailProps>): void {
    if (prevProps.restToPay !== this.props.restToPay) {
      this.setState({ value: this.props.restToPay })
    }
  }

  render(): React.ReactElement {
    console.debug('PaymentsDetail.render');
    const { requestInProgress, guestsSelectItems } = this.props;


    return (<Formik<PaymentModel>
      initialValues={{ dateOfPayment: new Date(), paymentMethod: PaymentMethodEnum.Cash, amount: _.round(this.props.restToPay, 0) } as PaymentModel}
      onSubmit={(values: PaymentModel, { setSubmitting }): void => {
        console.log(values);

        this.props.save({ ...values, reservationId: this.props.reservationId });
      }}
      enableReinitialize={true}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, values, setFieldValue } = formProps;

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container direction="row" alignItems="center" style={{ padding: '20px' }}>
              <Grid item xs={12}>
                <Typography></Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" classes={{ root: this.props.classes.contentRoot }}>
                  <Grid item xs={6}>
                    <Grid container direction="row" classes={{ root: this.props.classes.paymentData }}>
                      <Grid item xs={12}>
                        <Typography variant='h6'>Rezervace č.: {this.props.documentNumber}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <LinearProgressWithLabel value={this.props.percentPaid} label={`Zaplaceno`} />
                      </Grid>
                      <Grid item xs={4}>
                        <FormikInput
                          iconName="usd-circle"
                          labelText="Částka"
                          name={nameof('amount')}
                          InputProps={{
                            type: 'number',
                            onChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                              this.setState({ ...this.state, isTouched: true });

                              setFieldValue('amount', event.target.value);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormikDatePicker
                          iconName="calendar-day"
                          labelText="Datum platby"
                          InputLabelProps={{
                            shrink: true
                          }}
                          name={nameof('dateOfPayment')}
                          showErrorWhenNotTouched={true}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormikRelationSelect
                          iconName="wallet"
                          labelText="Způsob platby"
                          name={nameof('paymentMethod')}
                          items={this.props.paymentMethodsSelectItems as RelationSelectItem[]}
                          getItemDisabled={(option: RelationSelectItem): boolean => {
                            if (option.value === PaymentMethodEnum.Invoice)
                              return !this.props.allowPayByInvoice;
                            return false;
                          }}
                          onChange={(newValue: number) => {
                            if (newValue === PaymentMethodEnum.Cash) {
                              setFieldValue('amount', _.round(values.amount, 0));
                            } else {
                              if (!this.state.isTouched) {
                                setFieldValue('amount', this.state.value);
                              }
                            }
                          }}
                          showErrorWhenNotTouched={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormikRelationSelect
                          iconName="address-card"
                          labelText="Host"
                          name={nameof('guestId')}
                          items={guestsSelectItems as RelationSelectItem[]}
                          showErrorWhenNotTouched={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} style={{ border: '0px', borderLeft: '2px', borderColor: '#ddd', borderStyle: 'solid', }}>
                    <Typography variant='h6'>Přehled plateb</Typography>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Částka v Kč</TableCell>
                          <TableCell align='center' style={{ width: '120px' }}>Datum platby</TableCell>
                          <TableCell align='center' style={{ width: '130px' }}>Způsob platby</TableCell>
                          <TableCell align='center'>Host</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.payments && this.props.payments.map(payment => {
                          const method = (this.props.paymentMethodsSelectItems).find(i => i.value === payment.paymentMethod);
                          return <TableRow>
                            <TableCell className={this.props.classes.tableCell} align='right'>{payment.amount.toFixed(2)}</TableCell>
                            <TableCell className={this.props.classes.tableCell} align='center'>{moment(payment.dateOfPayment).format('DD. MM. yyyy')}</TableCell>
                            <TableCell className={this.props.classes.tableCell} align='center'>{method?.text}</TableCell>
                            <TableCell className={this.props.classes.tableCell} align='center'>{payment.guestName}</TableCell>
                            <TableCell className={this.props.classes.tableCell}> <IconButton
                              onClick={(): void => {
                                this.props.delete(payment.id);
                              }}
                              title={'Odstranit'}
                              icon={'trash'} /></TableCell>
                          </TableRow>
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
              <Box pt={3} width="100%" />
              <Grid container>
                <Grid item xs={12} classes={{ root: this.props.classes.gridButtonsRoot }}>
                  <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                    Zaplatit
                  </Button>

                  <Button
                    variant="contained"
                    title="Zavřít"
                    color="secondary"
                    disabled={requestInProgress}
                    onClick={(): void => {
                      console.debug('handleActionCancel');

                      this.props.cancel();
                    }}
                  >
                    Zavřít
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>);
      }}
    </Formik>);
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): PaymentsDetailStateProps => {
  const toPay = state.payments.currentToPay.totalToPayWithVat;
  const paid = state.payments.current.map(item => item.amount).reduce((prev, curr) => prev + curr, 0);
  let percentPaid = paid / (toPay / 100);
  if (percentPaid > 100) {
    percentPaid = 100;
  }

  let allowPayByInvoice = false;
  if (state.reservations.current && state.reservations.current.companyId) {
    allowPayByInvoice = state.companies.itemList.byId[state.reservations.current?.companyId].allowPayByInvoice;
  }

  return {
    id: ownProps.id,
    documentNumber: state.reservations.current.reservationNumber,
    reservationId: state.reservations.current.id,
    requestInProgress: state.payments.requestInProgress,
    payments: state.payments.current,
    paid: paid,
    toPay: state.payments.currentToPay.totalToPayWithVat,
    restToPay: toPay - paid,
    percentPaid: percentPaid,
    guestsSelectItems: selectGuestsSelectItems(state),
    paymentMethodsSelectItems: getPaymentMethodsSelectItems(),
    allowPayByInvoice
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PaymentsDetailDispatchProps => {
  return bindActionCreators(
    {
      load: loadDependenciesAsync.request,
      cancel: cancelItemAsync.request,
      save: saveItemAsync.request,
      delete: deleteItemAsync.request
    }, dispatch);
};

export default withOpenModal(connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(PaymentsDetail)));