import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import PriceListDetail, { PriceListDetailStateProps, PriceListDetailDispatchProps, PriceListDetailProps } from './priceListsDetail';
import * as actions from './actions';
import { PriceListModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectAccommodationsSelectItems } from '../accommodations';
import { relationConfiguration } from '../../configuration/relations';

type PriceListCreateContainerStateProps = {} & PriceListDetailStateProps;

type PriceListCreateContainerDispatchProps = {} & PriceListDetailDispatchProps;

type PriceListCreateContainerProps = {} & PriceListDetailProps & PriceListCreateContainerStateProps & PriceListCreateContainerDispatchProps;

const createInitialValues = { 
  name: '', 
  accommodationId: relationConfiguration.relationNotSetId 
} as PriceListModel;

const PriceListCreateContainer: React.FunctionComponent<PriceListCreateContainerProps> = memo(props => {
  console.debug('PriceListCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    accommodationsSelectItems,
    /*roomTypes,        
    selectRoomTypesSelectItemsByAccomodationId,*/
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <PriceListDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      accommodationsSelectItems={accommodationsSelectItems}
      /*roomTypes={roomTypes}
      selectRoomTypesSelectItemsByAccomodationId={selectRoomTypesSelectItemsByAccomodationId}*/
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): PriceListCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.priceLists.errorMessage,
    requestInProgress: state.priceLists.requestInProgress,
    accommodationsSelectItems: selectAccommodationsSelectItems(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PriceListCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    )
    /*selectRoomTypesSelectItemsByAccomodationId: selectRoomTypesSelectItemsByAccomodationId*/
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListCreateContainer);
