import { AccommodationOfferCalculationsState } from '.';
import { AccommodationOfferCalculationHeaderModel, AccommodationOfferRoomTypeCalculationModel, AccommodationOfferStateEnum, ReservationRoomModel } from '../../api/interfaces';
import { NormalizedListById } from '../../core';
import { ApplicationState } from '../../store/rootReducer';

export const getCalculations = (state: ApplicationState): AccommodationOfferCalculationsState => {
  return state.accommodationOfferCalculations;
};

export const getFreeRooms = (state: ApplicationState): NormalizedListById<AccommodationOfferRoomTypeCalculationModel> => {
  return state.accommodationOfferCalculations.freeRooms.byId;
};

export const getReservedRooms = (state: ApplicationState): NormalizedListById<AccommodationOfferRoomTypeCalculationModel> => {
  return state.accommodationOfferCalculations.reservedRooms.byId;
};

export const getPartiallyOccupiedRooms = (state: ApplicationState): NormalizedListById<AccommodationOfferRoomTypeCalculationModel> => {
  return state.accommodationOfferCalculations.partiallyOccupiedRooms.byId;
};

export const getSelectedFloorCapacity = (state: ApplicationState): number => {
  let total = 0;

  Object.values(state.accommodationOfferCalculations.floors.byId).forEach(floor => {
    if (floor.isSelected) {
      total += floor.freeCapacity;
    }
  });

  return total;
};

export const getSelectedRoomCapacity = (state: ApplicationState): number => {
  let total = 0;

  Object.values(state.accommodationOfferCalculations.freeRooms.byId).forEach(room => {
    if (room.rooms && room.rooms.length > 0) {
      total += room.rooms.length * (room.countOfBedsByRoomType / room.countOfAvailableRooms);
    }
  });

  Object.values(state.accommodationOfferCalculations.partiallyOccupiedRooms.byId).forEach(room => {
    if (room.rooms && room.rooms.length > 0) {
      total += room.rooms.length * (room.countOfBedsByRoomType / room.countOfAvailableRooms);
    }
  });

  if (state.accommodationOfferCalculations.offerHeader.state === AccommodationOfferStateEnum.Reservation) {
    Object.values(state.accommodationOfferCalculations.reservedRooms.byId).forEach(room => {
      if (room.rooms && room.rooms.length > 0) {
        total += room.rooms.length * (room.countOfBedsByRoomType / room.countOfAvailableRooms);
      }
    });
  }

  return total;
};

export const isFloorCapacityFullyLoaded = (state: ApplicationState): boolean => {
  return getSelectedFloorCapacity(state) >= state.accommodationOfferCalculations.offerHeader.numberOfPersons;
};

export const isRoomCapacityFullyLoaded = (state: ApplicationState): boolean => {
  return getSelectedRoomCapacity(state) >= state.accommodationOfferCalculations.offerHeader.numberOfPersons;
};

export const getAccommodationOfferRoomTypeCalculationById = (state: ApplicationState, id: number):
  AccommodationOfferRoomTypeCalculationModel => {
  return state.accommodationOfferCalculations.freeRooms.byId[id];
};

export const getCurrentReservedRooms = (state: ApplicationState): ReservationRoomModel[] => {
  return state.reservations.current.rooms;
};

export const getCurrentOfferHeader = (state: ApplicationState): AccommodationOfferCalculationHeaderModel => {
  return state.accommodationOfferCalculations.offerHeader;
};
