import React from 'react';
import {
  ListItem,
  makeStyles,
  Theme,
  createStyles,
  BottomNavigationAction
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useRouteMatch, NavLink as ReactRouterDomNavLink } from 'react-router-dom';

import { Icon } from '../icon';
import { MenuNavLinkItemProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.appBar,
      padding: '0px',
      /* '&:hover': {
          backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity)
      } */
    },
    label: {
      color: theme.palette.text.appBar,
      fontSize: '0.875rem',
      textTransform: 'uppercase',
      paddingTop: theme.spacing(0.5)
    }
  })
);

type HorizontalMenuNavLinkItemClassKey = 'root' | 'label';

type HorizontalMenuNavLinkItemProps = {
  classes?: Partial<ClassNameMap<HorizontalMenuNavLinkItemClassKey>>;
} & MenuNavLinkItemProps;

const HorizontalMenuNavLinkItem: React.FunctionComponent<HorizontalMenuNavLinkItemProps> = props => {
  const classes = useStyles(props);
  const match = useRouteMatch({
    path: props.to,
    exact: false
  });
  const showLabel = !!props.text;

  console.debug(`${props.to}-${!!match}`);

  return (
    <ReactRouterDomNavLink to={props.to}>
      <ListItem selected={!!match}>
        <BottomNavigationAction
          label={props.text}
          showLabel={showLabel}
          icon={<Icon iconName={props.iconName} IconProps={{ fontSize: 'large' }} />}
          classes={{ root: classes.root, label: classes.label }}
        />
      </ListItem>
    </ReactRouterDomNavLink>
  );
};

export default HorizontalMenuNavLinkItem;