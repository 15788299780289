import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { afterAuthenticatedAppDidMount } from './actions';
import { addErrorAlert } from '../../components';
/* import { getUserExpiryDate } from '../authentication';*/

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([yield takeEvery(afterAuthenticatedAppDidMount, afterAuthenticatedAppDidMountHandler)]);
  // yield all([yield takeEvery(changeLocation, changeLocationHandler)]);
}

// function* changeLocationHandler(
//   action: ReturnType<typeof changeLocation>
// ): Generator<SelectEffect | PutEffect<ActionType<typeof addErrorAlert>>, void> {
//   console.log("changeLocation.handler");
//   yield 
// }

function* afterAuthenticatedAppDidMountHandler(
  action: ReturnType<typeof afterAuthenticatedAppDidMount>
): Generator<SelectEffect | PutEffect<ActionType<typeof addErrorAlert>>, void, Date | null> {
  // TODO: : Generator {
  /*const userExpiryDate = new Date(); //yield select(getUserExpiryDate);
  let message =
      'Upozornění: Přístup do portálu po ukončení pracovního poměru bude možné max. 1 měsíc po posledním výplatním termínu – tj. k 15. dni v měsíci';
  if (userExpiryDate) {
      message = `Upozornění: Váš přístup do portálu bude ukončen ke dni: ${userExpiryDate.toLocaleDateString('cs')}`;
  }
  yield put(addErrorAlert(message));*/
}
