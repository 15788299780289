import { createCancelItemAsyncAction, createDeleteItemAsyncAction, createEditItemAsyncAction, createGetItemsAsyncAction, createLoadDependenciesAsyncAction, createNewItemAsyncAction, createPagination, createSaveItemAsyncAction } from '../../core';

import { AccommodationOffersEvidenceListModel, AccommodationOffersEvidenceListPriceItemModel } from '../../api/interfaces';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { ColumnValueChangedEvent } from '../../components/table';

const modelName = 'accommodationOffersEvidenceList';

const modelTableName = 'accommodationOffersEvidenceListPriceItem';

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const getItemsAsync = createGetItemsAsyncAction<AccommodationOffersEvidenceListPriceItemModel>(modelName);

export const saveItemAsync = createSaveItemAsyncAction<AccommodationOffersEvidenceListPriceItemModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const setPagination = createPagination(modelName);

export const newTableItemAsync = createNewItemAsyncAction(modelTableName);

export const loadTableDependenciesAsync = createLoadDependenciesAsyncAction(modelTableName);

export const columnValueChangedAction = createAction(`${modelName}/COLUMN_VALUE_CHANGED`)<ColumnValueChangedEvent>();

export const downloadAction = createAction(`${modelName}/DOWNLOAD`)();
