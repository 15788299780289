import {
    createEditItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createLoadDependenciesAsyncAction,
    createGetItemAsyncAction
} from '../../core';

import { MyCompanyModel } from '../../api/interfaces';
import { createAsyncAction } from 'typesafe-actions';

const modelName = 'myCompany';

export const getItemAsync = createAsyncAction(
    'myCompany/GET_MY_COMPANY_REQUEST',
    'myCompany/GET_MY_COMPANY_SUCCESS',
    'myCompany/GET_MY_COMPANY_FAILURE'
  )<void, MyCompanyModel, Error>();

export const editItemAsync = createEditItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<MyCompanyModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);