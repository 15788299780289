import React, { PureComponent } from 'react';

export type ConditionProps = {
  is: boolean;
};

class Condition extends PureComponent<ConditionProps> {
  render(): React.ReactNode {
    return this.props.is ? this.props.children : null;
  }
}

export default Condition;
