import React from 'react';
import { matchPath } from 'react-router';
import { default as MuiBreadcrumbs, BreadcrumbsClassKey as MuiBreadcrumbsClassKey } from '@material-ui/core/Breadcrumbs';
import { withStyles, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { NAMED_ROUTES } from '../../navigation';
import { BaseToolbar } from '../baseToolbar';

const withStylesFunction = withStyles(({ palette }: Theme) => ({
  li: {
    color: palette.common.white,
    textTransform: 'uppercase'
  },
  separator: { color: palette.common.white }
}));

type BreadcrumbsClassKey = {} & MuiBreadcrumbsClassKey;

type BreadcrumbsProps = { modalDialog: boolean; route: string; classes: Partial<ClassNameMap<BreadcrumbsClassKey>> };

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

class Breadcrumbs extends React.PureComponent<BreadcrumbsProps> {
  static defaultProps = {};

  constructor(props: BreadcrumbsProps) {
    super(props);

    this.state = { open: true };
  }

  render(): React.ReactElement {
    const pathnames = this.props.route.split('/').filter(x => x);
    const namedPaths = pathnames.map((subPath, index) => {
      const joinedPath = pathnames.reduce((accumulator, currentValue, currentIndex) => {
        if (index >= currentIndex) {
          return accumulator + `/${currentValue}`;
        } else {
          return accumulator;
        }
      }, '');

      for (const route in NAMED_ROUTES) {
        const match = matchPath(joinedPath, { path: route, exact: true, strict: true });
        if (match) {
          if (hasKey(NAMED_ROUTES, route)) {
            return NAMED_ROUTES[route].readebleText;
          }
        }
      }

      return joinedPath;
    });

    return (
      <BaseToolbar>
        <MuiBreadcrumbs separator="›" aria-label="breadcrumb" classes={this.props.classes}>
          {namedPaths.map((pathText) => {
            return <span key={`index-${pathText}`}>{pathText}</span>;
          })}
        </MuiBreadcrumbs>
      </BaseToolbar>
    );
  }
}

export default withStylesFunction(Breadcrumbs);
