import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import CompaniesTable from './companiesTable';
import * as actions from './actions';

import { CompanyModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  TablePaginationModel
} from '../../components';
import CompaniesFilterContainer from './companiesFilterContainer';
import { getCompaniesPagination } from './selectors';

type CompaniesContainerStateProps = {
  pagination: TablePaginationModel;
} & BaseListStateProps<CompanyModel>;

type CompaniesContainerDispatchProps = {} & BaseListDispatchProps<CompanyModel> & BaseListContainerDispatchProps<CompanyModel>;

type CompaniesContainerProps = {} & CompaniesContainerStateProps & CompaniesContainerDispatchProps;

const columns = mergeWithDefaultColumns<CompanyModel>([
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  { id: 'ico', label: 'IČ', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  { id: 'street', label: 'Ulice', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'city', label: 'Město', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'email', label: 'E-mail', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'phone', label: 'Telefon', minWidth: 120, align: 'left', isVisible: true, isTableActions: false }
]);

const CompaniesContainer: React.FunctionComponent<CompaniesContainerProps> = props => {
  console.debug('CompaniesContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <CompaniesTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        paging={true}
        pagination={props.pagination}
        loadAction={props.getItemsRequest}
        filter={{ component: CompaniesFilterContainer }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): CompaniesContainerStateProps => {
  return {
    items: state.companies.itemList.allIds.map<CompanyModel>(id => {
      return state.companies.itemList.byId[id];
    }),
    requestInProgress: state.companies.requestInProgress,
    pagination: getCompaniesPagination(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): CompaniesContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesContainer);
