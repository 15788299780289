// export const baseUrl = "https://dev.api.zamestnanec.online/api/v1"

//let baseUrl = 'https://localhost:44397/api/v1';
//let baseUrl = 'https://api.dev.zamestnanec.online/api/v1';
let baseUrl = 'https://localhost:44323/api/v1';
if (process.env.REACT_APP_BASE_API_URL) {
    baseUrl = process.env.REACT_APP_BASE_API_URL;
}

export default { baseUrl };
