import { Theme, makeStyles, createStyles } from '@material-ui/core';

export const useSignInStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoText: {
      margin: '20px 0px 0px 0px',
      lineHeight: 1.4,
      fontSize: '1.12rem'
    },
    grid: {
      paddingTop: '20px'
    },
    noAccountText: {
      padding: `${theme.spacing(2)}px 0px 0px `,
      lineHeight: 1.4,
      fontSize: '1.12rem'
    },
    text: {
      padding: `${theme.spacing(3)}px 0px 0px `,
      lineHeight: 1.4,
      fontSize: '1.12rem'
    },
    warningTextRoot: {
      padding: `${theme.spacing(3)}px 0px 0px 0px`
    },
    mailTo: {
      color: 'inherit'
    },
    actionButton: {
      color: theme.palette.text.primary
    }
  })
);
