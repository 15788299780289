import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import RoomsTable from './roomsTable';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import { RoomModel, AccommodationModel, RoomTypeModel, FloorModel } from '../../api/interfaces';
import { tableConfiguration } from '../../configuration/table';
import {
  mergeWithDefaultColumns,
  createRelation,
  GenericColumn,
  BaseTableRowModel,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps
} from '../../components';
import { NormalizedListById } from '../../core';
import { getAccommodationsById } from '../accommodations';
import { getRoomTypesById } from '../roomTypes';
import { getFloorsById } from '../floors';

type RoomsContainerStateProps = {
  accommodations: NormalizedListById<AccommodationModel>;
  roomTypes: NormalizedListById<RoomTypeModel>;
  floors: NormalizedListById<FloorModel>;
} & BaseListStateProps<RoomModel>;

type RoomsContainerDispatchProps = {} & BaseListDispatchProps<RoomModel> & BaseListContainerDispatchProps<RoomModel>;

type RoomsContainerProps = {} & RoomsContainerStateProps & RoomsContainerDispatchProps;

const columns = mergeWithDefaultColumns<RoomModel>([
  { id: 'name', label: 'Název', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  { id: 'description', label: 'Popis', minWidth: 250, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'accommodationId',
    label: 'Ubytovací zařízení',
    minWidth: tableConfiguration.MinColumnWidthForAccommodation,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<RoomModel, AccommodationModel>('accommodationId', 'name')
  },
  {
    id: 'roomTypeId',
    label: 'Typ pokoje',
    minWidth: tableConfiguration.MinColumnWidthForRoomType,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<RoomModel, RoomTypeModel>('roomTypeId', 'name')
  },
  {
    id: 'floorId',
    label: 'Patro',
    minWidth: tableConfiguration.MinColumnWidthForFloor,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<RoomModel, FloorModel>('floorId', 'name')
  }
]);

const RoomsContainer: React.FunctionComponent<RoomsContainerProps> = props => {
  console.debug('RoomsContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <RoomsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        relations={{ accommodationId: { items: props.accommodations }, roomTypeId: { items: props.roomTypes }, floorId: { items: props.floors } }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): RoomsContainerStateProps => {
  const items = state.rooms.itemList.allIds.map<RoomModel>(id => {
    return state.rooms.itemList.byId[id];
  }).sort((a, b) => {
    if (a.accommodationId < b.accommodationId) {
      return -1;
    } else if (a.accommodationId > b.accommodationId) {
      return 1;
    } 

    if (a.floorNumber > b.floorNumber) {
      return 1;
    } if (a.floorNumber < b.floorNumber) {
      return -1;
    } else {
      return 0;
    }
  });
  return {
    items: items,
    requestInProgress: state.rooms.requestInProgress,
    accommodations: getAccommodationsById(state),
    roomTypes: getRoomTypesById(state),
    floors: getFloorsById(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoomsContainer);
