import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import CompanyDetail, { CompanyDetailStateProps, CompanyDetailDispatchProps, CompanyDetailProps } from './companiesDetail';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import { selectStatesSelectItems } from '../states/selectors';
import { getCompanyInfoAsync } from '../ares';

type CompanyEditContainerStateProps = {} & CompanyDetailStateProps;

type CompanyEditContainerDispatchProps = {} & CompanyDetailDispatchProps;

type CompanyEditContainerProps = {} & CompanyDetailProps & CompanyEditContainerStateProps & CompanyEditContainerDispatchProps;

const CompanyEditContainer: React.FunctionComponent<CompanyEditContainerProps> = memo(props => {
  console.debug('CompanyEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    stateSelectItems,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    getCompanyInfoRequest
  } = props;

  return (
    <CompanyDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      stateSelectItems={stateSelectItems}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      getCompanyInfoRequest={getCompanyInfoRequest}

    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): CompanyEditContainerStateProps => {
  return {
    initialValues: state.companies.itemList.byId[ownProps.match.params.id],
    errorMessage: state.companies.errorMessage,
    requestInProgress: state.companies.requestInProgress,
    stateSelectItems: selectStatesSelectItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): CompanyEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        getCompanyInfoRequest: getCompanyInfoAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEditContainer);
