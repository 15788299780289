import { all, put, take, takeEvery, select, TakeEffect, PutEffect, SelectEffect, call, CallEffect } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import {
  saveItemAsync,
  getItemAsync,
  loadDependenciesAsync,
} from './actions';
import apiClient from '../../api/apiClientInstance';
import { AresModel, MyCompanyModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { addSuccessAlert } from '../../components';
import { getItemsAsync as loadStatesAsync } from '../states';
import { getAreItemsLoaded as getAreStatesLoaded } from '../states/selectors';
import { getCompanyInfoAsync } from '../ares/actions';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(getItemAsync.request, getMyCompanyRequest),
    yield takeEvery(saveItemAsync.request, saveMyCompanyRequest),
    yield takeEvery(getCompanyInfoAsync.request, getCompanyInfoRequest),
  ]);
}

type GetCompanyInfoRequestGeneratorType<AresModel> =
  | Promise<BaseDataResponse<AresModel>>
  | PutEffect<ActionType<typeof getCompanyInfoAsync.success>>
  | PutEffect<ActionType<typeof getCompanyInfoAsync.failure>>
  | SelectEffect
  | CallEffect;

function* getCompanyInfoRequest(
  action: ActionType<typeof getCompanyInfoAsync.request>
): Generator<GetCompanyInfoRequestGeneratorType<AresModel>, void, 
  BaseDataResponse<AresModel>
  & AresModel & boolean> {

  try {
    console.debug('getCompanyInfoRequest');

    const model = action.payload;
    const response: BaseDataResponse<AresModel> = yield apiClient.apiCallHandler(
      { context: apiClient.AresClient, apiCallFnName: 'getAsync' },
      model
    );

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(getCompanyInfoAsync.success(data));
    } else {
      yield put(getCompanyInfoAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getCompanyInfoAsync.failure(error));
  }
}

type SaveEntityRequestGeneratorType<MyCompanyModel> =
  | Promise<BaseDataResponse<MyCompanyModel>>
  | PutEffect<ActionType<typeof saveItemAsync.success>>
  | PutEffect<ActionType<typeof saveItemAsync.failure>>
  | SelectEffect
  | CallEffect
  | PutEffect<ActionType<typeof addSuccessAlert>>;

function* saveMyCompanyRequest(
  action: ActionType<typeof saveItemAsync.request>
): Generator<SaveEntityRequestGeneratorType<MyCompanyModel>, void, 
  BaseDataResponse<MyCompanyModel>
  & MyCompanyModel & boolean> {

  try {
    console.debug('saveMyCompanyRequest');

    const model = action.payload;
    const response: BaseDataResponse<MyCompanyModel> = yield apiClient.apiCallHandler(
      { context: apiClient.MyCompanyClient, apiCallFnName: 'updateAsync' },
      model
    );

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(saveItemAsync.success(data));
      yield put(addSuccessAlert('Fakturační údaje úspěšně uloženy.'));
    } else {
      yield put(saveItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(saveItemAsync.failure(error));
  }
}

type GetMyCompanyRequestGeneratorType =
  | SelectEffect
  | Promise<BaseDataResponse<MyCompanyModel>>
  | PutEffect<ActionType<typeof getItemAsync.request>>
  | PutEffect<ActionType<typeof getItemAsync.success>>
  | PutEffect<ActionType<typeof getItemAsync.failure>>;

function* getMyCompanyRequest(
  action: ReturnType<typeof getItemAsync.request>
): Generator<GetMyCompanyRequestGeneratorType, void, MyCompanyModel & BaseDataResponse<MyCompanyModel>> {
  try {
    console.debug('getMyCompanyRequest');

    const response: BaseDataResponse<MyCompanyModel> = yield apiClient.MyCompanyClient.getAsync();

    if (response.resultCode === 0) {
      yield put(getItemAsync.success(response.data));
    } else {
      yield put(getItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemAsync.failure(error));
  }
}

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | PutEffect<ActionType<typeof loadStatesAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean & void> {
  try {
    console.debug('loadDependenciesRequest');

    const areStatesLoaded = yield select(getAreStatesLoaded);
    if (!areStatesLoaded) {
      yield put(loadStatesAsync.request());
      yield take([getType(loadStatesAsync.success)]);
    }

    yield put(loadDependenciesAsync.success());

  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}