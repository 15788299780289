import React from 'react';
import { makeStyles, Theme, createStyles, IconButton, fade } from '@material-ui/core';

import { Icon } from '../../components';
import { IconName } from '../../icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '45px',
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: fade(theme.palette.action.active, theme.palette.action.hoverOpacity)
      }
    }
  })
);

export type DynamicToolbarButtonProps = {
  icon: IconName;
  ariaLabel: string;

  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const DynamicToolbarButton: React.FunctionComponent<DynamicToolbarButtonProps> = props => {
  console.debug('DynamicToolbarButton');
  const classes = useStyles();

  return (
    <IconButton color="primary" aria-label={props.ariaLabel} classes={{ root: classes.buttonRoot }} onClick={props.onClick}>
      <Icon iconName={props.icon} IconProps={{ fontSize: 'large' }} />
    </IconButton>
  );
};

export default DynamicToolbarButton;
