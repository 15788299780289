import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type PriceListsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const PriceListsTable: React.FunctionComponent<PriceListsTableProps> = memo(props => {
    console.debug('PriceListsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default PriceListsTable;
