import React, { memo, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box } from '@material-ui/core';

import { AccommodationOfferFeeModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { TableContainer, actionsColumn, GenericColumn, BaseTableRowModel, AddButton, EditButton } from '../../components';
import { ROUTES } from '../../navigation';
import { AccommodationOfferFeeCreateContainer, AccommodationOfferFeeEditContainer, removeFeeAction } from '../accommodationOfferFees';
import { mergeWithIdColumn } from '../../components/table';

type AccommodationOfferFeesContainerStateProps = {
  fees: AccommodationOfferFeeModel[];
};

type AccommodationOfferFeesContainerDispatchProps = {
  removeFee: typeof removeFeeAction;
};

type AccommodationOfferFeesContainerProps = {} & AccommodationOfferFeesContainerStateProps &
  AccommodationOfferFeesContainerDispatchProps;


const columns = mergeWithIdColumn<AccommodationOfferFeeModel>([
  { id: 'name', label: 'Název poplatku', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'amount', label: 'Počet', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  { id: 'price', label: 'Cena za noc', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  { id: 'totalPrice', label: 'Cena za pobyt', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  {
    ...actionsColumn(), editComponent: (id: number): any => {
      return <EditButton createComponent={{
        component: AccommodationOfferFeeEditContainer,
        route: ROUTES.ACCOMMODATION_OFFER_FEES_DETAIL,
        componentProps: { id: id },
        fullWidth: false
      }}
        onClick={(): void => { console.log(`EDIT: ${id}`) }} />
    }
  }
]);

class AccommodationOfferFeesContainer extends React.PureComponent<AccommodationOfferFeesContainerProps> {
  render(): React.ReactElement {
    console.debug('AccommodationOfferFeesContainer.render');

    return (<Grid container direction="row" alignItems="center">
      <Typography>

      </Typography>
      <TableContainer
        rows={this.props.fees}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        dataSourcePath=""
        editAction={() => {
          console.log('editAction');
        }}
        deleteAction={(id) => {
          this.props.removeFee(id);
        }}
      />
      <Box pt={3} width="100%" />
      <AddButton
        createComponent={{
          component: AccommodationOfferFeeCreateContainer,
          route: ROUTES.ACCOMMODATION_OFFER_FEES_CREATE
        }}
        text="Přidat poplatek"
        onClick={(): void => {
          console.log('addAccommodationOfferFeeAction');
        }}
      />

    </Grid>);
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferFeesContainerStateProps => {
  return {
    fees: state.accommodationOfferCalculations.fees.allIds.map<AccommodationOfferFeeModel>(id => {
      return state.accommodationOfferCalculations.fees.byId[id];
    })
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferFeesContainerDispatchProps => {
  return {
    ...bindActionCreators({
      removeFee: removeFeeAction
    }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferFeesContainer);