import { takeEvery, all, call, put, take, ForkEffect, AllEffect, CallEffect, PutEffect, TakeEffect, ChannelTakeEffect } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { Action } from 'react-redux/node_modules/redux';

import * as actions from './actions';
import browserHistory from '../browserHistory';
import { clearAlerts } from '../components';
import { isActionOf } from 'typesafe-actions';

type NavigateToHandlerGeneratorType = CallEffect;
type WatchsGeneratorType = ForkEffect;

function* navigateToHandler(action: ReturnType<typeof actions.navigateTo>): Generator<NavigateToHandlerGeneratorType, void, unknown> {
  try {
    yield call(browserHistory.push, { ...{ pathname: action.payload, state: undefined } });
  } catch (error) {
    console.error(error);
  }
}

function* navigateToDetailHandler(action: ReturnType<typeof actions.navigateToDetail>): Generator<NavigateToHandlerGeneratorType, void, unknown> {
  try {
    console.debug('navigateToDetailHandler');
    yield call(browserHistory.push, { ...{ pathname: action.payload, state: undefined } });
  } catch (error) {
    console.error(error);
  }
}

function* navigateToWithQueryHandler(
  action: ReturnType<typeof actions.navigateToWithQuery>
): Generator<NavigateToHandlerGeneratorType, void, unknown> {
  try {
    yield call(browserHistory.push, { ...{ pathname: action.payload.pathname, search: action.payload.query, state: undefined } });
  } catch (error) {
    console.error(error);
  }
}

function* navigateToLocationHandler(action: ReturnType<typeof actions.navigateToLocation>): Generator<NavigateToHandlerGeneratorType, void, unknown> {
  try {
    console.debug('navigateToLocationHandler');
    yield call(browserHistory.push, action.payload);
  } catch (error) {
    console.error(error);
  }
}

function* navigateBackHandler(action: ReturnType<typeof actions.navigateBack>): Generator<NavigateToHandlerGeneratorType, void, unknown> {
  try {
    yield call(browserHistory.goBack);
  } catch (error) {
    console.error(error);
  }
}

function* openUrlInTab(action: ReturnType<typeof actions.openUrlInTab>): Generator<NavigateToHandlerGeneratorType, void, Window | null> {
  try {
    const win = yield call(window.open, action.payload, '_blank');
    if (win) {
      win.focus();
    }
  } catch (error) {
    // TOOD - obecne handlovat chybu
    console.error(error);
  }
}

function* watchsNavigateTo(): Generator<WatchsGeneratorType, void, unknown> {
  yield takeEvery(actions.navigateTo, navigateToHandler);
}

function* watchsNavigateToDetail(): Generator<WatchsGeneratorType, void, unknown> {
  yield takeEvery(actions.navigateToDetailAction, navigateToDetailHandler);
}

function* watchsNavigateBack(): Generator<WatchsGeneratorType, void, unknown> {
  yield takeEvery(actions.navigateBack, navigateBackHandler);
}

function* watchsOpenUrlInTab(): Generator<WatchsGeneratorType, void, unknown> {
  yield takeEvery(actions.openUrlInTab, openUrlInTab);
}

function* watchsNavigateToWithQuery(): Generator<WatchsGeneratorType, void, unknown> {
  yield takeEvery(actions.navigateToWithQuery, navigateToWithQueryHandler);
}

function* watchsNavigateToLocation(): Generator<WatchsGeneratorType, void, unknown> {
  yield takeEvery(actions.navigateToLocation, navigateToLocationHandler);
}

function* watchsNavigateEvents(): Generator<ChannelTakeEffect<unknown> | PutEffect, void, unknown> {
  const channel = eventChannel(emitter => {
    browserHistory.listen((location, action) => {
      emitter({});
    });

    return (): void => {
      console.debug();
    };
  });

  while (true) {
    yield take(channel);
    yield put(clearAlerts());
  }
}

export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  return yield all([
    watchsNavigateTo(),
    watchsNavigateToDetail(),
    watchsNavigateBack(),
    watchsOpenUrlInTab(),
    watchsNavigateEvents(),
    watchsNavigateToWithQuery(),
    watchsNavigateToLocation()
  ]);
}
