import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RoomTypeModel } from '../../api/interfaces';
import { RelationSelectItem } from '../../components';
import { NormalizedListById } from '../../core';

export const getRoomTypesById = (state: ApplicationState): NormalizedListById<RoomTypeModel> => {
  return state.roomTypes.itemList.byId;
};

export const getRoomTypesAllIds = (state: ApplicationState): number[] => {
  return state.roomTypes.itemList.allIds;
};

export const getAreRoomTypesLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.roomTypes.itemList.loaded;
};

export const selectRoomTypesSelectItemsByAccomodationId = (
  selectedAccommodationId: number,
  roomTypesById: { [itemId: number]: DeepReadonly<RoomTypeModel> }
): RelationSelectItem[] => {
  console.debug('Calling selectRoomTypesSelectItemsByAccomodationId...');

  return Object.values(roomTypesById)
    .filter(roomType => {
      return roomType.accommodationId === selectedAccommodationId;
    })
    .map<RelationSelectItem>(roomType => {
      return { text: roomType.name, value: roomType.id } as RelationSelectItem;
    });
};
