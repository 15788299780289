import React, { memo } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  FormikDatePicker,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { vatValidationSchema } from './validations';
import { VatModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';

export type VatDetailStateProps = {
} & BaseDetailStateProps<VatModel>;

export type VatDetailDispatchProps = {
} & BaseDetailDispatchProps<VatModel>;

export type VatDetailProps = {}
  & BaseDetailProps<VatModel>
  & VatDetailStateProps & VatDetailDispatchProps;

type RenderFormProps = {
} & BaseDetailContainerWrappedComponentProps<VatModel>;

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues } = props;

  return (
    <Formik<VatModel>
      initialValues={initialValues}
      onSubmit={(values: VatModel, { setSubmitting }): void => {
        console.log('Submiting');
        setSubmitting(true);
        props.saveActionRequest(values);
      }}
      enableReinitialize={true}
      validationSchema={vatValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Typography variant="h5">DPH</Typography>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="sticky-note"
                    labelText="Název"
                    name={'name'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'string'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="percent"
                    labelText="Procento"
                    name={'amount'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'number'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>
              </Grid>
            </Grid>

          </form>);
      }}
    </Formik>
  );
};

const VatDetail: React.FunctionComponent<VatDetailProps> = memo(props => {
  console.debug('VatDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<VatModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default VatDetail;
