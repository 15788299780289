import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import RoomTypeDetail, { RoomTypeDetailStateProps, RoomTypeDetailDispatchProps, RoomTypeDetailProps } from './roomTypesDetail';
import * as actions from './actions';
import { RoomTypeModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { selectAccommodationsSelectItems } from '../accommodations';

type RoomTypeEditContainerStateProps = {} & RoomTypeDetailStateProps;

type RoomTypeEditContainerDispatchProps = {} & RoomTypeDetailDispatchProps;

type RoomTypeEditContainerProps = {} & RoomTypeDetailProps & RoomTypeEditContainerStateProps & RoomTypeEditContainerDispatchProps;

const RoomTypeEditContainer: React.FunctionComponent<RoomTypeEditContainerProps> = memo(props => {
    console.debug('RoomTypeEditContainer');

    const {
        errorMessage,
        requestInProgress,
        initialValues,
        accommodationsSelectItems,
        modalDialog,
        modalDialogRoute,
        saveActionRequest,
        cancelActionRequest,
        loadDependenciesRequest
    } = props;

    return (
        <RoomTypeDetail
            initialValues={initialValues}
            saveActionRequest={saveActionRequest}
            cancelActionRequest={cancelActionRequest}
            loadDependenciesRequest={loadDependenciesRequest}
            errorMessage={errorMessage}
            requestInProgress={requestInProgress}
            accommodationsSelectItems={accommodationsSelectItems}
            readonlyValues={{ accommodationId: true }}
            modalDialog={modalDialog}
            modalDialogRoute={modalDialogRoute}
        />
    );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): RoomTypeEditContainerStateProps => {
    return {
        initialValues: state.roomTypes.itemList.byId[ownProps.match.params.id],
        errorMessage: state.roomTypes.errorMessage,
        requestInProgress: state.roomTypes.requestInProgress,
        accommodationsSelectItems: selectAccommodationsSelectItems(state)
    };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomTypeEditContainerDispatchProps =>
    bindActionCreators(
        {
            saveActionRequest: actions.saveItemAsync.request,
            cancelActionRequest: actions.cancelItemAsync.request,
            loadDependenciesRequest: actions.loadDependenciesAsync.request
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeEditContainer);
