import React, { memo, useState } from 'react';
import { Grid, Button, TextField, Chip } from '@material-ui/core';
import { Formik, useField } from 'formik';
import { DeepReadonly } from 'utility-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';

import {
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { reservationValidationSchema } from './validations';
import { 
  ReservationRoomModel, 
  ReservationRoomGuestModel, 
  RoomTypeModel, 
  RoomModel
} from '../../api/interfaces';
import * as actions from './actions';
import { GuestsCreateContainer } from '../guests';
import { ROUTES } from '../../navigation';
import { getCountOfBedsByRoomAndRoomType } from './selectors';

export type ReservationRoomDetailStateProps = {
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  maxGuests: number;
} & BaseDetailStateProps<ReservationRoomModel>;

export type ReservationRoomDetailDispatchProps = {
  editRoom: typeof actions.editRoomAction;
} & BaseDetailDispatchProps<ReservationRoomModel>;

export type ReservationRoomDetailProps = {
} & BaseDetailProps<ReservationRoomModel> & ReservationRoomDetailStateProps & ReservationRoomDetailDispatchProps;

type RenderFormProps = {
  rooms: { [itemId: number]: DeepReadonly<RoomModel> };
  roomTypes: { [itemId: number]: DeepReadonly<RoomTypeModel> };
  roomsSelectItems: DeepReadonly<RelationSelectItem[]>;
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  editRoom: typeof actions.editRoomAction;
  maxGuests: number;
} & BaseDetailContainerWrappedComponentProps<ReservationRoomModel>;

const nameof = nameofFactory<ReservationRoomModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {

  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    guestsSelectItems,
    cancelActionRequest,
    editRoom,
    maxGuests
  } = props;


  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    cancelActionRequest();
  };

  return (
    <Formik<ReservationRoomModel>
      initialValues={initialValues}
      onSubmit={(values: ReservationRoomModel, { setSubmitting }): void => {
        setSubmitting(true);

        editRoom(values);
      }}
      validationSchema={reservationValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, setFieldValue, values } = formProps;
    
        return (
          <form onSubmit={handleSubmit} className='form-spacing-3' autoComplete="off">
            <Grid container className={classes.grid} spacing={3}>
              <Grid item xs={12}>
                <FormikInput
                  iconName="usd-circle"
                  labelText="Celkem lůžek"
                  name={nameof('countOfBedsByRoomType')}
                  errorMessage={errorMessage}
                  InputProps={{
                    type: 'number',
                    disabled: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  iconName="usd-circle"
                  labelText="Obsazené lůžka"
                  name={nameof('countOfGuests')}
                  errorMessage={errorMessage}
                  InputProps={{
                    type: 'number',
                    disabled: true
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikRelationSelect
                  iconName="hotel"
                  labelText="Ubytovaní hosté"
                  name="guestId"
                  showErrorWhenNotTouched={false}
                  errorMessage={errorMessage}
                  items={guestsSelectItems as RelationSelectItem[]}
                  createComponent={{ component: GuestsCreateContainer, route: ROUTES.SETTINGS_GUESTS_CREATE }}
                />
                <Button variant="contained" title="Přidat" onClick={(e: any): void => {
                  
                  const { guestId, roomId, countOfBedsByRoomType } = values;
                  if (guestId <= 0 || guestId === undefined ||  values.countOfGuests >= countOfBedsByRoomType) {
                    return;
                  }

                  setFieldValue('guestId', 0);
                  const guest = guestsSelectItems.find(guest => guest.value === guestId);
                  const guests = _.cloneDeep(values.guests);
                  let _maxGuests = maxGuests;
                  if (values._guests.length > guests.length) {
                    _maxGuests += (values._guests.length - guests.length);
                  }

                  if (guests.length <= _maxGuests && guests.findIndex(g => g.guestId === guestId) === -1) {
                    guests.push({ 
                      guestId: guestId, 
                      roomId: roomId, 
                      name: guest?.text } as ReservationRoomGuestModel);
                    setFieldValue('guests', guests);
                    setFieldValue('countOfGuests', guests.length);
                  }
                }}>
                  Přidat
                </Button>
              </Grid>
              <Grid item xs={12}>
                {formProps.values.guests && formProps.values.guests.map(guest => {
                  return <Chip
                    label={guest.name}
                    onDelete={(): any => {
                      const guests = formProps.values.guests.filter(g => g.guestId !== guest.guestId);
                      setFieldValue('guests', guests);
                      setFieldValue('countOfGuests', guests.length);
                    }}
                    color="secondary"
                  />
                })}
              </Grid>
            </Grid>
            <Grid container className={classes.grid}>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const ReservationRoomDetail: React.FunctionComponent<ReservationRoomDetailProps> = memo(props => {
  console.debug('ReservationRoomDetail');
  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    editRoom,
    guestsSelectItems,
    maxGuests
  } = props;

  return (
    <BaseDetailContainer<ReservationRoomModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        guestsSelectItems: guestsSelectItems,
        editRoom: editRoom,
        maxGuests: maxGuests
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default ReservationRoomDetail;
