import { createAction, createAsyncAction } from 'typesafe-actions';
import { FloorModel, PaymentModel } from '../../api/interfaces';
import { PaymentCalculationModel } from '../../api/interfaces/paymentCalculationModel';
import {
  createCancelItemAsyncAction,
  createDeleteItemAsyncAction,
  createEditItemAsyncAction,
  createGetItemAsyncAction,
  createGetItemsAsyncAction,
  createLoadDependenciesAsyncAction,
  createNewItemAsyncAction,
  createPagination,
  createSaveItemAsyncAction
} from '../../core';

const modelName = 'payments';

export const getItemAsync = createGetItemAsyncAction<PaymentModel>(modelName);

export const getItemsAsync = createAsyncAction<'GET_PAYMENTS_REQUEST', 'GET_PAYMENTS_SUCCESS', 'GET_PAYMENTS_FAILURE'>(
  `${modelName}/GET_PAYMENTS_REQUEST` as 'GET_PAYMENTS_REQUEST',
  `${modelName}/GET_PAYMENTS_SUCCESS` as 'GET_PAYMENTS_SUCCESS',
  `${modelName}/GET_PAYMENTS_FAILURE` as 'GET_PAYMENTS_FAILURE'
)<number, PaymentModel[], Error>();

export const getAllItemsAsync = createGetItemsAsyncAction<PaymentModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<PaymentModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createAsyncAction(
  `${modelName}/LOAD_CONTAINER_DEPENDENCIES_REQUEST`,
  `${modelName}/LOAD_CONTAINER_DEPENDENCIES_SUCCESS`,
  `${modelName}/LOAD_CONTAINER_DEPENDENCIES_FAILURE`
)<number, void, Error>();

export const calculateAsync = createAsyncAction(
  `${modelName}/CALCULATE_REQUEST`,
  `${modelName}/CALCULATE_SUCCESS`,
  `${modelName}/CALCULATE_FAILURE`
)<number, PaymentCalculationModel, Error>();

export const loadTableDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const setPagination = createPagination(modelName);