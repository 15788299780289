import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";

import { AppAction, AppState } from "./types";
import * as actions from './actions';

export const initialState: AppState = {
  didMount: false
};

const appDidMountReducer = createReducer<typeof initialState.didMount, AppAction>(initialState.didMount)
  .handleAction(actions.afterAuthenticatedAppDidMount, (state, action) => {
    return { ...action };
  })

export default combineReducers<AppState>({
  didMount: appDidMountReducer
});
