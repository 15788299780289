
import { AxiosInstance } from 'axios';

import { ExtendedApiModelClient, BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { AccommodationOffersEvidenceListPriceItemModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class AccommodationOffersEvidenceListPriceListItemClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<AccommodationOffersEvidenceListPriceItemModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'accommodationofferevidencelistpriceitem');
  }
  
  public async getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    throw new Error('Method not implemented.');
  }

  public async updateAsync(model: AccommodationOffersEvidenceListPriceItemModel): Promise<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel>> {
    throw new Error('Method not implemented.');
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    throw new Error('Method not implemented.');
  }

  public async createAsync(model: AccommodationOffersEvidenceListPriceItemModel): Promise<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel>> {
    throw new Error('Method not implemented.');
  }

  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAsync(id: number): Promise<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel>> {
    return await this.getHttpAsync<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel>>(`${id}`, '');
  }

  public async getAllAsync(): Promise<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>>('', '');
  }

  public async getByPageWithIdsAsync(skip: number, take: number, filter: string, ids: string): Promise<BasePageDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}&ids=${ids}`);
  }
}
