import React from 'react';
import {
  TableRow,
  TableCell,
  TablePagination as MuiTablePagination,
  TableFooter
} from '@material-ui/core';
import { TablePaginationModel, TablePageChangeModel } from '.';
import { GetAllRequestAsyncActionPayload } from '../../core';
import { defaultTake } from '../../utils/pagination';

type TablePaginationProps = {
  pagination: TablePaginationModel;
  loadAction?(payload: GetAllRequestAsyncActionPayload | void | TablePageChangeModel): void;
};

type TablePaginationState = {
  take: number;
};

class TablePagination extends React.Component<TablePaginationProps, TablePaginationState> {
  constructor(props: TablePaginationProps) {
    super(props);
    this.state = {
      take: defaultTake
    }
  }

  componentDidMount(): void {
    console.debug('TablePagination.didMount');
  }

  render(): React.ReactFragment {
    const { pagination: { total, page }, loadAction } = this.props;
    return <TableFooter>
      <TableRow>
        <TableCell colSpan={7} >
          <MuiTablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={total}
            labelRowsPerPage={"Počet řádků"}
            rowsPerPage={this.state.take}
            page={page}
            onPageChange={(event, page): void => { 
              if (loadAction) {
                const _take = this.state.take;
                loadAction({ page: page, skip: page * _take, take: _take } as TablePaginationModel);
              }
            }}
            onChangeRowsPerPage={(event): void => { 
              const _take = Number(event.target.value);
              this.setState({ take: _take });
              if (loadAction) {
                loadAction({ page: page, skip: page * _take, take: _take } as TablePaginationModel);
              }
            }}
          />
        </TableCell>
      </TableRow>
    </TableFooter>
  }
}

export default TablePagination;