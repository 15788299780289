import React from 'react';
import { Typography, createStyles, makeStyles, Theme } from '@material-ui/core';

// TODO: Doresit pozici kdyz je stranka malo vysoka, tak aby byla porad dole a to i kdyz je stranka dlouha a text konci v pulce,
// tak paticka musi byt dole
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerText: {
      margin: `${theme.spacing(4)}px 0px 0px `,
      fontSize: '0.9rem',
      fontWeight: 500
    }
  })
);

type UnauthenticatedAppFooterProps = {};

const UnauthenticatedAppFooter: React.FunctionComponent<UnauthenticatedAppFooterProps> = props => {
  const classes = useStyles();

  return (
    <footer>
      <Typography align="center" variant="subtitle1" color="textSecondary" className={classes.footerText}>
        HOTELIOS.city | K-system.CZ s.r.o.
      </Typography>
    </footer>
  );
};

export default UnauthenticatedAppFooter;
