import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { MyCompanyModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class MyCompanyClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'mycompany');
  }

  public async getAsync(): Promise<BaseDataResponse<MyCompanyModel>> {
    return await this.getHttpAsync<BaseDataResponse<MyCompanyModel>>('', '');
  }

  public async updateAsync(model: MyCompanyModel): Promise<BaseDataResponse<MyCompanyModel>> {
    return await this.putHttpAsync<BaseDataResponse<MyCompanyModel>, MyCompanyModel>(``, model);
  }
}
