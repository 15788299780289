
import { ApplicationState } from '../../store/rootReducer';
import { NormalizedListById } from '../../core';
import { LogModel } from '../../api/interfaces/logModel';
import { TablePaginationModel } from '../../components';

export const getAreLogsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.logs.itemList.loaded;
};

export const getAllById = (state: ApplicationState): NormalizedListById<LogModel> => {
  return state.logs.itemList.byId;
};

export const getLogsPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.logs.itemList.page ?? 0,
    skip: state.logs.itemList.skip ?? 0,
    take: state.logs.itemList.take ?? 100,
    total: state.logs.itemList.total ?? 0,
    filter: state.logs.itemList.filter ?? ''
  } as TablePaginationModel;
};