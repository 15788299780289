import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import FloorDetail, { FloorDetailStateProps, FloorDetailDispatchProps, FloorDetailProps } from './floorsDetail';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';

type FloorEditContainerStateProps = {} & FloorDetailStateProps;

type FloorEditContainerDispatchProps = {} & FloorDetailDispatchProps;

type FloorEditContainerProps = {} & FloorDetailProps & FloorEditContainerStateProps & FloorEditContainerDispatchProps;

const FloorEditContainer: React.FunctionComponent<FloorEditContainerProps> = memo(props => {
  console.debug('FloorEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <FloorDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): FloorEditContainerStateProps => {
  return {
    initialValues: state.floors.itemList.byId[ownProps.match.params.id],
    errorMessage: state.floors.errorMessage,
    requestInProgress: state.floors.requestInProgress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): FloorEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorEditContainer);
