import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { NormalizedListById } from '../../core';
import { PriceListModel } from '../../api/interfaces';

export const getPriceListsById = (state: ApplicationState): NormalizedListById<PriceListModel> => {
  return state.priceLists.itemList.byId;
};

export const getPriceListsAllIds = (state: ApplicationState): number[] => {
  return state.priceLists.itemList.allIds;
};

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.priceLists.itemList.loaded;
};

export const getArePriceListsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAccommodationIdByPriceListId = (
  priceListId: number,
  priceListsById: { [itemId: number]: DeepReadonly<PriceListModel> }
): number => {
  console.debug('Calling getAccommodationIdByPriceListId...');
  return Object.values(priceListsById)
    .filter(priceList => {
      return priceList.id === priceListId;
    })[0]?.accommodationId ?? 0;
};

export const selectPriceListsSelectItems = createSelector(
  [getPriceListsById],
  (priceListsById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectPriceListsSelectItems...');

    return Object.values(priceListsById).map<RelationSelectItem>(priceList => {
      return { text: priceList.name, value: priceList.id } as RelationSelectItem;
    });
  }
);
