import React, { memo } from 'react';

import { BaseTableSelectionHeaderComponentModel } from '../../components/table';
import { default as AccommodationOffersSelectionHeader } from './accommodationOffersSelectionHeader';

type AccommodationOffersSelectionHeaderContainerProps = {} & BaseTableSelectionHeaderComponentModel;

const AccommodationOffersSelectionHeaderContainer: React.FunctionComponent<AccommodationOffersSelectionHeaderContainerProps> = memo(props => {
    console.debug('AccommodationOffersSelectionHeaderContainer');

    return <AccommodationOffersSelectionHeader {...props} />;
});

export default AccommodationOffersSelectionHeaderContainer;
