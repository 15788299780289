import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikObserver,
  FormikSwitch
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { companyValidationSchema } from './validations';
import { CompanyModel } from '../../api/interfaces';
import { StatesCreateContainer } from '../states';
import { ROUTES } from '../../navigation';
import { getCompanyInfoAsync } from '../ares';

export type CompanyDetailStateProps = {
  stateSelectItems: DeepReadonly<RelationSelectItem[]>;
} & BaseDetailStateProps<CompanyModel>;

export type CompanyDetailDispatchProps = {
  getCompanyInfoRequest: typeof getCompanyInfoAsync.request;
} & BaseDetailDispatchProps<CompanyModel>;

export type CompanyDetailProps = {} & BaseDetailProps<CompanyModel> & CompanyDetailStateProps & CompanyDetailDispatchProps;

type RenderFormProps = {
  stateSelectItems: DeepReadonly<RelationSelectItem[]>;
  getCompanyInfoRequest: typeof getCompanyInfoAsync.request;
} & BaseDetailContainerWrappedComponentProps<CompanyModel>;

const nameof = nameofFactory<CompanyModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues, stateSelectItems } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<CompanyModel>
      initialValues={initialValues}
      onSubmit={(values: CompanyModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      enableReinitialize={true}
      validationSchema={companyValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormikObserver<CompanyModel> values={formProps.values} onChange={(values, prevValues): void => {
              if (values.ico !== prevValues.ico
                && values.ico?.replace(/\s/g, "").trim().length === 8) {
                props.getCompanyInfoRequest(values.ico.replace(/\s/g, "").trim());
              }
            }} />
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  {
                    <FormikInput
                      iconName="hotel"
                      labelText="Název firmy"
                      name={nameof('name')}
                      errorMessage={errorMessage}
                      InputProps={{
                        autoFocus: true
                      }}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  {<FormikInput iconName="sticky-note" labelText="IČ" name={nameof('ico')} errorMessage={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  {<FormikInput iconName="user" labelText="DIČ" name={nameof('vat')} errorMessage={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  <FormikInput iconName="address-card" labelText="Ulice" name={nameof('street')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput iconName="address-card" labelText="Město" name={nameof('city')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput iconName="address-card" labelText="PSČ" name={nameof('zip')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Stát"
                    name={nameof('stateId')}
                    errorMessage={errorMessage}
                    items={stateSelectItems as RelationSelectItem[]}
                    createComponent={{ component: StatesCreateContainer, route: ROUTES.SETTINGS_STATES_CREATE }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput iconName="address-card" labelText="E-mail" name={nameof('email')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput iconName="address-card" labelText="Telefon" name={nameof('phone')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput iconName="address-card" labelText="Poznámka" name={nameof('note')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={12}>
                  <FormikSwitch name={nameof('allowPayByInvoice')} errorMessage={errorMessage} labelText={'Platby na fakturu'} />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const CompanyDetail: React.FunctionComponent<CompanyDetailProps> = memo(props => {
  console.debug('CompanyDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    stateSelectItems,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    getCompanyInfoRequest
  } = props;

  return (
    <BaseDetailContainer<CompanyModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      {...{
        stateSelectItems: stateSelectItems,
        getCompanyInfoRequest: getCompanyInfoRequest
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default CompanyDetail;
