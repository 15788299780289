import { all, put, takeEvery, ForkEffect, AllEffect, PutEffect, call, CallEffect } from 'redux-saga/effects';
import cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import { setAuthenticatedUserFormCredentials, tryLoadAuthenticatedUserFormCredentials } from './actions';
import { User } from './types';
import { signOutAsync } from '../signOut';
import { signInValidateOptAsync, signInAsync } from '../signIn';
import { ActionType } from 'typesafe-actions';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([
    yield takeEvery(tryLoadAuthenticatedUserFormCredentials, tryLoadAuthenticatedUserFormCredentialHandler),
    yield takeEvery(signOutAsync.success, signOutSuccess),
    yield takeEvery(signInAsync.success, signInSuccess)
  ]);
}

function* tryLoadAuthenticatedUserFormCredentialHandler(
  action: ReturnType<typeof tryLoadAuthenticatedUserFormCredentials>
): Generator<
  LoadAuthenticatedUserFormCredentialGenerator,
  void,
  unknown
> {
  try {
    yield loadAuthenticatedUserFormCredential();
  } catch (error) {
    //TODO: Obecnou akci na chybu, reducer a nejak to propagovat do stavu
  }
}

type TokenDto = {
  email: string;
  roleIds: string;
  id: number;
  exp: number;
};

type LoadAuthenticatedUserFormCredentialGeneratorType = CallEffect | PutEffect<ActionType<typeof setAuthenticatedUserFormCredentials>>;
type LoadAuthenticatedUserFormCredentialGenerator = Generator<LoadAuthenticatedUserFormCredentialGeneratorType, void, unknown>;

function* loadAuthenticatedUserFormCredential(): LoadAuthenticatedUserFormCredentialGenerator {
  console.debug('tryLoadAuthenticatedUserFormCredentialHandler');

  const token = cookies.get('token');
  if (!token) {
    console.log('Token is not defined');
    return;
  }

  //TODO: vymyslet
  const decodedToken = jwtDecode<TokenDto>(token);

  console.debug(decodedToken);

  if (Date.now() >= decodedToken.exp * 1000) {
    // Je expirovany, neni co obnovit
    return;
  }

  // Neni expirovany, muzeme hydratovat uzivatele
  yield put(
    setAuthenticatedUserFormCredentials({
      email: decodedToken.email,
      roleIds: decodedToken.roleIds
    } as User)
  );
}

function* signOutSuccess(action: ReturnType<typeof signOutAsync.success>): Generator<void, void, unknown> {
  try {
    yield console.debug('sigOutSuccess');

    const token = cookies.get('token');
    if (!token) {
      return;
    }

    cookies.remove('token');
  } catch (error) {
    console.debug(error);
  }
}

function* signInSuccess(action: ReturnType<typeof signInAsync.success>): Generator<LoadAuthenticatedUserFormCredentialGenerator, void, unknown> {
  try {
    yield loadAuthenticatedUserFormCredential();
  } catch (error) {
    console.debug(error);
  }
}
