import React, {  } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Chip } from '@material-ui/core';

import { ApplicationState } from '../../store/rootReducer';
import { TableContainer, actionsColumn, GenericColumn, BaseTableRowModel, EditButton } from '../../components';
import { mergeWithIdColumn } from '../../components/table';
import { ReservationRoomModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';
import { ReservationRoomEditContainer, removeRoomAction } from '../reservations';

type ReservationGuestsContainerStateProps = {
  rooms: ReservationRoomModel[];
};

type ReservationGuestContainerDispatchProps = {
  removeRoom: typeof removeRoomAction;
};

type ReservationGuestContainerProps = {
} & ReservationGuestsContainerStateProps &
  ReservationGuestContainerDispatchProps;


const columns = (): GenericColumn<ReservationRoomModel>[] => {
  return mergeWithIdColumn<ReservationRoomModel>([
    // { id: 'id', label: '', minWidth: 25, align: 'left', isVisible: true, isTableActions: false },
    { id: 'roomName', label: 'Pokoj', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
    { id: 'roomTypeName', label: 'Typ pokoje', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
    { id: 'countOfEmptyBeds', label: 'Počet volných lůžek', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
    { id: 'countOfBedsByRoomType', label: 'Počet lůžek', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
    { id: 'countOfGuests', label: 'Počet osob', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
    {
      id: 'guests', label: 'Osoby', minWidth: 100, align: 'left', isVisible: true, isTableActions: false, customComponent: (row): any => {
        if (row.guests) {
          const guests = row.guests.map(guest => {
            return <Chip
              label={guest.name}
              color="secondary"
            />
          });
          if (guests.length > 0) {
            return guests;
          } else {
            return null;
          }
        } 
      }
    },
    {
      ...actionsColumn(), editComponent: (id: number): any => {
        return <EditButton createComponent={{
          component: ReservationRoomEditContainer,
          route: ROUTES.RESERVATION_ROOMS_DETAIL,
          componentProps: { id: id }
        }}
          onClick={(): void => { console.log(`EDIT: ${id}`) }} />
      }
    }
  ])
};

class ReservationGuestContainer extends React.PureComponent<ReservationGuestContainerProps> {
  render(): React.ReactElement {
    console.debug('ReservationGuestContainer.render');

    return (<Grid container direction="row" alignItems="center">
      <Typography>
      </Typography>
      <Grid>
        <TableContainer
          rows={this.props.rooms as []}
          rowNoClick={true}
          columns={columns() as GenericColumn<BaseTableRowModel>[]}
          dataSourcePath=""
          editAction={() => {
            console.log('editAction');
          }}
          deleteAction={(id) => {
            console.log('deleteAction');
            this.props.removeRoom(id);
          }}
        />
      </Grid>
      <Box pt={3} width="100%" />
    </Grid>);
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): ReservationGuestsContainerStateProps => {
  const rooms = state.reservations.current.rooms.filter(room => !room._deleted);
  return {
    rooms: rooms
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReservationGuestContainerDispatchProps => {
  return bindActionCreators(
    {
      removeRoom: removeRoomAction
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationGuestContainer);