import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actions from './actions';
import VatsTable from './vatsTable';
import { ApplicationState } from '../../store/rootReducer';
import { VatModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps
} from '../../components';

type VatContainerStateProps = {} & BaseListStateProps<VatModel>;

type VatContainerDispatchProps = {} & BaseListDispatchProps<VatModel> & BaseListContainerDispatchProps<VatModel>;

type VatsContainerProps = {} & VatContainerStateProps & VatContainerDispatchProps;

const columns = mergeWithDefaultColumns<VatModel>([
  { id: 'name', label: 'Název sazby', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'amount', label: 'Procento sazby', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
]);

const VatsContainer: React.FunctionComponent<VatsContainerProps> = props => {
  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <VatsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): VatContainerStateProps => ({
  items: state.vats.itemList.allIds.map(id => {
    return state.vats.itemList.byId[id];
  }),
  requestInProgress: state.vats.requestInProgress
});

const mapDispatchToProps = (dispatch: Dispatch): VatContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VatsContainer);
