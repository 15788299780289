import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import PriceListItemsTable from './priceListItemsTable';
import * as actions from './actions';

import { AccommodationModel, PriceListItemModel, PriceListModel, RoomTypeModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  BaseListContainer,
  createRelation
} from '../../components';
import { tableConfiguration } from '../../configuration/table';
import { NormalizedListById } from '../../core';
import { getPriceListsById } from '../priceLists/selectors';

type PriceListItemsContainerStateProps = { priceList: NormalizedListById<PriceListModel> } & BaseListStateProps<PriceListItemModel>;

type PriceListItemsContainerDispatchProps = {} & BaseListDispatchProps<PriceListItemModel> & BaseListContainerDispatchProps<PriceListItemModel>;

type PriceListItemsContainerProps = {} & PriceListItemsContainerStateProps & PriceListItemsContainerDispatchProps;

const columns = mergeWithDefaultColumns<PriceListItemModel>([
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  { id: 'description', label: 'Popis', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'priceListId',
    label: 'Ceník',
    minWidth: tableConfiguration.MinColumnWidthForAccommodation,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<PriceListItemModel, PriceListModel>('priceListId', 'name')
  },
  { id: 'price', label: 'Cena', minWidth: 150, align: 'left', isVisible: true, isTableActions: false }
]);

const PriceListItemsContainer: React.FunctionComponent<PriceListItemsContainerProps> = props => {
  console.debug('PriceListItemsContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <PriceListItemsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        relations={{ priceListId: { items: props.priceList } }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): PriceListItemsContainerStateProps => {
  return {
    items: state.priceListItems.itemList.allIds.map<PriceListItemModel>(id => {
      return state.priceListItems.itemList.byId[id];
    }),
    requestInProgress: state.priceListItems.requestInProgress,
    priceList: getPriceListsById(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PriceListItemsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PriceListItemsContainer);
