import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { NormalizedListById } from '../../core';
import { FloorModel } from '../../api/interfaces';

export const getAreFloorsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.floors.itemList.loaded;
};

export const getFloorsById = (state: ApplicationState): NormalizedListById<FloorModel> => {
  return state.floors.itemList.byId;
};

export const selectFloorsSelectItems = createSelector(
  [getFloorsById],
  (floorssById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectAccommodationsSelectItems...');

    return Object.values(floorssById).map<RelationSelectItem>(floor => {
      return { text: floor.name, value: floor.id } as RelationSelectItem;
    });
  }
);
