import React from 'react';
import { useField } from 'formik';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { InputProps as MuiInputProps } from '@material-ui/core';

import { VirtualComponentTypeEnum } from '.';
import { IconName } from '../../icons';
import { VirtualSelectClassKey } from './virtualSelect';
import { default as VirtualSelectContainer } from './virtualSelectContainer';
import { BaseComponentWithModalSupport } from '../baseDetail';
import { ROUTES } from '../../navigation';
import { relationConfiguration } from '../../configuration/relations';

type FormikVirtualSelectProps = {
  type: VirtualComponentTypeEnum;
  name: string;
  filter: number | string | undefined;
  errorMessage?: string;
  iconName: IconName;
  labelText: string;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  classes?: Partial<ClassNameMap<VirtualSelectClassKey>>;
  createComponent?: {
    iconName?: IconName;
    component: React.ComponentType<BaseComponentWithModalSupport>;
    route: ROUTES;
  };
  showErrorWhenNotTouched?: boolean;
  disabled?: boolean;
  onChange?: (newValue: number) => void;
};

const FormikVirtualSelect = (props: FormikVirtualSelectProps): React.ReactElement => {
  const [field, meta, helpers] = useField(props.name);

  const onChange = (newValue: number): void => {
    helpers.setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const onRemoveRelationHandler = (): void => {
    helpers.setValue(relationConfiguration.relationNotSetId, true);
  };

  return (
    <VirtualSelectContainer
      value={field.value}
      iconName={props.iconName}
      labelText={props.labelText}
      helperText={meta.touched || props.showErrorWhenNotTouched ? meta.error : ''}
      error={(Boolean(meta.touched) || props.showErrorWhenNotTouched) && (Boolean(meta.error) || Boolean(props.errorMessage))}
      classes={{ ...props.classes }}
      disabled={props.disabled}
      onRemoveRelation={onRemoveRelationHandler}
      createComponent={props.createComponent}
      InputProps={{
        ...props.InputProps,
        name: props.name,
        onBlur: field.onBlur,
      }}
      onChange={onChange}
      type={props.type}
      filter={props.filter}
    />
  );
};

export default FormikVirtualSelect;
