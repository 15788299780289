import { RoleEnum } from '../api/interfaces';
import { MenuItemModel } from '../components/menu';
import { ROUTES } from '../navigation';

export const MenuItemsConfiguration: MenuItemModel[] = [
    new MenuItemModel(ROUTES.TIME_LINE, 'Kalendář', 'calendar-alt'),
    new MenuItemModel(ROUTES.ACCOMMODATION_OFFERS, 'Rezervace', 'list-alt'),
    new MenuItemModel(ROUTES.SETTINGS_GUESTS, 'Hosté', 'users'),
    new MenuItemModel(ROUTES.ACCOMMODATED_GUESTS, 'Ubytovaní', 'users'),
    new MenuItemModel(ROUTES.PAYMENTS, 'Platby', 'wallet'),
    new MenuItemModel(ROUTES.SETTINGS, 'Nastavení', 'cog', RoleEnum.Admin)
];

export const SettingsMenuItemsConfiguration: MenuItemModel[] = [
    new MenuItemModel(ROUTES.SETTINGS_USERS, 'Uživatelé', 'users', RoleEnum.SuperAdmin),
    new MenuItemModel(ROUTES.SETTINGS_ACCOMMODATIONS, 'Ubytovací zařízení', 'hotel', RoleEnum.SuperAdmin),
    new MenuItemModel(ROUTES.SETTINGS_ROOM_TYPES, 'Typy pokojů', 'hotel', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_FLOORS, 'Patra', 'users', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_ROOMS, 'Pokoje', 'users', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_GUESTS, 'Hosté', 'users', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_COMPANIES, 'Firmy', 'users', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_PRICE_LISTS, 'Ceníky', 'usd-circle', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_PRICE_LIST_ITEMS, 'Položky ceníku', 'usd-circle', RoleEnum.Admin),
    new MenuItemModel(ROUTES.SETTINGS_NUMBER_SERIES, 'Řady', 'usd-circle', RoleEnum.SuperAdmin),
    new MenuItemModel(ROUTES.SETTINGS_VATS, 'DPH', 'usd-circle', RoleEnum.SuperAdmin),
    new MenuItemModel(ROUTES.SETTINGS_MY_COMPANY, 'Fakturační údaje', 'usd-circle', RoleEnum.SuperAdmin),
    new MenuItemModel(ROUTES.SETTINGS_LOGS, 'Logy', 'usd-circle', RoleEnum.SuperAdmin)

];
