import React from 'react';
import { IconProps as MuiIconProps, default as MuiIcon } from '@material-ui/core/Icon';
import { IconPrefix, IconName } from '../../icons';
import { Tooltip } from '@material-ui/core';

type IconProps = {
  fixedWidth?: boolean;
  iconPrefix?: IconPrefix;
  IconProps?: MuiIconProps;
  iconName: IconName;
  className?: string;
  toolTip?: string;
};

export default (props: IconProps): React.ReactElement => {
  const { iconName, iconPrefix, IconProps, className, toolTip, ...otherProps } = props;

  return (
    <MuiIcon {...IconProps}>
      {toolTip && <Tooltip title={toolTip}>
        <i className={[className, `icon-${iconName}`].join(' ')} {...otherProps} />
      </Tooltip>}
      {!toolTip && <i className={[className, `icon-${iconName}`].join(' ')} {...otherProps} />}
    </MuiIcon>
  );
};
