import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actions from './actions';
import { ReservationRoomGuestModel, ReservationRoomModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import ReservationRoomDetail, { ReservationRoomDetailProps, ReservationRoomDetailDispatchProps, ReservationRoomDetailStateProps } from './reservationRoomDetail';
import { selectGuestsSelectItems } from '../guests';
import { totalGuestsThatCanBeAdded } from './selectors';

type ReservationRoomCreateContainerStateProps = {

} & ReservationRoomDetailStateProps;

type ReservationRoomCreateContainerDispatchProps = {
  editRoom: typeof actions.editRoomAction;
} & ReservationRoomDetailDispatchProps;

type ReservationRoomCreateContainerProps = {} & ReservationRoomDetailProps & ReservationRoomCreateContainerStateProps & ReservationRoomCreateContainerDispatchProps;

const createInitialValues = {
  guests: [] as ReservationRoomGuestModel[]
} as ReservationRoomModel;

const ReservationRoomCreateContainer: React.FunctionComponent<ReservationRoomCreateContainerProps> = memo(props => {
  console.debug('ReservationRoomCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    editRoom,
    guestsSelectItems,
    maxGuests
  } = props;

  return (
    <React.Fragment>
      <ReservationRoomDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        editRoom={editRoom}
        guestsSelectItems={guestsSelectItems}
        maxGuests={maxGuests}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): ReservationRoomCreateContainerStateProps => {
  const maxGuests = totalGuestsThatCanBeAdded(state);

  return {
    initialValues: createInitialValues,
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    guestsSelectItems: selectGuestsSelectItems(state),
    maxGuests: maxGuests
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReservationRoomCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveRoomItemAsync.request,
        cancelActionRequest: actions.cancelRoomItemAsync.request,
        loadDependenciesRequest: actions.loadRoomDependenciesAsync.request,
        editRoom: actions.editRoomAction
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRoomCreateContainer);
