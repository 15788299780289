import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ROUTES } from '../../navigation';
import { AccommodationsContainer, AccommodationsCreateContainer, AccommodationsEditContainer } from '../accommodations';
import { UsersContainer, UsersEditContainer, InviteUserContainer } from '../users';
import { RoomTypesContainer, RoomTypesCreateContainer, RoomTypesEditContainer } from '../roomTypes';
import { RoomsCreateContainer, RoomsContainer, RoomsEditContainer } from '../rooms';
import { FloorsCreateContainer, FloorsContainer, FloorsEditContainer } from '../floors';
import { GuestsContainer, GuestsCreateContainer, GuestsEditContainer } from '../guests';
import { CompaniesContainer, CompaniesCreateContainer, CompaniesEditContainer } from '../companies';
import { PriceListsContainer, PriceListsCreateContainer, PriceListsEditContainer } from '../priceLists';
import { PriceListItemsContainer, PriceListItemsCreateContainer, PriceListItemsEditContainer } from '../priceListItems';
import { NumberSeriesContainer } from '../numberSeries';
import { MyCompanyContainer } from '../myCompany';
import { VatsContainer, VatCreateContainer, VatEditContainer } from '../vats';
import { LogsContainer } from '../logs';

type SettingsProps = {};

const Settings: React.FunctionComponent<SettingsProps> = props => {
  console.debug('SettingsRouter');

  return (
    <Switch>
      <Route exact path={ROUTES.SETTINGS}>
        <Redirect to={ROUTES.SETTINGS_ACCOMMODATIONS} />;
      </Route>
      <Route exact path={ROUTES.SETTINGS_USERS}>
        <UsersContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_USERS_INVITE}>
        <InviteUserContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_USERS_DETAIL} component={UsersEditContainer} />
      <Route exact path={ROUTES.SETTINGS_ACCOMMODATIONS}>
        <AccommodationsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_ACCOMMODATIONS_CREATE}>
        <AccommodationsCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_ACCOMMODATIONS_DETAIL} component={AccommodationsEditContainer} />

      <Route exact path={ROUTES.SETTINGS_ROOM_TYPES}>
        <RoomTypesContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_ROOM_TYPES_CREATE}>
        <RoomTypesCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_ROOM_TYPES_DETAIL} component={RoomTypesEditContainer} />

      <Route exact path={ROUTES.SETTINGS_ROOMS}>
        <RoomsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_ROOMS_CREATE}>
        <RoomsCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_ROOMS_DETAIL} component={RoomsEditContainer} />

      <Route exact path={ROUTES.SETTINGS_FLOORS}>
        <FloorsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_FLOORS_CREATE}>
        <FloorsCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_FLOORS_DETAIL} component={FloorsEditContainer} />

      <Route exact path={ROUTES.SETTINGS_GUESTS}>
        <GuestsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_GUESTS_CREATE}>
        <GuestsCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_GUESTS_DETAIL} component={GuestsEditContainer} />

      <Route exact path={ROUTES.SETTINGS_COMPANIES}>
        <CompaniesContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_COMPANIES_CREATE}>
        <CompaniesCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_COMPANIES_DETAIL} component={CompaniesEditContainer} />

      <Route exact path={ROUTES.SETTINGS_PRICE_LISTS}>
        <PriceListsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_PRICE_LISTS_CREATE}>
        <PriceListsCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_PRICE_LISTS_DETAIL} component={PriceListsEditContainer} />

      <Route exact path={ROUTES.SETTINGS_PRICE_LIST_ITEMS}>
        <PriceListItemsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_PRICE_LIST_ITEMS_CREATE}>
        <PriceListItemsCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_PRICE_LIST_ITEMS_DETAIL} component={PriceListItemsEditContainer} />

      <Route exact path={ROUTES.SETTINGS_NUMBER_SERIES}>
        <NumberSeriesContainer />
      </Route>

      <Route exact path={ROUTES.SETTINGS_MY_COMPANY}>
        <MyCompanyContainer />
      </Route>

      <Route exact path={ROUTES.SETTINGS_VATS}>
        <VatsContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_VATS_CREATE}>
        <VatCreateContainer />
      </Route>
      <Route exact path={ROUTES.SETTINGS_VATS_DETAIL} component={VatEditContainer} />

      <Route exact path={ROUTES.SETTINGS_LOGS}>
        <LogsContainer />
      </Route>

      <Route path="*">
        <Redirect to={ROUTES.ROOT} />
      </Route>
    </Switch>
  );
};

export default Settings;
