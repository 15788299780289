import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import FloorDetail, { FloorDetailStateProps, FloorDetailDispatchProps, FloorDetailProps } from './floorsDetail';
import * as actions from './actions';
import { FloorModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';

type FloorCreateContainerStateProps = {} & FloorDetailStateProps;

type FloorCreateContainerDispatchProps = {} & FloorDetailDispatchProps;

type FloorCreateContainerProps = {} & FloorDetailProps & FloorCreateContainerStateProps & FloorCreateContainerDispatchProps;

const createInitialValues = {
    description: '',
    name: '',
    number: 0
} as FloorModel;

const FloorCreateContainer: React.FunctionComponent<FloorCreateContainerProps> = memo(props => {
    console.debug('FloorCreateContainer');

    const {
        errorMessage,
        requestInProgress,
        initialValues,
        modalDialog,
        modalDialogRoute,
        saveActionRequest,
        cancelActionRequest,
        loadDependenciesRequest
    } = props;

    return (
        <FloorDetail
            initialValues={initialValues}
            loadDependenciesRequest={loadDependenciesRequest}
            saveActionRequest={saveActionRequest}
            cancelActionRequest={cancelActionRequest}
            errorMessage={errorMessage}
            requestInProgress={requestInProgress}
            modalDialog={modalDialog}
            modalDialogRoute={modalDialogRoute}
        />
    );
});

const mapStateToProps = (state: ApplicationState): FloorCreateContainerStateProps => {
    return {
        initialValues: createInitialValues,
        errorMessage: state.rooms.errorMessage,
        requestInProgress: state.rooms.requestInProgress,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): FloorCreateContainerDispatchProps => {
    return {
        ...bindActionCreators(
            {
                saveActionRequest: actions.saveItemAsync.request,
                cancelActionRequest: actions.cancelItemAsync.request,
                loadDependenciesRequest: actions.loadDependenciesAsync.request
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorCreateContainer);
