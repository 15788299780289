import React, { memo, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Chip, MenuItem, Select, Input } from '@material-ui/core';

import { ApplicationState } from '../../store/rootReducer';
import { AccommodationOfferRoomTypeCalculationModel, PriceListItemModel, PriceListItemType } from '../../api/interfaces';
import { BaseTableColumnId } from '../../components/table';
import moment from 'moment';
import _ from 'lodash';

type RoomTypeTotalPriceContainerStateProps = {
  priceListItem: PriceListItemModel | undefined;
  value: number;
  type: PriceListItemType;
  from: Date;
  to: Date;
};

type RoomTypeTotalPriceContainerDispatchProps = {
};

type RoomTypePriceContainerProps = {
  roomTypeId: number;
  priceListItemPerBedAndNight: number;
  priceListItemPerRoomAndNight: number;
  onChange: (value: number, columnId: BaseTableColumnId) => {};
} & RoomTypeTotalPriceContainerStateProps &
  RoomTypeTotalPriceContainerDispatchProps;

const RoomTypePriceContainer: React.FunctionComponent<RoomTypePriceContainerProps> = memo(props => {
  const { priceListItem, onChange, type, from, to } = props;
  const [value, setValue] = React.useState(props.value);

  useEffect(() => {
    console.debug('RoomTypePriceContainer.useEffect');
  }, [priceListItem]);

  useEffect(() => {
    console.debug('RoomTypePriceContainer.useEffect');
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const getDiffDays = () => {
    const _from = moment(from);
    const _to = moment(to);

    return _to.diff(_from, 'days');
  }

  return <div>
    <Input type="number"
      value={value}
      inputProps={{ style: { textAlign: 'right' } }}
      onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
        const newValue = event.target.value as number;
        const columnId = type === PriceListItemType.PerBedAndNight ? 'pricePerBedAndNight' : 'pricePerRoomAndNight';
        onChange(newValue / getDiffDays(), columnId as BaseTableColumnId);
        setValue(newValue);
      }} />
  </div>
});

const mapStateToProps = (state: ApplicationState, ownProps: any): RoomTypeTotalPriceContainerStateProps => {
  const { payForRoomAndNight, from, to } = state.accommodationOfferCalculations.offerHeader;
  const type = payForRoomAndNight ? PriceListItemType.PerRoomAndNight : PriceListItemType.PerBedAndNight;
  const items = state.priceListItems.itemList.allIds.map<PriceListItemModel>(id => {
    return state.priceListItems.itemList.byId[id];
  });
  const priceListItemId = payForRoomAndNight ? ownProps.priceListItemPerRoomAndNight : ownProps.priceListItemPerBedAndNight;
  const value = payForRoomAndNight ? ownProps.pricePerRoomAndNight : ownProps.pricePerBedAndNight;
  const priceListItem = items.find(item => item.id === priceListItemId);
  const _from = moment(from);
  const _to = moment(to);
  const _totalDays = _to.diff(_from, 'days');

  return {
    priceListItem: priceListItem,
    value: _.round(value * _totalDays, 2),
    from: from,
    to: to,
    type: type
  };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomTypeTotalPriceContainerDispatchProps => {
  return bindActionCreators(
    {
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypePriceContainer);