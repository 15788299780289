import { AxiosInstance } from 'axios';

import { ExtendedApiModelClient, BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { AccommodationOffersEvidenceListModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class AccommodationOffersEvidenceListClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<AccommodationOffersEvidenceListModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'accommodationofferevidencelist');
  }
 
  public async getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    throw new Error('Method not implemented.');
  }

  public async updateAsync(model: AccommodationOffersEvidenceListModel): Promise<BaseDataResponse<AccommodationOffersEvidenceListModel>> {
    throw new Error('Method not implemented.');
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    throw new Error('Method not implemented.');
  }

  public async createAsync(model: AccommodationOffersEvidenceListModel): Promise<BaseDataResponse<AccommodationOffersEvidenceListModel>> {
    throw new Error('Method not implemented.');
  }

  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<AccommodationOffersEvidenceListModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<AccommodationOffersEvidenceListModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAsync(id: number): Promise<BaseDataResponse<AccommodationOffersEvidenceListModel>> {
    return await this.getHttpAsync<BaseDataResponse<AccommodationOffersEvidenceListModel>>(`${id}`, '');
  }

  public async getAllAsync(): Promise<BaseDataResponse<AccommodationOffersEvidenceListModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<AccommodationOffersEvidenceListModel[]>>('', '');
  }
}