import { createAction } from 'typesafe-actions';

import {
  createNewItemAsyncAction,
  createSaveItemAsyncAction,
  createCancelItemAsyncAction,
  createLoadDependenciesAsyncAction
} from '../../core';

import { AccommodationOfferFeeModel, PriceListItemModel } from '../../api/interfaces';

const modelName = 'accommodationOfferFee';

export const newItemAsync = createNewItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<AccommodationOfferFeeModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const addFeeAction = createAction(`${modelName}/ADD_FEE`)<AccommodationOfferFeeModel>();

export const removeFeeAction = createAction(`${modelName}/REMOVE_FEE`)<number>();

export const selectPriceListItemAction = createAction(`${modelName}/SELECT_PRICE_LIST_ITEM`)<{ id: number; text: string }>();
