import React from 'react';
import { makeStyles, Theme, createStyles, Toolbar } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      minHeight: '48px'
    }
  })
);

export type BaseToolbarProps = {};

const BaseToolbar: React.FunctionComponent<BaseToolbarProps> = props => {
  console.debug('BaseToolbar');
  const classes = useStyles();

  return <Toolbar className={classes.toolBar}>{props.children}</Toolbar>;
};

export default BaseToolbar;
