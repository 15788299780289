import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import AccommodationsTable from './accommodationsTable';
import * as actions from './actions';

import { AccommodationModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  BaseListContainer
} from '../../components';

type AccommodationsContainerStateProps = {} & BaseListStateProps<AccommodationModel>;

type AccommodationsContainerDispatchProps = {} & BaseListDispatchProps<AccommodationModel> & BaseListContainerDispatchProps<AccommodationModel>;

type AccommodationsContainerProps = {} & AccommodationsContainerStateProps & AccommodationsContainerDispatchProps;

const columns = mergeWithDefaultColumns<AccommodationModel>([
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  { id: 'description', label: 'Popis', minWidth: 200, align: 'left', isVisible: true, isTableActions: false }
]);

const AccommodationsContainer: React.FunctionComponent<AccommodationsContainerProps> = props => {
  console.debug('AccommodationsContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <AccommodationsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): AccommodationsContainerStateProps => {
  return {
    items: state.accommodations.itemList.allIds.map(id => {
      return state.accommodations.itemList.byId[id];
    }),
    requestInProgress: state.accommodations.requestInProgress
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationsContainer);
