import { default as TenantDomains } from './tenantDomains';

class TenantProvider {
  getTenant = (): string => {
    const currentTenantDomain = this.getSubdomain();

    for (const domainKey in TenantDomains) {
      const domain = TenantDomains[domainKey];
      if (currentTenantDomain === domain) {
        return domainKey;
      }
    }

    throw new Error(`Unsupported tenant ${currentTenantDomain}`);
  };

  private getSubdomain = (): string => {
    const full = window.location.host;
    const parts = full.split('.');
    const subWithPort = parts[0];
    const subWithPortParts = subWithPort.split(':');

    return subWithPortParts[0];
  };
}

export default new TenantProvider();
