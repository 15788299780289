import { put, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  PaginationActionType,
  GetItemAsyncType,
  GetItemsAsyncType,
  SaveItemAsyncType,
  CancelItemAsyncActionType,
  DeleteItemAsyncActionType
} from '../core';

import apiClient from '../api/apiClientInstance';
import { BaseModel } from '../api/interfaces';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../api/responses';
import { navigateTo, ROUTES } from '../navigation';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired } from '../core';
import { ApiModelClient, ExtendedApiModelClient, ApiModelClientWithDelete } from '../api/clients';
import { getIsSomeModalOpened, closeTopModal } from '../components';
import { TablePageChangeModel } from '../components/table';
import pagination from '../utils/pagination';
import { ApplicationState } from '../store/rootReducer';

type GetEntityRequestGeneratorType<TModel extends BaseModel> =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<TModel>>
  | PutEffect<ActionType<GetItemAsyncType<TModel>['cancel']>>
  | PutEffect<ActionType<GetItemAsyncType<TModel>['success']>>
  | PutEffect<ActionType<GetItemAsyncType<TModel>['failure']>>;

export const registerGetEntityRequest = <TModel extends BaseModel>(
  getItemAsyncSuccess: GetItemAsyncType<TModel>['success'],
  getItemAsyncFailure: GetItemAsyncType<TModel>['failure'],
  getItemAsyncCancel: GetItemAsyncType<TModel>['cancel'],
  apiModelClient: ExtendedApiModelClient<TModel>
) =>
  function* getEntityRequest(
    action: ActionType<GetItemAsyncType<TModel>['request']>
  ): Generator<GetEntityRequestGeneratorType<TModel>, void, boolean & BaseDataResponse<TModel>> {
    try {

      console.debug('getEntityRequest');
      const response: BaseDataResponse<TModel> = yield apiClient.apiCallHandler({
        context: apiModelClient,
        apiCallFnName: 'getAsync',
      }, action.payload);

      if (response.resultCode === 0) {
        yield put(getItemAsyncSuccess(response.data));
      } else {
        yield put(getItemAsyncFailure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
      }
    } catch (error) {
      yield put(getItemAsyncFailure(error));
    }
  };

type GetEntitiesRequestGeneratorType<TModel extends BaseModel> =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<TModel[]>>
  | PutEffect<ActionType<GetItemsAsyncType<TModel>['cancel']>>
  | PutEffect<ActionType<GetItemsAsyncType<TModel>['success']>>
  | PutEffect<ActionType<GetItemsAsyncType<TModel>['failure']>>;

export const registerGetEntitiesRequest = <TModel extends BaseModel>(
  getItemsAsyncSuccess: GetItemsAsyncType<TModel>['success'],
  getItemsAsyncFailure: GetItemsAsyncType<TModel>['failure'],
  getItemsAsyncCancel: GetItemsAsyncType<TModel>['cancel'],
  apiModelClient: ApiModelClient<TModel>
) =>
  function* getEntitiesRequest(
    action: ActionType<GetItemsAsyncType<TModel>['request']>
  ): Generator<GetEntitiesRequestGeneratorType<TModel>, void, boolean & BaseDataResponse<TModel[]>> {
    try {
      console.debug('getEntitiesRequest');

      const payload = action.payload as GetAllRequestAsyncActionPayload;
      const isReloadRequired = yield isDataReloadRequired(() => true, payload);
      if (!isReloadRequired) {
        yield put(getItemsAsyncCancel());
        return;
      }

      const response: BaseDataResponse<TModel[]> = yield apiClient.apiCallHandler({
        context: apiModelClient,
        apiCallFnName: 'getAllAsync'
      });

      if (response.resultCode === 0) {
        yield put(getItemsAsyncSuccess(response.data));
      } else {
        yield put(getItemsAsyncFailure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
      }
    } catch (error) {
      yield put(getItemsAsyncFailure(error));
    }
  };

type SaveEntityRequestGeneratorType<TModel extends BaseModel> =
  | Promise<BaseDataResponse<TModel>>
  | PutEffect<ActionType<SaveItemAsyncType['success']>>
  | PutEffect<ActionType<SaveItemAsyncType['failure']>>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

export const registerSaveEntityRequest = <TModel extends BaseModel>(
  saveItemAsyncSuccess: SaveItemAsyncType['success'],
  saveItemAsyncFailure: SaveItemAsyncType['failure'],
  apiModelClient: ApiModelClient<TModel>,
  navigateToRoute: ROUTES
) =>
  function* saveEntityRequest(
    action: ActionType<SaveItemAsyncType['request']>
  ): Generator<SaveEntityRequestGeneratorType<TModel>, void, BaseDataResponse<TModel> & boolean> {
    try {
      console.debug('saveEntityRequest');

      const model = action.payload;
      const method: keyof typeof apiClient.Accommodations = model.id > 0 ? 'updateAsync' : 'createAsync';
      const response: BaseDataResponse<TModel> = yield apiClient.apiCallHandler({ context: apiModelClient, apiCallFnName: method }, model);

      if (response.resultCode === 0) {
        const data = response.data;
        yield put(saveItemAsyncSuccess(data));

        const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
        if (isSomeModalOpend) {
          yield put(closeTopModal());
        } else {
          yield put(navigateTo(navigateToRoute));
        }
      } else {
        yield put(saveItemAsyncFailure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
      }
    } catch (error) {
      yield put(saveItemAsyncFailure(error));
    }
  };

type CancelEntityRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<CancelItemAsyncActionType['success']>>
  | PutEffect<ActionType<CancelItemAsyncActionType['failure']>>
  | SelectEffect;

export const registerCancelEntityRequest = (
  cancelItemAsyncSuccess: CancelItemAsyncActionType['success'],
  cancelItemAsyncFailure: CancelItemAsyncActionType['failure'],
  navigateToRoute: ROUTES
) =>
  function* cancelEntityRequest(
  ): Generator<CancelEntityRequestGeneratorType, void, boolean> {
    try {
      console.debug('cancelEntityRequest');
      const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);

      if (isSomeModalOpend) {
        yield put(closeTopModal());
      } else {
        yield put(navigateTo(navigateToRoute));
      }
      yield put(cancelItemAsyncSuccess());
    } catch (error) {
      yield put(cancelItemAsyncFailure(error));
    }
  };

type DeleteEntityRequestGeneratorType =
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<DeleteItemAsyncActionType['success']>>
  | PutEffect<ActionType<DeleteItemAsyncActionType['failure']>>;

export const registerDeleteEntityRequest = (
  deleteItemAsyncSuccess: DeleteItemAsyncActionType['success'],
  deleteItemAsyncFailure: DeleteItemAsyncActionType['failure'],
  apiModelClient: ApiModelClientWithDelete,
  navigateToRoute: ROUTES
) =>
  function* deleteEntityRequest(
    action: ActionType<DeleteItemAsyncActionType['request']>
  ): Generator<DeleteEntityRequestGeneratorType, void, BaseResponse> {
    try {
      console.debug('deleteEntityRequest');
      const itemToDeleteId = action.payload;
      const response: BaseResponse = yield apiClient.apiCallHandler(
        { context: apiModelClient, apiCallFnName: 'deleteAsync' },
        itemToDeleteId
      );

      if (response.resultCode === 0) {
        yield put(deleteItemAsyncSuccess(itemToDeleteId));
        yield put(navigateTo(navigateToRoute));
      } else {
        yield put(deleteItemAsyncFailure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
      }
    } catch (error) {
      yield put(deleteItemAsyncFailure(error));
    }
  };

type GetEntitiesByPageRequestGeneratorType<TModel extends BaseModel> =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<TModel[]>>
  | PutEffect<ActionType<PaginationActionType>>
  | PutEffect<ActionType<GetItemsAsyncType<TModel>['cancel']>>
  | PutEffect<ActionType<GetItemsAsyncType<TModel>['success']>>
  | PutEffect<ActionType<GetItemsAsyncType<TModel>['failure']>>;

export const registerGetEntitiesByPageRequest = <TModel extends BaseModel>(
  getItemsAsyncSuccess: GetItemsAsyncType<TModel>['success'],
  getItemsAsyncFailure: GetItemsAsyncType<TModel>['failure'],
  getItemsAsyncCancel: GetItemsAsyncType<TModel>['cancel'],
  apiModelClient: ExtendedApiModelClient<TModel>,
  setPagination: PaginationActionType,
  getPagination: (state: ApplicationState) => { skip: number; take?: number; page: number; filter: string }
) =>
  function* getEntitiesByPageRequest(
    action: ActionType<GetItemsAsyncType<TModel>['request']>
  ): Generator<GetEntitiesByPageRequestGeneratorType<TModel>, void,
    boolean
    & TablePageChangeModel
    & BasePageDataResponse<TModel[]>> {
    try {
      console.debug('getEntityRequest');
      const payload = action.payload as TablePageChangeModel;
      const stored = yield select(getPagination);
      const { _filter, _skip, _take } = pagination(payload, stored);

      const response: BasePageDataResponse<TModel[]> = yield apiClient.apiCallHandler({
        context: apiModelClient,
        apiCallFnName: 'getByPageAsync'
      }, _skip, _take, _filter);

      if (response.resultCode === 0) {
        yield put(setPagination({
          page: payload?.page ?? 0,
          skip: _skip,
          take: _take,
          total: response.total,
          filter: payload?.filter ?? _filter ?? ""
        }));

        yield put(getItemsAsyncSuccess(response.data));
      } else {
        yield put(getItemsAsyncFailure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
      }
    } catch (error) {
      yield put(getItemsAsyncFailure(error));
    }
  };