import React, { useState, ReactNode } from 'react';
import { Theme, InputAdornment, Input as MuiInput, InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { InputProps as MuiInputProps } from '@material-ui/core/Input';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { IconName, Icon } from '../../icons';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  labelFormControl: {
    transform: 'translate(44px, 24px) scale(1)'
  },
  iconRoot: {
    width: '1.3rem',
    color: palette.text.secondary
  },
  inputInput: {
    fontWeight: 'bold'
  },
  inputRoot: {
  },
  inputStartAdornment: {
    marginRight: spacing(2)
  }
}));

export type InputClassKey = 'labelFormControl' | 'iconRoot' | 'inputInput' | 'inputRoot' | 'inputStartAdornment';

export type InputProps = {
  classes: Partial<ClassNameMap<InputClassKey>>;
  iconName: IconName;
  labelText: string;
  helperText: ReactNode;
  error: boolean;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
};

const Input: React.FunctionComponent<InputProps> = props => {
  const shrink = props.InputLabelProps ? props.InputLabelProps.shrink : false;

  return (
    <React.Fragment>
      <FormControl fullWidth>
        <InputLabel
          classes={{
            formControl: !shrink ? props.classes.labelFormControl : undefined
          }}
          {...props.InputLabelProps}
        >
          {props.labelText}
        </InputLabel>
        <MuiInput
          classes={{
            root: props.classes.inputRoot,
            input: props.classes.inputInput
          }}
          startAdornment={
            <InputAdornment position="start" classes={{ root: props.classes.inputStartAdornment }}>
              <Icon iconName={props.iconName} IconProps={{ className: props.classes.iconRoot }} fixedWidth />
            </InputAdornment>
          }
          {...{ ...props.InputProps, error: props.error }}
        />
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      </FormControl>
    </React.Fragment>
  );
};

export default withStylesFunction(Input);
