import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actions from './actions';
import { ReservationGuestCheckOutModel, ReservationRoomModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import ReservationGuestCheckOutDetail, { 
  ReservationGuestCheckOutDetailProps, 
  ReservationGuestCheckOutDetailDispatchProps, 
  ReservationGuestCheckOutDetailStateProps } from './reservationGuestCheckOutDetail';
import { selectGuestsSelectItems, selectRoomsSelectItems, selectRoomsSelectItemsByGuestId } from '.';
import { NormalizedListById } from '../../core';

type ReservationGuestCheckOutEditContainerStateProps = {

} & ReservationGuestCheckOutDetailStateProps;

type ReservationGuestCheckOutEditContainerDispatchProps = {
} & ReservationGuestCheckOutDetailDispatchProps;

type ReservationGuestCheckOutEditContainerProps = { } & ReservationGuestCheckOutDetailProps & ReservationGuestCheckOutEditContainerStateProps & ReservationGuestCheckOutEditContainerDispatchProps;

const createInitialValues = {
  guestId: 0,
  from: new Date(),
  to: new Date(),
  id: 0,
  roomId: 0
} as ReservationGuestCheckOutModel;

const ReservationGuestCheckOutEditContainer: React.FunctionComponent<ReservationGuestCheckOutEditContainerProps> = memo(props => {
  console.debug('ReservationGuestCheckOutEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    guestsSelectItems,
    roomsSelectItems,
    selectRoomsSelectItemsByGuestId,
    rooms,
    type,
    getInfo,
    info
  } = props;

  return (
    <React.Fragment>
      <ReservationGuestCheckOutDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        guestsSelectItems={guestsSelectItems}
        roomsSelectItems={roomsSelectItems}
        selectRoomsSelectItemsByGuestId={selectRoomsSelectItemsByGuestId}
        rooms={rooms}
        type={type}
        getInfo={getInfo}
        info={info}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): ReservationGuestCheckOutEditContainerStateProps => {
  const rooms: NormalizedListById<ReservationRoomModel> = [];
  state.reservations.current.rooms.map((model: ReservationRoomModel) => {
    rooms[model.id] = model;

    return model.id;
  });

  const checkOut = state.reservations.checkOuts.find(checkOut => checkOut.id === ownProps.id) ?? createInitialValues;

  return {
    initialValues: { ...checkOut, reservationId: state.reservations.current.id },
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    guestsSelectItems: selectGuestsSelectItems(state),
    roomsSelectItems: selectRoomsSelectItems(state),
    rooms: rooms,
    type: ownProps.type,
    info: state.reservations.info
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReservationGuestCheckOutEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveCheckOutItemAsync.request,
        cancelActionRequest: actions.cancelCheckOutItemAsync.request,
        loadDependenciesRequest: actions.loadCheckOutDependenciesAsync.request,
        getInfo: actions.getCheckOutInfoAsync.request
      },
      dispatch
    ),
    selectRoomsSelectItemsByGuestId: selectRoomsSelectItemsByGuestId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationGuestCheckOutEditContainer);
