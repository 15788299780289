import axios from 'axios';
import { all, put, takeEvery, PutEffect, SelectEffect, select, take, TakeEffect } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import apiClient from '../../api/apiClientInstance';
import { deleteItemAsync, getItemsAsync, loadDependenciesAsync, loadTableDependenciesAsync, newTableItemAsync, setPagination, newItemAsync, cancelItemAsync, downloadAction } from './actions';
import { getAccommodationOffersEvidenceListPagination, getAreItemsLoaded, getSelectedIds, getSelectedItems, getState } from './selectors';
import {
  AccommodationOffersEvidenceListModel,
  AccommodationOffersEvidenceListPriceItemModel
} from '../../api/interfaces';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../../api/responses';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerCancelEntityRequest, registerGetEntitiesByPageRequest } from '../../core';
import { TablePageChangeModel } from '../../components/table';
import pagination from '../../utils/pagination';
import { ROUTES } from '../../navigation';
import apiConfig from '../../configuration/api';
import { AccommodationOffersEvidenceListState } from './types';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequestHandler),
    yield takeEvery(loadTableDependenciesAsync.request, loadTableDependenciesRequestHandler),
    yield takeEvery(getItemsAsync.request, getItemsRequestHandler),
    yield takeEvery(deleteItemAsync.request, deleteItemRequestHandler),
    yield takeEvery(newItemAsync.request, newItemRequestHandler),
    yield takeEvery(newTableItemAsync.request, newTableItemRequestHandler),
    yield takeEvery(cancelItemAsync.request, cancelRequestHandler),
    yield takeEvery(downloadAction, downloadActionHandler)
  ]);
}

type DownloadActionHandlerGeneratorType = SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>>;

function* downloadActionHandler(
  action: ActionType<typeof downloadAction>
): Generator<DownloadActionHandlerGeneratorType, void, void & Array<number> & AccommodationOffersEvidenceListState> {
  console.debug('downloadActionHandler');
  const model: AccommodationOffersEvidenceListState = yield select(getState);
  const ids: Array<number> = yield select(getSelectedIds);
  
  const axiosInstance = axios.create({
    baseURL: apiConfig.baseUrl,
    timeout: 300000,
    withCredentials: true,
    headers: {

    },
    validateStatus: (status: number) => status >= 200 && status < 300
  });

  const list = model.itemList.allIds.map<AccommodationOffersEvidenceListPriceItemModel>(id => {
    return model.itemList.byId[id];
  });

  axiosInstance.post(`accommodationOfferEvidenceList/report`, { ids: ids, items: list }, { responseType: 'blob' })
    .then(response => {
      let fileName = response.headers["content-disposition"].split("filename=")[1].split(';')[0];
      fileName = fileName.replace(/^"|"$/g, '');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
}

const cancelRequestHandler = registerCancelEntityRequest(
  cancelItemAsync.success,
  cancelItemAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

type NewEntityRequestGeneratorType =
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newItemRequestHandler(
  action: ActionType<typeof newItemAsync.request>
): Generator<NewEntityRequestGeneratorType, void, BaseResponse> {
  try {
    console.debug('newTableItemRequest');
    yield put(newItemAsync.success(action.payload));
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type NewTableEntityRequestGeneratorType =
  | PutEffect<ActionType<typeof newTableItemAsync.success>>
  | PutEffect<ActionType<typeof newTableItemAsync.failure>>;

function* newTableItemRequestHandler(
  action: ActionType<typeof newTableItemAsync.request>
): Generator<NewTableEntityRequestGeneratorType, void, BaseResponse> {
  try {
    console.debug('newTableItemRequest');
    yield put(newTableItemAsync.success(action.payload));
  } catch (error) {
    yield put(newTableItemAsync.failure(error));
  }
}

type DeleteEntityRequestGeneratorType =
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof deleteItemAsync.success>>
  | PutEffect<ActionType<typeof deleteItemAsync.failure>>;

function* deleteItemRequestHandler(
  action: ActionType<typeof deleteItemAsync.request>
): Generator<DeleteEntityRequestGeneratorType, void, BaseResponse> {
  try {
    console.debug('deleteItemRequest');
    yield put(deleteItemAsync.success(action.payload));
  } catch (error) {
    yield put(deleteItemAsync.failure(error));
  }
}


type GetItemsRequestGeneratorType =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>>
  | PutEffect<ActionType<typeof setPagination>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getItemsRequestHandler(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetItemsRequestGeneratorType, void,
  boolean &
  Array<number> &
  TablePageChangeModel &
  BasePageDataResponse<AccommodationOffersEvidenceListPriceItemModel[]>> {
  try {
    console.debug('getAccommodationsRequest');

    const payload = action.payload as TablePageChangeModel;
    const stored = yield select(getAccommodationOffersEvidenceListPagination);
    const { _filter, _skip, _take } = pagination(payload, stored);
    const selectedIds: Array<number> = yield select(getSelectedItems);

    const response: BasePageDataResponse<AccommodationOffersEvidenceListPriceItemModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.AccommodationOffersEvidenceListPriceItemClient,
      apiCallFnName: 'getByPageWithIdsAsync'
    }, _skip, _take, _filter, selectedIds);

    if (response.resultCode === 0) {
      yield put(setPagination({
        page: payload?.page ?? 0,
        skip: _skip,
        take: _take,
        total: response.total,
        filter: payload?.filter ?? ""
      }));

      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequestHandler(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, void
  & boolean> {
  try {
    console.debug('loadDependenciesRequest');

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}

type LoadTableDependenciesRequestGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadTableDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadTableDependenciesAsync.failure>>;

function* loadTableDependenciesRequestHandler(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadTableDependenciesRequestGeneratorType, void, void
  & boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}