
import React, { CSSProperties, ChangeEventHandler, useRef, useState } from 'react';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker, DateRange, SelectRangeEventHandler, ClassNames } from 'react-day-picker';
import { usePopper } from 'react-popper';
import { cs } from 'date-fns/locale';
import './dateRangePicker.css';
import { FormControl, Grid, Input, Typography } from '@material-ui/core';

export type DateRangePickerProps = {
  onChange: (from: Date, to: Date) => void;
  reset: () => void;
};

const styles = {
  popper: {
    zIndex: 9999,
    backgroundColor: '#fff',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
  } as CSSProperties,
};

const DateRangePicker: React.FunctionComponent<DateRangePickerProps> = props => {
  const [selectedRange, setSelectedRange] = useState<DateRange>();
  const [fromValue, setFromValue] = useState<string>('');
  const [toValue, setToValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, 'dd. MM. yyyy', new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, 'dd. MM. yyyy', new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };

  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined
  ) => {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, 'dd. MM. yyyy'));
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, 'dd. MM. yyyy'));
    } else {
      setToValue('');
    }

    if (range !== undefined && range.from !== undefined && range.to !== undefined) {
      props.onChange(range?.from, range?.to);
    }

    if (range === undefined) {
      props.reset();
    }
  };

  return (
    <div>
      <div ref={popperRef}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h6" color="primary">
              Od
            </Typography>
            <FormControl fullWidth>
              <Input placeholder={format(new Date(), 'dd. MM. yyyy')}
                value={fromValue}
                onClick={handleButtonClick}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" color="primary">
              Do
            </Typography>
            <FormControl fullWidth>
              <Input placeholder={format(new Date(), 'dd. MM. yyyy')}
                value={toValue}
                onClick={handleButtonClick}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current || undefined
          }}
        >
          <div
            tabIndex={-1}
            style={{ ...popper.styles.popper, ...styles.popper }}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            aria-label="Kalendář"
          >
            <DayPicker
              locale={cs}
              initialFocus={isPopperOpen}
              className='daterangepicker'
              mode="range"              
              min={10}
              max={30}
              modifiersClassNames={{
                selected: 'daterangepicker-selected',
                today: 'daterangepicker-today'
              }}
              selected={selectedRange}
              onSelect={handleRangeSelect}
              defaultMonth={selectedRange?.from}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}

export default DateRangePicker;