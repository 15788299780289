import React, { RefObject } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Button, Tabs, Tab, withStyles, createStyles, Theme } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { ApplicationState } from '../../store/rootReducer';
import { setWizardAction, capacityIsNotLoadedAction, AccommodationOfferWizard, isFloorCapacityFullyLoaded, isRoomCapacityFullyLoaded } from '../accommodationOfferCalculations';
import { default as AccommodationOfferFeesContainer } from './accommodationOfferFeesContainer';
import { default as AccommodationOfferFloorsContainer } from './accommodationOfferFloorsContainer';
import { default as AccommodationOfferRoomTypesContainer } from './accommodationOfferRoomTypesContainer';
import { AccommodationOfferModel, AccommodationOfferSaveAsEnum, AccommodationOfferStateEnum, AccommodationOfferSubmitTypeEnum } from '../../api/interfaces';
import { default as ReservationGuestsContainer } from './reservationGuestsContainer';
import { FormikErrors, FormikTouched } from 'formik';
import { ConfirmDialogContainer } from '../../components';
import AccommodationOfferSummaryContainer from './accommodationOfferSummaryContainer';
import { setDefaults } from './actions';

type AccommodationOffersWizardStateProps = {
  wizard: AccommodationOfferWizard;
  editMode: boolean;
  isFloorCapacityLoaded: boolean;
  isRoomCapacityLoaded: boolean;
};

type AccommodationOffersWizardDispatchProps = {
  setWizard: typeof setWizardAction;
  capacityIsNotLoaded: typeof capacityIsNotLoadedAction;
  setDefaults: typeof setDefaults;
};

type AccommodationOffersWizardProps = {
  touched: FormikTouched<AccommodationOfferModel>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isValid: boolean;
  submitForm: () => Promise<void>;
  validateForm: (values?: any) => Promise<FormikErrors<any>>;
  values: AccommodationOfferModel;
} & AccommodationOffersWizardStateProps &
  AccommodationOffersWizardDispatchProps;

const steps = ['Patra', 'Pokoje', 'Poplatky', 'Hosté'];

const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light
    }
  })
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    selected: {
      fontWeight: 'bold'
    }
  })
)(Tab);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps): any {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number): any {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  };
}

class AccommodationOffersWizard extends React.Component<AccommodationOffersWizardProps> {
  constructor(props: AccommodationOffersWizardProps) {
    super(props);

    this.confirmDialog = React.createRef();
  }

  isNotTouched = (): boolean => {
    const { touched, values } = this.props;
    // Only reservations are checked so return false everytime
    if (values.state === AccommodationOfferStateEnum.Offer) {
      return false;
    }

    return touched.numberOfPersons === undefined || touched.numberOfPersons === false
  }

  confirmDialog: RefObject<ConfirmDialogContainer>;

  handleStep(e: number): any {
    console.debug(e);
  }

  handleNext = () => {
    const { submitForm, wizard: { activeStep, completed, isValidated },
      setWizard,
      capacityIsNotLoaded,
      setDefaults,
      validateForm,
      setFieldValue,
      values,
      isValid,
      isFloorCapacityLoaded,
      isRoomCapacityLoaded } = this.props;

    const payload = {
      activeStep: activeStep + 1,
      completed: [...completed.slice(0, activeStep), true, ...completed.slice(activeStep + 1, completed.length)],
      isValidated: [...isValidated.slice(0, activeStep), isValid, ...isValidated.slice(activeStep + 1, isValidated.length)]
    };
    setFieldValue('validationType', 1);
    if (this.isNotTouched()) {
      setWizard(payload);
    } else {
      validateForm({ ...values, validationType: 1 }).then((errors) => {
        if (isEmpty(errors)) {
          if (isFloorCapacityLoaded && isRoomCapacityLoaded) {
            setDefaults();
          }

          if ((isFloorCapacityLoaded && activeStep === 0)
            || ((
              (isRoomCapacityLoaded && activeStep === 1 && (values.id === 0 || values.id === undefined))
              || (values.id > 0 && values.state === AccommodationOfferStateEnum.Offer)
              // TODO editace rezervace v kroku POKOJE prochazi i kdyz by nemela v pripade odstraneni rezervovaneho pokoje a nevyplneni jineho
              || (isFloorCapacityLoaded && isRoomCapacityLoaded && activeStep === 1 && values.id > 0
                && values.state === AccommodationOfferStateEnum.Reservation)
            ))
            || activeStep > 1) {
            setWizard(payload);
            if (activeStep < 1) {
              submitForm();
            }
          } else {
            capacityIsNotLoaded();
            return false;
          }
        }
      });
    }
  };

  handleBack = (): void => {
    const { wizard: { activeStep }, setFieldValue } = this.props;

    this.props.setWizard({ ...this.props.wizard, activeStep: activeStep - 1 });

    if (activeStep > 1) {
      setFieldValue('validationType', 1);
    } else {
      setFieldValue('validationType', 0);
    }
  };

  saveAsOffer = (): void => {
    const { setFieldValue, submitForm } = this.props;

    setFieldValue('saveAs', AccommodationOfferSaveAsEnum.Offer);
    setFieldValue('submitType', AccommodationOfferSubmitTypeEnum.Save, false);
    submitForm();
  }

  saveAsReservation = (): void => {
    const { setFieldValue, submitForm } = this.props;

    setFieldValue('saveAs', AccommodationOfferSaveAsEnum.Reservation);
    setFieldValue('submitType', AccommodationOfferSubmitTypeEnum.Save, false);
    submitForm();
  }

  renderSaveBtn = (): React.ReactElement => {
    const { wizard: { activeStep, completed }, setFieldValue, submitForm, values } = this.props;
    console.debug(values);

    if (activeStep === 1 || activeStep === 2) {
      return <React.Fragment>
        {values.state === AccommodationOfferStateEnum.Reservation && <ConfirmDialogContainer
          ref={this.confirmDialog}
          approveAction={this.saveAsOffer}
          text="Opravdu si přejete uložit rezervaci jako nabídku ?"
        />}
        {(values.state === undefined || values.state === AccommodationOfferStateEnum.NotSet || values.state === AccommodationOfferStateEnum.Offer) && <ConfirmDialogContainer
          ref={this.confirmDialog}
          approveAction={this.saveAsOffer}
          text="Opravdu si přejete uložit nabídku ?"
        />}
        <Button
          variant="contained"
          title="Uložit nabídku"
          color="primary"
          type="submit"
          onClick={(event): void => {
            event.preventDefault();
            this.confirmDialog?.current?.open(0);
          }}>
          Uložit nabídku
        </Button></React.Fragment>
    }

    if (activeStep !== steps.length - 1) {
      return <React.Fragment></React.Fragment>;
    }

    return <Button
      variant="contained"
      title="Uložit rezervaci"
      color="primary"
      type="submit"
      onClick={(event): void => {
        event.preventDefault();
        this.saveAsReservation();
      }}>
      Uložit rezervaci
    </Button>
  }

  renderNextBtn = (): React.ReactElement => {
    const { wizard: { activeStep, completed }, setFieldValue, values } = this.props;
    if (activeStep === steps.length - 1) {
      return <React.Fragment></React.Fragment>;
    }

    if (activeStep === 2) {
      if (values.id > 0 && completed[0] === false && completed[1] === false) {
        return <React.Fragment></React.Fragment>;
      }
    }

    return <Button
      variant="contained"
      title="Pokračovat"
      color="primary"
      type="submit"
      onClick={(event): void => {
        event.preventDefault();
        setFieldValue('submitType', AccommodationOfferSubmitTypeEnum.Calculate, false);
        this.handleNext();
      }}
    >
      Pokračovat
    </Button>
  }

  render(): React.ReactElement {
    const { wizard, editMode, setFieldValue, setWizard } = this.props;
    const { activeStep } = wizard;
    return (<React.Fragment>
      <Box>
        <StyledTabs value={activeStep}
          aria-label="offer tabs"
          indicatorColor="primary">
          <StyledTab label="Patra" {...a11yProps(0)} onClick={() => {
            if (editMode || (wizard.completed[0] === true)) {
              setWizard({ ...wizard, activeStep: 0 })
            }
          }} />
          <StyledTab label="Pokoje" {...a11yProps(1)} onClick={() => {
            if (editMode || (wizard.completed[0] === true)) {
              setWizard({ ...wizard, activeStep: 1 })
            }
          }} />
          <StyledTab label="Poplatky" {...a11yProps(2)} onClick={() => {
            if (editMode || (wizard.completed[0] === true && wizard.completed[1] === true)) {
              setWizard({ ...wizard, activeStep: 2 })
            }
          }} />
          <StyledTab label="Hosté" {...a11yProps(3)} onClick={() => {
            if (editMode || (wizard.completed[0] === true && wizard.completed[1] === true && wizard.completed[2] === true)) {
              setWizard({ ...wizard, activeStep: 3 })
            }
          }} />
        </StyledTabs>
        <TabPanel value={activeStep} index={0}>
          <AccommodationOfferFloorsContainer showProgress={!this.isNotTouched()} setFieldValue={setFieldValue} />
        </TabPanel>
        <TabPanel value={activeStep} index={1}>
          <AccommodationOfferRoomTypesContainer showProgress={!this.isNotTouched()} />
          <AccommodationOfferSummaryContainer />
        </TabPanel>
        <TabPanel value={activeStep} index={2} >
          <AccommodationOfferFeesContainer />
          <AccommodationOfferSummaryContainer />
        </TabPanel>
        <TabPanel value={activeStep} index={3}>
          <ReservationGuestsContainer />
          <AccommodationOfferSummaryContainer />
        </TabPanel>
      </Box>
      <Grid item xs={12}>
        <Button
          disabled={activeStep === 0}
          onClick={this.handleBack}
        >
          Zpět
        </Button>
        {this.renderNextBtn()}
        &nbsp;
        {this.renderSaveBtn()}
      </Grid>
    </React.Fragment>)
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOffersWizardStateProps => {
  return {
    wizard: state.accommodationOfferCalculations.wizard,
    editMode: state.accommodationOfferCalculations.offerHeader.id !== 0,
    isFloorCapacityLoaded: isFloorCapacityFullyLoaded(state),
    isRoomCapacityLoaded: isRoomCapacityFullyLoaded(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOffersWizardDispatchProps => {
  return {
    ...bindActionCreators(
      {
        setWizard: setWizardAction,
        capacityIsNotLoaded: capacityIsNotLoadedAction,
        setDefaults: setDefaults
      },
      dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOffersWizard);