import { ActionType } from "typesafe-actions";

import * as actions from './actions';

export type VirtualState = {
  virtuals: { [itemId: number]: VirtualModel };
};

export type VirtualModel = {
  items: VirtualSelectItem[];
  total: number;
} & VirtualValueModel & VirtualSearchModel & VirtualScrollModel;

export type VirtualQueryModel = { id: number } & VirtualSearchModel & VirtualScrollModel;

export type VirtualScrollModel = {
  page: number;
  type: VirtualComponentTypeEnum;
};

export type VirtualValueModel = {
  value: number;
  type: VirtualComponentTypeEnum;
};

export enum VirtualComponentTypeEnum {
  Guests = 1,
  Companies = 2
}

export type VirtualSearchModel = {
  search: string;
  type: VirtualComponentTypeEnum;
  filter: string;
};

export type VirtualSelectItem = {
  value: number;
  text: string;
};

export type VirtualAction = ActionType<typeof actions>;