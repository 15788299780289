import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import moment from 'moment';
import { DeepReadonly } from 'utility-types';

import { TimeLineState, TimeLineAction } from './types';
import * as actions from './actions';
import { timeLineGroupModelsToTimeLineGroupMapper, timeLineItemModelsToTimeLineItemMapper } from './mappers';

const countOfDaysToShowBeforeToday = 10;
const countOfDaysToShowAfterToday = 10;

export const initialState: TimeLineState = {
  items: [],
  groups: [],
  countOfDaysToShowBeforeToday: countOfDaysToShowBeforeToday,
  countOfDaysToShowAfterToday: countOfDaysToShowAfterToday,
  defaultDataStartDate: moment().add(-countOfDaysToShowBeforeToday, 'day'),
  defaultDataEndDate: moment().add(countOfDaysToShowAfterToday, 'day'),
  visibleDataStartDate: moment().add(-countOfDaysToShowBeforeToday, 'day'),
  visibleDataEndDate: moment().add(countOfDaysToShowAfterToday, 'day'),
  loadedDataStartDate: moment().add(-countOfDaysToShowBeforeToday, 'day'),
  loadedDataEndDate: moment().add(countOfDaysToShowAfterToday, 'day'),
  selectedAccommodation: -1,
  selectedFloor: -1
};

const itemsReducer = createReducer<Readonly<typeof initialState.items>, TimeLineAction>(initialState.items)
  .handleAction(actions.getTimeLineAsync.success, (state, action) => {
    return timeLineItemModelsToTimeLineItemMapper(action.payload.items);
  })
  .handleAction(actions.getTimeLineAsync.failure, (state, action) => initialState.items);

const groupsReducer = createReducer<Readonly<typeof initialState.groups>, TimeLineAction>(initialState.groups)
  .handleAction(actions.getTimeLineAsync.success, (state, action) => {
    return timeLineGroupModelsToTimeLineGroupMapper(action.payload.groups);
  })
  .handleAction(actions.getTimeLineAsync.failure, (state, action) => initialState.groups);

const countOfDaysToShowBeforeTodayReducer = createReducer<Readonly<typeof initialState.countOfDaysToShowBeforeToday>, TimeLineAction>(
  initialState.countOfDaysToShowBeforeToday
);

const countOfDaysToShowAfterTodayReducer = createReducer<Readonly<typeof initialState.countOfDaysToShowAfterToday>, TimeLineAction>(
  initialState.countOfDaysToShowAfterToday
);
const defaultDataStartDateReducer = createReducer<Readonly<typeof initialState.visibleDataStartDate>, TimeLineAction>(
  initialState.visibleDataStartDate
);

const defaultDataEndDateReducer = createReducer<Readonly<typeof initialState.visibleDataEndDate>, TimeLineAction>(initialState.visibleDataEndDate);

const visibleDataStartDateReducer = createReducer<Readonly<typeof initialState.visibleDataStartDate>, TimeLineAction>(
  initialState.visibleDataStartDate
).handleAction(actions.visibleRangeChanged, (state, action) => action.payload.start);

const visibleDataEndDateReducer = createReducer<Readonly<typeof initialState.visibleDataEndDate>, TimeLineAction>(
  initialState.visibleDataEndDate
).handleAction(actions.visibleRangeChanged, (state, action) => action.payload.end);

const loadedDataStartDateReducer = createReducer<Readonly<typeof initialState.loadedDataStartDate>, TimeLineAction>(initialState.loadedDataStartDate);

const loadedDataEndDateReducer = createReducer<Readonly<typeof initialState.loadedDataEndDate>, TimeLineAction>(initialState.loadedDataEndDate);

const selectedAccommodationReducer = createReducer<Readonly<typeof initialState.selectedAccommodation>, TimeLineAction>(
  initialState.selectedAccommodation
).handleAction(actions.filterByAccommodation, (state, action) => {
  return action.payload;
});

const selectedFloorReducer = createReducer<Readonly<typeof initialState.selectedFloor>, TimeLineAction>(initialState.selectedFloor)
  .handleAction(actions.filterByFloor, (state, action) => {
    return action.payload;
  });

export default combineReducers<TimeLineState>({
  items: itemsReducer,
  groups: groupsReducer,
  countOfDaysToShowBeforeToday: countOfDaysToShowBeforeTodayReducer,
  countOfDaysToShowAfterToday: countOfDaysToShowAfterTodayReducer,
  defaultDataStartDate: defaultDataStartDateReducer,
  defaultDataEndDate: defaultDataEndDateReducer,
  visibleDataStartDate: visibleDataStartDateReducer,
  visibleDataEndDate: visibleDataEndDateReducer,
  loadedDataStartDate: loadedDataStartDateReducer,
  loadedDataEndDate: loadedDataEndDateReducer,
  selectedAccommodation: selectedAccommodationReducer,
  selectedFloor: selectedFloorReducer
});
