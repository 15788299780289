import { all, put, takeEvery, PutEffect, SelectEffect, call, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import {
  AccommodationOfferPriceCalculationModel,
  AccommodationOfferPriceCalculationMutationModel,
  AccommodationOfferRoomPriceCalculationMutationModel,
  AccommodationOfferRoomTypePriceCalculationMutationModel,
  AccommodationOfferFeePriceCalculationMutationModel,
  AccommodationOfferRoomTypeCalculationModel,
  AccommodationOfferFeeModel,
  ReservationRoomModel
} from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';

import apiClient from '../../api/apiClientInstance';
import { calculatePriceAsyncAction } from './actions';
import { AccommodationOfferPriceCalculationsState } from '.';
import { AccommodationOfferCalculationsState, getCalculations, getCurrentReservedRooms } from '../accommodationOfferCalculations';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(calculatePriceAsyncAction.request, calculatePriceActionRequestHandler),
  ]);
}

type CalculateActionRequestGeneratorType =
  | Promise<BaseDataResponse<AccommodationOfferPriceCalculationModel>>
  | SelectEffect
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.success>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.failure>>;

function* calculatePriceActionRequestHandler(
  action: ReturnType<typeof calculatePriceAsyncAction.request>
): Generator<CalculateActionRequestGeneratorType, void, BaseDataResponse<AccommodationOfferPriceCalculationModel>
  & AccommodationOfferPriceCalculationsState & AccommodationOfferCalculationsState & ReservationRoomModel[]> {
  try {
    console.debug('calculateActionRequest');

    const calculations: AccommodationOfferCalculationsState = yield select(getCalculations);
    const freeRoomTypes = Object.entries(calculations.freeRooms.byId).map((key, value) => {
      return key[1] as AccommodationOfferRoomTypeCalculationModel;
    });
    const partiallyOccupiedRoomTypes = Object.entries(calculations.partiallyOccupiedRooms.byId).map((key, value) => {
      return key[1] as AccommodationOfferRoomTypeCalculationModel;
    });
    const reservedRoomTypes = Object.entries(calculations.reservedRooms.byId).map((key, value) => {
      return key[1] as AccommodationOfferRoomTypeCalculationModel;
    });
    const fees = Object.entries(calculations.fees.byId).map((key, value) => {
      return key[1] as AccommodationOfferFeeModel;
    });

    const currentReservedRooms: ReservationRoomModel[] = yield select(getCurrentReservedRooms);

    const model = {
      freeRooms: freeRoomTypes?.map(roomType => ({
        id: roomType.id,
        floorNumber: roomType.floorNumber,
        pricePerBedAndNight: roomType.pricePerBedAndNight,
        pricePerRoomAndNight: roomType.pricePerRoomAndNight,
        vatRatePerBedAndNight: roomType.vatRatePerBedAndNight,
        vatRatePerRoomAndNight: roomType.vatRatePerRoomAndNight,
        rooms: roomType.rooms?.map(room => ({
          roomId: room.roomId
        } as AccommodationOfferRoomPriceCalculationMutationModel)),
        countOfOfferedBeds: roomType.countOfOfferedBeds
      } as AccommodationOfferRoomTypePriceCalculationMutationModel)),
      partiallyOccupiedRooms: partiallyOccupiedRoomTypes?.map(roomType => ({
        id: roomType.id,
        floorNumber: roomType.floorNumber,
        pricePerBedAndNight: roomType.pricePerBedAndNight,
        pricePerRoomAndNight: roomType.pricePerRoomAndNight,
        vatRatePerBedAndNight: roomType.vatRatePerBedAndNight,
        vatRatePerRoomAndNight: roomType.vatRatePerRoomAndNight,
        rooms: roomType.rooms?.map(room => ({
          roomId: room.roomId
        } as AccommodationOfferRoomPriceCalculationMutationModel)),
        countOfOfferedBeds: roomType.countOfOfferedBeds
      } as AccommodationOfferRoomTypePriceCalculationMutationModel)),
      reservedRooms: reservedRoomTypes?.map(roomType => ({
        id: roomType.id,
        floorNumber: roomType.floorNumber,
        pricePerBedAndNight: roomType.pricePerBedAndNight,
        pricePerRoomAndNight: roomType.pricePerRoomAndNight,
        vatRatePerBedAndNight: roomType.vatRatePerBedAndNight,
        vatRatePerRoomAndNight: roomType.vatRatePerRoomAndNight,
        rooms: roomType.rooms?.map(room => {
          const index = currentReservedRooms.findIndex(rRoom => rRoom.roomId == room.roomId);
          let guests = 0;
          if (index != -1) {
            const rRoom = currentReservedRooms[index];
            guests = rRoom.guests.length;
          }

          return ({
            roomId: room.roomId,
            guests: guests
          } as AccommodationOfferRoomPriceCalculationMutationModel)
        }),
        /* Offered beds */
        countOfOfferedBeds: roomType.countOfOfferedBeds
      } as AccommodationOfferRoomTypePriceCalculationMutationModel)),
      from: calculations.offerHeader.from,
      to: calculations.offerHeader.to,
      numberOfNights: calculations.offerHeader.numberOfNights,
      numberOfPersons: calculations.offerHeader.numberOfPersons,
      priceListId: calculations.offerHeader.priceListId,
      fees: fees?.map(fee => ({
        amount: fee.amount,
        price: fee.price,
        priceListItemId: fee.priceListItemId,
        totalPrice: fee.totalPrice
      } as AccommodationOfferFeePriceCalculationMutationModel)),
      payForRoomAndNight: calculations.offerHeader.payForRoomAndNight,
      state: calculations.offerHeader.state,
      id: calculations.offerHeader.id
    } as AccommodationOfferPriceCalculationMutationModel;

    const response: BaseDataResponse<AccommodationOfferPriceCalculationModel> = yield apiClient.apiCallHandler(
      { context: apiClient.AccommodationOfferPriceCalculations, apiCallFnName: 'calculatePriceAsync' },
      model
    );

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(calculatePriceAsyncAction.success(data));
    } else {
      yield put(calculatePriceAsyncAction.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(calculatePriceAsyncAction.failure(error));
  }
}