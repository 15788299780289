import React, { } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Grid, withStyles, createStyles, Theme } from '@material-ui/core';
import { LogModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import {
  GenericColumn,
  BaseTableRowModel,
  BaseListContainer,
  BaseListContainerDispatchProps,
  BaseListStateProps,
  BaseListDispatchProps,
  TrimText
} from '../../components';
import { mergeWithIdColumn, TablePaginationModel } from '../../components/table';
import LogsTable from './logsTable';
import * as actions from './actions';
import { getLogsPagination } from './selectors';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import moment from 'moment';

type LogsContainerClassKey = 'root' | 'toolBarRoot' | 'divider' | 'relationSelectTextRoot' | 'relationSelectRoot';

type LogsContainerStateProps = {
  pagination: TablePaginationModel;
} & BaseListStateProps<LogModel>;

type LogsContainerDispatchProps = {
} & BaseListDispatchProps<LogModel> & BaseListContainerDispatchProps<LogModel>;

type LogsContainerProps = {
  classes: Partial<ClassNameMap<LogsContainerClassKey>>;
} & LogsContainerStateProps &
  LogsContainerDispatchProps;

const columns = mergeWithIdColumn<LogModel>([
  {
    id: 'dateOfCreate', label: 'Datum', minWidth: 100, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: LogModel): any => { return moment(row.dateOfCreate).format('DD. MM. yyyy') }
  },
  { id: 'type', label: 'Typ', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'operation', label: 'Operace', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'diff', label: 'Změna', minWidth: 250, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: LogModel): any => { 
      if (row.operationId === 1) {
        return <TrimText value={row.mutation} showClipboardButton={true} /> 
      }

      return <TrimText value={row.diff} showClipboardButton={true} /> 
    }
  },
  { id: 'model', label: 'Obsah', minWidth: 250, align: 'left', isVisible: true, isTableActions: false, 
    customComponent: (row: LogModel): any => { 
      return <TrimText value={row.model} showClipboardButton={true} />
    }
  },
]);

const withStylesFunction = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1"
    },
    toolBarRoot: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      minHeight: theme.spacing(9),
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    divider: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.primary.main
    },
    relationSelectTextRoot: { marginLeft: theme.spacing(2), marginRight: theme.spacing(1) },
    relationSelectRoot: { width: '300px', marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }
  })
);

const LogsContainer: React.FunctionComponent<LogsContainerProps> = props => {
  console.debug('LogsContainer.render');

  return (<div className={props.classes.root}>

    <Grid container direction="row" alignItems="center" className={props.classes.root}>
      <BaseListContainer
        getItemsRequest={props.getItemsRequest}
        newItemRequest={props.newItemRequest}
        loadDependenciesRequest={props.loadDependenciesRequest}
        disableNew={true}
      >
        <LogsTable
          rows={props.items}
          rowNoClick={true}
          paging={true}
          pagination={props.pagination}
          columns={columns as GenericColumn<BaseTableRowModel>[]}
          editAction={props.editItemRequest}
          deleteAction={props.deleteItemRequest}
          loadAction={props.getItemsRequest}
        />
      </BaseListContainer>
    </Grid>
  </div>);
}

const mapStateToProps = (state: ApplicationState): LogsContainerStateProps => {
  return {
    items: state.logs.itemList.allIds.map<LogModel>(id => {
      return state.logs.itemList.byId[id];
    }),
    requestInProgress: state.logs.requestInProgress,
    pagination: getLogsPagination(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): LogsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request,
      newItemRequest: actions.newItemAsync.request,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(LogsContainer));