import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { AccommodationModel } from '../../api/interfaces';
import { NormalizedListById } from '../../core';
import { RelationSelectItem } from '../../components';

export const getAccommodationsById = (state: ApplicationState): NormalizedListById<AccommodationModel> => {
  return state.accommodations.itemList.byId;
};

export const getAccommodationsAllIds = (state: ApplicationState): number[] => {
  return state.accommodations.itemList.allIds;
};

export const getAreAccommodationsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.accommodations.itemList.loaded;
};

export const selectAccommodationsSelectItems = createSelector(
  [getAccommodationsById],
  (accomodationsById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectAccommodationsSelectItems...');

    return Object.values(accomodationsById).map<RelationSelectItem>(accommodation => {
      return { text: accommodation.name, value: accommodation.id } as RelationSelectItem;
    });
  }
);
