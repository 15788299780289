import React, { memo } from 'react';

import { BaseTableFilterComponentModel } from '../../components/table';
import { default as GuestsFilter } from './guestsFilter';

type GuestsFilterContainerProps = {} & BaseTableFilterComponentModel;

const GuestsFilterContainer: React.FunctionComponent<GuestsFilterContainerProps> = memo(props => {
  console.debug('GuestsFilterContainer');

  return <GuestsFilter {...props} />;
});

export default GuestsFilterContainer;
