import React from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';
import MomentUtils from '@date-io/moment';

import { } from '.';
import { ApplicationState } from '../../store/rootReducer';
import { FormikDatePicker, FormikRelationSelect, RelationSelectItem, TablePaginationModel } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { selectAccommodationsSelectItems } from '../accommodations';
import { AccommodationOfferStateEnum, ReservationPaymentStatusEnum } from '../../api/interfaces';
import * as actions from './actions';
import { TablePageChangeModel } from '../../components/table';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

const nameof = nameofFactory<AccommodatedGuestsToolbarModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '30px'
    }
  })
);

type AccommodatedGuestsToolbarModel = {
  group: Date;
};

type AccommodatedGuestsToolbarStateProps = {
};

type AccommodatedGuestsToolbarDispatchProps = {
  getItems: typeof actions.getItemsAsync.request;
};

type AccommodatedGuestsToolbarProps = {
} & AccommodatedGuestsToolbarStateProps & AccommodatedGuestsToolbarDispatchProps;

const AccommodatedGuestsToolbar: React.FunctionComponent<AccommodatedGuestsToolbarProps> = props => {
  console.debug('AccommodatedGuestsToolbar');
  const classes = useStyles();
  const { getItems } = props;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(new Date());

  return (
    <React.Fragment>
      <Formik<AccommodatedGuestsToolbarModel>
        initialValues={{ group: new Date() }}
        onSubmit={(values: any): void => {
          let filter = "";
          if (selectedDate !== undefined) {
            filter += `group=${moment(selectedDate).format('MM/YYYY')}`;
          }
          getItems({ page: 0, filter: filter, skip: 0 } as TablePageChangeModel);
        }}
      >
        {(formProps): React.ReactElement => {
          const { handleSubmit, resetForm, submitForm } = formProps;
          return (
            <form style={{ textAlign: 'left' }} onSubmit={handleSubmit} autoComplete="off">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container>
                  <DatePicker
                    name="group"
                    variant="inline"
                    openTo="year"
                    views={["year", "month"]}
                    label="Rok a měsíc"
                    helperText="Začni výběrem roku" 
                    value={selectedDate} 
                    onChange={(date: MaterialUiPickersDate): void => {
                      setSelectedDate(moment(date).toDate());
                    }}
                    onClose={(): void => {
                      submitForm();
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}  
                    />
                </Grid>
              </MuiPickersUtilsProvider>
            </form>);
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): AccommodatedGuestsToolbarStateProps => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodatedGuestsToolbarDispatchProps =>
  bindActionCreators({
    getItems: actions.getItemsAsync.request
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodatedGuestsToolbar);