import * as Yup from 'yup';

import { accommodationIdValidationSchema } from '../validations';

export const accommodationOfferUpdateValidationSchema = Yup.object().shape({
});

export const accommodationOfferCreateValidationSchema = Yup.object().shape({
  accommodationId: accommodationIdValidationSchema,
  from: Yup.date().required('Datum příjezdu musí být nastaveno'),
  to: Yup.date().required('Datum odjezdu musí být nastaveno')
});

export const accommodationOfferCalculateValidationSchema = Yup.object().shape({
  accommodationId: accommodationIdValidationSchema,
  numberOfPersons: Yup.number()
    .required('Počet osob musí být nastaven')
    .min(1, 'Počet osob musí být nastaven'),
  numberOfNights: Yup.number()
    .required('Počet nocí musí být nastaven')
    .min(1, 'Počet nocí musí být nastaven'),
  from: Yup.date().required('Datum příjezdu musí být nastaveno'),
  to: Yup.date().required('Datum odjezdu musí být nastaveno'),
  guestId: Yup.number()
    .required('Kontaktní osoba musí být vybrána')
    .min(1, 'Kontaktní osoba musí být vybrána'),
  companyId: Yup.number()
    .required('Firma musí být vybrána')
    .min(1, 'Firma musí být vybrána'),
  priceListId: Yup.number()
    .required('Ceník musí být vybrán')
    .min(1, 'Ceník musí být vybrán')
});