import { RequiredColorPalette } from '../types';

const palette: RequiredColorPalette = {
  primary: { main: '#C6A530', light: '#F5EDD5', dark: '#B2942B' },
  secondary: { main: '#C5635D' },
  action: {
    selected: '#B2942B'
  },
  background: {
    default: 'white'
  },
  common: {
    transparent: 'transparent'
  }
};

export default palette;
