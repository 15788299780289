import React, { RefObject } from 'react';
import {
  Theme
} from '@material-ui/core';
import axios from 'axios';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Icon, IconName } from '../../../icons';
import { BaseComponentWithModalSupport } from '../../baseDetail';
import { withOpenModal, WithOpenModalProps } from '../../modalDialogManager';
import { ROUTES } from '../../../navigation';
import apiConfig from '../../../configuration/api';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {},
  actionIconRoot: {
    '&:not(:last-of-type)': {
      marginRight: spacing(1)
    }
  },
  actionIcon: {
    fontSize: '1.2rem'
  }
}));

type DownloadButtonClassKey = 'actionIconRoot' | 'actionIcon';

export type DownloadButtonProps = {
  onClick: () => void;
  url: string;
  classes: Partial<ClassNameMap<DownloadButtonClassKey>>;
} & WithOpenModalProps;


type DownloadButtonState = {
  open: boolean;
};

class DownloadButton extends React.PureComponent<DownloadButtonProps, DownloadButtonState> {
  selectRef: RefObject<any>;

  constructor(props: DownloadButtonProps) {
    super(props);

    this.state = { open: false };
    this.selectRef = React.createRef<any>();
  }

  downloadFile(): void {
    const axiosInstance = axios.create({
      baseURL: apiConfig.baseUrl,
      timeout: 300000,
      withCredentials: true,
      headers: {
      },
      validateStatus: (status: number) => status >= 200 && status < 300
    });

    axiosInstance.get(this.props.url, { responseType: 'blob' })
      .then(response => {
        let fileName = response.headers["content-disposition"].split("filename=")[1].split(';')[0];
        fileName = fileName.replace(/^"|"$/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  }

  render(): React.ReactElement {
    console.debug('DownloadButton.render');

    return (
      <React.Fragment>
        <Icon
          iconName="file-invoice"
          className={this.props.classes.actionIcon}
          IconProps={{
            classes: { root: this.props.classes.actionIconRoot },
            color: 'secondary',
            title: 'Stáhnout',
            style: { cursor: 'pointer' },
            onClick: (event: React.MouseEvent<HTMLSpanElement>): void => {
              this.downloadFile();

              this.props.onClick();
            }
          }} />
      </React.Fragment>
    );
  }
}

export default withOpenModal(withStylesFunction(DownloadButton));
