import { BaseResponse } from '../responses';
import { IErrorHandler } from '../abstractions';

const NETWORK_ERROR = 'network error';
const NO_INTERNET_MESSAGE = 'Server není dostupný. Zkontrolujte prosím připojení k internetu a akci opakujte.';
const UNAUTHORIZE_MESSAGE = 'Aktuální příhlášení vypršelo.';

export default class ErrorHandler implements IErrorHandler {
  public async handleRequestAsync<TResponse extends BaseResponse>(request: () => Promise<TResponse>): Promise<TResponse> {
    try {
      const responses = await request();
      console.debug(responses);

      return responses;
    } catch (error) {
      console.log(error.response);
      console.log(error.code);

      const response = new BaseResponse() as TResponse;
      response.resultCode = -500;
      response.resultReason = 'Akce se nepodařila. Opakujte prosím akci později.';

      if (error.message && error.message.toLowerCase().includes(NETWORK_ERROR)) {
        response.resultReason = NO_INTERNET_MESSAGE;
      }

      if (error.response && error.response.status === 401) {
        response.resultCode = -401;
        response.resultReason = UNAUTHORIZE_MESSAGE;
      }

      if (error.response && error.response.data && error.response.data.resultCode > 0 && error.response.data.resultReason) {
        response.resultCode = error.response.data.resultCode;
        response.resultReason = error.response.data.resultReason;
      }

      return response;
    }
  }
}
