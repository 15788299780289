import { createAction, createAsyncAction } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { TimeLineDataModel } from '../../api/interfaces';
import { TimeLineRange } from './types';
import { createLoadDependenciesAsyncAction } from '../../core';

const modelName = 'timeLine';

export const getTimeLineAsync = createAsyncAction(
    'timeLine/GET_TIME_LINE_REQUEST',
    'timeLine/GET_TIME_LINE_SUCCESS',
    'timeLine/GET_TIME_LINE_FAILURE'
)<void, Readonly<TimeLineDataModel>, Error>();

export const timeScopeChanged = createAction<string>('timeLine/TIME_SCOPE_CHANGED')<TimeLineRange>();

export const visibleRangeChanged = createAction<string>('timeLine/_VISIBLE_RANGE_CHANGED')<TimeLineRange>();

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const filterByAccommodation = createAction<string>('timeLine/FILTER_BY_ACCOMMODATION')<number>();

export const filterByFloor = createAction<string>('timeLine/FILTER_BY_FLOOR')<number>();

export const chipSelected = createAction<string>('timeLine/CHIP_SELECTED')<number>();
