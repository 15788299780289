import React, { memo } from 'react';
import { Grid, Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { DeepReadonly } from 'utility-types';
import _ from 'lodash';
import axios from 'axios';

import {
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikRelationSelect,
  RelationSelectItem,
  TablePaginationModel,
  BaseListStateProps,
  mergeWithDefaultColumns,
  mergeWithIdColumn,
  GenericColumn,
  BaseTableRowModel,
  BaseListContainerDispatchProps,
  BaseListContainer
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { accommodationOfferEvidenceListValidationSchema } from './validations';
import {
  AccommodationOffersEvidenceListModel,
  AccommodationOffersEvidenceListPriceItemModel
} from '../../api/interfaces';
import * as actions from './actions';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import AccommodationOffersEvidenceListTable from './accommodationOffersEvidenceListTable';
import { BaseAction, BaseActionOperation, BaseActions } from '../../core';
import { actionsColumn, EditableColumn } from '../../components/table';
import apiConfig from '../../configuration/api';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

type myStylesClasses = 'chips' | 'button';

export type AccommodationOffersEvidenceListDetailStateProps = {
  pagination: TablePaginationModel;
} & BaseDetailStateProps<AccommodationOffersEvidenceListModel> & BaseListStateProps<AccommodationOffersEvidenceListPriceItemModel>;

export type AccommodationOffersEvidenceListDetailDispatchProps = {
  loadTableDependenciesRequest: BaseActionOperation<BaseAction<AccommodationOffersEvidenceListPriceItemModel, BaseActions<AccommodationOffersEvidenceListPriceItemModel>, 'loadDependenciesAsync'>, 'request'>;
  columnValueChangedAction: typeof actions.columnValueChangedAction;
  deleteItemRequest: typeof actions.deleteItemAsync.request;
  downloadAction: typeof actions.downloadAction;
} & BaseDetailDispatchProps<AccommodationOffersEvidenceListModel> & BaseListContainerDispatchProps<AccommodationOffersEvidenceListPriceItemModel>;

export type AccommodationOffersEvidenceListDetailProps = {

} & BaseDetailProps<AccommodationOffersEvidenceListModel> & AccommodationOffersEvidenceListDetailStateProps & AccommodationOffersEvidenceListDetailDispatchProps;

type RenderFormProps = {
  myStyles: ClassNameMap<myStylesClasses>;
  items: AccommodationOffersEvidenceListPriceItemModel[];
  pagination: TablePaginationModel;
  deleteItemRequest: typeof actions.deleteItemAsync.request;
  newItemRequest: BaseActionOperation<BaseAction<AccommodationOffersEvidenceListPriceItemModel, BaseActions<AccommodationOffersEvidenceListPriceItemModel>, 'newItemAsync'>, 'request'>;
  getItemsRequest: BaseActionOperation<BaseAction<AccommodationOffersEvidenceListPriceItemModel, BaseActions<AccommodationOffersEvidenceListPriceItemModel>, 'getItemsAsync'>, 'request'>;
  loadTableDependenciesRequest: BaseActionOperation<BaseAction<AccommodationOffersEvidenceListPriceItemModel, BaseActions<AccommodationOffersEvidenceListPriceItemModel>, 'loadDependenciesAsync'>, 'request'>;
  columnValueChangedAction: typeof actions.columnValueChangedAction;
  downloadAction: typeof actions.downloadAction;
} & BaseDetailContainerWrappedComponentProps<AccommodationOffersEvidenceListModel>;

const columns = mergeWithIdColumn<AccommodationOffersEvidenceListPriceItemModel>([
  { id: 'reservationNumber', label: 'Rezervace', minWidth: 170, align: 'left', isVisible: true, isTableActions: false, editable: { type: 'string' } as EditableColumn },
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false, editable: { type: 'string' } as EditableColumn },
  { id: 'amount', label: 'Množství', minWidth: 170, align: 'left', isVisible: true, isTableActions: false, editable: { type: 'number' } as EditableColumn },
  { id: 'price', label: 'Cena', minWidth: 170, align: 'left', isVisible: true, isTableActions: false, editable: { type: 'number' } as EditableColumn },
  { id: 'totalPrice', label: 'Cena celkem', minWidth: 170, align: 'left', isVisible: true, isTableActions: false, editable: { type: 'number' } as EditableColumn },
  {
    ...actionsColumn(), editComponent: (id: number): any => {
      return <span>&nbsp;</span>
    }
  }
]);




const nameof = nameofFactory<AccommodationOffersEvidenceListModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {

  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    cancelActionRequest,
    myStyles,
  } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    cancelActionRequest();
  };

  const downloadFile = (): void => {
    const axiosInstance = axios.create({
      baseURL: apiConfig.baseUrl,
      timeout: 300000,
      withCredentials: true,
      headers: {

      },
      validateStatus: (status: number) => status >= 200 && status < 300
    });

    axiosInstance.post(`accommodationOfferEvidenceList/report`, { ids: [0, 1] }, { responseType: 'blob' })
      .then(response => {
        let fileName = response.headers["content-disposition"].split("filename=")[1].split(';')[0];
        fileName = fileName.replace(/^"|"$/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  }

  return (
    <Formik<AccommodationOffersEvidenceListModel>
      initialValues={initialValues}
      onSubmit={(values: AccommodationOffersEvidenceListModel, { setSubmitting }): void => {
        setSubmitting(true);
      }}
      validationSchema={accommodationOfferEvidenceListValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, setFieldValue, values } = formProps;

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <BaseListContainer
              getItemsRequest={props.getItemsRequest}
              loadDependenciesRequest={props.loadTableDependenciesRequest}
              newItemRequest={props.newItemRequest}>
              <AccommodationOffersEvidenceListTable
                rows={props.items}
                rowNoClick={true}
                paging={true}
                pagination={props.pagination}
                columns={columns as GenericColumn<BaseTableRowModel>[]}
                editAction={() => { console.debug('EditAction'); }}
                deleteAction={props.deleteItemRequest}
                loadAction={props.getItemsRequest}
                columnValueChangedAction={props.columnValueChangedAction}
              />
            </BaseListContainer>
            <Grid container className={classes.grid}>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="outlined" color="inherit" onClick={props.downloadAction}>
                  Tisk
                </Button>
                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const AccommodationOffersEvidenceListDetail: React.FunctionComponent<AccommodationOffersEvidenceListDetailProps> = memo(props => {
  console.debug('AccommodationOffersEvidenceListDetail');
  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    getItemsRequest,
    items,
    pagination,
    newItemRequest,
    loadTableDependenciesRequest,
    columnValueChangedAction,
    deleteItemRequest,
    downloadAction
  } = props;

  const myStyles = useStyles();

  return (
    <BaseDetailContainer<AccommodationOffersEvidenceListModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        myStyles: myStyles,
        items: items,
        pagination: pagination,
        getItemsRequest: getItemsRequest,
        newItemRequest: newItemRequest,
        loadTableDependenciesRequest: loadTableDependenciesRequest,
        columnValueChangedAction: columnValueChangedAction,
        deleteItemRequest: deleteItemRequest,
        downloadAction: downloadAction
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default AccommodationOffersEvidenceListDetail;
