import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import GuestDetail, { GuestDetailStateProps, GuestDetailDispatchProps, GuestDetailProps } from './guestsDetail';
import * as actions from './actions';
import * as accommodationOffersActions from '../accommodationOffers/actions';

import { ApplicationState } from '../../store/rootReducer';
import { selectStatesSelectItems } from '../states';
import { selectCompaniesSelectItems } from '../companies';

type GuestEditContainerStateProps = {} & GuestDetailStateProps;

type GuestEditContainerDispatchProps = {
  setAccommodationOffersPagination: typeof accommodationOffersActions.setPagination;
  getAccommodationOffersItemsRequest: typeof accommodationOffersActions.getItemsAsync.request;
} & GuestDetailDispatchProps;

type GuestEditContainerProps = {} & GuestDetailProps & GuestEditContainerStateProps & GuestEditContainerDispatchProps;

const GuestEditContainer: React.FunctionComponent<GuestEditContainerProps> = memo(props => {
  console.debug('GuestEditContainer');


  const {
    errorMessage,
    requestInProgress,
    initialValues,
    stateSelectItems,
    companySelectItems,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    setAccommodationOffersPagination
  } = props;

  useEffect(() => {
    setAccommodationOffersPagination({ page: 0, skip: 0, total: 100, filter: `guestId=${props.initialValues?.id}` });

    props.getAccommodationOffersItemsRequest();
  });

  useEffect(() => {
    return () => {
      setAccommodationOffersPagination({ page: 0, skip: 0, total: 100, filter: `` });

      props.getAccommodationOffersItemsRequest();
    }
  }, []);

  return (
    <GuestDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      stateSelectItems={stateSelectItems}
      companySelectItems={companySelectItems}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): GuestEditContainerStateProps => {
  return {
    initialValues: state.guests.itemList.byId[ownProps.match.params.id],
    errorMessage: state.guests.errorMessage,
    requestInProgress: state.guests.requestInProgress,
    stateSelectItems: selectStatesSelectItems(state),
    companySelectItems: selectCompaniesSelectItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): GuestEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        setAccommodationOffersPagination: accommodationOffersActions.setPagination,
        getAccommodationOffersItemsRequest: accommodationOffersActions.getItemsAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestEditContainer);
