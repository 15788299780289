import React from 'react';
import { withStyles, Theme } from '@material-ui/core';
import { ClassNameMap, StyleRules } from '@material-ui/core/styles/withStyles';

import { Logo } from '../../components';
import { HOTELIOS_LOGO_GOLD } from '../../customization/assets';

const styles = (theme: Theme): StyleRules<UnauthenticatedAppHeaderKey> => ({
  root: {
    paddingTop: '71px'
  },
  image: {
    height: 90,
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
});

type UnauthenticatedAppHeaderKey = 'root' | 'image';

type UnauthenticatedAppHeaderProps = {
  classes: ClassNameMap<UnauthenticatedAppHeaderKey>;
};

const UnauthenticatedAppHeader: React.FunctionComponent<UnauthenticatedAppHeaderProps> = props => {
  return (
    <header className={props.classes.root}>
      <Logo imageSrc={HOTELIOS_LOGO_GOLD} classes={{ root: props.classes.image }} alt="logo" />
    </header>
  );
};

export default withStyles(styles)(UnauthenticatedAppHeader);
