import { ForkEffect, AllEffect, all, put, takeEvery, PutEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { genericError, unauthorizedError } from './actions';
import { addErrorAlert } from '../../components';
import { addUnauthorizedStickyAlert } from '../stickyAlerts';

export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([yield takeEvery(genericError, genericErrorHandler)]);
  yield all([yield takeEvery(unauthorizedError, unauthorizedErrorHandler)]);
}

function* unauthorizedErrorHandler(action: ReturnType<typeof unauthorizedError>):
  Generator<PutEffect<ActionType<typeof addUnauthorizedStickyAlert>>, void, void> {
  try {
    yield put(addUnauthorizedStickyAlert(action.payload.errorMessage));
  } catch (error) {
    console.error(error);
  }
}

function* genericErrorHandler(action: ReturnType<typeof genericError>):
  Generator<PutEffect<ActionType<typeof addErrorAlert>>, void, void> {
  try {
    yield put(addErrorAlert(action.payload));
  } catch (error) {
    console.error(error);
  }
}