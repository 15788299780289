import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { StyleRules, Theme, Grid, Typography } from '@material-ui/core';

import { Icon } from '../icon';

interface TrimTextProps {
  value: string;
  className?: any;
  showClipboardButton?: boolean;
  translate?: boolean;
  classes: ClassNameMap<TrimTextComponentKey>;
}

const styles = (theme: Theme): StyleRules<TrimTextComponentKey> => ({
  root: {
    width: '100%', 
    position: 'absolute', 
    top: 0, 
    padding: theme.spacing(1), 
    left: 0
  },
  overme: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  "copied-to-clipboard": {
    fontWeight: 'bold'
  },
  "hide-clipboard-button": {
    visibility: 'hidden'
  },
  "overme-applied": {

  }
});

type TrimTextComponentKey =
  | 'root'
  | 'overme'
  | 'overme-applied'
  | 'hide-clipboard-button'
  | 'copied-to-clipboard';

const TrimText = (props: TrimTextProps) => {
  const { value: rawValue, className, showClipboardButton = false } = props;
  const divRef = React.useRef<HTMLDivElement>(null);
  const [overflowActive, setOverflowActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [copied, setCopied] = useState(false);
  const value = rawValue;

  const classWrapper = classnames({
    [props.classes.overme]: true,
    [props.classes['overme-applied']]: overflowActive,
    [className]: className
  });

  const clipboardClassWrapper = classnames({
    [props.classes['hide-clipboard-button']]: !(hover && showClipboardButton && overflowActive),
    [props.classes['copied-to-clipboard']]: copied,
  });

  const checkIfEllipsisActive = () => {
    const trimDiv = divRef?.current;

    if (trimDiv) {
      return trimDiv.offsetHeight < trimDiv.scrollHeight || trimDiv.offsetWidth < trimDiv.scrollWidth;
    }
    return false;
  }

  useEffect(() => {
    const trimDiv = divRef?.current;

    if (trimDiv) {
      const isEllipsisActive = checkIfEllipsisActive();
      setOverflowActive(isEllipsisActive);

      const resizeObserver = new ResizeObserver(() => {
        const trimDiv = divRef?.current;

        if (trimDiv) {
          const isEllipsisActive = checkIfEllipsisActive();
          setOverflowActive(isEllipsisActive);
        }
      });

      resizeObserver.observe(trimDiv);
      return (() => {
        if (trimDiv) {
          resizeObserver.unobserve(trimDiv);
        }
      });
    }
  }, [divRef]);

  const renderTitle = () => {
    if (overflowActive) {
      return { title: value }
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
  }

  return (
    <div className={props.classes.root}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => { setHover(false); setCopied(false); }}
      >
        <div className={classWrapper} ref={divRef}>
          <span {...renderTitle()}>{value}&nbsp;</span>
        </div>
        {hover && showClipboardButton && overflowActive && <span onClick={copyToClipboard}><Icon iconName="clipboard" className={clipboardClassWrapper} /></span>}
      </div>
    </div>
  );
}

export default withStyles(styles)(TrimText);