import React, { useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  List
} from '@material-ui/core';
import { NavLink as ReactRouterDomNavLink } from 'react-router-dom';

import AuthenticatedAppRouter from './authenticatedAppRouter';
import { Logo, Icon, HorizontalMenuNavLinkItem, MenuItemModel, ModalDialogManagerContainer } from '../../components';
import * as actions from './actions';
import { HOTELIOS_LOGO_WHITEL } from '../../customization/assets';
import { MenuItemsConfiguration } from '../../configuration/mainMenu';
import StickyAlertsContainer from '../stickyAlerts/stickyAlertsContainer';
import AlertsContainer from '../../components/alerts/alertsContainer';
import { User } from '../authentication';
import { DeepReadonly } from 'utility-types';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
    },
    appBar: {
    },
    toolBar: {
    },
    toolBarImage: {
    },
    menuButton: {
    },
    toolbar: {
      ...theme.mixins.toolbar,
      minHeight: '69px',
      [theme.breakpoints.up('sm')]: {
        minHeight: '69px'
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    contentContainer: {
      flexGrow: 1
    },
    content: {
      flexGrow: 1
    },
    grow: {
      flexGrow: 1
    },
    sectionDesktop: {
      display: 'none',
      padding: '0px',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
        /* width: 'fit-content',    */
      },
      '& > a': {
        textDecoration: 'none'
      }
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center'
    },
    userInfoPersonalId: {
      fontWeight: 500
    },
    userInfoAvatar: {
      width: '5rem',
      height: '5rem'
    },
    menuItemRoot: {
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    menuItemDivider: {
      width: '4px',
      backgroundColor: theme.palette.primary.main
    },
    menuItemIcon: {
      fontSize: '1.2rem',
      color: theme.palette.text.secondary
    },
    menuItemText: {
      fontSize: '1.2rem'
    },
    menuItemActive: {
      width: '4px',
      backgroundColor: theme.palette.primary.main
    },
    menuItemInActive: {
      width: '0px',
      backgroundColor: 'transparent'
    },
    root: {
      minHeight: '100vh',
      display: 'flex',
      flex: '1 0 auto',
      justifyContent: 'space-between'
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    appBarButtonRoot: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    appBarButton: {
      flexDirection: 'column',
      color: theme.palette.text.appBar
    },
    appBarMenuItem: {
      flexDirection: 'column',
      color: theme.palette.text.appBar
    },
    appBarMenuItemIcon: {
      justifyContent: 'center',
      color: theme.palette.text.appBar
    },
    appBarMenuItemText: {
      justifyContent: 'center',
      color: theme.palette.text.appBar,
      margin: '0px'
    },
    appBarImage: {
      height: '43px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingRight: '80px'
    }
  })
);

type AuthenticatedAppProps = {
  user:  DeepReadonly<User>;
  afterAuthenticatedAppDidMount: typeof actions.afterAuthenticatedAppDidMount;
};

export const AuthenticatedApp: React.FunctionComponent<AuthenticatedAppProps> = props => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const afterAuthenticatedAppDidMount = props.afterAuthenticatedAppDidMount;

  useEffect(() => {
    console.debug('AuthenticatedApp.didMount');
    afterAuthenticatedAppDidMount();
  }, [afterAuthenticatedAppDidMount]);

  const renderDesktopMenu = (
    <React.Fragment>
      <div className={classes.sectionDesktop}>
        {/*  <Divider orientation="vertical" /> */}
        <IconButton aria-label="show notifications" className={classes.appBarButton}>
          <Badge /* badgeContent={1} */>
            <Icon iconName="bell" IconProps={{ fontSize: 'large' }} />
          </Badge>
        </IconButton>
        {/*  <Divider orientation="vertical" /> */}
        <ReactRouterDomNavLink to={'/signout'}>
          <IconButton aria-label="signout" className={classes.appBarButton}>
            <Icon iconName="power-off" IconProps={{ fontSize: 'large' }} />
          </IconButton>
        </ReactRouterDomNavLink>
      </div>
    </React.Fragment>
  );
  console.debug('AuthenticatedApp');

  return (
    <React.Fragment>
      <div className={classes.root}>
        <div id="main" className={classes.main}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
              <div><Logo imageSrc={HOTELIOS_LOGO_WHITEL} classes={{ root: classes.appBarImage }} alt="logo" /></div>
              <List className={classes.sectionDesktop}>
                {MenuItemsConfiguration.map((menuItem: MenuItemModel) => {
                  if (menuItem.roleId > 0) {
                    const roleIdsString = props.user.roleIds;
                    const roleIdsList = roleIdsString.split(',');
                    const roleIds = roleIdsList.map(s => Number(s));
                    if (!roleIds.includes(menuItem.roleId)) {
                      return;
                    }
                  }
                  return <HorizontalMenuNavLinkItem key={menuItem.to} to={menuItem.to} iconName={menuItem.icon} text={menuItem.label} />
                })}
              </List>
              <div className={classes.grow} />
              {renderDesktopMenu}
            </Toolbar>
          </AppBar>

          <section className={classes.contentContainer}>
            <div className={classes.toolbar} />
            <div className={classes.content}>
              <ModalDialogManagerContainer />
              <StickyAlertsContainer />
              <AuthenticatedAppRouter />
            </div>
          </section>

          <footer>
            <AlertsContainer />
            <Typography align="center" variant="subtitle1" color="textSecondary">
              K-system.CZ s.r.o. | HOTELIOS.city
            </Typography>
          </footer>
        </div>
      </div>
    </React.Fragment>
  );
};
