import { combineReducers } from 'redux';

import { GuestsState } from './types';
import * as actions from './actions';
import { BaseActions, 
  createBaseListReducer, 
  createBaseErrorMessageReducer, 
  createBaseRequestInProgressReducer } from '../../core';
import { GuestModel } from '../../api/interfaces';

export const initialState: GuestsState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false
  },
  errorMessage: '',
  requestInProgress: false
};

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync,
  setPagination: actions.setPagination
} as BaseActions<GuestModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<GuestsState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer
});
