import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';
import { DeepReadonly } from 'utility-types';

import { nameofFactory } from '../../utils/nameofFactory';
import { priceListValidationSchema } from './validations';
import { PriceListModel } from '../../api/interfaces';
import {
  FormikInput,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  RelationSelectItem,
  FormikRelationSelect
} from '../../components';
import { AccommodationsCreateContainer } from '../accommodations';
import { ROUTES } from '../../navigation';

export type PriceListDetailStateProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
} & BaseDetailStateProps<PriceListModel>;

export type PriceListDetailDispatchProps = {
} & BaseDetailDispatchProps<PriceListModel>;

export type PriceListDetailProps = {} & BaseDetailProps<PriceListModel> & PriceListDetailStateProps & PriceListDetailDispatchProps;

type RenderFormProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  handleAccomodationChanged: (event: React.MouseEvent<HTMLInputElement>) => void;
} & BaseDetailContainerWrappedComponentProps<PriceListModel>;

const nameof = nameofFactory<PriceListModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    accommodationsSelectItems
  } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<PriceListModel>
      initialValues={initialValues}
      onSubmit={(values: PriceListModel, { setSubmitting }): void => {
        console.log("Submit");
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={priceListValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;
        
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid} spacing={3}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Název"
                    name={nameof('name')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Ubytovací zařízení"
                    name={nameof('accommodationId')}
                    errorMessage={errorMessage}
                    items={accommodationsSelectItems as RelationSelectItem[]}
                    createComponent={{
                      component: AccommodationsCreateContainer,
                      route: ROUTES.SETTINGS_ACCOMMODATIONS_CREATE
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

/*const initialRoomTypesSelectItems: RelationSelectItem[] = [];*/

const PriceListDetail: React.FunctionComponent<PriceListDetailProps> = memo(props => {
  console.debug('PriceListDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    accommodationsSelectItems,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<PriceListModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        accommodationsSelectItems: accommodationsSelectItems
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default PriceListDetail;
