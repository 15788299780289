/* eslint-disable @typescript-eslint/camelcase */

import { AxiosInstance } from 'axios';
import moment from 'moment';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { TimeLineDataModel, TimeLineItemModel, TimeLineGroupModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class TimeLineClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'timeline');
  }

  public async getAsync(start: Date, end: Date): Promise<BaseDataResponse<TimeLineDataModel>> {
    const query = `start=${start.toJSON()}&end=${end.toJSON()}`;
    return await this.getHttpAsync<BaseDataResponse<TimeLineDataModel>>('', query);
  }
}