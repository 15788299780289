import { createAsyncAction, createAction } from 'typesafe-actions';

import { User } from './types';

export const loadAuthenticatedUserFormStorageAsync = createAsyncAction(
  'authentication/LOAD_AUTHENTICATED_USER_FROM_STORAGE_REQUEST',
  'authentication/LOAD_AUTHENTICATED_USER_FROM_STORAGE_SUCCESS',
  'authentication/LOAD_AUTHENTICATED_USER_FROM_STORAGE_FAILURE'
)<void, User, void>();

export const tryLoadAuthenticatedUserFormCredentials = createAction('authentication/TRY_LOAD_AUTHENTICATED_USER_FROM_CREDENTIALS')<void>();

export const setAuthenticatedUserFormCredentials = createAction('authentication/SET_AUTHENTICATED_USER_FROM_CREDENTIALS')<User>();

