import * as Yup from 'yup';

import { accommodationIdValidationSchema, nameValidationSchema } from '../validations';

export const roomTypeValidationSchema = Yup.object().shape({
    name: nameValidationSchema,
    personCount: Yup.number()
        .required('Počet osob je povinný')
        .min(1, 'Počet osob musí být nastaven'),
    accommodationId: accommodationIdValidationSchema
});
