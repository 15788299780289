import { put, all, takeEvery, select, PutEffect, SelectEffect, ForkEffect, AllEffect, call } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import sha1 from 'sha1';

import apiClientInstance from '../../api/apiClientInstance';
import { signUpAsync } from './actions';
import { BaseResponse } from '../../api/responses';
import { UserRegistrationModel } from '../../api/interfaces';
import { navigateTo, ROUTES } from '../../navigation';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([yield takeEvery(signUpAsync.request, signUpRequest)]);
}

type SignUpRequestGeneratorType =
    | Promise<BaseResponse>
    | PutEffect<ActionType<typeof signUpAsync.success>>
    | PutEffect<ActionType<typeof navigateTo>>
    | PutEffect<ActionType<typeof signUpAsync.failure>>;

function* signUpRequest(action: ReturnType<typeof signUpAsync.request>): Generator<SignUpRequestGeneratorType, void, BaseResponse> {
    // TODO: AsyncGenerator {
    try {
        console.debug(action.payload);

        const passwordHash: string = sha1(action.payload.password);
        const method: keyof typeof apiClientInstance.Users = 'registerAsync';
        const model = {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            password: passwordHash,
            inviteToken: action.payload.inviteToken
        } as UserRegistrationModel;

        const response: BaseResponse = yield apiClientInstance.apiCallHandler(
            {
                context: apiClientInstance.Users,
                apiCallFnName: method
            },
            model
        );

        if (response.resultCode === 0) {
            yield put(signUpAsync.success());
            yield put(navigateTo(ROUTES.SINGIN));
        } else {
            yield put(signUpAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
        }
    } catch (error) {
        if (error instanceof Error) {
            yield put(signUpAsync.failure(error));
        }
    }
}
