import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type PaymentsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const PaymentsTable: React.FunctionComponent<PaymentsTableProps> = memo(props => {
    console.debug('PaymentsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default PaymentsTable;
