import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';

import {
  FormikInput,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { floorValidationSchema } from './validations';
import { FloorModel } from '../../api/interfaces';

export type FloorDetailStateProps = {
} & BaseDetailStateProps<FloorModel>;

export type FloorDetailDispatchProps = {
} & BaseDetailDispatchProps<FloorModel>;

export type FloorDetailProps = {} & BaseDetailProps<FloorModel> & FloorDetailStateProps & FloorDetailDispatchProps;

type RenderFormProps = {
} & BaseDetailContainerWrappedComponentProps<FloorModel>;

const nameof = nameofFactory<FloorModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues } = props;

  const handleActionCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<FloorModel>
      initialValues={initialValues}
      onSubmit={(values: FloorModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={floorValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, values } = formProps;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Název"
                    name={nameof('name')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="sticky-note"
                    labelText="Popisek"
                    name={nameof('description')}
                    errorMessage={errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="user"
                    labelText="Patro"
                    name={nameof('number')}
                    errorMessage={errorMessage}
                    InputProps={{
                      type: 'number'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};


const FloorDetail: React.FunctionComponent<FloorDetailProps> = memo(props => {
  console.debug('FloorDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;



  return (
    <BaseDetailContainer<FloorModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default FloorDetail;
