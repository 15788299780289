import { ApplicationState } from '../../store/rootReducer';
import { ReservationModel, ReservationRoomModel, RoomModel, RoomTypeModel } from '../../api/interfaces';
import { DeepReadonly } from 'utility-types';
import { retry } from 'redux-saga/effects';
import { RelationSelectItem } from '../../components';

export const getCurrentReservation = (state: ApplicationState): ReservationModel => {
  return state.reservations.current;
};

export const selectRooms = (state: ApplicationState, items: DeepReadonly<RelationSelectItem[]>): DeepReadonly<RelationSelectItem[]> => {
  const ids = state.reservations.current.rooms.map(room => room.roomId);
  return items.filter(item => {
    return !ids.includes(item.value);
  });
};

export const getRoomModel = (state: ApplicationState, id: number): ReservationRoomModel => {
  return state.reservations.current.rooms.find(room => room.id === id) as ReservationRoomModel;
};

export const selectRoomSelectItems = (state: ApplicationState, id: number): DeepReadonly<RelationSelectItem[]> => {
  const roomModel = getRoomModel(state, id);

  const rooms = [] as RoomModel[];
  Object.values(state.rooms.itemList.byId).forEach(room => {
    if (roomModel.roomId === room.id) {
      rooms.push(room);
    }
  });

  return rooms.map(room => {
    return { text: room.name, value: room.id } as RelationSelectItem;
  });
};

export const selectRoomsSelectItems = (state: ApplicationState): DeepReadonly<RelationSelectItem[]> => {
  const rooms = [] as RelationSelectItem[];
  state.reservations.current.rooms.forEach(room => {
    rooms.push({ text: room.roomName, value: room.roomId });
  });

  return rooms;
};


export const selectRoomsSelectItemsByGuestId = (
  selectedGuestId: number,
  roomsById: { [itemId: number]: DeepReadonly<ReservationRoomModel> }
): RelationSelectItem[] => {
  console.debug('Calling selectRoomTypesSelectItemsByAccomodationId...');

  return Object.values(roomsById)
    .filter(room => {
      return room.guests.some(guest => guest.guestId === selectedGuestId);
    })
    .map<RelationSelectItem>(room => {
      return { text: room.roomName, value: room.roomId } as RelationSelectItem;
    });
};

export const selectGuestsSelectItems = (state: ApplicationState): DeepReadonly<RelationSelectItem[]> => {
  const guests = [] as RelationSelectItem[];
  state.reservations.current.rooms.forEach(room => {
    room.guests.forEach(guest => {
      guests.push({ text: guest.name, value: guest.guestId });
    })
  });

  return guests;
};

export const getCountOfBedsByRoomAndRoomType = (
  roomId: number,
  roomsById: { [itemId: number]: DeepReadonly<RoomModel> },
  roomTypesById: { [itemId: number]: DeepReadonly<RoomTypeModel> }
): number => {
  if (roomsById && roomTypesById) {
    const room = Object.values(roomsById).find(room => room.id === roomId);
    if (room) {
      const roomType = Object.values(roomTypesById).find(roomType => roomType.id === room.roomTypeId);

      if (roomType) {
        return roomType.personCount;
      }
    }
  }

  return 0;
};

export const totalGuestsThatCanBeAdded = (state: ApplicationState): number => {
  const numberOfPersons = state.accommodationOfferCalculations.offerHeader.numberOfPersons; //state.reservations.current.numberOfPersons;

  let totalGuests = 0;
  state.reservations.current.rooms.forEach(room => {
    totalGuests += room.guests.length;
  });

  const diff = numberOfPersons - totalGuests;

  if (diff < 0) {
    return 0;
  } else {
    return diff;
  }
};