import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import RoomTypeDetail, { RoomTypeDetailStateProps, RoomTypeDetailDispatchProps, RoomTypeDetailProps } from './roomTypesDetail';
import * as actions from './actions';
import { RoomTypeModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectAccommodationsSelectItems } from '../accommodations';

type RoomTypeCreateContainerStateProps = {} & RoomTypeDetailStateProps;

type RoomTypeCreateContainerDispatchProps = {} & RoomTypeDetailDispatchProps;

type RoomTypeCreateContainerProps = {} & RoomTypeDetailProps & RoomTypeCreateContainerStateProps & RoomTypeCreateContainerDispatchProps;

const createInitialValues = { description: '', name: '', personCount: 0, accommodationId: 0 } as RoomTypeModel;

const RoomTypeCreateContainer: React.FunctionComponent<RoomTypeCreateContainerProps> = memo(props => {
  console.debug('RoomTypeCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    accommodationsSelectItems,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <RoomTypeDetail
      initialValues={initialValues}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      loadDependenciesRequest={loadDependenciesRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      accommodationsSelectItems={accommodationsSelectItems}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState): RoomTypeCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.roomTypes.errorMessage,
    requestInProgress: state.roomTypes.requestInProgress,
    accommodationsSelectItems: selectAccommodationsSelectItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomTypeCreateContainerDispatchProps =>
  bindActionCreators(
    {
      saveActionRequest: actions.saveItemAsync.request,
      cancelActionRequest: actions.cancelItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeCreateContainer);
