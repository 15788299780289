import React, { memo } from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { AddButton, Icon, RelationSelectItem } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { selectCompaniesSelectItems } from '../companies';
import { selectAccommodationsSelectItems } from '../accommodations';
import * as actions from './actions';
import classNames from 'classnames';
import { AccommodationOffersEvidenceListCreateContainer } from '../accommodationOffersEvidenceList';
import { ROUTES } from '../../navigation';

const nameof = nameofFactory<AccommodationOffersSelectionHeaderModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      position: 'relative'
    },
    headerBtn: {
      textAlign: 'right'
    },
    body: {
      position: 'absolute',
      top: '2.9rem',
      right: '-1.0rem',
      width: '350px',
      minHeight: '200px',
      zIndex: 10000,
      background: '#fafafa',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '5px -2px 13px 1px rgba(0, 0, 0, 0.2)'
    },
    bodyHidden: {
      display: 'none'
    },
    bodyVisible: {
      display: 'flex'
    },
    bodyRowIcon: {
      padding: '5px'
    },
    bodyRowText: {
      padding: '5px',
      fontWeight: 600,
      lineHeight: '2.5rem',
    },
    headerContent: {
      height: '3.0rem',
      lineHeight: '3.0rem',
      padding: '0 20px',
      borderBottom: '1px solid #E1E1E1',
      fontSize: '1.2rem',
      fontWeight: 700,
      display: 'flex',
      justifyContent: 'space-between'
    },
    footContent: {
      padding: '0px 20px',
      borderTop: '1px solid #E1E1E1',
      textAlign: 'right',
      height: '3.0rem',
      lineHeight: '3.0rem',
    },
    bodyContent: {
      flex: '1 auto'
    }
  })
);

type AccommodationOffersSelectionHeaderModel = {
};

type AccommodationOffersSelectionHeaderStateProps = {
  ids: Array<number>;
  isOpen: boolean;
  items: { [itemId: number]: { id: number; name: string } };
};

type AccommodationOffersSelectionHeaderDispatchProps = {
  setSelection: typeof actions.setSelection;
};

type AccommodationOffersSelectionHeaderProps = {

} & AccommodationOffersSelectionHeaderStateProps & AccommodationOffersSelectionHeaderDispatchProps;

const AccommodationOffersSelectionHeader: React.FunctionComponent<AccommodationOffersSelectionHeaderProps> = memo(props => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOnclick = (event: React.ChangeEvent<{}>): void => {
    setIsOpen(!isOpen);

    props.setSelection({ ids: props.ids, isOpen: !isOpen, items: props.items });
  };

  const handlePrintBtn = (): void => { 
    setIsOpen(false);

    props.setSelection({ ids: props.ids, isOpen: false, items: props.items });
  };

  console.debug('AccommodationOffersSelectionHeader');
  const classes = useStyles();
  const bodyClass = classNames(classes.body, { [classes.bodyHidden]: !props.isOpen, [classes.bodyVisible]: props.isOpen });

  return (

    <React.Fragment>
      <div className={classes.header}>
        <div className={classes.headerBtn} onClick={handleOnclick}>
          <Icon
            iconName={"layer-group"}
            IconProps={{ fontSize: 'large' }} />
        </div>
        <div className={bodyClass}>
          <div className={classes.headerContent}>
            Výběr rezervací
          </div>
          <div className={classes.bodyContent}>
          {props.ids.map(id => {
            return <div>
              <span className={classes.bodyRowIcon}>
                <Icon
                  iconName={'layer-minus'}
                  IconProps={{
                    color: 'secondary',
                    title: 'Odebrat',
                    style: { cursor: 'pointer' },
                    onClick: (event: React.MouseEvent<HTMLSpanElement>): void => {
                      let ids = props.ids;
                      if (ids.includes(id)) {
                        ids = [...ids.filter(_id => _id !== id)];
                      }

                      const newItems = { ...props.items };
                      delete newItems[id];

                      props.setSelection({ ids: ids, isOpen: true, items: newItems });
                    }
                  }}
                />
              </span>
              <span className={classes.bodyRowText}>{props.items[id].name}</span>
            </div>
          })}
          </div>
          <div className={classes.footContent}>
            <AddButton
              createComponent={{
                component: AccommodationOffersEvidenceListCreateContainer,
                route: ROUTES.ACCOMMODATION_OFFERS_EVIDENCE_LIST_CREATE,
              }}
              text="Evidenční list"
              onClick={handlePrintBtn}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState): AccommodationOffersSelectionHeaderStateProps => {
  return {
    ids: state.accommodationOffers.itemList.selection?.ids || [],
    isOpen: state.accommodationOffers.itemList.selection?.isOpen || false,
    items: state.accommodationOffers.itemList.selection?.items || {}
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOffersSelectionHeaderDispatchProps =>
  bindActionCreators({
    setSelection: actions.setSelection
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOffersSelectionHeader);
