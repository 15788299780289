
import { ApplicationState } from '../../store/rootReducer';
import { NormalizedListById } from '../../core';
import { RoomModel } from '../../api/interfaces';

type RoomAndFloor = {
  floorNumber: number;
  roomTypeId: number;
};

export const getAreRoomsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.rooms.itemList.loaded;
};

export const getAllById = (state: ApplicationState): NormalizedListById<RoomModel> => {
  return state.rooms.itemList.byId;
};