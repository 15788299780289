import * as Yup from 'yup';

export const signUpValidationSchema = Yup.object().shape({
    firstName: Yup.string().required('Jméno je povinné'),
    lastName: Yup.string().required('Přijmení je povinné'),
    password: Yup.string().required('Heslo je povinné'),
    passwordCheck: Yup.string()
        .required('Kontrolní heslo je povinné')
        .when('password', (password: string, schema: Yup.StringSchema) => {
            return schema.test(password, 'Hesla se neshodují', value => value === password);
        }),
    inviteToken: Yup.string()
});
