import { AccommodationOfferFeeModel, AccommodationOfferFloorModel, AccommodationOfferRoomTypeModel, ReservationModel } from '.';
import { BaseModel } from './baseModel';

export enum AccommodationOfferSubmitTypeEnum {
  Save = 1,
  Calculate = 2
}

export enum AccommodationOfferStateEnum {
  NotSet = 0,
  Offer = 1,
  Reservation = 2
}

export enum AccommodationOfferSaveAsEnum {
  Offer = 1,
  Reservation = 2,
  OfferWithoutRoomTypesUpdate = 3,
  OfferWithRoomTypesUpdate = 4,
}

export enum ReservationPaymentStatusEnum {
  No = 1,
  NotPaidTotally = 2,
  Paid = 3,
  OverPaid = 4
}

export type AccommodationOfferModel = {
  roomTypes: AccommodationOfferRoomTypeModel[];
  floors: AccommodationOfferFloorModel[];
  fees: AccommodationOfferFeeModel[];
} & AccommodationOfferHeaderModel & BaseModel & {
  submitType: AccommodationOfferSubmitTypeEnum;
  saveAs: AccommodationOfferSaveAsEnum;
  validationType: number;
};

export type AccommodationOfferHeaderModel = {
  accommodationId: number;
  state: AccommodationOfferStateEnum;
  guestId: number;
  companyId?: number;
  priceListId: number;
  from: Date;
  to: Date;
  numberOfNights: number;
  numberOfPersons: number;
  reservation: ReservationModel;
  offerNumber: string;
  paymentStatus: ReservationPaymentStatusEnum;
  payForFreeBeds: boolean;
  payForRoomAndNight: boolean;
};