import { createReducer } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { ErrorsState, ErrorsAction } from './types';
import { genericError, unauthorizedError } from './actions';

export const initialState: ErrorsState = {
  errorCode: 0,
  errorMessage: ''
};

const errorsReducer = createReducer<DeepReadonly<ErrorsState>, ErrorsAction>(initialState)
  .handleAction(genericError, (state, action) => initialState)
  .handleAction(unauthorizedError, (state, action) => initialState);

export default errorsReducer;
