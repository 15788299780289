import React from 'react';
import { useField } from 'formik';
import { SwitchProps as MuiSwitchProps } from '@material-ui/core/Switch';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { default as Switch, SwitchClassKey } from './switch';

type FormikSwitchProps = {
  name: string;
  errorMessage?: string;
  labelText: string;
  switchProps?: MuiSwitchProps;
  classes?: Partial<ClassNameMap<SwitchClassKey>>;
};

const FormikSwitch = (props: FormikSwitchProps): React.ReactElement => {
  const [field, meta] = useField(props.name);

  let hasValue = !!field.value;
  if (props.switchProps && props.switchProps.type === 'number') {
    hasValue = field.value !== 'NaN';
  }

  return (
    <Switch
      labelText={props.labelText}
      helperText={meta.touched ? meta.error : ''}
      error={Boolean(meta.touched) && (Boolean(meta.error) || Boolean(props.errorMessage))}
      classes={{ ...props.classes }}
      switchProps={{
        name: props.name,
        onChange: field.onChange,
        value: field.value,
        ...props.switchProps
      }}
    />
  );
};

export default FormikSwitch;
