import React, { useState, ReactNode } from 'react';
import { Formik, useField } from 'formik';
import { InputProps as MuiInputProps } from '@material-ui/core/Input';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import _ from 'lodash';

import { IconName } from '../../icons';
import { default as DatePicker, DatePickerClassKey } from './datePicker';

type FormikDatePickerProps = {
  name: string;
  errorMessage?: string;
  iconName: IconName;
  labelText: string;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  classes?: Partial<ClassNameMap<DatePickerClassKey>>;
  showErrorWhenNotTouched?: boolean;
  onChange?: (date: MaterialUiPickersDate | null, value?: string | null) => void;
};

const FormikDatePicker = (props: FormikDatePickerProps): React.ReactElement => {
  const [field, meta, helper] = useField<Date | null>(props.name);

  const hasValue = !!field.value;
  const [shrink, setShrink] = useState(hasValue);
  const shrinkLabel = (): void => {
    setShrink(true);
  };
  const unShrinkLabel = (event: React.FocusEvent<HTMLInputElement>): void => {
    if (event.target.value === null || event.target.value.length === 0) {
      setShrink(false);
    }
  };
  const onFocusEventHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    shrinkLabel();
  };

  const onBlurEventHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
    unShrinkLabel(event);
    field.onBlur(event);
  };

  const onChangeHandler = (date: MaterialUiPickersDate | null, value?: string | null): void => {
    if (date) {
      helper.setValue(new Date(date.toDate()), true);
      setShrink(true);
    } else {
      helper.setValue(null, true);
      setShrink(false);
    }

    if (props.onChange) {
      props.onChange(date);
    }
  };

  const convertToDateTime = (value: any): Date => {
    if (_.isString(value)) {
      return new Date(value);
    }

    return value;
  };

  return (
    <DatePicker
      iconName={props.iconName}
      labelText={props.labelText}
      helperText={meta.touched || props.showErrorWhenNotTouched ? meta.error : ''}
      error={(Boolean(meta.touched) || props.showErrorWhenNotTouched) && (Boolean(meta.error) || Boolean(props.errorMessage))}
      classes={{ ...props.classes }}
      name={props.name}
      value={convertToDateTime(field.value)}
      onChange={onChangeHandler}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink: shrink
      }}
      InputProps={{
        onFocus: onFocusEventHandler,
        onBlur: onBlurEventHandler,
        ...props.InputProps
      }}
    />
  );
};

export default FormikDatePicker;
