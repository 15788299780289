import React from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { getAccommodationOfferPaymentStatusSelectItems, getAccommodationOfferStateSelectItems } from '.';
import { ApplicationState } from '../../store/rootReducer';
import { FormikDatePicker, FormikRelationSelect, RelationSelectItem, TablePaginationModel } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { selectCompaniesSelectItems } from '../companies';
import { selectAccommodationsSelectItems } from '../accommodations';
import { AccommodationOfferStateEnum, ReservationPaymentStatusEnum } from '../../api/interfaces';
import * as actions from './actions';
import { TablePageChangeModel } from '../../components/table';

const nameof = nameofFactory<AccommodationOffersFilterModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '30px'
    }
  })
);

type AccommodationOffersFilterModel = {
  companyId: number;
  accommodationId: number;
  state: AccommodationOfferStateEnum;
  from?: Date;
  to?: Date;
  paymentStatus: ReservationPaymentStatusEnum | undefined;
};

type AccommodationOffersFilterStateProps = {
  companiesSelectItems: DeepReadonly<RelationSelectItem[]>;
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
};

type AccommodationOffersFilterDispatchProps = {
  getItems: typeof actions.getItemsAsync.request;
};

type AccommodationOffersFilterProps = {
  onClose(): void;
} & AccommodationOffersFilterStateProps & AccommodationOffersFilterDispatchProps;

const AccommodationOffersFilter: React.FunctionComponent<AccommodationOffersFilterProps> = props => {
  console.debug('AccommodationOffersFilter');
  const classes = useStyles();
  const accommodationOfferStateSelectItems = getAccommodationOfferStateSelectItems();
  const accommodationOfferPaymentStatusSelectItems = getAccommodationOfferPaymentStatusSelectItems();
  const { companiesSelectItems, accommodationsSelectItems, getItems } = props;
  return (
    <React.Fragment>
      <Formik<AccommodationOffersFilterModel>
        initialValues={{ companyId: 0, accommodationId: 0, state: 0, from: undefined, to: undefined, paymentStatus: undefined }}
        onSubmit={(values: any): void => {
          let filter = "";
          if (values.companyId > 0) {
            filter += `companyId=${values.companyId}%26`
          }
          if (values.state > 0) {
            filter += `state=${values.state}%26`
          }
          if (values.accommodationId > 0) {
            filter += `accommodationId=${values.accommodationId}%26`
          }
          if (values.from !== undefined) {
            filter += `from=${values.from.toISOString()}%26`
          }
          if (values.to !== undefined) {
            filter += `to=${values.to.toISOString()}`
          }
          if (values.paymentStatus !== undefined) {
            filter += `paymentStatus=${values.paymentStatus}`
          }

          getItems({ page: 0, filter: filter, skip: 0 } as TablePageChangeModel);
          props.onClose();
        }}
      >
        {(formProps): React.ReactElement => {
          const { handleSubmit, resetForm, submitForm } = formProps;
          return (
            <form style={{ width: '500px' }} onSubmit={handleSubmit} autoComplete="off">
              <Grid container className={classes.grid} spacing={3}>
                <Grid container item>
                  <FormikRelationSelect
                    iconName="industry"
                    labelText="Firma"
                    name={nameof('companyId')}
                    items={companiesSelectItems as RelationSelectItem[]}
                    showErrorWhenNotTouched={true} />
                </Grid>
                <Grid container item>
                  <FormikRelationSelect
                    iconName="info"
                    labelText="Stav"
                    name={nameof('state')}
                    items={accommodationOfferStateSelectItems as RelationSelectItem[]}
                    showErrorWhenNotTouched={true}
                  />
                </Grid>
                <Grid container item>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Ubytovací zařízení"
                    name={nameof('accommodationId')}
                    items={accommodationsSelectItems as RelationSelectItem[]}
                    showErrorWhenNotTouched={true}
                  />
                </Grid>
                <Grid container item>
                  <FormikRelationSelect
                    iconName="wallet"
                    labelText="Stav platby"
                    name={nameof('paymentStatus')}
                    items={accommodationOfferPaymentStatusSelectItems as RelationSelectItem[]}
                    showErrorWhenNotTouched={true}
                  />
                </Grid>
                <Grid container item>
                  <Grid container item xs={6}>
                    <FormikDatePicker
                      iconName="address-card"
                      labelText="Od"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={nameof('from')}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                  <Grid container item xs={6}>
                    <FormikDatePicker
                      iconName="address-card"
                      labelText="Do"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={nameof('to')}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={(): void => {
                      resetForm();
                      submitForm();
                    }}>
                      Odstranit vše
                    </Button>&nbsp;
                    <Button color="primary" type="submit" variant="contained">
                      Hledat
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>);
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): AccommodationOffersFilterStateProps => {
  return {
    companiesSelectItems: selectCompaniesSelectItems(state),
    accommodationsSelectItems: selectAccommodationsSelectItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOffersFilterDispatchProps =>
  bindActionCreators({
    getItems: actions.getItemsAsync.request
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOffersFilter);
