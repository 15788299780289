import React, { memo, useState } from 'react';
import { Grid, Button, Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { DeepReadonly } from 'utility-types';
import _ from 'lodash';

import {
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikRelationSelect,
  RelationSelectItem,
  FormikObserver
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { accommodationOfferRoomValidationSchema } from './validations';
import {
  AccommodationOfferRoomTypeModel,
  AccommodationOfferRoomModel,
  RoomModel
} from '../../api/interfaces';
import * as actions from './actions';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chips: {
      marginRight: '2px'
    },
    button: {
      marginTop: '5px'
    }
  })
);

type myStylesClasses = 'chips' | 'button';

export type AccommodationOfferRoomTypeDetailStateProps = {
  rooms: { [itemId: number]: DeepReadonly<RoomModel> };
  roomsSelectItems: DeepReadonly<RelationSelectItem[]>;
 
} & BaseDetailStateProps<AccommodationOfferRoomTypeModel>;

export type AccommodationOfferRoomTypeDetailDispatchProps = {
  editRoomType: typeof actions.editRoomTypeAction;
} & BaseDetailDispatchProps<AccommodationOfferRoomTypeModel>;

export type AccommodationOfferRoomTypeDetailProps = {
  _roomsSelectItems: RelationSelectItem[];
  _setRoomsSelectItems: React.Dispatch<React.SetStateAction<RelationSelectItem[]>>;
} & BaseDetailProps<AccommodationOfferRoomTypeModel> & AccommodationOfferRoomTypeDetailStateProps & AccommodationOfferRoomTypeDetailDispatchProps;

type RenderFormProps = {
  rooms: { [itemId: number]: DeepReadonly<RoomModel> };
  roomsSelectItems: DeepReadonly<RelationSelectItem[]>;
  _roomsSelectItems: RelationSelectItem[];
  _setRoomsSelectItems: React.Dispatch<React.SetStateAction<RelationSelectItem[]>>;
  editRoomType: typeof actions.editRoomTypeAction;
  myStyles: ClassNameMap<myStylesClasses>;
} & BaseDetailContainerWrappedComponentProps<AccommodationOfferRoomTypeModel>;

const nameof = nameofFactory<AccommodationOfferRoomTypeModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    cancelActionRequest,
    editRoomType,
    roomsSelectItems,
    myStyles,
    _roomsSelectItems,
    _setRoomsSelectItems
  } = props;


  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    cancelActionRequest();
  };

  const getItems = (): RelationSelectItem[] => {
    const filteredRooms = roomsSelectItems.filter(room => initialValues.rooms.findIndex(_room => _room.roomId === room.value) === -1);

    if (_roomsSelectItems.length > 0) {
      return filteredRooms.filter(room => {
        return _roomsSelectItems.findIndex(_room => _room.value === room.value) === -1;
      }) as RelationSelectItem[];
    }

    return filteredRooms as RelationSelectItem[];
  }

  return (
    <Formik<AccommodationOfferRoomTypeModel>
      initialValues={initialValues}
      onSubmit={(values: AccommodationOfferRoomTypeModel, { setSubmitting }): void => {
        setSubmitting(true);
        editRoomType(values);
      }}
      validationSchema={accommodationOfferRoomValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, setFieldValue, values } = formProps;

        return (
          <form onSubmit={handleSubmit} className='form-spacing-3' autoComplete="off">
            <FormikObserver<AccommodationOfferRoomTypeModel> values={formProps.values} onChange={(values, prevValues): void => {
                if (values.rooms !== prevValues.rooms) {
                  _setRoomsSelectItems(values.rooms.map(room => {
                    return {
                      text: room.roomName,
                      value: room.roomId
                    } as RelationSelectItem;
                  }));
                }
            }}/>
            <Grid container className={classes.grid} spacing={3}>
              <Grid item xs={12}>
                <div style={{ display: 'flex' }}>
                  <div style={{ flex: '1 auto' }}>
                    <FormikRelationSelect
                      iconName="hotel"
                      labelText="Pokoje"
                      name="roomId"
                      showErrorWhenNotTouched={false}
                      errorMessage={errorMessage}
                      items={getItems() as RelationSelectItem[]}
                    />
                  </div>
                  <div style={{ flex: '0 auto', alignSelf: 'center', margin: 'auto', padding: '5px' }}>
                    <Button className={myStyles.button} variant="contained" title="Přidat" onClick={(e: any): void => {
                      const { roomId } = values;
                      setFieldValue('roomId', 0);
                      if (roomId <= 0) {
                        return;
                      }
                      const room = roomsSelectItems.find(room => room.value === roomId);
                      const rooms = _.cloneDeep(values.rooms);

                      if (rooms.findIndex(r => r.roomId === roomId) === -1) {
                        rooms.push({
                          roomName: room?.text,
                          roomId: roomId
                        } as AccommodationOfferRoomModel);
                        setFieldValue('rooms', rooms);
                      }
                    }}>
                      Přidat
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} style={{ width: '300px' }}>
                <div>
                {formProps.values.rooms && formProps.values.rooms.map(room => {
                  return <Chip
                    label={room.roomName}
                    className={myStyles.chips}
                    onDelete={(): any => {
                      setFieldValue('rooms', formProps.values.rooms.filter(g => g.roomId !== room.roomId));
                    }}
                    style={{ margin: '2px' }}
                    color="secondary"
                  />
                })}
                </div>
              </Grid>
            </Grid>
            <Grid container className={classes.grid}>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const AccommodationOfferRoomTypeDetail: React.FunctionComponent<AccommodationOfferRoomTypeDetailProps> = memo(props => {
  console.debug('AccommodationOfferRoomTypeDetail');
  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    editRoomType,
    roomsSelectItems,
    rooms,
    _roomsSelectItems,
    _setRoomsSelectItems
  } = props;

  const myStyles = useStyles();

  return (
    <BaseDetailContainer<AccommodationOfferRoomTypeModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        editRoomType: editRoomType,
        roomsSelectItems: roomsSelectItems,
        rooms: rooms,
        myStyles: myStyles,
        _roomsSelectItems,
        _setRoomsSelectItems
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default AccommodationOfferRoomTypeDetail;
