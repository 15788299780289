import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AccommodationOfferFeeDetail, { AccommodationOfferFeeDetailProps, AccommodationOfferFeeDetailDispatchProps, AccommodationOfferFeeDetailStateProps } from './accommodationOfferFeeDetail';
import * as actions from './actions';
import { AccommodationOfferFeeModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectPriceListsSelectItems } from '../priceLists';
import { getPriceListItemsById } from '../priceListItems';
import { selectPriceListItemsSelectFeesItems } from '../priceListItems/selectors';

type AccommodationOfferFeeCreateContainerStateProps = {
} & AccommodationOfferFeeDetailStateProps;

type AccommodationOfferFeeCreateContainerDispatchProps = {
  addFee: typeof actions.addFeeAction;
  selectPriceListItem: typeof actions.selectPriceListItemAction;
} & AccommodationOfferFeeDetailDispatchProps;

type AccommodationOfferFeeCreateContainerProps = {} & AccommodationOfferFeeDetailProps & AccommodationOfferFeeCreateContainerStateProps & AccommodationOfferFeeCreateContainerDispatchProps;

const createInitialValues = {
  id: -1,
  name: '',
  priceListId: -1,
  priceListItemId: -1,
} as AccommodationOfferFeeModel;

const AccommodationOfferFeeCreateContainer: React.FunctionComponent<AccommodationOfferFeeCreateContainerProps> = memo(props => {
  console.debug('AccommodationOfferFeeCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    priceListsSelectItems,
    priceListItemsSelectItems,
    addFee,
    selectPriceListItem,
    priceListItems
  } = props;

  return (
    <React.Fragment>
      <AccommodationOfferFeeDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        priceListsSelectItems={priceListsSelectItems}
        priceListItemsSelectItems={priceListItemsSelectItems}
        addFee={addFee}
        selectPriceListItem={selectPriceListItem}
        priceListItems={priceListItems}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferFeeCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    priceListsSelectItems: selectPriceListsSelectItems(state),
    priceListItemsSelectItems: selectPriceListItemsSelectFeesItems(state),
    priceListItems: getPriceListItemsById(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferFeeCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        addFee: actions.addFeeAction,
        selectPriceListItem: actions.selectPriceListItemAction
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferFeeCreateContainer);
