import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync, getAreAccommodationsLoaded } from '../accommodations';

import apiClient from '../../api/apiClientInstance';
import { RoomTypeModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest } from '../../core';

// use them in parallel
export default function* rootSaga(): any {
    yield all([
        yield takeEvery(getItemsAsync.request, getRommTypesRequest),
        yield takeEvery(newItemAsync.request, newRommTypeRequest),
        yield takeEvery(saveItemAsync.request, saveRommTypeRequest),
        yield takeEvery(cancelItemAsync.request, cancelRommTypeRequest),
        yield takeEvery(editItemAsync.request, editRommTypeRequest),
        yield takeEvery(deleteItemAsync.request, deleteRommTypeRequest),
        yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
    ]);
}

type GetRommTypeRequestGeneratorType =
    | Generator<SelectEffect, boolean, boolean>
    | Promise<BaseDataResponse<RoomTypeModel[]>>
    | PutEffect<ActionType<typeof getItemsAsync.cancel>>
    | PutEffect<ActionType<typeof getItemsAsync.success>>
    | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getRommTypesRequest(
    action: ReturnType<typeof getItemsAsync.request>
): Generator<GetRommTypeRequestGeneratorType, void, boolean & BaseDataResponse<RoomTypeModel[]>> {
    try {
        console.debug('getRommTypesRequest');

        const payload = action.payload as GetAllRequestAsyncActionPayload;
        const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
        if (!isReloadRequired) {
            yield put(getItemsAsync.cancel());
            return;
        }

        const response: BaseDataResponse<RoomTypeModel[]> = yield apiClient.apiCallHandler({
            context: apiClient.RoomTypes,
            apiCallFnName: 'getAllAsync'
        });

        if (response.resultCode === 0) {
            yield put(getItemsAsync.success(response.data));
        } else {
            yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
        }
    } catch (error) {
        yield put(getItemsAsync.failure(error));
    }
}

type NewRommTypeRequestGeneratorType =
    | PutEffect<ActionType<typeof navigateTo>>
    | PutEffect<ActionType<typeof newItemAsync.success>>
    | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newRommTypeRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewRommTypeRequestGeneratorType, void, void> {
    try {
        console.debug('newRommTypesRequest');

        yield put(navigateTo(ROUTES.SETTINGS_ROOM_TYPES_CREATE));
        yield put(newItemAsync.success());
    } catch (error) {
        yield put(newItemAsync.failure(error));
    }
}

type EditRommTypeRequestGeneratorType =
    | Promise<BaseDataResponse<RoomTypeModel>>
    | PutEffect<ActionType<typeof editItemAsync.success>>
    | PutEffect<ActionType<typeof editItemAsync.failure>>
    | PutEffect<ActionType<typeof navigateToDetail>>
    | PutEffect<ActionType<typeof navigateTo>>;

function* editRommTypeRequest(
    action: ReturnType<typeof editItemAsync.request>
): Generator<EditRommTypeRequestGeneratorType, void, BaseDataResponse<RoomTypeModel>> {
    try {
        console.debug('editRommTypeRequest');
        const itemToEditId = action.payload;

        yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_ROOM_TYPES_DETAIL, detailId: itemToEditId } as NavigateToDetail));
        yield put(editItemAsync.success());
    } catch (error) {
        yield put(editItemAsync.failure(error));
    }
}

const deleteRommTypeRequest = registerDeleteEntityRequest(
    deleteItemAsync.success,
    deleteItemAsync.failure,
    apiClient.RoomTypes,
    ROUTES.SETTINGS_ROOM_TYPES
);

const saveRommTypeRequest = registerSaveEntityRequest<RoomTypeModel>(
    saveItemAsync.success,
    saveItemAsync.failure,
    apiClient.RoomTypes,
    ROUTES.SETTINGS_ROOM_TYPES
);

const cancelRommTypeRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_ROOM_TYPES);

type LoadDependenciesRequestGeneratorType =
    | SelectEffect
    | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
    | PutEffect<ActionType<typeof getItemsAsync.request>>
    | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
    | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
    action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
    try {
        console.debug('loadDependenciesRequest');

        const areAcoommodationsLoaded = yield select(getAreAccommodationsLoaded);
        if (!areAcoommodationsLoaded) {
            yield put(loadAccommodationsAsync.request());
        }

        const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
        if (!areItemsLoaded) {
            yield put(getItemsAsync.request());
        }

        yield put(loadDependenciesAsync.success());
    } catch (error) {
        yield put(loadDependenciesAsync.failure(error));
    }
}
