import React from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import { AccommodationOfferPriceCalculationModel } from '../../api/interfaces';
import * as actions from './actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '30px'
    }
  })
);

type AccommodationOfferSummaryStateProps = {
  prices: AccommodationOfferPriceCalculationModel;
};

type AccommodationOfferSummaryDispatchProps = {
};

type AccommodationOfferSummaryProps = {
} & AccommodationOfferSummaryStateProps & AccommodationOfferSummaryDispatchProps;

const AccommodationOfferSummary: React.FunctionComponent<AccommodationOfferSummaryProps> = props => {
  console.debug('AccommodationOfferSummary');
  const classes = useStyles();
  return <React.Fragment>
    <Grid container className={classes.grid} spacing={2} direction="row" alignItems="center">
      <Grid item xs={8}> </Grid>
      <Grid item xs={4}>
        <Grid container direction="row" style={{ textAlign: 'right'}}>
          <Grid item xs={4}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            Nabídka
          </Grid>
          <Grid item xs={4}>
            Obsazená lůžka
          </Grid>

          <Grid item xs={4}>
            Celkem bez DPH:
          </Grid>
          <Grid item xs={4}>
            {props.prices.totalWithoutVat} CZK 
          </Grid>
          <Grid item xs={4}>
            {props.prices.realTotalWithoutVat} CZK
          </Grid>

          <Grid item xs={4}>
            DPH:
          </Grid>
          <Grid item xs={4}>
            {props.prices.totalVat} CZK
          </Grid>
          <Grid item xs={4}>
            {props.prices.realTotalVat} CZK
          </Grid>

          <Grid item xs={4}>
            Celkem s DPH:
          </Grid>
          <Grid item xs={4}>
            {props.prices.totalWithVat} CZK
          </Grid>
          <Grid item xs={4}>
            {props.prices.realTotalWithVat} CZK
          </Grid>
      </Grid>
     </Grid>
    </Grid>
  </React.Fragment>;
};

const mapStateToProps = (state: ApplicationState): AccommodationOfferSummaryStateProps => {
  return {
    prices: state.accommodationOfferPriceCalculations.prices,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferSummaryDispatchProps =>
  bindActionCreators({
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferSummary);
