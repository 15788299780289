import { all, put, takeEvery, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, cancelItemAsync, setPagination, loadDependenciesAsync } from './actions';
import apiClient from '../../api/apiClientInstance';
import { AccommodatedGuestModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';
import { getAccommodatedGuestsPagination, getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, registerCancelEntityRequest, registerGetEntitiesByPageRequest } from '../../core';
import { BaseDataResponse, BasePageDataResponse } from '../../api/responses';
import { TablePageChangeModel } from '../../components/table';
import pagination from '../../utils/pagination';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getAccommodatedGuestsRequest),
    yield takeEvery(cancelItemAsync.request, cancelAccommodatedGuestsRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}
type GetGuestsRequestGeneratorType =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<AccommodatedGuestModel[]>>
  | PutEffect<ActionType<typeof setPagination>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getAccommodatedGuestsRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetGuestsRequestGeneratorType, void,
  boolean
  & TablePageChangeModel
  & BasePageDataResponse<AccommodatedGuestModel[]>> {
  try {
    console.debug('getAccommodatedGuestsRequest');
    const payload = action.payload as TablePageChangeModel;
    const stored = yield select(getAccommodatedGuestsPagination);
    const { _filter, _skip, _take } = pagination(payload, stored);
    const filter = payload?.filter ?? stored.filter;

    const response: BasePageDataResponse<AccommodatedGuestModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.AccommodatedGuestsClient,
      apiCallFnName: 'getByPageAsync'
    }, _skip, _take, filter);

    if (response.resultCode === 0) {
      yield put(setPagination({
        page: payload?.page ?? 0,
        skip: _skip,
        take: _take,
        total: response.total,
        filter: filter
      }));

      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

/*const getAccommodatedGuestsRequest = registerGetEntitiesByPageRequest<AccommodatedGuestModel>(
  getItemsAsync.success,
  getItemsAsync.failure,
  getItemsAsync.cancel,
  apiClient.AccommodatedGuestsClient,
  setPagination,
  getAccommodatedGuestsPagination
);*/

const cancelAccommodatedGuestsRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_ACCOMMODATIONS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
