import { createAction, createAsyncAction } from 'typesafe-actions';
import { ReservationGuestCheckOutModel, ReservationModel, ReservationRoomModel, ReservationGuestStornoModel } from '../../api/interfaces';
import {
  createSaveItemAsyncAction,
  createCancelItemAsyncAction,
  createLoadDependenciesAsyncAction
} from '../../core';

const reservationModelName = 'reservation';
const checkoutsModelName = 'reservationCheckOuts';
const stornoModelName = 'reservationStorno';

/* CHECKOUTS */
export const loadCheckOutDependenciesAsync = createLoadDependenciesAsyncAction(checkoutsModelName);

export const getCheckOutItemsAsync = createAsyncAction<'GET_CHECK_OUTS_REQUEST', 'GET_CHECK_OUTS_SUCCESS', 'GET_CHECK_OUTS_FAILURE'>(
  `${checkoutsModelName}/GET_CHECK_OUTS_REQUEST` as 'GET_CHECK_OUTS_REQUEST',
  `${checkoutsModelName}/GET_CHECK_OUTS_SUCCESS` as 'GET_CHECK_OUTS_SUCCESS',
  `${checkoutsModelName}/GET_CHECK_OUTS_FAILURE` as 'GET_CHECK_OUTS_FAILURE'
)<number, ReservationGuestCheckOutModel[], Error>();

export const deleteCheckOutItemAsync = createAsyncAction<'DELETE_REQUEST', 'DELETE_SUCCESS', 'DELETE_FAILURE'>(
  `${checkoutsModelName}/DELETE_REQUEST` as 'DELETE_REQUEST',
  `${checkoutsModelName}/DELETE_SUCCESS` as 'DELETE_SUCCESS',
  `${checkoutsModelName}/DELETE_FAILURE` as 'DELETE_FAILURE'
)<number, number, Error>();

export const saveCheckOutItemAsync = createSaveItemAsyncAction<ReservationGuestCheckOutModel>(checkoutsModelName);

export const cancelCheckOutItemAsync = createCancelItemAsyncAction(checkoutsModelName);

export const getCheckOutInfoAsync = createAsyncAction(
  `${checkoutsModelName}/GET_CHECKOUT_INFO_REQUEST`,
  `${checkoutsModelName}/GET_CHECKOUT_INFO_SUCCESS`,
  `${checkoutsModelName}/GET_CHECKOUT_INFO_FAILURE`
)<ReservationGuestCheckOutModel, string, void>();

export const clearCheckOutInfoAction = createAction(`${checkoutsModelName}/CLEAR_CHECK_OUT_INFO`)<void>();

export const setReservationAction = createAction(`${reservationModelName}/SET`)<ReservationModel>();

/* ROOMS */
export const loadRoomDependenciesAsync = createLoadDependenciesAsyncAction(reservationModelName);

export const saveRoomItemAsync = createSaveItemAsyncAction<ReservationRoomModel>(reservationModelName);

export const cancelRoomItemAsync = createCancelItemAsyncAction(reservationModelName);

export const removeRoomAction = createAction(`${reservationModelName}/REMOVE_ROOM`)<number>();

export const editRoomAction = createAction(`${reservationModelName}/EDIT_ROOM`)<ReservationRoomModel>();

export const editRoomsAction = createAction(`${reservationModelName}/EDIT_ROOMS`)<ReservationRoomModel[]>();

export const createOrUpdateRoomAction = createAction(`${reservationModelName}/CREATE_OR_UPDATE_ROOM`)<ReservationRoomModel>();

export const clearAction = createAction(`${reservationModelName}/CLEAR`)<void>();

/* STORNO */
export const loadStornoDependenciesAsync = createLoadDependenciesAsyncAction(stornoModelName);

export const saveStornoItemAsync = createSaveItemAsyncAction<ReservationGuestStornoModel>(stornoModelName);

export const cancelStornoItemAsync = createCancelItemAsyncAction(stornoModelName);

export const clearStornoInfoAction = createAction(`${stornoModelName}/CLEAR_STORNO_INFO`)<void>();
