import React, { } from 'react';
import { debounce } from 'lodash';

type FormikObserverProps<T> = {
  onChange: (values: T, prevValues: T) => void;
  values: T;
}

type FormikObserverState<T> = {
  values: T;
}

class FormikObserver<T> extends React.PureComponent<FormikObserverProps<T>> {
  onChange: (values: T, prevValues: T) => void;
  static readonly waitMs = 15;

  constructor(props: FormikObserverProps<T>) {
    super(props);
    this.state = {
      values: props.values
    } as FormikObserverState<T>;
    this.onChange = debounce((next, prev) => props.onChange(next, prev), FormikObserver.waitMs);
  }

  componentDidUpdate(prevProps: FormikObserverProps<T>, prevState: FormikObserverState<T>): void {
    const { values } = this.props;
    if (prevProps.values !== values) {
      this.onChange(values, prevProps.values);
      this.setState({ values: values })
    }
  }

  render(): React.ReactNode {
    return null;
  }
}

export default FormikObserver;