import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DeepReadonly } from 'utility-types';

import { UsersState, UsersAction } from './types';
import * as actions from './actions';
import { GetAllRequestAsyncActionPayload } from '../../core';

export const initialState: UsersState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false
  },
  errorMessage: '',
  requestInProgress: false
};

const itemListReducer = createReducer<typeof initialState.itemList, UsersAction>(initialState.itemList)
  .handleAction(actions.getItemsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.allIds = action.payload.map(model => {
        draft.byId[model.id] = model;

        return model.id;
      });
      draft.loaded = true;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.request, (state, action) => {
    if (action.payload as GetAllRequestAsyncActionPayload) {
      return initialState.itemList;
    }

    return state;
  })
  .handleAction(actions.deleteItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      const index = draft.allIds.findIndex(id => id === action.payload)
      if (index !== -1) draft.allIds.splice(index, 1)
    });

    return newState;
  });

const errorMessageReducer = createReducer<DeepReadonly<string>, UsersAction>(initialState.errorMessage)
  .handleAction(
    [
      actions.getItemsAsync.request,
      actions.getItemsAsync.success
      //actions.editAccommodationAsync.request,
      //actions.editAccommodationAsync.success,
      //actions.deleteAccommodationAsync.request,
      //actions.deleteAccommodationAsync.success
    ],
    (state, action) => initialState.errorMessage
  )
  .handleAction(
    [actions.getItemsAsync.failure /* , actions.editAccommodationAsync.failure, actions.deleteAccommodationAsync.failure */],
    (state, action) => action.payload.message
  );

const requestInProgressReducer = createReducer<DeepReadonly<boolean>, UsersAction>(initialState.requestInProgress)
  .handleAction(
    [actions.getItemsAsync.request /* actions.editAccommodationAsync.request, actions.deleteAccommodationAsync.request */],
    (state, action) => true
  )
  .handleAction(
    [
      actions.getItemsAsync.success,
      //actions.editAccommodationAsync.success,
      //actions.deleteAccommodationAsync.success,
      actions.getItemsAsync.failure
      //actions.editAccommodationAsync.failure,
      //actions.deleteAccommodationAsync.failure
    ],
    (state, action) => initialState.requestInProgress
  );

export default combineReducers<UsersState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer
});
