import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import GuestDetail, { GuestDetailStateProps, GuestDetailDispatchProps, GuestDetailProps } from './guestsDetail';
import * as actions from './actions';
import { GuestModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectStatesSelectItems } from '../states';
import { selectCompaniesSelectItems } from '../companies';

type GuestCreateContainerStateProps = {} & GuestDetailStateProps;

type GuestCreateContainerDispatchProps = {} & GuestDetailDispatchProps;

type GuestCreateContainerProps = {} & GuestDetailProps & GuestCreateContainerStateProps & GuestCreateContainerDispatchProps;

const createInitialValues = {
  name: '',
  surname: '',
  stateId: 0,
  birthdate: undefined,
  city: '',
  companyId: undefined,
  note: '',
  email: '',
  phone: '',
  street: '',
  titleAfter: '',
  titleBefore: '',
  citizenshipId: undefined,
  zip: ''
} as GuestModel;

const GuestCreateContainer: React.FunctionComponent<GuestCreateContainerProps> = memo(props => {
  console.debug('GuestCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    stateSelectItems,
    companySelectItems,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <GuestDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      stateSelectItems={stateSelectItems}
      companySelectItems={companySelectItems}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): GuestCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.guests.errorMessage,
    requestInProgress: state.guests.requestInProgress,
    stateSelectItems: selectStatesSelectItems(state),
    companySelectItems: selectCompaniesSelectItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): GuestCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestCreateContainer);
