import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { PriceListModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class PriceListClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'pricelist');
  }

  public async getAllAsync(): Promise<BaseDataResponse<PriceListModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<PriceListModel[]>>('', '');
  }

  public async createAsync(model: PriceListModel): Promise<BaseDataResponse<PriceListModel>> {
    return await this.postHttpAsync<BaseDataResponse<PriceListModel>, PriceListModel>('', model);
  }

  public async updateAsync(model: PriceListModel): Promise<BaseDataResponse<PriceListModel>> {
    return await this.putHttpAsync<BaseDataResponse<PriceListModel>, PriceListModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
