import { all, put, take, takeEvery, select, TakeEffect, PutEffect, SelectEffect, CallEffect } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import {
  editRoomAction,
  saveRoomItemAsync,
  cancelRoomItemAsync,
  loadRoomDependenciesAsync,
  createOrUpdateRoomAction,
  loadCheckOutDependenciesAsync,
  getCheckOutItemsAsync,
  setReservationAction,
  saveCheckOutItemAsync,
  deleteCheckOutItemAsync,
  getCheckOutInfoAsync,
  clearCheckOutInfoAction,
  cancelCheckOutItemAsync,
  clearStornoInfoAction,
  loadStornoDependenciesAsync,
  cancelStornoItemAsync,
  saveStornoItemAsync
} from './actions';

import { AccommodationOfferModel, ReservationGuestCheckOutModel, ReservationGuestStornoModel, ReservationModel } from '../../api/interfaces';
import { navigateTo, ROUTES } from '../../navigation';
import {
  NormalizedListById,
  registerCancelEntityRequest,
  registerDeleteEntityRequest,
} from '../../core';
import { getCurrentReservation } from '.';
import { getIsSomeModalOpened, closeTopModal } from '../../components';
import { getAreRoomTypesLoaded, getItemsAsync as loadRoomTypesAsync } from '../roomTypes';
import { getAreRoomsLoaded, getItemsAsync as loadRoomsAsync } from '../rooms';
import { getAreGuestsLoaded, getItemsAsync as loadGuestsAsync } from '../guests';
import { getItemsAsync as loadAccommodationOffersAsync, getAreItemsLoaded as getAreAccommodationOffersLoaded, getAccommodationOffersById } from '../accommodationOffers';
import apiClient from '../../api/apiClientInstance';
import { BaseDataResponse } from '../../api/responses';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(saveRoomItemAsync.request, saveRoomRequest),
    yield takeEvery(cancelRoomItemAsync.request, cancelRoomRequest),
    yield takeEvery(cancelCheckOutItemAsync.request, cancelCheckOutRequest),
    yield takeEvery(loadRoomDependenciesAsync.request, loadRoomDependenciesRequest),
    yield takeEvery(editRoomAction, editRoomActionHandler),
    yield takeEvery(loadCheckOutDependenciesAsync.request, loadCheckOutDependenciesRequest),
    yield takeEvery(getCheckOutItemsAsync.request, getCheckOutsAsyncHandler),
    yield takeEvery(saveCheckOutItemAsync.request, saveCheckOutRequest),
    yield takeEvery(deleteCheckOutItemAsync.request, deleteCheckOutRequest),
    yield takeEvery(getCheckOutInfoAsync.request, getCheckOutInfoAsyncRequest),
    yield takeEvery(loadStornoDependenciesAsync.request, loadStornoDependenciesRequest),
    yield takeEvery(saveStornoItemAsync.request, saveReservationStornoRequest),
    yield takeEvery(cancelStornoItemAsync.request, cancelStornoRequest)
  ]);
}

type GetCheckOutInfoAsyncGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getCheckOutInfoAsync.request>>
  | PutEffect<ActionType<typeof getCheckOutInfoAsync.success>>
  | PutEffect<ActionType<typeof getCheckOutInfoAsync.failure>>
  | Promise<BaseDataResponse<string>>;

function* getCheckOutInfoAsyncRequest(action: ReturnType<typeof getCheckOutInfoAsync.request>):
  Generator<GetCheckOutInfoAsyncGeneratorType, void,
    void
    & boolean & string> {
  try {

    const response: BaseDataResponse<string> = yield apiClient.apiCallHandler({
      context: apiClient.ReservationGuestCheckOut,
      apiCallFnName: 'getInfoAsync'
    }, action.payload);

    yield put(getCheckOutInfoAsync.success(response.data));
  }
  catch (error) {
    yield put(getCheckOutInfoAsync.failure(error));
  }
}

const deleteCheckOutRequest = registerDeleteEntityRequest(
  deleteCheckOutItemAsync.success,
  deleteCheckOutItemAsync.failure,
  apiClient.ReservationGuestCheckOut,
  ROUTES.ACCOMMODATION_OFFERS);

type SaveReservationCheckOutRequestGeneratorType =
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof loadAccommodationOffersAsync.request>>
  | PutEffect<ActionType<typeof saveCheckOutItemAsync.success>>
  | PutEffect<ActionType<typeof saveCheckOutItemAsync.failure>>
  | SelectEffect
  | Promise<BaseDataResponse<ReservationGuestCheckOutModel>>
  ;

function* saveCheckOutRequest(
  action: ReturnType<typeof saveCheckOutItemAsync.request>
): Generator<SaveReservationCheckOutRequestGeneratorType, void,
  void
  & boolean
  & BaseDataResponse<ReservationGuestCheckOutModel>> {
  try {
    const model = action.payload;
    const method: keyof typeof apiClient.ReservationGuestCheckOut = model.id > 0 ? 'updateAsync' : 'createAsync';
    const response: BaseDataResponse<ReservationGuestCheckOutModel> = yield apiClient.apiCallHandler({ context: apiClient.ReservationGuestCheckOut, apiCallFnName: method }, model);

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(saveCheckOutItemAsync.success(data));

      const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
      if (isSomeModalOpend) {
        yield put(closeTopModal());

        yield put(loadAccommodationOffersAsync.request());
      }
    } else {
      yield put(saveCheckOutItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }

  } catch (error) {
    yield put(saveCheckOutItemAsync.failure(error));
  }
}

type GetCheckOutsActionGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof setReservationAction>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof getCheckOutItemsAsync.request>>
  | PutEffect<ActionType<typeof getCheckOutItemsAsync.success>>
  | PutEffect<ActionType<typeof getCheckOutItemsAsync.failure>>
  | Promise<BaseDataResponse<ReservationGuestCheckOutModel[]>>;

function* getCheckOutsAsyncHandler(action: ReturnType<typeof getCheckOutItemsAsync.request>):
  Generator<GetCheckOutsActionGeneratorType, void,
    void
    & boolean
    & ReservationModel
    & NormalizedListById<AccommodationOfferModel>
    & BaseDataResponse<ReservationGuestCheckOutModel[]>> {
  try {
    const accommodations: NormalizedListById<AccommodationOfferModel> = yield select(getAccommodationOffersById);
    const accommodation = accommodations[action.payload];

    const areGuestsLoaded = yield select(getAreGuestsLoaded);
    if (!areGuestsLoaded) {
      yield put(loadGuestsAsync.request());
      yield take([getType(loadGuestsAsync.success)]);
    }

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    const response: BaseDataResponse<ReservationGuestCheckOutModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.ReservationGuestCheckOut,
      apiCallFnName: 'getAllAsync'
    }, accommodation.reservation.id);

    if (response.resultCode === 0) {
      yield put(getCheckOutItemsAsync.success(response.data));
    } else {
      yield put(getCheckOutItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }

    yield put(setReservationAction(accommodation.reservation));
  } catch (error) {
    yield put(getCheckOutItemsAsync.failure(error));
  }
}

type EditRoomActionGeneratorType = SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | Generator<SelectEffect, ReservationModel, boolean>
  | PutEffect<ActionType<typeof createOrUpdateRoomAction>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof loadRoomTypesAsync.request>>
  | PutEffect<ActionType<typeof closeTopModal>>;

function* editRoomActionHandler(action: ReturnType<typeof editRoomAction>): Generator<EditRoomActionGeneratorType, void,
  void
  & boolean
  & ReservationModel> {
  try {
    const areGuestsLoaded = yield select(getAreGuestsLoaded);
    if (!areGuestsLoaded) {
      yield put(loadGuestsAsync.request());
      yield take([getType(loadGuestsAsync.success)]);
    }

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
      yield take([getType(loadRoomTypesAsync.success)]);
    }

    yield put(createOrUpdateRoomAction(action.payload));

    const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
    if (isSomeModalOpend) {
      yield put(closeTopModal());
    }
  } catch (error) {
    console.log(error);
  }
}

type SaveReservationRoomRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof saveRoomItemAsync.success>>
  | PutEffect<ActionType<typeof saveRoomItemAsync.failure>>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* saveRoomRequest(
  action: ReturnType<typeof saveRoomItemAsync.request>
): Generator<SaveReservationRoomRequestGeneratorType, void, void & boolean> {
  try {
    const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
    if (isSomeModalOpend) {
      yield put(closeTopModal());
    }

  } catch (error) {
    yield put(saveRoomItemAsync.failure(error));
  }
}

const cancelCheckOutRequest = registerCancelEntityRequest(
  cancelCheckOutItemAsync.success,
  cancelCheckOutItemAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

const cancelRoomRequest = registerCancelEntityRequest(
  cancelRoomItemAsync.success,
  cancelRoomItemAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

const cancelStornoRequest = registerCancelEntityRequest(
  cancelStornoItemAsync.success,
  cancelStornoItemAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

type LoadCheckOutDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof clearCheckOutInfoAction>>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadCheckOutDependenciesAsync.request>>
  | PutEffect<ActionType<typeof loadCheckOutDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadCheckOutDependenciesAsync.failure>>;

function* loadCheckOutDependenciesRequest(
  action: ReturnType<typeof loadCheckOutDependenciesAsync.request>
): Generator<LoadCheckOutDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    const areGuestsLoaded = yield select(getAreGuestsLoaded);
    if (!areGuestsLoaded) {
      yield put(loadGuestsAsync.request());
      yield take([getType(loadGuestsAsync.success)]);
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
      yield take([getType(loadRoomTypesAsync.success)]);
    }

    yield put(clearCheckOutInfoAction());

    yield put(loadCheckOutDependenciesAsync.success());
  } catch (error) {
    yield put(loadCheckOutDependenciesAsync.failure(error));
  }
}

type LoadRoomDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadRoomDependenciesAsync.request>>
  | PutEffect<ActionType<typeof loadRoomDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadRoomDependenciesAsync.failure>>;

function* loadRoomDependenciesRequest(
  action: ReturnType<typeof loadRoomDependenciesAsync.request>
): Generator<LoadRoomDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    const areGuestsLoaded = yield select(getAreGuestsLoaded);
    if (!areGuestsLoaded) {
      yield put(loadGuestsAsync.request());
      yield take([getType(loadGuestsAsync.success)]);
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
      yield take([getType(loadRoomTypesAsync.success)]);
    }

    yield put(loadRoomDependenciesAsync.success());
  } catch (error) {
    yield put(loadRoomDependenciesAsync.failure(error));
  }
}

type LoadStornoDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof clearStornoInfoAction>>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadStornoDependenciesAsync.request>>
  | PutEffect<ActionType<typeof loadStornoDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadStornoDependenciesAsync.failure>>;

function* loadStornoDependenciesRequest(
  action: ReturnType<typeof loadStornoDependenciesAsync.request>
): Generator<LoadStornoDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    const areGuestsLoaded = yield select(getAreGuestsLoaded);
    if (!areGuestsLoaded) {
      yield put(loadGuestsAsync.request());
      yield take([getType(loadGuestsAsync.success)]);
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
      yield take([getType(loadRoomTypesAsync.success)]);
    }

    yield put(clearStornoInfoAction());

    yield put(loadStornoDependenciesAsync.success());
  } catch (error) {
    yield put(loadStornoDependenciesAsync.failure(error));
  }
}

type SaveReservationStornoRequestGeneratorType =
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof loadAccommodationOffersAsync.request>>
  | PutEffect<ActionType<typeof saveStornoItemAsync.success>>
  | PutEffect<ActionType<typeof saveStornoItemAsync.failure>>
  | SelectEffect
  | Promise<BaseDataResponse<ReservationGuestStornoModel>>
  ;

function* saveReservationStornoRequest(
  action: ReturnType<typeof saveStornoItemAsync.request>
): Generator<SaveReservationStornoRequestGeneratorType, void,
  void
  & boolean
  & ReservationModel
  & BaseDataResponse<ReservationGuestStornoModel>> {
  try {
    let reservationRoomId = 0;
    
    const reservation: ReservationModel = yield select(getCurrentReservation);
    reservation.rooms.map(room => {
      if (room.roomId === action.payload.roomId) {
        reservationRoomId = room.id;
      }
    });

    const model = { ... action.payload, reservationRoomId: reservationRoomId };

    const method: keyof typeof apiClient.ReservationGuestStorno = model.id > 0 ? 'updateAsync' : 'createAsync';
    const response: BaseDataResponse<ReservationGuestStornoModel> = yield apiClient.apiCallHandler({ context: apiClient.ReservationGuestStorno, apiCallFnName: method }, model);

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(saveStornoItemAsync.success(data));

      const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
      if (isSomeModalOpend) {
        yield put(closeTopModal());

        yield put(loadAccommodationOffersAsync.request());
      }
    } else {
      yield put(saveStornoItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }

  } catch (error) {
    yield put(saveStornoItemAsync.failure(error));
  }
}