import * as Yup from 'yup';

import { nameValidationSchema } from '../validations';

export const floorValidationSchema = Yup.object().shape({
    name: nameValidationSchema,
    number: Yup.number()
        .required('Patro je povinné')
        .min(-5, 'Patro musí být nastaveno'),
});
