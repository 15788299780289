import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync } from '../accommodations';

import apiClient from '../../api/apiClientInstance';
import { StateModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded as getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest } from '../../core';

export default function* rootSaga(): any {
    yield all([
        yield takeEvery(getItemsAsync.request, getStatesRequest),
        yield takeEvery(newItemAsync.request, newStateRequest),
        yield takeEvery(saveItemAsync.request, saveStateRequest),
        yield takeEvery(cancelItemAsync.request, cancelStateRequest),
        yield takeEvery(editItemAsync.request, editStateRequest),
        yield takeEvery(deleteItemAsync.request, deleteStateRequest),
        yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
    ]);
}

type GetStatesRequestGeneratorType =
    | SelectEffect
    | Generator<SelectEffect, boolean, boolean>
    | Promise<BaseDataResponse<StateModel[]>>
    | PutEffect<ActionType<typeof getItemsAsync.cancel>>
    | PutEffect<ActionType<typeof getItemsAsync.success>>
    | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getStatesRequest(
    action: ReturnType<typeof getItemsAsync.request>
): Generator<GetStatesRequestGeneratorType, void,  boolean & BaseDataResponse<StateModel[]>> {
    try {
        console.debug('getStatesRequest');

        const payload = action.payload as GetAllRequestAsyncActionPayload;
        const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
        if (!isReloadRequired) {
            yield put(getItemsAsync.cancel());
            return;
        }
        
        const response: BaseDataResponse<StateModel[]> = yield apiClient.apiCallHandler({
            context: apiClient.States,
            apiCallFnName: 'getAllAsync'
        });

        if (response.resultCode === 0) {
            yield put(getItemsAsync.success(response.data));
        } else {
            yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
        }
    } catch (error) {
        yield put(getItemsAsync.failure(error));
    }
}

type NewStateRequestGeneratorType =
    | PutEffect<ActionType<typeof navigateTo>>
    | PutEffect<ActionType<typeof newItemAsync.success>>
    | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newStateRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewStateRequestGeneratorType, void, void> {
    try {
        console.debug('newStateRequest');

        yield put(navigateTo(ROUTES.SETTINGS_STATES_CREATE));
        yield put(newItemAsync.success());
    } catch (error) {
        yield put(newItemAsync.failure(error));
    }
}

type EditStateRequestGeneratorType =
    | Promise<BaseDataResponse<StateModel>>
    | PutEffect<ActionType<typeof editItemAsync.success>>
    | PutEffect<ActionType<typeof editItemAsync.failure>>
    | PutEffect<ActionType<typeof navigateToDetail>>
    | PutEffect<ActionType<typeof navigateTo>>;

function* editStateRequest(
    action: ReturnType<typeof editItemAsync.request>
): Generator<EditStateRequestGeneratorType, void, BaseDataResponse<StateModel>> {
    try {
        console.debug('editStateRequest');
        const itemToEditId = action.payload;

        yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_STATES_DETAIL, detailId: itemToEditId } as NavigateToDetail));
        yield put(editItemAsync.success());
    } catch (error) {
        yield put(editItemAsync.failure(error));
    }
}

const deleteStateRequest = registerDeleteEntityRequest(
    deleteItemAsync.success,
    deleteItemAsync.failure,
    apiClient.States,
    ROUTES.SETTINGS_STATES
);

const saveStateRequest = registerSaveEntityRequest<StateModel>(
    saveItemAsync.success,
    saveItemAsync.failure,
    apiClient.States,
    ROUTES.SETTINGS_STATES
);

const cancelStateRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_STATES);

type LoadDependenciesRequestGeneratorType =
    | SelectEffect
    | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
    | PutEffect<ActionType<typeof getItemsAsync.request>>
    | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
    | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
    action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
    try {
        console.debug('loadDependenciesRequest');

        /*const areAcoommodationsLoaded = yield select(getAreAccommodationsLoaded);
        if (!areAcoommodationsLoaded) {
            yield put(loadAccommodationsAsync.request());
        }*/

        const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
        if (!areItemsLoaded) {
            yield put(getItemsAsync.request());
        }

        yield put(loadDependenciesAsync.success());
    } catch (error) {
        yield put(loadDependenciesAsync.failure(error));
    }
}
