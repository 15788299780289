export const LocalhostTenantDomainKey = 'localhost';
export const DevTenantDomainKey = 'dev';
export const CEZEPTenantDomianKey = 'cezep';

const TenantDomains = {
    [LocalhostTenantDomainKey]: 'localhost',
    [DevTenantDomainKey]: 'dev',
    [CEZEPTenantDomianKey]: 'cezep'
} as Record<string, string>;

export default TenantDomains;
