import { createAction, PayloadAction } from 'typesafe-actions';
import { Location } from 'history';

import { ROUTES } from './types';
import { NavigateToWithQuery, NavigateToDetail } from './types';

export const navigateTo = createAction('navigation/navigateTo')<ROUTES>();

export const navigateToWithQuery = createAction('navigation/navigateToWithQuery')<NavigateToWithQuery>();

export const navigateToLocation = createAction('navigation/navigateToLocation')<Location<any>>();

export const navigateToDetail = (payload: NavigateToDetail): ReturnType<typeof navigateToDetailAction> => {
    const pathname = payload.masterPage.replace(':id', payload.detailId.toString());

    return navigateToDetailAction(pathname);
};

export const navigateToDetailAction = createAction('navigation/navigateToDetail')<string>();

export const navigateBack = createAction('navigation/navigateBack')<void>();

export const openUrlInTab = createAction('navigation/openUrlInTab')<string>();