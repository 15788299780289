import React, { useState, ReactNode } from 'react';
import { Theme, InputAdornment, Checkbox as MuiCheckbox, InputLabel, FormControl, FormHelperText, FormControlLabel } from '@material-ui/core';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { IconName, Icon } from '../../icons';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
    root: {
        paddingTop: spacing(0.75)
    }
}));

export type CheckboxClassKey = 'root';

export type CheckboxProps = {
    classes: Partial<ClassNameMap<CheckboxClassKey>>;
    labelText: string;
    helperText: ReactNode;
    error: boolean;
    CheckboxProps: MuiCheckboxProps;
};

const Checkbox: React.FunctionComponent<CheckboxProps> = props => {
    return <FormControlLabel control={<MuiCheckbox {...props.CheckboxProps} />} label={props.labelText} classes={{ root: props.classes.root }} />;
};

export default withStylesFunction(Checkbox);
