import { all, put, takeEvery, PutEffect, SelectEffect, select, take, TakeEffect } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { cancelRoomTypeAsync, editRoomTypeAction, loadDependenciesAsync, saveRoomTypeAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync } from '../accommodations';
import { ReservationModel } from '../../api/interfaces';
import { closeTopModal, getIsSomeModalOpened } from '../../components';
import { registerCancelEntityRequest } from '../../core';
import { navigateTo, ROUTES } from '../../navigation';
import { getAreRoomsLoaded, getItemsAsync as loadRoomsAsync } from '../rooms';
import { getAreRoomTypesLoaded, getItemsAsync as loadRoomTypesAsync } from '../roomTypes';
import { editRoomTypeAction as calculationEditRoomTypeAction } from '../accommodationOfferCalculations';
import { calculatePriceAsyncAction } from '../accommodationOfferPriceCalculations';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(saveRoomTypeAsync.request, saveRoomTypeRequest),
    yield takeEvery(cancelRoomTypeAsync.request, cancelRoomRequest),
    yield takeEvery(editRoomTypeAction, editRoomTypeActionHandler),
  ]);
}

type EditRoomTypeActionGeneratorType = SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | Generator<SelectEffect, ReservationModel, boolean>
  | PutEffect<ActionType<typeof calculationEditRoomTypeAction>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof loadRoomTypesAsync.request>>
  | PutEffect<ActionType<typeof closeTopModal>>;

function* editRoomTypeActionHandler(action: ReturnType<typeof editRoomTypeAction>): Generator<EditRoomTypeActionGeneratorType, void, 
void 
& boolean
& ReservationModel> {
  try {
    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
      yield take([getType(loadRoomTypesAsync.success)]);
    } 

    yield put(calculationEditRoomTypeAction(action.payload));

    yield put(calculatePriceAsyncAction.request());

    const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
    if (isSomeModalOpend) {
      yield put(closeTopModal());
    }
  } catch (error) {
    console.log(error);
  }
}

type SaveRoomTypeRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof saveRoomTypeAsync.success>>
  | PutEffect<ActionType<typeof saveRoomTypeAsync.failure>>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* saveRoomTypeRequest(
  action: ReturnType<typeof saveRoomTypeAsync.request>
): Generator<SaveRoomTypeRequestGeneratorType, void, void & boolean> {
  try {
    const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
    if (isSomeModalOpend) {
      yield put(closeTopModal());
    }

  } catch (error) {
    yield put(saveRoomTypeAsync.failure(error));
  }
}

const cancelRoomRequest = registerCancelEntityRequest(
  cancelRoomTypeAsync.success,
  cancelRoomTypeAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, void 
& boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([getType(loadRoomsAsync.success)]);
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
