import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { signInPersonalIdValidationSchema } from './validations';
import { useSignInStyles } from './styles';

type SingInPersonalIdViewProps = {
  errorMessage?: string;
  requestInProgress: boolean;
  supportEmail: string;

  requestOpt: (parsonalId: string) => void;
};

type SingInPersonalId = Yup.InferType<typeof signInPersonalIdValidationSchema>;

const nameof = nameofFactory<SingInPersonalId>();

const SingInPersonalIdView: React.FunctionComponent<SingInPersonalIdViewProps> = props => {
  const classes = useSignInStyles(props);
  const { errorMessage, requestInProgress, supportEmail } = props;

  const mailToHref = `mailto:${supportEmail}`;
  const mailToComponent = (
    <a href={mailToHref} className={classes.mailTo}>
      {supportEmail}
    </a>
  );

  return (
    <Formik<SingInPersonalId>
      initialValues={{ personalId: '' }}
      onSubmit={(values: SingInPersonalId, { setSubmitting }): void => {
        setSubmitting(true);

        props.requestOpt(values.personalId);
      }}
      validationSchema={signInPersonalIdValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { values, touched, errors, /* isSubmitting, */ handleChange, handleBlur, handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <Typography align="left" variant="h6" classes={{ root: classes.infoText }}>
              Pro přihlášení zadejte svoje osobní číslo a na uložené telefonní číslo Vám zašleme PIN pomocí SMS.
            </Typography>
            <Grid container className={classes.grid} spacing={3}>
              <Grid item xs={12}>
                <Input
                  iconName="id-card-alt"
                  labelText="Osobní číslo"
                  helperText={touched.personalId ? errors.personalId || errorMessage : ''}
                  error={Boolean(touched.personalId) && (Boolean(errors.personalId) || Boolean(errorMessage))}
                  InputProps={{
                    name: nameof('personalId'),
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.personalId,
                    autoFocus: true
                  }}
                />
              </Grid>
              <Grid item container justify="flex-end" xs={12}>
                <Button variant="contained" title="Zaslat PIN kód" color="primary" type="submit" disabled={requestInProgress}>
                  Zaslat PIN kód
                </Button>
              </Grid>
            </Grid>
            <Typography align="left" variant="h6" className={classes.text}>
              Přístup do portálu je pro nového zaměstnance možný až v první den výplatního termínu. V případě problémů nebo nahlášení
              změny přihlašovacího telefonního čísla, kontaktujte vždy nejpozději do posledního dne v měsíci personální oddělení na
              adrese: {mailToComponent}
            </Typography>

            <Typography align="left" variant="body1" className={classes.warningTextRoot} color="error">
              Upozornění: Přístup do portálu po ukončení pracovního poměru bude možné max. 1 měsíc po posledním výplatním termínu – tj.
              k 15. dni v měsíci
            </Typography>
          </form>
        );
      }}
    </Formik>
  );
};

export default SingInPersonalIdView;
