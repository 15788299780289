import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { UserModel, UserInvitationModel, UserRegistrationModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class UserClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'user');
  }

  public async getAllAsync(): Promise<BaseDataResponse<UserModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<UserModel[]>>('', '');
  }

  public async inviteAsync(model: UserInvitationModel): Promise<BaseResponse> {
    return await this.postHttpAsync<BaseResponse, UserInvitationModel>('invite', model);
  }

  public async registerAsync(model: UserRegistrationModel): Promise<BaseResponse> {
    return await this.postHttpAsync<BaseResponse, UserRegistrationModel>('register', model);
  }

  public async updateAsync(model: UserModel): Promise<BaseDataResponse<UserModel>> {
    return await this.putHttpAsync<BaseDataResponse<UserModel>, UserModel>(`${model.id}`, model);
  }

  public async createAsync(model: UserModel): Promise<BaseDataResponse<UserModel>> {
    throw new Error('Method not implemented.');
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
