import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AccommodationDetail, {
    AccommodationDetailStateProps,
    AccommodationDetailDispatchProps,
    AccommodationDetailProps
} from './accommodationsDetail';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';

type AccommodationEditContainerStateProps = {} & AccommodationDetailStateProps;

type AccommodationEditContainerDispatchProps = {} & AccommodationDetailDispatchProps;

type AccommodationEditContainerProps = {} & AccommodationDetailProps & AccommodationEditContainerStateProps & AccommodationEditContainerDispatchProps;

const AccommodationEditContainer: React.FunctionComponent<AccommodationEditContainerProps> = memo(props => {
    console.debug('AccommodationEditContainer');

    const {
        errorMessage,
        requestInProgress,
        initialValues,
        modalDialog,
        modalDialogRoute,
        saveActionRequest,
        cancelActionRequest,
        loadDependenciesRequest
    } = props;

    return (
        <AccommodationDetail
            initialValues={initialValues}
            saveActionRequest={saveActionRequest}
            cancelActionRequest={cancelActionRequest}
            loadDependenciesRequest={loadDependenciesRequest}
            errorMessage={errorMessage}
            requestInProgress={requestInProgress}
            modalDialog={modalDialog}
            modalDialogRoute={modalDialogRoute}
        />
    );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationEditContainerStateProps => {
    return {
        initialValues: state.accommodations.itemList.byId[ownProps.match.params.id],
        errorMessage: state.accommodations.errorMessage,
        requestInProgress: state.accommodations.requestInProgress
    };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationEditContainerDispatchProps =>
    bindActionCreators(
        {
            saveActionRequest: actions.saveItemAsync.request,
            cancelActionRequest: actions.cancelItemAsync.request,
            loadDependenciesRequest: actions.loadDependenciesAsync.request
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationEditContainer);
