import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { SignInPageEnum, SignInState, SignInAction } from './types';
import { signInRequestOptAsync, signInValidateOptAsync, signInAsync, signInSilentAsync } from './actions';
import { SignOutAction } from '../signOut';

export const initialState: SignInState = {
  page: SignInPageEnum.UserNamePassword,
  personalId: '',
  errorMessage: '',
  requestInProgress: false
};

const pageReducer = createReducer<DeepReadonly<SignInPageEnum>, SignInAction>(initialState.page)
  .handleAction(signInRequestOptAsync.success, (state, action) => SignInPageEnum.PinCode)
  .handleAction(signInRequestOptAsync.failure, (state, action) => SignInPageEnum.PersonalId);

const personalIdReducer = createReducer<DeepReadonly<string>, SignInAction>(initialState.personalId)
  .handleAction(signInRequestOptAsync.request, (state, action) => initialState.personalId)
  .handleAction(signInRequestOptAsync.success, (state, action) => action.payload)
  .handleAction(signInRequestOptAsync.failure, (state, action) => initialState.personalId);

const errorMessageReducer = createReducer<DeepReadonly<string>, SignInAction>(initialState.errorMessage)
  .handleAction(
    [
      signInValidateOptAsync.request,
      signInValidateOptAsync.success,
      signInRequestOptAsync.request,
      signInRequestOptAsync.success,
      signInAsync.request,
      signInAsync.success,
      signInSilentAsync.success,
      signInSilentAsync.failure
    ],
    (state, action) => initialState.errorMessage
  )
  .handleAction([signInValidateOptAsync.failure, signInRequestOptAsync.failure, signInAsync.failure], (state, action) => action.payload.message);

const requestInProgressReducer = createReducer<DeepReadonly<boolean>, SignInAction>(initialState.requestInProgress)
  .handleAction([signInValidateOptAsync.request, signInRequestOptAsync.request], (state, action) => true)
  .handleAction(
    [
      signInValidateOptAsync.success,
      signInRequestOptAsync.success,
      signInValidateOptAsync.failure,
      signInRequestOptAsync.failure,
      signInAsync.success,
      signInAsync.failure,
      signInSilentAsync.success,
      signInSilentAsync.failure
    ],
    (state, action) => initialState.requestInProgress
  );

export default combineReducers<SignInState, SignInAction>({
  page: pageReducer,
  personalId: personalIdReducer,
  errorMessage: errorMessageReducer,
  requestInProgress: requestInProgressReducer
});
