import React, {  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import * as actions from './actions';

import { getCompanyInfoAsync } from '../ares';

import MyCompanyDetail, { MyCompanyDetailDispatchProps, MyCompanyDetailProps, MyCompanyDetailStateProps } from './myCompanyDetail';
import { selectStatesSelectItems } from '../states/selectors';

type MyCompanyContainerStateProps = {
  initialValues: {
  };
} & MyCompanyDetailStateProps;

type MyCompanyContainerDispatchProps = {
  getMyCompanyRequest: typeof actions.getItemAsync.request;
  loadDependenciesRequest: typeof actions.loadDependenciesAsync.request;
} & MyCompanyDetailDispatchProps;

type MyCompanyContainerProps = {} & MyCompanyDetailProps & MyCompanyContainerStateProps & MyCompanyContainerDispatchProps;

class MyCompanyContainer extends React.PureComponent<MyCompanyContainerProps> {
  componentDidMount(): void {
    console.debug('MyCompanyContainer.componentDidMount');
    this.props.getMyCompanyRequest();
    this.props.loadDependenciesRequest();
  }

  render(): React.ReactNode {
    const { initialValues, 
      loadDependenciesRequest, 
      saveActionRequest, 
      cancelActionRequest,
      errorMessage,
      requestInProgress,
      modalDialog,
      modalDialogRoute,
      stateSelectItems,
      getCompanyInfoRequest,
      loaded
   } = this.props;
    return (
      <MyCompanyDetail
        initialValues={initialValues}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        stateSelectItems={stateSelectItems}
        getCompanyInfoRequest={getCompanyInfoRequest}
        loaded={loaded}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): MyCompanyContainerStateProps => {
  return {
    initialValues: state.myCompany.current,
    errorMessage: state.myCompany.errorMessage,
    requestInProgress: state.myCompany.requestInProgress,
    stateSelectItems: selectStatesSelectItems(state),
    loaded: state.myCompany.loaded
  };
};

const mapDispatchToProps = (dispatch: Dispatch): MyCompanyContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        getMyCompanyRequest: actions.getItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        getCompanyInfoRequest: getCompanyInfoAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCompanyContainer);
