import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actions from './actions';
import { ReservationGuestStornoModel, ReservationRoomModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import ReservationGuestStornoDetail, { 
  ReservationGuestStornoDetailProps, 
  ReservationGuestStornoDetailDispatchProps, 
  ReservationGuestStornoDetailStateProps } from './reservationGuestStornoDetail';
import { selectGuestsSelectItems, selectRoomsSelectItems, selectRoomsSelectItemsByGuestId } from '.';
import { NormalizedListById } from '../../core';

type ReservationGuestStornoCreateContainerStateProps = {

} & ReservationGuestStornoDetailStateProps;

type ReservationGuestStornoCreateContainerDispatchProps = {
} & ReservationGuestStornoDetailDispatchProps;

type ReservationGuestStornoCreateContainerProps = { } & ReservationGuestStornoDetailProps & ReservationGuestStornoCreateContainerStateProps & ReservationGuestStornoCreateContainerDispatchProps;

const createInitialValues = {
  guestId: 0,
  to: null,
  id: 0,
  roomId: 0,
} as ReservationGuestStornoModel;

const ReservationGuestStornoCreateContainer: React.FunctionComponent<ReservationGuestStornoCreateContainerProps> = memo(props => {
  console.debug('ReservationGuestStornoCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    guestsSelectItems,
    roomsSelectItems,
    selectRoomsSelectItemsByGuestId,
    rooms,
    type,
    info
  } = props;

  return (
    <React.Fragment>
      <ReservationGuestStornoDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        guestsSelectItems={guestsSelectItems}
        roomsSelectItems={roomsSelectItems}
        selectRoomsSelectItemsByGuestId={selectRoomsSelectItemsByGuestId}
        rooms={rooms}
        type={type}
        info={info}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): ReservationGuestStornoCreateContainerStateProps => {
  const rooms: NormalizedListById<ReservationRoomModel> = [];
  state.reservations.current.rooms.map((model: ReservationRoomModel) => {
    rooms[model.id] = model;

    return model.id;
  });

  return {
    initialValues: { ...createInitialValues, reservationId: state.reservations.current.id },
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    guestsSelectItems: selectGuestsSelectItems(state),
    roomsSelectItems: selectRoomsSelectItems(state),
    rooms: rooms,
    type: ownProps.type,
    info: state.reservations.info
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReservationGuestStornoCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveStornoItemAsync.request,
        cancelActionRequest: actions.cancelStornoItemAsync.request,
        loadDependenciesRequest: actions.loadStornoDependenciesAsync.request,
      },
      dispatch
    ),
    selectRoomsSelectItemsByGuestId: selectRoomsSelectItemsByGuestId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationGuestStornoCreateContainer);
