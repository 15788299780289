import React from 'react';
import { connect } from 'react-redux';
import { Backdrop, CircularProgress, makeStyles, createStyles, Theme } from '@material-ui/core';
import { VisibilityProperty } from 'csstype';

import { StyleRules } from '@material-ui/core/styles';
import { ApplicationState } from '../../store/rootReducer';

const useStyles = makeStyles(
  (theme: Theme): StyleRules<FullPageLoadingIndicatorClassKey, FullPageLoadingIndicatorStyleProps> =>
    createStyles({
      root: {
        position: 'fixed' as 'fixed',
        zIndex: 2300,
        right: '0px',
        bottom: '0px',
        top: '0px',
        left: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: (props): VisibilityProperty => props.visibility
      }
    })
);

type FullPageLoadingIndicatorClassKey = 'root';

type FullPageLoadingIndicatorStyleProps = {
  visibility: VisibilityProperty;
};

type FullPageLoadingIndicatorProps = {
  size: number;
} & FullPageLoadingIndicatorStateProps;

type FullPageLoadingIndicatorStateProps = {
  show: boolean;
};

const FullPageLoadingIndicatorContainer: React.FunctionComponent<FullPageLoadingIndicatorProps> = props => {
  console.debug('FullPageLoadingIndicator');
  const classes = useStyles({ visibility: props.show ? 'visible' : 'hidden' });

  return (
    <div className={classes.root}>
      <Backdrop open={props.show} />
      <CircularProgress size={props.size} />
    </div>
  );
};

const mapStateToProps = ({ fullPageLoadingIndicator }: ApplicationState): FullPageLoadingIndicatorStateProps => {
  return {
    show: fullPageLoadingIndicator.showIndicator
  };
};

export default connect(mapStateToProps)(FullPageLoadingIndicatorContainer);
