import React from 'react';
import { Grid, Container, Theme, makeStyles, createStyles } from '@material-ui/core';

import { default as UnauthenticatedAppHeader } from './unauthenticatedAppHeader';
import { default as UnauthenticatedAppFooter } from './unauthenticatedAppFooter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent'
    },
    itemRoot: {
      backgroundColor: 'white',
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
      borderStyle: 'solid'
    }
  })
);

type UnauthenticatedAppOneColumnLayoutKey = 'root';

type UnauthenticatedAppOneColumnLayoutProps = {
};

const UnauthenticatedAppOneColumnLayout: React.FunctionComponent<UnauthenticatedAppOneColumnLayoutProps> = props => {
  const classes = useStyles();

  return (
    <Grid container classes={{ root: classes.root }} direction="row" justifyContent="center" alignItems="center">
      <Grid item classes={{ root: classes.itemRoot }}>
        <UnauthenticatedAppHeader />
          {props.children}
        <UnauthenticatedAppFooter />
      </Grid>
    </Grid>
  );
};

export default UnauthenticatedAppOneColumnLayout;
