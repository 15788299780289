import { createAction, createAsyncAction } from 'typesafe-actions';
import { NumberSeriesDataModel } from '../../api/interfaces';
import {
  createSaveItemAsyncAction,
  createCancelItemAsyncAction,
  createLoadDependenciesAsyncAction
} from '../../core';

const modelName = 'numberseries';

export const getNumberSeriesAsync = createAsyncAction(
  'numberseries/GET_NUMBER_SERIES_REQUEST',
  'numberseries/GET_NUMBER_SERIES_SUCCESS',
  'numberseries/GET_NUMBER_SERIES_FAILURE'
)<void, Readonly<NumberSeriesDataModel>, Error>();

export const loadNumberSeriesDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const saveNumberSeriesAsync = createSaveItemAsyncAction<NumberSeriesDataModel>(modelName);

export const cancelNumberSeriesAsync = createCancelItemAsyncAction(modelName);

export const setUniqueReservationNumberAction = createAction<string>(`${modelName}/SET_RESERVATION_UNIQUE_NUMBER`)<string>();

export const setUniqueOfferNumberAction = createAction<string>(`${modelName}/SET_OFFER_UNIQUE_NUMBER`)<string>();