import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync, setPagination } from './actions';
import { getAreAccommodationsLoaded, getItemsAsync as loadAccommodationsAsync } from '../accommodations';

import apiClient from '../../api/apiClientInstance';
import { GuestModel } from '../../api/interfaces';
import { BaseDataResponse, BasePageDataResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded, getGuestsPagination } from './selectors';
import {
  registerSaveEntityRequest,
  registerCancelEntityRequest,
  registerDeleteEntityRequest,
  registerGetEntitiesByPageRequest
} from '../../core';
import { getItemsAsync as loadStatesAsync, getAreStatesLoaded } from '../states';
import { getAllItemsAsync as loadCompaniesAsync, getAreCompaniesLoaded } from '../companies';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getGuestsRequest),
    yield takeEvery(newItemAsync.request, newGuestRequest),
    yield takeEvery(saveItemAsync.request, saveGuestRequest),
    yield takeEvery(cancelItemAsync.request, cancelGuestRequest),
    yield takeEvery(editItemAsync.request, editGuestRequest),
    yield takeEvery(deleteItemAsync.request, deleteGuestRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

const getGuestsRequest = registerGetEntitiesByPageRequest<GuestModel>(
  getItemsAsync.success,
  getItemsAsync.failure,
  getItemsAsync.cancel,
  apiClient.Guests,
  setPagination,
  getGuestsPagination
);

type NewGuestRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newGuestRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewGuestRequestGeneratorType, void, void> {
  try {
    console.debug('newGuestRequest');

    yield put(navigateTo(ROUTES.SETTINGS_GUESTS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditGuestRequestGeneratorType =
  | Promise<BaseDataResponse<GuestModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editGuestRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditGuestRequestGeneratorType, void, BaseDataResponse<GuestModel>> {
  try {
    console.debug('editGuestRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_GUESTS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteGuestRequest = registerDeleteEntityRequest(deleteItemAsync.success, deleteItemAsync.failure, apiClient.Guests, ROUTES.SETTINGS_GUESTS);

const saveGuestRequest = registerSaveEntityRequest<GuestModel>(
  saveItemAsync.success,
  saveItemAsync.failure,
  apiClient.Guests,
  ROUTES.SETTINGS_GUESTS
);

const cancelGuestRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_GUESTS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadCompaniesAsync.request>>
  | PutEffect<ActionType<typeof loadStatesAsync.request>>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areAcoommodationsLoaded = yield select(getAreAccommodationsLoaded);
    if (!areAcoommodationsLoaded) {
      yield put(loadAccommodationsAsync.request());
    }

    const areCompaniesLoaded = yield select(getAreCompaniesLoaded);
    if (!areCompaniesLoaded) {
      yield put(loadCompaniesAsync.request());
    }

    const areStatesLoaded = yield select(getAreStatesLoaded);
    if (!areStatesLoaded) {
      yield put(loadStatesAsync.request());
    }

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
