import React, { memo } from 'react';
import { useField } from 'formik';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { InputProps as MuiInputProps } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';

import { IconName } from '../../icons';
import { default as RelationSelect, RelationSelectClassKey, RelationSelectItem } from './relationSelect';
import { BaseComponentWithModalSupport } from '../baseDetail';
import { ROUTES } from '../../navigation';
import { relationConfiguration } from '../../configuration/relations';

type FormikRelationSelectProps = {
  name: string;
  items: RelationSelectItem[];
  getItemDisabled?: (option: RelationSelectItem) => boolean;
  errorMessage?: string;
  iconName: IconName;
  labelText: string;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  classes?: Partial<ClassNameMap<RelationSelectClassKey>>;
  createComponent?: {
    iconName?: IconName;
    component: React.ComponentType<BaseComponentWithModalSupport>;
    route: ROUTES;
  };
  disableUnselect?: boolean;
  showErrorWhenNotTouched?: boolean;
  disabled?: boolean;
  onChange?: (newValue: number) => void;
  filterId?: number;
};


const FormikRelationSelect = (props: FormikRelationSelectProps): React.ReactElement => {
  const [field, meta, helpers] = useField(props.name);

  const onChangeEventHandler = (newValue: number): void => {
    helpers.setValue(newValue);

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const onRemoveRelationHandler = (): void => {
    helpers.setValue(relationConfiguration.relationNotSetId, true);
  };

  let items = props.items;
  if (!props.disableUnselect) {
    items = [{ value: relationConfiguration.relationNotSetId, text: 'Nevybráno' }].concat(items);
  }

  const fieldValue = field.value ? field.value : relationConfiguration.relationNotSetId;
  let value = items.find(item => item.value === fieldValue);
  if (!value) {
    value = { text: '', value: -1 };
  }

  return (
    <RelationSelect
      items={items}
      getItemDisabled={props.getItemDisabled}
      value={value}
      iconName={props.iconName}
      labelText={props.labelText}
      helperText={meta.touched || props.showErrorWhenNotTouched ? meta.error : ''}
      error={(Boolean(meta.touched) || props.showErrorWhenNotTouched) && (Boolean(meta.error) || Boolean(props.errorMessage))}
      classes={{ ...props.classes }}
      disableUnselect={props.disableUnselect}
      disabled={props.disabled}
      onRemoveRelation={onRemoveRelationHandler}
      createComponent={props.createComponent}
      InputProps={{
        ...props.InputProps,
        name: props.name,
        onBlur: field.onBlur
      }}
      onChange={onChangeEventHandler}
      filterId={props.filterId}
    />
  );
};

export default memo(FormikRelationSelect);
