import { BaseResponse } from '../responses';
import { ApiModelClient } from '../clients/baseClient';
import { BaseModel } from '../interfaces';

export const apiCallHandler = async <
    TModel extends BaseModel,
    TResult extends BaseResponse,
    Ctx extends { [P in Name]: ApiCallFn },
    Name extends string,
    ApiCallFn extends (this: Ctx, ...args: any[]) => Promise<TResult>
>(
    ctxAndFnName: { context: Ctx; apiCallFnName: Name },
    ...args: ApiCallParameters<ApiCallFn>
): Promise<TResult> => {
    console.debug(...args);

    const apiCallFnName = ctxAndFnName.apiCallFnName;
    const ctx = ctxAndFnName.context;
    const response = await ctx[apiCallFnName](...args);

    console.debug(response);

    return response as TResult;
};

type ApiCallParameters<T extends (...args: any) => any> = T extends (...args: infer P) => any ? P : never;
