import { ActionType } from 'typesafe-actions';

import * as actions from './actions';

export type FileProps = {
  file: File;
  uploadProgress: number;
  uploadError: string;
};

export type FileUploadState = {
  progress: number;
};

export enum AcceptedFileType {
  Text = ".txt",
  Gif = ".gif",
  Jpeg = ".jpg",
  Png = ".png",
  Doc = ".doc",
  Pdf = ".pdf",
  AllImages = "image/*",
  AllVideos = "video/*",
  AllAudios = "audio/*"
}

export type FileUploadAction = ActionType<typeof actions>;
