import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { AccommodatedGuestModel } from '../../api/interfaces';
import { NormalizedListById } from '../../core';
import { TablePaginationModel } from '../../components';
import moment from 'moment';

export const getAccommodatedGuestsById = (state: ApplicationState): NormalizedListById<AccommodatedGuestModel> => {
  return state.accommodatedGuests.itemList.byId;
};

export const getAccommodatedGuestsAllIds = (state: ApplicationState): number[] => {
  return state.accommodatedGuests.itemList.allIds;
};

export const getAreAccommodatedGuestsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.accommodatedGuests.itemList.loaded;
};

export const getAccommodatedGuestsPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.accommodatedGuests.itemList.page ?? 0,
    skip: state.accommodatedGuests.itemList.skip ?? 0,
    take: state.accommodatedGuests.itemList.take ?? 100,
    total: state.accommodatedGuests.itemList.total ?? 0,
    filter: state.accommodatedGuests.itemList.filter ?? `group=${moment(new Date()).format('MM/YYYY')}`
  } as TablePaginationModel;
};