import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import StateDetail, { StateDetailStateProps, StateDetailDispatchProps, StateDetailProps } from './statesDetail';
import * as actions from './actions';
import { StateModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';

type StateCreateContainerStateProps = {} & StateDetailStateProps;

type StateCreateContainerDispatchProps = {} & StateDetailDispatchProps;

type StateCreateContainerProps = {} & StateDetailProps & StateCreateContainerStateProps & StateCreateContainerDispatchProps;

const createInitialValues = {
  /*  description: '', name: '' , personCount: 0 */
} as StateModel;

const StateCreateContainer: React.FunctionComponent<StateCreateContainerProps> = memo(props => {
  console.debug('StateCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <StateDetail
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): StateCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress
  };
};

const mapDispatchToProps = (dispatch: Dispatch): StateCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StateCreateContainer);
