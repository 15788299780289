import { select, SelectEffect } from 'redux-saga/effects';

import { GetAllRequestAsyncActionPayload } from './types';
import { ApplicationState } from '../store/rootReducer';

export function* isDataReloadRequired(
  getAreItemsLoadedSelector: (state: ApplicationState) => boolean,
  actionPayload: GetAllRequestAsyncActionPayload
): Generator<SelectEffect, boolean, boolean> {
  const areItemsLoaded: boolean = yield select(getAreItemsLoadedSelector);
  if (areItemsLoaded) {
    if (actionPayload && !actionPayload.forceReload) {
      return false;
    } else if (!actionPayload) {
      return false;
    }
  }

  return true;
}
