import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect, throttle, take, TakeEffect } from 'redux-saga/effects';
import { ActionType, getType, isOfType, Action } from 'typesafe-actions';

import { BaseDataResponse } from '../../api/responses';
import { TimeLineDataModel } from '../../api/interfaces';
import apiClientInstance from '../../api/apiClientInstance';
import { genericError } from '../errors';

import { TimeLineRange } from './types';
import { getIsAccommodationSelected, getLoadedDataRangeSelector, getVisibleDataRangeSelector } from './selectors';
import { getTimeLineAsync, timeScopeChanged, loadDependenciesAsync, visibleRangeChanged, filterByAccommodation, chipSelected, filterByFloor } from './actions';
import { getItemsAsync as loadAccommodationsAsync, getAreAccommodationsLoaded, getAccommodationsAllIds } from '../accommodations';
import { getItemsAsync as loadFloorsAsync, getAreFloorsLoaded, getFloorsById } from '../floors';
import { getAreRoomsLoaded, getItemsAsync as loadRoomsAsync } from '../rooms';
import { NavigateToDetail, navigateToDetail, ROUTES } from '../../navigation';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
  yield all([
    yield takeEvery(getTimeLineAsync.request, getTimeLineRequest),
    yield throttle(500, timeScopeChanged, timeScopeChangedHandler),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(chipSelected, chipSelectedHandler)
  ]);
}

function* chipSelectedHandler(action: ReturnType<typeof chipSelected>): Generator<
  | PutEffect<ActionType<typeof navigateToDetail>>> {
  try {
    yield put(navigateToDetail({ masterPage: ROUTES.ACCOMMODATION_OFFERS_DETAIL, detailId: action.payload } as NavigateToDetail));
  } catch (error) {
    console.log(error);
  }
}

type GetTimeLineRequestGeneratorType =
  | SelectEffect
  | Promise<BaseDataResponse<TimeLineDataModel>>
  | PutEffect<ActionType<typeof getTimeLineAsync.success>>
  | PutEffect<ActionType<typeof getTimeLineAsync.failure>>;

function* getTimeLineRequest(
  action: ReturnType<typeof getTimeLineAsync.request>
): Generator<GetTimeLineRequestGeneratorType, void, TimeLineRange & BaseDataResponse<TimeLineDataModel>> {
  try {
    console.debug('getPayrollsRequest');

    const visibleDataRange: TimeLineRange = yield select(getVisibleDataRangeSelector);
    const response: BaseDataResponse<TimeLineDataModel> = yield apiClientInstance.TimeLine.getAsync(
      visibleDataRange.start.toDate(),
      visibleDataRange.end.toDate()
    );

    if (response.resultCode === 0) {
      yield put(getTimeLineAsync.success(response.data));
    } else {
      yield put(getTimeLineAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getTimeLineAsync.failure(error));
  }
}

type TimeScopeChangedGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof genericError>>
  | PutEffect<ActionType<typeof visibleRangeChanged>>
  | Promise<BaseDataResponse<TimeLineDataModel>>
  | PutEffect<ActionType<typeof getTimeLineAsync.success>>
  | PutEffect<ActionType<typeof getTimeLineAsync.failure>>;
type TimeScopeChangedGeneratorNextType = TimeLineRange & BaseDataResponse<TimeLineDataModel>;

function* timeScopeChangedHandler(
  action: ReturnType<typeof timeScopeChanged>
): Generator<TimeScopeChangedGeneratorType, void, TimeScopeChangedGeneratorNextType> {
  try {
    console.debug('timeScopeChanged');
    const timeLineRange: TimeLineRange = action.payload;
    const loadedDataRange: TimeLineRange = yield select(getLoadedDataRangeSelector);

    if (timeLineRange.start === undefined || timeLineRange.end === undefined) {
      return;
    }

    if (loadedDataRange.start <= timeLineRange.start && loadedDataRange.end >= timeLineRange.end) {
      // Jsou nactena vsechna data
      return;
    }

    const rangeForLoadNewData: TimeLineRange = { start: timeLineRange.start, end: timeLineRange.end };
    const starts = timeLineRange.start.clone().add(1, 'days');
    const ends = timeLineRange.end.clone().add(1, 'days');

    let doRequest = false;

    if (loadedDataRange.start > starts) {
      rangeForLoadNewData.start = starts;
      rangeForLoadNewData.end = ends;
      doRequest = true;
    } else if (loadedDataRange.end < ends) {
      rangeForLoadNewData.start = starts;
      rangeForLoadNewData.end = ends;
      doRequest = true;
    }

    if (doRequest) {
      const response: BaseDataResponse<TimeLineDataModel> = yield apiClientInstance.TimeLine.getAsync(
        rangeForLoadNewData.start.toDate(),
        rangeForLoadNewData.end.toDate()
      );

      if (response.resultCode === 0) {
        yield put(getTimeLineAsync.success(response.data));
      } else {
        yield put(getTimeLineAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
      }
    }

    yield put(visibleRangeChanged(timeLineRange));
    
  } catch (error) {
    yield put(genericError(error));
  }
}

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadFloorsAsync.request>>
  | PutEffect<ActionType<typeof loadRoomsAsync.request>>
  | TakeEffect
  | PutEffect<ActionType<typeof filterByAccommodation>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean & number[] & number> {
  try {
    console.debug('loadDependenciesRequest');
    console.error('ACTION');
    console.error(action);

    const areAcoommodationsLoaded = yield select(getAreAccommodationsLoaded);
    if (!areAcoommodationsLoaded) {
      yield put(loadAccommodationsAsync.request());
      yield take([loadAccommodationsAsync.failure, loadAccommodationsAsync.success]);
    }

    const areFloorsLoaded = yield select(getAreFloorsLoaded);
    if (!areFloorsLoaded) {
      yield put(loadFloorsAsync.request());
      yield take([loadFloorsAsync.failure, loadFloorsAsync.success]);
    }

    const allAccommodationIds: number[] = yield select(getAccommodationsAllIds);
    const isSelected: boolean = yield select(getIsAccommodationSelected);
    if (allAccommodationIds.length > 0 && !isSelected) {
      const selectedAccommodationId = allAccommodationIds[0];
      yield put(filterByAccommodation(selectedAccommodationId));
    }

    const areRoomsLoaded = yield select(getAreRoomsLoaded);
    if (!areRoomsLoaded) {
      yield put(loadRoomsAsync.request());
      yield take([loadRoomsAsync.failure, loadRoomsAsync.success]);
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
