import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { NormalizedListById } from '../../core';
import { VatModel } from '../../api/interfaces';

export const getVatsById = (state: ApplicationState): NormalizedListById<VatModel> => {
  return state.vats.itemList.byId;
};

export const getVatsAllIds = (state: ApplicationState): number[] => {
  return state.vats.itemList.allIds;
};

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.vats.itemList.loaded;
};

export const getAreVatsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const selectVatsSelectItems = createSelector(
  [getVatsById],
  (vatsById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectVatsSelectItems...');

    return Object.values(vatsById).map<RelationSelectItem>(priceList => {
      return { text: priceList.name, value: priceList.id } as RelationSelectItem;
    });
  }
);
