import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect, CallEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import {
  getItemsAsync,
  sendUserInvitationAsync,
  cancelItemAsync,
  newItemAsync,
  deleteItemAsync,
  loadDependenciesAsync,
  editItemAsync,
  saveItemAsync
} from './actions';
import apiClientInstance from '../../api/apiClientInstance';

import { UserModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { addSuccessAlert, closeTopModal, getIsSomeModalOpened } from '../../components';
import { ROUTES, navigateTo, navigateToDetail, NavigateToDetail } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerDeleteEntityRequest, registerSaveEntityRequest } from '../../core';
import sha1 from 'sha1';

// use them in parallel
export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getUsersRequest),
    yield takeEvery(newItemAsync.request, newUserRequest),
    yield takeEvery(sendUserInvitationAsync.request, sendUserInvitationRequest),
    yield takeEvery(cancelItemAsync.request, cancelUserRequest),
    yield takeEvery(editItemAsync.request, editUserRequest),
    yield takeEvery(saveItemAsync.request, saveUserRequest),
    yield takeEvery(deleteItemAsync.request, deleteUserRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type SaveEntityRequestGeneratorType<UserModel> =
  | Promise<BaseDataResponse<UserModel>>
  | PutEffect<ActionType<typeof saveItemAsync.success>>
  | PutEffect<ActionType<typeof saveItemAsync.failure>>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* saveUserRequest(
  action: ActionType<typeof saveItemAsync.request>
): Generator<SaveEntityRequestGeneratorType<UserModel>, void,
  BaseDataResponse<UserModel>
  & UserModel & boolean> {

  try {
    console.debug('saveUserRequest');

    const model = { ... action.payload };
    
    if (model.password?.trim().length) {
       model.password = sha1(model.password);
    }

    const response: BaseDataResponse<UserModel> = yield apiClientInstance.apiCallHandler({ context: apiClientInstance.Users, apiCallFnName: 'updateAsync' }, model);

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(saveItemAsync.success(data));

      const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
      if (isSomeModalOpend) {
        yield put(closeTopModal());
      } else {
        yield put(navigateTo(ROUTES.SETTINGS_USERS));
      }
    } else {
      yield put(saveItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(saveItemAsync.failure(error));
  }
}

type EditUserRequestGeneratorType =
  | Promise<BaseDataResponse<UserModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editUserRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditUserRequestGeneratorType, void, BaseDataResponse<UserModel>> {
  try {
    console.debug('editUserRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_USERS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

type GetUserRequestGeneratorType =
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<UserModel[]>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getUsersRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetUserRequestGeneratorType, void, boolean & BaseDataResponse<UserModel[]>> {
  try {
    console.debug('getUsersRequest');

    const payload = action.payload as GetAllRequestAsyncActionPayload;
    const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
    if (!isReloadRequired) {
      yield put(getItemsAsync.cancel());
      return;
    }

    const method: keyof typeof apiClientInstance.Users = 'getAllAsync';
    const response: BaseDataResponse<UserModel[]> = yield apiClientInstance.apiCallHandler({
      context: apiClientInstance.Users,
      apiCallFnName: method
    });

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type NewRoomRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newUserRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewRoomRequestGeneratorType, void, void> {
  try {
    console.debug('newUserRequest');

    yield put(navigateTo(ROUTES.SETTINGS_USERS_INVITE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type SendUserInvitationRequestGeneratorType =
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof sendUserInvitationAsync.success>>
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof addSuccessAlert>>
  | PutEffect<ActionType<typeof sendUserInvitationAsync.failure>>;

function* sendUserInvitationRequest(
  action: ReturnType<typeof sendUserInvitationAsync.request>
): Generator<SendUserInvitationRequestGeneratorType, void, BaseResponse> {
  try {
    console.debug('getUsersRequest');
    const method: keyof typeof apiClientInstance.Users = 'inviteAsync';
    const response: BaseResponse = yield apiClientInstance.apiCallHandler(
      {
        context: apiClientInstance.Users,
        apiCallFnName: method
      },
      action.payload
    );

    if (response.resultCode === 0) {
      yield put(sendUserInvitationAsync.success());
      yield put(navigateTo(ROUTES.SETTINGS_USERS));
      yield put(addSuccessAlert('Pozvánka byla úspěšně odeslána.'));
    } else {
      yield put(sendUserInvitationAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(sendUserInvitationAsync.failure(error));
  }
}

const deleteUserRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClientInstance.Users,
  ROUTES.SETTINGS_USERS
);

type CancelUserRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof cancelItemAsync.success>>
  | PutEffect<ActionType<typeof cancelItemAsync.failure>>;

function* cancelUserRequest(action: ReturnType<typeof cancelItemAsync.request>): Generator<CancelUserRequestGeneratorType, void, void> {
  try {
    console.debug('cancelAUserRequest');

    yield put(navigateTo(ROUTES.SETTINGS_USERS));
    yield put(cancelItemAsync.success());
  } catch (error) {
    yield put(cancelItemAsync.failure(error));
  }
}

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
