import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import FloorsTable from './floorsTable';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import { FloorModel } from '../../api/interfaces';
import {
  mergeWithDefaultColumns,
  GenericColumn,
  BaseTableRowModel,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps
} from '../../components';

type FloorsContainerStateProps = {
} & BaseListStateProps<FloorModel>;

type FloorsContainerDispatchProps = {} & BaseListDispatchProps<FloorModel> & BaseListContainerDispatchProps<FloorModel>;

type FloorsContainerProps = {} & FloorsContainerStateProps & FloorsContainerDispatchProps;

const columns = mergeWithDefaultColumns<FloorModel>([
  { id: 'name', label: 'Název', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  { id: 'description', label: 'Popis', minWidth: 250, align: 'left', isVisible: true, isTableActions: false },
  { id: 'number', label: 'Patro', minWidth: 250, align: 'left', isVisible: true, isTableActions: false },
]);

const FloorsContainer: React.FunctionComponent<FloorsContainerProps> = props => {
  console.debug('FloorsContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <FloorsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): FloorsContainerStateProps => {
  return {
    items: state.floors.itemList.allIds.map<FloorModel>(id => {
      return state.floors.itemList.byId[id];
    }),
    requestInProgress: state.floors.requestInProgress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): FloorsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FloorsContainer);
