import React from 'react';
import { ListItem, ListItemText, makeStyles, Theme, createStyles } from '@material-ui/core';
import { useRouteMatch, NavLink as ReactRouterDomNavLink } from 'react-router-dom';

import { MenuNavLinkItemProps } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dwawerListItemLiRoot: {
      color: theme.palette.text.primary,
      '&$dwawerListItemLiSelected, &$dwawerListItemLiSelected:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
      }
    },
    dwawerListItemLiSelected: {
      /* color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
          backgroundColor: theme.palette.primary.main
      },
      '&selected:hover': {
          backgroundColor: theme.palette.primary.main
      } */
    },
    dwawerListItemLiSelectedIndicatorRoot: {
      textAlign: 'end'
    }
  })
);

type VerticalMenuNavLinkItemProps = {} & MenuNavLinkItemProps;

const VerticalMenuNavLinkItem: React.FunctionComponent<VerticalMenuNavLinkItemProps> = props => {
  const classes = useStyles();
  const match = useRouteMatch({
    path: props.to,
    exact: false
  });
  const selected = !!match;

  return (
    <ReactRouterDomNavLink to={props.to}>
      <ListItem selected={selected} key={props.to} classes={{ root: classes.dwawerListItemLiRoot, selected: classes.dwawerListItemLiSelected }}>
        <ListItemText primary={props.text} />
        {selected && <ListItemText primary=">" classes={{ root: classes.dwawerListItemLiSelectedIndicatorRoot }} />}
      </ListItem>
    </ReactRouterDomNavLink>
  );
};

export default VerticalMenuNavLinkItem;
