import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as actions from './actions';
import UsersTable from './usersTable';
import { ApplicationState } from '../../store/rootReducer';
import { UserModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps
} from '../../components';

type UserContainerStateProps = {} & BaseListStateProps<UserModel>;

type UserContainerDispatchProps = {} & BaseListDispatchProps<UserModel> & BaseListContainerDispatchProps<UserModel>;

type UsersContainerProps = {} & UserContainerStateProps & UserContainerDispatchProps;

const columns = mergeWithDefaultColumns<UserModel>([
  { id: 'firstName', label: 'Jméno', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'lastName', label: 'Přijmení', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'email', label: 'Email', minWidth: 170, align: 'left', isVisible: true, isTableActions: false }
]);

const UsersContainer: React.FunctionComponent<UsersContainerProps> = props => {
  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <UsersTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): UserContainerStateProps => ({
  items: state.users.itemList.allIds.map(id => {
    return state.users.itemList.byId[id];
  }),
  requestInProgress: state.users.requestInProgress
});

const mapDispatchToProps = (dispatch: Dispatch): UserContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
