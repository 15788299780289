import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AccommodationDetail, {
    AccommodationDetailStateProps,
    AccommodationDetailDispatchProps,
    AccommodationDetailProps
} from './accommodationsDetail';
import * as actions from './actions';
import { AccommodationModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';

type AccommodationCreateContainerStateProps = {} & AccommodationDetailStateProps;

type AccommodationCreateContainerDispatchProps = {} & AccommodationDetailDispatchProps;

type AccommodationCreateContainerProps = {} & AccommodationDetailProps &
    AccommodationCreateContainerStateProps &
    AccommodationCreateContainerDispatchProps;

const createInitialValues = {
    description: '',
    name: ''
} as AccommodationModel;

const AccommodationCreateContainer: React.FunctionComponent<AccommodationCreateContainerProps> = memo(props => {
    console.debug('AccommodationCreateContainer');

    const {
        errorMessage,
        requestInProgress,
        initialValues,
        modalDialog,
        modalDialogRoute,
        saveActionRequest,
        cancelActionRequest,
        loadDependenciesRequest
    } = props;

    return (
        <AccommodationDetail
            initialValues={initialValues}
            saveActionRequest={saveActionRequest}
            cancelActionRequest={cancelActionRequest}
            loadDependenciesRequest={loadDependenciesRequest}
            errorMessage={errorMessage}
            requestInProgress={requestInProgress}
            modalDialog={modalDialog}
            modalDialogRoute={modalDialogRoute}
        />
    );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationCreateContainerStateProps => {
    return {
        initialValues: createInitialValues,
        errorMessage: state.accommodations.errorMessage,
        requestInProgress: state.accommodations.requestInProgress
    };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationCreateContainerDispatchProps =>
    bindActionCreators(
        {
            saveActionRequest: actions.saveItemAsync.request,
            cancelActionRequest: actions.cancelItemAsync.request,
            loadDependenciesRequest: actions.loadDependenciesAsync.request
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationCreateContainer);
