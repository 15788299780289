import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type CompaniesTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const CompaniesTable: React.FunctionComponent<CompaniesTableProps> = memo(props => {
  console.debug('CompanysTable');

  return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default CompaniesTable;
