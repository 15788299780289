import { ActionType } from 'typesafe-actions';
import uuidV4 from 'uuid/v4';

import * as actions from './actions';

export type AlertType = 'info' | 'success' | 'warning' | 'error';

export class AlertMessage {
  type: AlertType;
  message: string;

  constructor(init: AlertMessage) {
    this.type = 'info';
    this.message = '';
    
    Object.assign(this, init);
  }
}

export class AlertModel extends AlertMessage {
  id: string;

  constructor(init: AlertMessage) {
    super(init);

    this.id = uuidV4();
  }
}

export type AlertsState = {
  alerts: AlertModel[];
  containers: string[];
};

export type AlertsAction = ActionType<typeof actions>;
