import React from 'react';
import { makeStyles, Theme, createStyles, List, Toolbar } from '@material-ui/core';

import { BaseToolbar } from '../baseToolbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionDesktop: {
      display: 'none',
      padding: '0px',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
        /* width: 'fit-content',    */
      },
      '& > a': {
        textDecoration: 'none'
      }
    }
  })
);

export type DynamicToolbarProps = {
  /*  menuItems: MenuItemModel[]; */
};

const DynamicToolbar: React.FunctionComponent<DynamicToolbarProps> = props => {
  console.debug('DynamicToolbar');
  const classes = useStyles();

  return (
    <BaseToolbar>
      <List className={classes.sectionDesktop} component="nav">
        {props.children}
      </List>
    </BaseToolbar>
  );
};

export default DynamicToolbar;
