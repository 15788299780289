import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, createStyles, Theme, Container } from '@material-ui/core';

import * as actions from './actions';
import { SignInPageEnum } from './types';
import SignInPersonalIdView from './signInPersonalIdView';
import SignInPinCodeView from './signInPinCodeView';
import SignInUserNamePasswordView from './signInUserNamePasswordView';
import { ApplicationState } from '../../store/rootReducer';
import { tenantConfiguratinService } from '../tenant';

type SingInContainerProps = {} & SingInContainerStateProps & SingInContainerDispatchProps;

type SingInContainerStateProps = {
  page: SignInPageEnum;
  errorMessage: string;
  requestInProgress: boolean;
};

type SingInContainerDispatchProps = {
  signInRequestOptRequest: typeof actions.signInRequestOptAsync.request;
  signInValidateOtpRequest: typeof actions.signInValidateOptAsync.request;
  signInRequest: typeof actions.signInAsync.request;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}px`,
      minHeight: '100%'
    },
    loginText: {
      color: theme.palette.primary.main
      /* padding: `${theme.spacing(4)}px 0px 0px 0px` */
    }
  })
);

const getSignInView = (props: SingInContainerProps): React.ReactElement => {
  switch (props.page) {
    case SignInPageEnum.PersonalId:
      return (
        <SignInPersonalIdView
          requestOpt={props.signInRequestOptRequest}
          errorMessage={props.errorMessage}
          requestInProgress={props.requestInProgress}
          supportEmail={tenantConfiguratinService.getRegistrationEmail()}
        />
      );
    case SignInPageEnum.PinCode:
      return (
        <SignInPinCodeView
          validateOpt={props.signInValidateOtpRequest}
          errorMessage={props.errorMessage}
          requestInProgress={props.requestInProgress}
        />
      );
    case SignInPageEnum.UserNamePassword:
      return (
        <SignInUserNamePasswordView
          signIn={props.signInRequest}
          errorMessage={props.errorMessage}
          requestInProgress={props.requestInProgress}
        />
      );

    default:
      throw new Error('Nepodporovaný typ přihlašovací stránky');
  }
};

const SingInContainer: React.FunctionComponent<SingInContainerProps> = props => {
  const componet = getSignInView(props);
  const classes = useStyles(props);

  return (
    <Container maxWidth="sm" classes={{ root: classes.root }}>
      {componet}
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState): SingInContainerStateProps => ({
  page: state.signIn.page,
  errorMessage: state.signIn.errorMessage,
  requestInProgress: state.signIn.requestInProgress
});

const mapDispatchToProps = (dispatch: Dispatch): SingInContainerDispatchProps =>
  bindActionCreators(
    {
      signInRequestOptRequest: actions.signInRequestOptAsync.request,
      signInValidateOtpRequest: actions.signInValidateOptAsync.request,
      signInRequest: actions.signInAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingInContainer);
