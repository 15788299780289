import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios';

import apiConfig from '../../configuration/api';

export type ImageContainerProps = {
  url: string;
};

export type ImageContainerState = {
  downloaded: boolean;
};

const ImageContainer = (props: ImageContainerProps): React.ReactElement => {
  const [image, setImage] = useState('');
  const [mimeType, setMimeType] = useState('');

  useEffect(() => {
    downloadLogo();
  }, []);

  const downloadLogo = () => {
    const axiosInstance = axios.create({
      baseURL: apiConfig.baseUrl,
      timeout: 300000,
      withCredentials: true,
      headers: {
      },
      validateStatus: (status: number) => status >= 200 && status < 300
    });

    axiosInstance.get(props.url, { responseType: "arraybuffer" })
      .then(response => {
        const mimeType = response.headers["content-disposition"].split("filename=")[1].split(';')[0].replaceAll('"', '');
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          ));
        setImage(base64);
        setMimeType(mimeType);
      });
  }

  return (
    <div style={{ height: "200px", width: "400px", border: '1px solid #ddd', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src={`data:${mimeType};charset=utf-8;base64,${image}`} style={{ maxWidth: '400px', maxHeight: '200px' }} />
    </div>
  );
};


export default ImageContainer;