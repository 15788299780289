import React from 'react';
import { Theme, StyleRules, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { Breadcrumbs } from '../../components';
import { AlertsContainer } from '../alerts';
import { ListItemState, BaseActionOperation, BaseAction, BaseActions } from '../../core';
import { ApplicationState } from '../../store/rootReducer';
import { ROUTES } from '../../navigation';

const withStylesFunction = withStyles(
  (theme: Theme): StyleRules<DetailClassKey> => ({
    root: {},
    grid: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    },
    gridButtonsRoot: {
      paddingTop: theme.spacing(3),
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(2)
      }
    }
  })
);

type DetailClassKey = 'root' | 'grid' | 'gridButtonsRoot';

export type BaseDetailProps<TModel extends ListItemState> = {
  /*  initialValues: TModel; */
  classes?: Partial<ClassNameMap<DetailClassKey>>;
  /*  errorMessage: string;
  requestInProgress: boolean; */
} & BaseComponentWithModalSupport;

export type BaseComponentWithModalSupport = {
  modalDialog?: boolean;
  modalDialogRoute?: ROUTES;
};

export type BaseDetailStateProps<TModel extends ListItemState> = {} & BaseDetailInternalContainerStateProps<TModel>;

export type BaseDetailDispatchProps<TModel extends ListItemState> = {
  saveActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'saveItemAsync'>, 'request'>;
  cancelActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'cancelItemAsync'>, 'request'>;
} & BaseDetailInternalContainerDispatchProps<TModel>;

type BaseDetailContainerProps<TModel extends ListItemState> = {};

type BaseDetailContainerStateProps = { editItemId: number };

type BaseDetailConainerOwnProps = { modalDialog: boolean; modalDialogRoute: ROUTES; match: any } & RouteComponentProps;

type BaseDetailContainerDispatchProps = {};

/*****************************/
type BaseDetailLoaderContainerProps<TModel extends ListItemState> = BaseDetailLoaderContainerStateProps<TModel> &
  BaseDetailLoaderDispatchProps<TModel> & {
    modalDialog: boolean;
    modalDialogRoute: ROUTES;
    classes?: Partial<ClassNameMap<DetailClassKey>>;

    children(props: BaseDetailContainerWrappedComponentProps<TModel>): JSX.Element;
    afterInitialize?: () => void;
    editItemId: number;
  };
type BaseDetailLoaderContainerStateProps<TModel extends ListItemState> = {
  initialValues: TModel;
  errorMessage: string;
  requestInProgress: boolean;
};

export type BaseDetailLoaderDispatchProps<TModel extends ListItemState> = {
  saveActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'saveItemAsync'>, 'request'>;
  cancelActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'cancelItemAsync'>, 'request'>;
  getItemAsync?: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'getItemAsync'>, 'request'>;
};

/*****************************/
type BaseDetailInternalContainerStateProps<TModel extends ListItemState> = {
  initialValues: TModel;
  errorMessage: string;
  requestInProgress: boolean;
};

type BaseDetailInternalContainerDispatchProps<TModel extends ListItemState> = {
  loadDependenciesRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'loadDependenciesAsync'>, 'request'>;
  getItemAsync?: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'getItemAsync'>, 'request'>;
};

type BaseDetailInternalContainerProps<TModel extends ListItemState> = BaseDetailInternalContainerStateProps<TModel> &
  BaseDetailDispatchProps<TModel> & {
    modalDialog: boolean;
    modalDialogRoute: ROUTES;
    classes?: Partial<ClassNameMap<DetailClassKey>>;

    children(props: BaseDetailContainerWrappedComponentProps<TModel>): JSX.Element;
    afterInitialize?: () => void;
  };

/*****************************/
type BaseStyledDetailContainerProps<TModel extends ListItemState> = {
  children(props: BaseDetailContainerWrappedComponentProps<TModel>): React.ReactElement;
} & {
  initialValues: TModel;
  classes: Partial<ClassNameMap<DetailClassKey>>;
  errorMessage: string;
  requestInProgress: boolean;
  modalDialog: boolean;
  route: string;
} & {
  saveActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'saveItemAsync'>, 'request'>;
  cancelActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'cancelItemAsync'>, 'request'>;

  afterInitialize?: () => void;
};

export type BaseDetailContainerWrappedComponentProps<TModel extends ListItemState> = {
  initialValues: TModel;
  classes: Partial<ClassNameMap<DetailClassKey>>;
  errorMessage: string;
  requestInProgress: boolean;
} & {
  saveActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'saveItemAsync'>, 'request'>;
  cancelActionRequest: BaseActionOperation<BaseAction<TModel, BaseActions<TModel>, 'cancelItemAsync'>, 'request'>;

  afterInitialize?: () => void;
};

class BaseDetailContainerWrapper<TModel extends ListItemState> extends React.PureComponent<BaseStyledDetailContainerProps<TModel>> {
  componentDidMount(): void {
    if (this.props.afterInitialize) {
      this.props.afterInitialize();
    }
  }

  render(): React.ReactNode {
    console.debug('BaseDetailContainerWrapper');

    const {
      children,
      classes,
      initialValues,
      errorMessage,
      requestInProgress,
      modalDialog,
      route,
      saveActionRequest,
      cancelActionRequest,
      ...otherProps
    } = this.props;

    return (
      <div className={classes.root}>
        <Breadcrumbs modalDialog={modalDialog} route={route} />
        {children({
          classes: classes,
          initialValues: initialValues,
          errorMessage: errorMessage,
          requestInProgress: requestInProgress,
          saveActionRequest: saveActionRequest,
          cancelActionRequest: cancelActionRequest,
          ...otherProps
        })}
      </div>
    );
  }
}

type BaseDetailContainerWrapperOwnProps = { modalDialog: boolean; modalDialogRoute: ROUTES } & RouteComponentProps;

type BaseDetailContainerWrapperStateProps = { route: string };

type BaseDetailContainerWrapperDispatchProps = { };

const mapStateToProps = (state: ApplicationState, ownProps: BaseDetailContainerWrapperOwnProps): BaseDetailContainerWrapperStateProps => {
  return {
    route: ownProps.modalDialog ? ownProps.modalDialogRoute : ownProps.location.pathname
  };
};

const wrapperComponent = withRouter(connect(mapStateToProps)(BaseDetailContainerWrapper));

const BaseStyledDetailContainer = withStylesFunction(wrapperComponent);

class BaseDetailLoaderContainer<TModel extends ListItemState> extends React.PureComponent<BaseDetailLoaderContainerProps<TModel>> {
  componentDidMount(): void {
    console.debug(`BaseDetailLoaderContainer.didMount`);

    if (this.props.getItemAsync) {
      this.props.getItemAsync(this.props.editItemId);
    }
  }

  render(): React.ReactNode {
    console.debug(`BaseDetailLoaderContainer`);
    const { initialValues, classes, ...otherProps } = this.props;

     if (!initialValues) {
      return null;
    }

    return <BaseStyledDetailContainer  {...otherProps} initialValues={initialValues} classes={{ ...classes }} />;
  }
} 

const loaderMapStateToProps = (state: ApplicationState, ownProps: BaseDetailConainerOwnProps): BaseDetailContainerStateProps => {
  return {
    editItemId: ownProps.match.params.id
  };
};

const BaseLoaderDetailComponent = withRouter(connect(loaderMapStateToProps)(BaseDetailLoaderContainer));

class BaseDetailContainer<TModel extends ListItemState> extends React.PureComponent<BaseDetailInternalContainerProps<TModel>> {
  static defaultProps = { modalDialog: false, modalDialogRoute: '' };

  componentDidMount(): void {
    console.debug('BaseDetailContainer.didMount');
    this.props.loadDependenciesRequest();
  }

  render(): React.ReactNode {
    console.debug('BaseDetailContainer');
    const { initialValues, classes, loadDependenciesRequest, ...otherProps } = this.props;

    return <BaseLoaderDetailComponent {...otherProps} initialValues={initialValues} classes={{ ...classes }} />;
  }
}

export default BaseDetailContainer;
