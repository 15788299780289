import produce from 'immer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import { PaymentModel } from '../../api/interfaces';
import { PaymentCalculationModel } from '../../api/interfaces/paymentCalculationModel';
import { GetAllRequestAsyncActionPayload } from '../../core';

import * as actions from './actions';
import { PaymentsAction, PaymentsState } from './types';

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  getAllItemsAsync: actions.getAllItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync
};

export const initialState: PaymentsState = {
  current: [] as PaymentModel[],
  currentToPay: {} as PaymentCalculationModel,
  requestInProgress: false,
  itemList: {
    allIds: [],
    byId: {},
    loaded: false,
    page: 0,
    skip: 0,
    take: 100,
    filter: ""
  },
  errorMessage: '',
};

const itemListReducer = createReducer<typeof initialState.itemList, PaymentsAction>(initialState.itemList)
  .handleAction(actions.setPagination, (state, action) => {
    const newState = produce(state, draft => {
      draft.total = action.payload.total;
      draft.page = action.payload.page;
      draft.skip = action.payload.skip;
      draft.take = action.payload.take;
      draft.filter = action.payload.filter;
    });

    return newState;
  })
  .handleAction(actions.getAllItemsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.allIds = action.payload.map(model => {
        draft.byId[model.id] = model;

        return model.id;
      });
      draft.loaded = true;
    });

    return newState;
  })
  .handleAction(actions.getAllItemsAsync.request, (state, action) => {
    if (action.payload as GetAllRequestAsyncActionPayload) {
      return initialState.itemList;
    }

    return state;
  })
  .handleAction(actions.deleteItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      const index = draft.allIds.findIndex(id => id === action.payload)
      if (index !== -1) draft.allIds.splice(index, 1)
    });

    return newState;
  })
  ;


const currentToPayReducer = createReducer<typeof initialState.currentToPay, ActionType<typeof actions.calculateAsync>>(initialState.currentToPay)
  .handleAction(actions.calculateAsync.success, (state, action) => {
    return action.payload;
  });

const currentReducer = createReducer<typeof initialState.current, ActionType<typeof baseActions>>(initialState.current)
  .handleAction(baseActions.getItemsAsync.success, (state, action) => {
    return action.payload;
  })
  .handleAction(baseActions.deleteItemAsync.success, (state, action) => {
    const newState = state.filter(item => item.id !== action.payload);
    return newState;
  });

const requestInProgressReducer = createReducer<typeof initialState.requestInProgress, ActionType<typeof baseActions>>(initialState.requestInProgress)
  .handleAction([actions.editItemAsync.request, actions.deleteItemAsync.request], (state, action) => true)
  .handleAction(
    [
      actions.getItemsAsync.success,
      actions.getAllItemsAsync.success,
      actions.editItemAsync.success,
      actions.deleteItemAsync.success,
      actions.getItemsAsync.failure,
      actions.getAllItemsAsync.failure,
      actions.editItemAsync.failure,
      actions.deleteItemAsync.failure,
    ],
    (state, action) => initialState.requestInProgress
  );

const errorMessageReducer = createReducer<typeof initialState.errorMessage, ActionType<typeof baseActions>>(initialState.errorMessage)
  .handleAction(
    [actions.getItemsAsync.failure, actions.getAllItemsAsync.failure, actions.editItemAsync.failure, actions.deleteItemAsync.failure],
    (state, action) => action.payload.message
  )
  .handleAction(
    [
      actions.getItemsAsync.request,
      actions.getItemsAsync.success,
      actions.getAllItemsAsync.request,
      actions.getAllItemsAsync.success,
      actions.editItemAsync.request,
      actions.editItemAsync.success,
      actions.deleteItemAsync.request,
      actions.deleteItemAsync.success
    ],
    (state, action) => initialState.errorMessage
  );

export default combineReducers<PaymentsState>({
  current: currentReducer,
  currentToPay: currentToPayReducer,
  requestInProgress: requestInProgressReducer,
  itemList: itemListReducer,
  errorMessage: errorMessageReducer
});

