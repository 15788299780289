import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';

import {
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { stateValidationSchema } from './validations';
import { StateModel } from '../../api/interfaces';

export type StateDetailStateProps = {
} & BaseDetailStateProps<StateModel>;

export type StateDetailDispatchProps = {
} & BaseDetailDispatchProps<StateModel>;

export type StateDetailProps = {} & BaseDetailProps<StateModel> & StateDetailStateProps & StateDetailDispatchProps;

type RenderFormProps = {
} & BaseDetailContainerWrappedComponentProps<StateModel>;


const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    requestInProgress,
    initialValues
  } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<StateModel>
      initialValues={initialValues}
      onSubmit={(values: StateModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={stateValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>
                 
                </Grid>
                <Grid item xs={12}>

                </Grid>
                <Grid item xs={12}>

                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const StateDetail: React.FunctionComponent<StateDetailProps> = memo(props => {
  console.debug('StateDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<StateModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default StateDetail;
