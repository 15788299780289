import { Action } from 'redux';

import { navigateTo } from './actions';

export type NavigateToActionType = Action<typeof navigateTo>;

export type NavigateToWithQuery = {
  pathname: ROUTES;
  query: string;
};

export type NavigateToDetail = {
  masterPage: ROUTES;
  detailId: number;
};

type Route = {
  readebleText: string;
};

type NamedRoutes = {
  [key in ROUTES]: Route;
};

export const NAMED_ROUTES = {
  '/': { readebleText: 'Doma' },
  '/timeline': { readebleText: 'Rezervace' },
  '/guests': { readebleText: 'Hosté' },

  '/settings': { readebleText: 'Nastavení' },

  // Users
  '/settings/users': { readebleText: 'Uživatelé' },
  '/settings/users/:id': { readebleText: 'Editace' },
  '/settings/users/invite': { readebleText: 'Pozvánka' },

  // Accommodations
  '/settings/accommodations': { readebleText: 'Ubytovací domy' },
  '/settings/accommodations/create': { readebleText: 'Nový' },
  '/settings/accommodations/:id': { readebleText: 'Editace' },

  // Room types
  '/settings/roomtypes': { readebleText: 'Typy pokojů' },
  '/settings/roomtypes/create': { readebleText: 'Nový' },
  '/settings/roomtypes/:id': { readebleText: 'Editace' },

  // Rooms
  '/settings/rooms': { readebleText: 'Pokoje' },
  '/settings/rooms/create': { readebleText: 'Nový' },
  '/settings/rooms/:id': { readebleText: 'Editace' },

  // Guests
  '/settings/guests': { readebleText: 'Hosté' },
  '/settings/guests/create': { readebleText: 'Nový' },
  '/settings/guests/:id': { readebleText: 'Editace' },

  // States
  '/settings/states': { readebleText: 'Státy' },
  '/settings/states/create': { readebleText: 'Nový' },
  '/settings/states/:id': { readebleText: 'Editace' },

  // Companys
  '/settings/companies': { readebleText: 'Firmy' },
  '/settings/companies/create': { readebleText: 'Nová' },
  '/settings/companies/:id': { readebleText: 'Editace' },

  // Price lists
  '/settings/pricelists': { readebleText: 'Ceníky' },
  '/settings/pricelists/create': { readebleText: 'Nový' },
  '/settings/pricelists/:id': { readebleText: 'Editace' },

  // Price list items
  '/settings/pricelistitems': { readebleText: 'Položky ceníku' },
  '/settings/pricelistitems/create': { readebleText: 'Nová' },
  '/settings/pricelistitems/:id': { readebleText: 'Editace' },

  // Number series
  '/settings/numberseries': { readebleText: 'Řady' },

  // Vats
  '/settings/vats': { readebleText: 'Položky DPH' },
  '/settings/vats/create': { readebleText: 'Nová' },
  '/settings/vats/:id': { readebleText: 'Editace' },

  '/reports': { readebleText: 'Přehledy' },

  '/signin': { readebleText: 'Přihlášení' },
  '/signup': { readebleText: 'Registrace' },
  '/signout': { readebleText: 'Odhlášení' },

  '/accommodationoffers': { readebleText: 'Rezervace' },
  '/accommodationoffers/create': { readebleText: 'Nová rezervace' },
  '/accommodationoffers/:id': { readebleText: 'Editace rezervace' },
  '/accommodationoffers/copy/:id': { readebleText: 'Kopie' },

  '/accommodationofferfees': { readebleText: 'Poplatky' },
  '/accommodationofferfees/create': { readebleText: 'Nový poplatek' },
  '/accommodationofferfees/:id': { readebleText: 'Editace poplatku' },

  '/reservationrooms': { readebleText: 'Rezervace pokoje' },
  '/reservationrooms/create': { readebleText: 'Nová rezervace pokoje' },
  '/reservationrooms/:id': { readebleText: 'Editace rezervace pokoje' },

  '/accommodationofferrooms': { readebleText: 'Výběr pokoje' },
  '/accommodationofferrooms/create': { readebleText: 'Nová rezervace pokoje' },
  '/accommodationofferrooms/:id': { readebleText: 'Editace rezervace pokoje' },

  '/reservationguestcheckouts': { readebleText: 'Odhlášení hosta' },
  '/reservationguestcheckouts/create': { readebleText: 'Nové odhlášení hosta' },

  '/settings/mycompany': { readebleText: 'Fakturační údaje' },

  '/reservationgueststorno': { readebleText: 'Storno' },
  '/reservationgueststorno/create': { readebleText: 'Nové storno' },

  '/accommodationoffersevidencelist': { readebleText: 'Evidenční list' },
  '/accommodationoffersevidencelist/create': { readebleText: 'Tisk' },

  '/settings/floors': { readebleText: 'Patra' },
  '/settings/floors/create': { readebleText: 'Nové patro' },

} as NamedRoutes;

export enum ROUTES {
  ROOT = '/',
  TIME_LINE = '/timeline',
  GUESTS = '/guests',

  SETTINGS = '/settings',

  // Users
  SETTINGS_USERS = '/settings/users',
  SETTINGS_USERS_DETAIL = '/settings/users/:id',
  SETTINGS_USERS_INVITE = '/settings/users/invite',

  // Accommodations
  SETTINGS_ACCOMMODATIONS = '/settings/accommodations',
  SETTINGS_ACCOMMODATIONS_CREATE = '/settings/accommodations/create',
  SETTINGS_ACCOMMODATIONS_DETAIL = '/settings/accommodations/:id',

  // Room types
  SETTINGS_ROOM_TYPES = '/settings/roomtypes',
  SETTINGS_ROOM_TYPES_CREATE = '/settings/roomtypes/create',
  SETTINGS_ROOM_TYPES_DETAIL = '/settings/roomtypes/:id',

  // Rooms
  SETTINGS_ROOMS = '/settings/rooms',
  SETTINGS_ROOMS_CREATE = '/settings/rooms/create',
  SETTINGS_ROOMS_DETAIL = '/settings/rooms/:id',

  // Floors
  SETTINGS_FLOORS = '/settings/floors',
  SETTINGS_FLOORS_CREATE = '/settings/floors/create',
  SETTINGS_FLOORS_DETAIL = '/settings/floors/:id',

  // Guests
  SETTINGS_GUESTS = '/settings/guests',
  SETTINGS_GUESTS_CREATE = '/settings/guests/create',
  SETTINGS_GUESTS_DETAIL = '/settings/guests/:id',

  // States
  SETTINGS_STATES = '/settings/states',
  SETTINGS_STATES_CREATE = '/settings/states/create',
  SETTINGS_STATES_DETAIL = '/settings/states/:id',

  // Companies
  SETTINGS_COMPANIES = '/settings/companies',
  SETTINGS_COMPANIES_CREATE = '/settings/companies/create',
  SETTINGS_COMPANIES_DETAIL = '/settings/companies/:id',

  // Price lists
  SETTINGS_PRICE_LISTS = '/settings/pricelists',
  SETTINGS_PRICE_LISTS_CREATE = '/settings/pricelists/create',
  SETTINGS_PRICE_LISTS_DETAIL = '/settings/pricelists/:id',

  // Price list items
  SETTINGS_PRICE_LIST_ITEMS = '/settings/pricelistitems',
  SETTINGS_PRICE_LIST_ITEMS_CREATE = '/settings/pricelistitems/create',
  SETTINGS_PRICE_LIST_ITEMS_DETAIL = '/settings/pricelistitems/:id',

  // Number series
  SETTINGS_NUMBER_SERIES = '/settings/numberseries',

  // My company
  SETTINGS_MY_COMPANY = '/settings/mycompany',

  // Accommodation offers
  ACCOMMODATION_OFFERS = '/accommodationoffers',
  ACCOMMODATION_OFFERS_CREATE = '/accommodationoffers/create',
  ACCOMMODATION_OFFERS_COPY = '/accommodationoffers/copy/:id',
  ACCOMMODATION_OFFERS_DETAIL = '/accommodationoffers/:id',

  // AccommodationOfferRooms
  SETTINGS_ACCOMMODATIONOFFERROOMS = '/settings/accommodationofferrooms',
  SETTINGS_ACCOMMODATIONOFFERROOMS_CREATE = '/settings/accommodationofferrooms/create',
  SETTINGS_ACCOMMODATIONOFFERROOMS_DETAIL = '/settings/accommodationofferrooms/:id',

  // AccommodationOfferCalculations
  ACCOMMODATION_OFFER_CALCULATIONS = '/accommodationoffercalculations',
  ACCOMMODATION_OFFER_CALCULATIONS_CREATE = '/accommodationoffercalculations/create',
  ACCOMMODATION_OFFER_CALCULATIONS_DETAIL = '/accommodationoffercalculations/:id',

  // AccommodationOfferFees
  ACCOMMODATION_OFFER_FEES = '/accommodationofferfees',
  ACCOMMODATION_OFFER_FEES_CREATE = '/accommodationofferfees/create',
  ACCOMMODATION_OFFER_FEES_DETAIL = '/accommodationofferfees/:id',

  // ReservationRooms
  RESERVATION_ROOMS = '/reservationrooms',
  RESERVATION_ROOMS_CREATE = '/reservationrooms/create',
  RESERVATION_ROOMS_DETAIL = '/reservationrooms/:id',

  // AccommodationOfferRooms
  ACCOMMODATION_OFFER_ROOMS = '/accommodationofferrooms',
  ACCOMMODATION_OFFER_ROOMS_CREATE = '/accommodationofferrooms/create',
  ACCOMMODATION_OFFER_ROOMS_DETAIL = '/accommodationofferrooms/:id',

  // ReservationGuestCheckouts
  RESERVATION_GUEST_CHECKOUTS = '/reservationguestcheckouts',
  RESERVATION_GUEST_CHECKOUTS_CREATE = '/reservationguestcheckouts/create',
  RESERVATION_GUEST_CHECKOUTS_DETAIL = '/reservationguestcheckouts/:id',

  // ReservationGuestStorno
  RESERVATION_GUEST_STORNO = '/reservationgueststorno',
  RESERVATION_GUEST_STORNO_CREATE = '/reservationgueststorno/create',
  RESERVATION_GUEST_STORNO_DETAIL = '/reservationgueststorno/:id',

  // Payments
  PAYMENTS = '/payments',
  PAYMENTS_CREATE = '/payments/create',
  PAYMENTS_DETAIL = '/payments/:id',

  // Vats
  SETTINGS_VATS = '/settings/vats',
  SETTINGS_VATS_CREATE = '/settings/vats/create',
  SETTINGS_VATS_DETAIL = '/settings/vats/:id',

  // AccommodationOffersEvidenceList
  ACCOMMODATION_OFFERS_EVIDENCE_LIST = '/accommodationoffersevidencelist',
  ACCOMMODATION_OFFERS_EVIDENCE_LIST_CREATE = '/accommodationoffersevidencelist/create',
  ACCOMMODATION_OFFERS_EVIDENCE_LIST_DETAIL = '/accommodationoffersevidencelist/:id',

  // Accommodation offers
  ACCOMMODATED_GUESTS = '/accommodatedguests',

  // Logs
  SETTINGS_LOGS = '/settings/logs',

  /*routes*/
  REPORTS = '/reports',

  SINGIN = '/signin',
  SINGGUP = '/signup',
  SINGOUT = '/signout'
}
