import { AxiosInstance } from 'axios';

import { ExtendedApiModelClient, BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { AccommodationOfferModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class AccommodationOfferClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<AccommodationOfferModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'accommodationoffer');
  }
  
  public async getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    throw new Error('Method not implemented.');
  }
 
  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<AccommodationOfferModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<AccommodationOfferModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAsync(id: number): Promise<BaseDataResponse<AccommodationOfferModel>> {
    return await this.getHttpAsync<BaseDataResponse<AccommodationOfferModel>>(`${id}`, '');
  }

  public async getAllAsync(): Promise<BaseDataResponse<AccommodationOfferModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<AccommodationOfferModel[]>>('', '');
  }

  public async createAsync(model: AccommodationOfferModel): Promise<BaseDataResponse<AccommodationOfferModel>> {
    return await this.postHttpAsync<BaseDataResponse<AccommodationOfferModel>, AccommodationOfferModel>('', model);
  }

  public async updateAsync(model: AccommodationOfferModel): Promise<BaseDataResponse<AccommodationOfferModel>> {
    return await this.putHttpAsync<BaseDataResponse<AccommodationOfferModel>, AccommodationOfferModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
