import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import PriceListDetail, { PriceListDetailStateProps, PriceListDetailDispatchProps, PriceListDetailProps } from './priceListsDetail';
import * as actions from './actions';
import { PriceListModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectAccommodationsSelectItems } from '../accommodations';

type PriceListEditContainerStateProps = {} & PriceListDetailStateProps;

type PriceListEditContainerDispatchProps = {} & PriceListDetailDispatchProps;

type PriceListEditContainerProps = {} & PriceListDetailProps & PriceListEditContainerStateProps & PriceListEditContainerDispatchProps;

const PriceListEditContainer: React.FunctionComponent<PriceListEditContainerProps> = memo(props => {
    console.debug('PriceListEditContainer');

    const {
        errorMessage,
        requestInProgress,
        initialValues,
        modalDialog,
        modalDialogRoute,
        accommodationsSelectItems,
        /*roomTypes,        
        selectRoomTypesSelectItemsByAccomodationId,*/
        saveActionRequest,
        cancelActionRequest,      
        loadDependenciesRequest
    } = props;

    return (
        <PriceListDetail
           initialValues={initialValues}
            loadDependenciesRequest={loadDependenciesRequest}
            saveActionRequest={saveActionRequest}
            cancelActionRequest={cancelActionRequest}
            errorMessage={errorMessage}
            requestInProgress={requestInProgress}
            modalDialog={modalDialog}
            modalDialogRoute={modalDialogRoute}
            accommodationsSelectItems={accommodationsSelectItems}
            /*roomTypes={roomTypes}
            selectRoomTypesSelectItemsByAccomodationId={selectRoomTypesSelectItemsByAccomodationId}*/
        />
    );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): PriceListEditContainerStateProps => {
    return {
        initialValues: state.priceLists.itemList.byId[ownProps.match.params.id],
        errorMessage: state.priceLists.errorMessage,
        requestInProgress: state.priceLists.requestInProgress,
        accommodationsSelectItems: selectAccommodationsSelectItems(state),
        /*roomTypes: state.roomTypes.itemList.byId*/
    };
};

const mapDispatchToProps = (dispatch: Dispatch): PriceListEditContainerDispatchProps => {
    return {
        ...bindActionCreators(
        {
            saveActionRequest: actions.saveItemAsync.request,
            cancelActionRequest: actions.cancelItemAsync.request,
            loadDependenciesRequest: actions.loadDependenciesAsync.request,
        },
        dispatch
    ),
                /*selectRoomTypesSelectItemsByAccomodationId: selectRoomTypesSelectItemsByAccomodationId*/
                 };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListEditContainer);