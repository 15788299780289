import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import produce from 'immer';
import { DeepReadonly } from 'utility-types';
import { v4 as uuidv4 } from 'uuid';

import * as actions from './actions';
import { ModalDialogManagerState, ModalDialogManagerAction } from './types';

export const initialState: ModalDialogManagerState = {
  modals: [/*  */]
};

const modalsReducer = createReducer<typeof initialState.modals, ModalDialogManagerAction>(initialState.modals)
  .handleAction(actions.openModal, (state, action) => {
    const nextState = produce(state, draft => {
      draft.push(action.payload);
    });

    return nextState;
  })
  .handleAction(actions.closeModal, (state, action) => {
    const nextState = produce(state, draft => {
      const index = draft.findIndex(modal => modal.id === action.payload);
      if (index !== -1) draft.splice(index, 1);
    });

    return nextState;
  })
  .handleAction(actions.closeTopModal, (state, action) => {
    const nextState = produce(state, draft => {
      draft.pop();
    });

    return nextState;
  });

export default combineReducers<ModalDialogManagerState>({
  modals: modalsReducer
});
