import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type FloorsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const FloorsTable: React.FunctionComponent<FloorsTableProps> = memo(props => {
    console.debug('FloorsTable');

    return <TableContainer {...props} dataSourcePath="" />;
});

export default FloorsTable;
