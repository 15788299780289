import {
  createPagination,
  createGetItemAsyncAction,
  createGetItemsAsyncAction,
  createNewItemAsyncAction,
  createEditItemAsyncAction,
  createSaveItemAsyncAction,
  createCancelItemAsyncAction,
  createDeleteItemAsyncAction,
  createLoadDependenciesAsyncAction,
  createSelection,
  createCopyItemAsyncAction
} from '../../core';

import { AccommodationOfferModel } from '../../api/interfaces';
import { ColumnValueChangedEvent } from '../../components/table';
import { createAction } from 'typesafe-actions';

const modelName = 'accommodationOffer';

export const getItemsAsync = createGetItemsAsyncAction<AccommodationOfferModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<AccommodationOfferModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const getItemAsync = createGetItemAsyncAction<AccommodationOfferModel>(modelName);

export const columnValueChangedAction = createAction(`${modelName}/COLUMN_VALUE_CHANGED`)<ColumnValueChangedEvent>();

export const loadTableDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const setPagination = createPagination(modelName);

export const setSelection = createSelection(modelName);

export const copyItemAsync = createCopyItemAsyncAction(modelName);

export const setDefaults = createAction(`${modelName}/SET_DEFAULTS`)<void>();
