import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import AccommodationOffersTable, { AccommodationOffersTableProps } from './accommodationOffersTable';
import * as actions from './actions';
import axios from 'axios';

import { AccommodationModel, AccommodationOfferModel, AccommodationOfferStateEnum, CompanyModel, ReservationPaymentStatusEnum } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  BaseListContainer,
  createRelation,
  TablePaginationModel,
  actionsColumn,
  mergeWithIdColumn,
  CalendarButton,
  IconButton,
} from '../../components';
import { IconName, Icon } from '../../icons';
import { NormalizedListById } from '../../core';
import { default as AccommodationOffersFilterContainer } from './accommodationOffersFilterContainer';
import { default as AccommodationOffersSelectionHeaderContainer } from './accommodationOffersSelectionHeaderContainer';

import { getAccommodationsById } from '../accommodations';
import { getCompanyById } from '../companies';
import { getAccommodationOffersPagination, getAccommodationOffersSelection, getAccommodationOfferStateSelectItems } from '.';

import moment from 'moment';
import { ROUTES } from '../../navigation';
import { ReservationGuestCheckOutContainer } from '../reservations';
import { PaymentsDetail } from '../payments';
import { Checkbox } from '@material-ui/core';
import { TableSelectionModel } from '../../components/table';
import SelectionButton from './selectionButton';
import apiConfig from '../../configuration/api';
import { RouteComponentProps } from 'react-router';

const downloadFile = (id: number): void => {
  const axiosInstance = axios.create({
    baseURL: apiConfig.baseUrl,
    timeout: 300000,
    withCredentials: true,
    headers: {

    },
    validateStatus: (status: number) => status >= 200 && status < 300
  });

  axiosInstance.get(`accommodationoffer/${id}/report`, { responseType: 'blob' })
    .then(response => {
      let fileName = response.headers["content-disposition"].split("filename=")[1].split(';')[0];
      fileName = fileName.replace(/^"|"$/g, '');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
}

type AccommodationOffersContainerOwnProps = { filter?: string | undefined; hideMenu?: boolean };

type AccommodationOffersContainerStateProps = {
  accommodations: NormalizedListById<AccommodationModel>;
  companies: NormalizedListById<CompanyModel>;
  pagination: TablePaginationModel;
  selection: TableSelectionModel;
  filter: string;
  hideMenu: boolean;
} & BaseListStateProps<AccommodationOfferModel>;

type AccommodationOffersContainerDispatchProps = {
  setPagination: typeof actions.setPagination;
} & BaseListDispatchProps<AccommodationOfferModel>
  & BaseListContainerDispatchProps<AccommodationOfferModel>;

type AccommodationOffersContainerProps = {} & AccommodationOffersContainerStateProps & AccommodationOffersContainerDispatchProps;

const columns = (copyItem?: typeof actions.copyItemAsync.request) => mergeWithIdColumn<AccommodationOfferModel>([
  {
    id: 'state', label: 'Číslo', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodationOfferModel): any => {
      let result = "Neznámé číslo";

      switch (row.state) {
        case AccommodationOfferStateEnum.Offer:
          result = `${row.offerNumber}`;
          break;
        case AccommodationOfferStateEnum.Reservation:
          result = `${row.reservation?.reservationNumber}`;
          break;
      }

      return result;
    }
  },
  {
    id: 'from', label: 'Od', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodationOfferModel): any => { return moment(row.from).format('DD. MM. yyyy') }
  },
  {
    id: 'to', label: 'Do', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodationOfferModel): any => { return moment(row.to).format('DD. MM. yyyy') }
  },
  {
    id: 'companyId',
    label: 'Firma',
    minWidth: 140,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<AccommodationOfferModel, CompanyModel>("companyId", "name")
  },
  {
    id: 'state', label: 'Typ', minWidth: 150, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodationOfferModel): any => {
      let result = "Nenastaveno";
      const translations = Object.fromEntries(getAccommodationOfferStateSelectItems().map(t => [t.value, t.text]));

      switch (row.state) {
        case AccommodationOfferStateEnum.Offer:
          result = translations[AccommodationOfferStateEnum.Offer];
          break;
        case AccommodationOfferStateEnum.Reservation:
          result = translations[AccommodationOfferStateEnum.Reservation];
          break;
      }

      return result;
    }
  },
  {
    id: 'accommodationId',
    label: 'Ubytovací zařízení',
    minWidth: 140,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<AccommodationOfferModel, AccommodationModel>("accommodationId", "name")
  },
  {
    id: 'paymentStatus', label: 'Stav', minWidth: 150, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodationOfferModel): any => {
      let result: IconName;
      let color = "#000";
      let text = "";
      switch (row.paymentStatus) {
        case ReservationPaymentStatusEnum.No:
          result = "wallet";
          color = "#bbb";
          text = "Nezaplaceno";
          break;
        case ReservationPaymentStatusEnum.NotPaidTotally:
          result = "wallet-half";
          text = "Zaplaceno částečně";
          break;
        case ReservationPaymentStatusEnum.OverPaid:
          result = "wallet-full";
          text = "Přeplaceno";
          break;
        case ReservationPaymentStatusEnum.Paid:
          result = "wallet";
          text = "Zaplaceno";
          break;
      }

      return <Icon toolTip={text} iconName={result} IconProps={{ style: { color: color } }} />;
    }
  },
  {
    ...actionsColumn(), actionComponent: (id: number, row: AccommodationOfferModel, classes): any => {
      if (row.state === AccommodationOfferStateEnum.Reservation) {
        return <React.Fragment>
          <IconButton
            onClick={(): void => {
              if (copyItem) {
                copyItem(id);
              }
            }}
            title={'Kopie'}
            icon={'copy'} />
          <CalendarButton createComponent={{
            component: ReservationGuestCheckOutContainer,
            route: ROUTES.RESERVATION_GUEST_CHECKOUTS,
            componentProps: { id: id }
          }}
            onClick={(): void => { console.log(`EDIT: ${id}`) }} />
          <IconButton createComponent={{
            component: PaymentsDetail,
            route: ROUTES.PAYMENTS,
            componentProps: { id: id }
          }}
            onClick={(): void => { console.log(`EDIT: ${id}`); }}
            title={'Zaplatit'}
            icon={'wallet'} />
          <IconButton
            onClick={(): void => {
              downloadFile(id);
            }}
            title={'Tisk'}
            icon={'print'} />
          <SelectionButton id={row.id} name={row.reservation?.reservationNumber} />
        </React.Fragment>
      } else {
        return <React.Fragment>
          <IconButton
            onClick={(): void => {
              if (copyItem) {
                copyItem(id);
              }
            }}
            title={'Kopie'}
            icon={'copy'} />
          <IconButton
            onClick={(): void => {
              downloadFile(id);
            }}
            title={'Tisk'}
            icon={'print'} />
        </React.Fragment>
      }

    }
  }
]);

const AccommodationOffersContainer: React.FunctionComponent<AccommodationOffersContainerProps> = props => {
  console.debug('AccommodationOffersContainer');
  const attributes = {} as AccommodationOffersTableProps;
  if (!props.hideMenu) {
    attributes.filter = { component: AccommodationOffersFilterContainer }
    attributes.selectionHeader = { component: AccommodationOffersSelectionHeaderContainer }
  }

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
      hideMenu={props.hideMenu}
    >
      <AccommodationOffersTable
        {...attributes}
        rows={props.items}
        rowNoClick={true}
        paging={true}
        pagination={props.pagination}
        selection={props.selection}
        columns={columns(props.copyItemRequest) as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        loadAction={props.getItemsRequest}
        relations={{ accommodationId: { items: props.accommodations }, companyId: { items: props.companies } }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState, ownProps: AccommodationOffersContainerOwnProps): AccommodationOffersContainerStateProps => {
  return {
    items: state.accommodationOffers.itemList.allIds.map<AccommodationOfferModel>(id => {
      return state.accommodationOffers.itemList.byId[id];
    }),
    requestInProgress: state.accommodationOffers.requestInProgress,
    accommodations: getAccommodationsById(state),
    companies: getCompanyById(state),
    pagination: getAccommodationOffersPagination(state),
    selection: getAccommodationOffersSelection(state),
    filter: ownProps.filter ?? "",
    hideMenu: ownProps.hideMenu ?? false
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOffersContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      copyItemRequest: actions.copyItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadTableDependenciesAsync.request,
      setPagination: actions.setPagination
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOffersContainer);
