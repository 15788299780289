import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import apiClient from '../../api/apiClientInstance';
import { AccommodationModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest } from '../../core';

// use them in parallel
export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getAccommodationsRequest),
    yield takeEvery(newItemAsync.request, newAccommodationRequest),
    yield takeEvery(saveItemAsync.request, saveAccommodationRequest),
    yield takeEvery(cancelItemAsync.request, cancelAccommodationRequest),
    yield takeEvery(editItemAsync.request, editAccommodationRequest),
    yield takeEvery(deleteItemAsync.request, deleteAccommodationRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type GetAccommodationRequestGeneratorType =
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<AccommodationModel[]>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getAccommodationsRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetAccommodationRequestGeneratorType, void, boolean & BaseDataResponse<AccommodationModel[]>> {
  try {
    console.debug('getAccommodationsRequest');

    const payload = action.payload as GetAllRequestAsyncActionPayload;
    const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
    if (!isReloadRequired) {
      yield put(getItemsAsync.cancel());
      return;
    }

    const response: BaseDataResponse<AccommodationModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.Accommodations,
      apiCallFnName: 'getAllAsync'
    });

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type NewAccommodationRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newAccommodationRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewAccommodationRequestGeneratorType, void, void> {
  try {
    console.debug('newAccommodationsRequest');

    yield put(navigateTo(ROUTES.SETTINGS_ACCOMMODATIONS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditAccommodationRequestGeneratorType =
  | Promise<BaseDataResponse<AccommodationModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editAccommodationRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditAccommodationRequestGeneratorType, void, BaseDataResponse<AccommodationModel>> {
  try {
    console.debug('editAccommodationRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_ACCOMMODATIONS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteAccommodationRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.Accommodations,
  ROUTES.SETTINGS_ACCOMMODATIONS
);

const saveAccommodationRequest = registerSaveEntityRequest<AccommodationModel>(
  saveItemAsync.success,
  saveItemAsync.failure,
  apiClient.Accommodations,
  ROUTES.SETTINGS_ACCOMMODATIONS
);

const cancelAccommodationRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_ACCOMMODATIONS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
