import { combineReducers } from 'redux';

import { RoomsState } from './types';
import * as actions from './actions';
import { createBaseListReducer, createBaseErrorMessageReducer, createBaseRequestInProgressReducer, BaseActions } from '../../core';
import { RoomModel } from '../../api/interfaces';

export const initialState: RoomsState = {
    itemList: {
        allIds: [],
        byId: {},
        loaded: false
    },
    errorMessage: '',
    requestInProgress: false
};

const baseActions = {
    getItemsAsync: actions.getItemsAsync,
    saveItemAsync: actions.saveItemAsync,
    deleteItemAsync: actions.deleteItemAsync,
    editItemAsync: actions.editItemAsync
} as BaseActions<RoomModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<RoomsState>({
    itemList: itemListReducer,
    requestInProgress: requestInProgressReducer,
    errorMessage: errorMessageReducer
});
