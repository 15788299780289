import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type RoomsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const RoomsTable: React.FunctionComponent<RoomsTableProps> = memo(props => {
    console.debug('RoomsTable');

    return <TableContainer {...props} dataSourcePath="" />;
});

export default RoomsTable;
