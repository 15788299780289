import React from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import { FormikInput } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import * as actions from './actions';
import { TablePageChangeModel } from '../../components/table';

const nameof = nameofFactory<GuestsFilterModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '30px'
    }
  })
);

type GuestsFilterModel = {
  name: string;
  surname: string;
  passportNumber: string;
  identityCardNumber: string;
};

type GuestsFilterStateProps = {
};

type GuestsFilterDispatchProps = {
  getItems: typeof actions.getItemsAsync.request;
};

type GuestsFilterProps = {
  onClose(): void;
} & GuestsFilterStateProps & GuestsFilterDispatchProps;

const GuestsFilter: React.FunctionComponent<GuestsFilterProps> = props => {
  console.debug('GuestsFilter');
  const classes = useStyles();

  const { getItems } = props;
  return (
    <React.Fragment>
      <Formik<GuestsFilterModel>
        initialValues={{ name: '', surname: '', passportNumber: '', identityCardNumber: '', }}
        onSubmit={(values: GuestsFilterModel): void => {
          console.debug(values);
          let filter = "";
          if (values.name.length > 0) {
            filter += `name=${values.name}%26`
          }
          if (values.surname.length > 0) {
            filter += `surname=${values.surname}%26`
          }
          if (values.passportNumber.length > 0) {
            filter += `passportNumber=${values.surname}%26`
          }
          if (values.identityCardNumber.length > 0) {
            filter += `identityCardNumber=${values.surname}%26`
          }

          getItems({ page: 0, filter: filter, skip: 0 } as TablePageChangeModel);
          props.onClose();
        }}
      >
        {(formProps): React.ReactElement => {
          const { handleSubmit, resetForm, submitForm } = formProps;
          return (
            <form style={{ width: '500px' }} onSubmit={handleSubmit} autoComplete="off">
              <Grid container className={classes.grid} spacing={3}>
                <Grid container item>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="Jméno"
                      name={nameof('name')}
                      InputProps={{
                      }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="Příjmení"
                      name={nameof('surname')}
                      InputProps={{
                      }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="Číslo občanského průkazu"
                      name={nameof('identityCardNumber')}
                      InputProps={{
                      }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="Číslo pasu"
                      name={nameof('passportNumber')}
                      InputProps={{
                      }} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={(): void => {
                      resetForm();
                      submitForm();
                    }}>
                      Odstranit vše
                    </Button>&nbsp;
                    <Button color="primary" type="submit" variant="contained">
                      Hledat
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>);
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): GuestsFilterStateProps => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch): GuestsFilterDispatchProps =>
  bindActionCreators({
    getItems: actions.getItemsAsync.request
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GuestsFilter);
