import { createAction, createAsyncAction } from 'typesafe-actions';

import { VirtualValueModel } from '.';
import { createLoadDependenciesAsyncAction } from '../../core';
import { VirtualModel, VirtualQueryModel, VirtualSearchModel, VirtualScrollModel } from './types';

const modelName = 'virtual';

export const setVirtualValue = createAction(`${modelName}/SET_VIRTUAL_VALUE`)<VirtualValueModel>();

export const setVirtualSearch = createAction(`${modelName}/SET_VIRTUAL_SEARCH`)<VirtualSearchModel>();

export const setVirtualScroll = createAction(`${modelName}/SET_VIRTUAL_LOAD`)<VirtualScrollModel>();

export const getVirtualData = createAsyncAction(
  'virtual/GET_DATA_BEGIN',
  'virtual/GET_DATA_END',
  'virtual/GET_DATA_ERROR'
)<VirtualQueryModel, VirtualModel, Error>();

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);
