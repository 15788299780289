import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ActionType } from "typesafe-actions";

import { NumberSeriesState } from './types';
import { saveNumberSeriesAsync, getNumberSeriesAsync, setUniqueReservationNumberAction, setUniqueOfferNumberAction } from './actions';
import { NumberSeriesModel } from '../../api/interfaces';
import produce from 'immer';

export const initialState: NumberSeriesState = {
  errorMessage: '',
  requestInProgress: false,
  current: {
    id: 0,
    accommodation: { prefix: ' ', id: 0, lastNumber: 0 } as NumberSeriesModel,
    reservation: { prefix: ' ', id: 0, lastNumber: 0 } as NumberSeriesModel,
    invoicing: { prefix: ' ', id: 0, lastNumber: 0 } as NumberSeriesModel
  },
  loaded: false,
  uniqueOfferNumber: '',
  uniqueReservationNumber: ''
};


const extendedActions = {
  saveNumberLineAsync: saveNumberSeriesAsync,
  getNumberSeriesAsync
}

const numberSeriesReducer = createReducer<typeof initialState.current, ActionType<typeof extendedActions>>(initialState.current)
  .handleAction(saveNumberSeriesAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.accommodation = action.payload.accommodation;
      draft.reservation = action.payload.reservation;
      draft.invoicing = action.payload.invoicing;

      return draft;
    });

    return newState;
  }).handleAction(getNumberSeriesAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.accommodation = action.payload.accommodation;
      draft.reservation = action.payload.reservation;
      draft.invoicing = action.payload.invoicing;

      return draft;
    });

    return newState;
  });

const loadedReducer = createReducer<typeof initialState.loaded, ActionType<typeof getNumberSeriesAsync>>(initialState.loaded)
  .handleAction(getNumberSeriesAsync.success, (state, action) => {
    return initialState.loaded;
  });

const errorMessageReducer = createReducer<typeof initialState.errorMessage, ActionType<typeof saveNumberSeriesAsync>>(initialState.errorMessage)
  .handleAction(saveNumberSeriesAsync.success, (state, action) => {
    return initialState.errorMessage;
  });

const requestInProgressReducer = createReducer<typeof initialState.requestInProgress, ActionType<typeof saveNumberSeriesAsync>>(initialState.requestInProgress)
  .handleAction(saveNumberSeriesAsync.request, (state, action) => true)
  .handleAction(saveNumberSeriesAsync.success, (state, action) => {
    return initialState.requestInProgress;
  });

const uniqueOfferNumberReducer = createReducer<typeof initialState.uniqueOfferNumber, ActionType<typeof setUniqueOfferNumberAction>>(initialState.uniqueOfferNumber)
  .handleAction(setUniqueOfferNumberAction, (state, action) => {
    return action.payload;
  });


const uniqueReservationNumberReducer = createReducer<typeof initialState.uniqueReservationNumber, ActionType<typeof setUniqueReservationNumberAction>>(initialState.uniqueReservationNumber)
  .handleAction(setUniqueReservationNumberAction, (state, action) => {
    return action.payload;
  });

export default combineReducers<NumberSeriesState>({
  current: numberSeriesReducer,
  loaded: loadedReducer,
  errorMessage: errorMessageReducer,
  requestInProgress: requestInProgressReducer,
  uniqueOfferNumber: uniqueOfferNumberReducer,
  uniqueReservationNumber: uniqueReservationNumberReducer
});
