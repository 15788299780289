import React from 'react';
import { Typography, Box, LinearProgress } from '@material-ui/core';
import { LinearProgressWithLabelProps } from "./types";

const LinearProgressWithLabel: React.FunctionComponent<LinearProgressWithLabelProps> = (props): React.ReactElement => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: '1 auto', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ textAlign: 'right', flex: '0 auto' }}>
        {props.label ?? <Typography variant="body2" color="textSecondary">{props.label}</Typography>}
        {!props.label ??<Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>}
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;