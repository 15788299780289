import React, { memo, useRef, RefObject } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  FormikDatePicker,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikObserver,
  FileUpload,
  FileProps,
  AcceptedFileType
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { myCompanyValidationSchema } from './validations';
import { MyCompanyModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';
import { StatesCreateContainer } from '../states';
import { getCompanyInfoAsync } from '../ares';
import ImageContainer from './imageContainer';

export type MyCompanyDetailStateProps = {
  stateSelectItems: DeepReadonly<RelationSelectItem[]>;
  loaded: boolean;
} & BaseDetailStateProps<MyCompanyModel>;

export type MyCompanyDetailDispatchProps = {
  getCompanyInfoRequest: typeof getCompanyInfoAsync.request;
} & BaseDetailDispatchProps<MyCompanyModel>;

export type MyCompanyDetailProps = {}
  & BaseDetailProps<MyCompanyModel>
  & MyCompanyDetailStateProps & MyCompanyDetailDispatchProps;

type RenderFormProps = {
  stateSelectItems: DeepReadonly<RelationSelectItem[]>;
  loaded: boolean;
  getCompanyInfoRequest: typeof getCompanyInfoAsync.request;
} & BaseDetailContainerWrappedComponentProps<MyCompanyModel>
  & {
    onSubmit: () => void;
    refUploadFile: RefObject<FileUpload>;
  };

const nameof = nameofFactory<MyCompanyModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues, stateSelectItems, loaded, refUploadFile } = props;

  return (
    <Formik<MyCompanyModel>
      initialValues={initialValues}
      onSubmit={(values: MyCompanyModel, { setSubmitting }): void => {
        console.log('Submiting');
        props.onSubmit();
        setSubmitting(true);
        props.saveActionRequest(values);
      }}
      enableReinitialize={true}
      validationSchema={myCompanyValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormikObserver<MyCompanyModel> values={formProps.values} onChange={(values, prevValues): void => {
              if (values.ico !== prevValues.ico
                && values.ico?.trim().replace(/\s/g, "").length === 8) {
                props.getCompanyInfoRequest(values.ico.replace(/\s/g, "").trim());
              }
            }} />
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  <Grid container item spacing={2}>

                    <Grid item xs={12}>
                      <FormikInput
                        iconName="hotel"
                        labelText="Název firmy"
                        name={nameof('name')}
                        errorMessage={errorMessage}
                        InputProps={{
                          autoFocus: true,
                          type: 'string'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikInput iconName="sticky-note" labelText="IČ" name={nameof('ico')} errorMessage={errorMessage} InputProps={{
                        autoFocus: true,
                        type: 'string'
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikInput
                        iconName="user"
                        labelText="DIČ"
                        name={nameof('vat')}
                        errorMessage={errorMessage}
                        InputProps={{
                          autoFocus: true,
                          type: 'string',
                        }} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikInput
                        iconName="address-card"
                        labelText="Ulice"
                        name={nameof('street')}
                        errorMessage={errorMessage}
                        InputProps={{
                          autoFocus: true,
                          type: 'string'
                        }} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container item spacing={2} style={{ textAlign: 'center' }}>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImageContainer url="/mycompany/downloadlogo" />
                    </Grid>
                    <Grid item xs={12}>
                      <FileUpload fileType={[AcceptedFileType.AllImages]} ref={refUploadFile} path="/mycompany/uploadlogo" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <FormikInput
                    iconName="address-card"
                    labelText="Město"
                    name={nameof('city')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'string'
                    }} />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput
                    iconName="address-card"
                    labelText="PSČ"
                    name={nameof('zip')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'string'
                    }} />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Stát"
                    name={nameof('stateId')}
                    errorMessage={errorMessage}
                    items={stateSelectItems as RelationSelectItem[]}
                    createComponent={{ component: StatesCreateContainer, route: ROUTES.SETTINGS_STATES_CREATE }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput iconName="address-card" labelText="E-mail" name={nameof('email')} errorMessage={errorMessage} InputProps={{ autoFocus: true }} />
                </Grid>
                <Grid item xs={6}>
                  <FormikInput iconName="address-card" labelText="Telefon" name={nameof('phone')} errorMessage={errorMessage} InputProps={{ autoFocus: true }} />
                </Grid>
                <Grid item xs={8}>
                  <FormikInput iconName="address-card" labelText="Číslo bankovního účtu" name={nameof('bankAccountNumber')} errorMessage={errorMessage} InputProps={{ autoFocus: true }} />
                </Grid>
                <Grid item xs={4}>
                  <FormikInput iconName="address-card" labelText="Banka" name={nameof('bankCode')} errorMessage={errorMessage} InputProps={{ autoFocus: true }} />
                </Grid>
                <Grid item xs={4}>
                  <FormikInput iconName="address-card" labelText="Konstantní symbol" name={nameof('constantSymbol')} errorMessage={errorMessage} InputProps={{ autoFocus: true }} />
                </Grid>
                <Grid item xs={4}>
                  <FormikInput iconName="address-card" labelText="Specifický symbol" name={nameof('specificSymbol')} errorMessage={errorMessage} InputProps={{ autoFocus: true }} />
                </Grid>
                <Grid item xs={4}>
                  <FormikInput iconName="address-card" labelText="Splatnost (dní)" name={nameof('numberOfDueDays')} errorMessage={errorMessage} InputProps={{ autoFocus: true, type: 'number' }} />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>
              </Grid>
            </Grid>
          </form>);
      }}
    </Formik>
  );
};

const MyCompanyDetail: React.FunctionComponent<MyCompanyDetailProps> = memo(props => {
  console.debug('MyCompanyDetail');
  const refUploadFile = useRef<FileUpload>(null);

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    stateSelectItems,
    getCompanyInfoRequest,
    loaded
  } = props;

  const onSubmit = (): void => {
    const uploadFileComponent = refUploadFile.current;
    if (uploadFileComponent !== undefined) {
      uploadFileComponent?.uploadFile();
    }
  }

  return (
    <BaseDetailContainer<MyCompanyModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        stateSelectItems: stateSelectItems,
        loaded: loaded,
        getCompanyInfoRequest: getCompanyInfoRequest,
        onSubmit,
        refUploadFile
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default MyCompanyDetail;
