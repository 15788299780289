import {
  createGetItemsAsyncAction,
  createNewItemAsyncAction,
  createEditItemAsyncAction,
  createSaveItemAsyncAction,
  createCancelItemAsyncAction,
  createDeleteItemAsyncAction,
  createLoadDependenciesAsyncAction,
  createPagination,
  GetAllRequestAsyncActionPayload,
  createGetAllItemsAsyncAction,
} from '../../core';

import { CompanyModel } from '../../api/interfaces';
import { createAsyncAction } from 'typesafe-actions';

const modelName = 'company';

export const getItemsAsync = createGetItemsAsyncAction<CompanyModel>(modelName);

export const getAllItemsAsync = createGetAllItemsAsyncAction<CompanyModel>(modelName);

/*createAsyncAction<'GET_ALL_COMPANIES_REQUEST', 'GET_ALL_COMPANIES_SUCCESS', 'GET_ALL_COMPANIES_FAILURE', 'GET_ALL_COMPANIES_CANCEL'>(
  `${modelName}/GET_ALL_COMPANIES_REQUEST` as 'GET_ALL_COMPANIES_REQUEST',
  `${modelName}/GET_ALL_COMPANIES_SUCCESS` as 'GET_ALL_COMPANIES_SUCCESS',
  `${modelName}/GET_ALL_COMPANIES_FAILURE` as 'GET_ALL_COMPANIES_FAILURE',
  `${modelName}/GET_ALL_COMPANIES_CANCEL` as 'GET_ALL_COMPANIES_CANCEL',
)<GetAllRequestAsyncActionPayload | void, CompanyModel[], Error, void>();*/

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<CompanyModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const setPagination = createPagination(modelName);