import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SignOutContainer } from '../signOut';
import { ROUTES } from '../../navigation';
import { TimeLineContainer } from '../timeLine';
import { Settings } from '../settings';
import {
  AccommodationOffersContainer,
  AccommodationOffersCreateContainer,
  AccommodationOffersCreateCopyContainer,
  AccommodationOffersEditContainer
} from '../accommodationOffers';
import {
  ReservationGuestCheckOutCreateContainer,
  ReservationGuestCheckOutEditContainer,
  ReservationGuestStornoCreateContainer,
} from '../reservations';

import {
  PaymentsContainer
} from '../payments';


import {
  AccommodationOffersEvidenceListCreateContainer
} from '../accommodationOffersEvidenceList';

import { AccommodatedGuestsContainer } from '../accommodatedGuests';
type AuthenticatedAppRouterProps = {};

const AuthenticatedAppRouter: React.FunctionComponent<AuthenticatedAppRouterProps> = (): React.ReactElement => {
  console.debug('AuthenticatedAppRouter');

  return (
    <Switch>
      <Route exact path={ROUTES.ROOT}>
        <Redirect to={ROUTES.TIME_LINE} />;
      </Route>
      <Route exact path={ROUTES.TIME_LINE}>
        <TimeLineContainer />
      </Route>
      <Route exact path={ROUTES.ACCOMMODATION_OFFERS}>
        <AccommodationOffersContainer />
      </Route>
      <Route exact path={ROUTES.PAYMENTS}>
        <PaymentsContainer />
      </Route>
      <Route exact path={ROUTES.ACCOMMODATED_GUESTS}>
        <AccommodatedGuestsContainer />
      </Route>
      <Route exact path={ROUTES.ACCOMMODATION_OFFERS_CREATE}>
        <AccommodationOffersCreateContainer />
      </Route>
      <Route exact path={ROUTES.ACCOMMODATION_OFFERS_COPY} component={AccommodationOffersCreateCopyContainer} />
      <Route exact path={ROUTES.ACCOMMODATION_OFFERS_DETAIL} component={AccommodationOffersEditContainer} />
      <Route exact path={ROUTES.RESERVATION_GUEST_CHECKOUTS_CREATE}>
        <ReservationGuestCheckOutCreateContainer />
      </Route>
      <Route exact path={ROUTES.RESERVATION_GUEST_CHECKOUTS_DETAIL} component={ReservationGuestCheckOutEditContainer} />
      <Route path={ROUTES.SETTINGS}>
        <Settings />
      </Route>
      <Route exact path={ROUTES.RESERVATION_GUEST_STORNO_CREATE}>
        <ReservationGuestStornoCreateContainer />
      </Route>
      <Route exact path={ROUTES.SINGOUT}>
        <SignOutContainer />
      </Route>
      <Route exact path={ROUTES.ACCOMMODATION_OFFERS_EVIDENCE_LIST_CREATE}>
        <AccommodationOffersEvidenceListCreateContainer />
      </Route>
      <Route
        path="*"
        render={({ location }): React.ReactElement => {
          if (location.pathname === ROUTES.SINGIN && location.state) {
            // Ne vzdy je state, napriklad po prihlaseni
            return <Redirect to={{ pathname: location.state.referer.pathname }} />;
          }

          return <Redirect to={ROUTES.TIME_LINE} />;
        }}
      />
    </Switch>
  );
};

export default AuthenticatedAppRouter;
