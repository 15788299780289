import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Subtract } from 'utility-types';

import * as actions from './actions';
import { BaseComponentWithModalSupport } from '../baseDetail';
import { ROUTES } from '../../navigation';
import { ModalDialogModel } from './types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export type WithOpenModalProps = {
  openModal: (modalComponentToOpen: React.ComponentType<BaseComponentWithModalSupport>, route: ROUTES, modalComponentToOpenProps?: any, fullWidth?: boolean) => void;
};

export type WithModalDispatchProps = {
  openModalAction: typeof actions.openModal;
};

export type WithModalProps = { children: (props: WithOpenModalProps) => React.ReactNode } & WithModalDispatchProps;

const mapDispatchToProps = (dispatch: any): WithModalDispatchProps => {
  return bindActionCreators(
    {
      openModalAction: actions.openModal
    },
    dispatch
  );
};

class WithModal extends React.PureComponent<WithModalProps> {
  openModalHandler = (modalComponentToOpen: React.ComponentType<BaseComponentWithModalSupport>, route: ROUTES, modalComponentToOpenProps?: any, fullWidth?: boolean): void => {
    this.props.openModalAction({ id: uuidv4(), route: route, component: modalComponentToOpen, componentProps: modalComponentToOpenProps, fullWidth: fullWidth } as ModalDialogModel);
  };

  render(): React.ReactNode {
    return this.props.children({ openModal: this.openModalHandler });
  }
}

const WithModalContainer = connect(undefined, mapDispatchToProps)(WithModal);

const withOpenModalHoc = <P extends WithOpenModalProps>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<Subtract<P, WithOpenModalProps>> => {
  return class WithOpenModal extends React.PureComponent<Subtract<P, WithOpenModalProps>> {
    render(): React.ReactNode {
      return (
        <WithModalContainer>
          {(props: WithOpenModalProps): React.ReactNode => {
            return <WrappedComponent {...(this.props as P)} openModal={props.openModal} />;
          }}
        </WithModalContainer>
      );
    }
  };
};

export const withOpenModal = withOpenModalHoc;
