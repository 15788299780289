import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem, TablePaginationModel } from '../../components';
import { NormalizedListById } from '../../core';
import {  } from '../../api/interfaces';
import { AccommodationOffersEvidenceListClient } from '../../api/clients';
import { AccommodationOffersEvidenceListState } from './types';

export const getAccommodationOffersEvidenceListPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.accommodationOffersEvidenceList.itemList.page ?? 0,
    skip: state.accommodationOffersEvidenceList.itemList.skip ?? 0,
    take: state.accommodationOffersEvidenceList.itemList.take ?? 100,
    total: state.accommodationOffersEvidenceList.itemList.total ?? 0,
    filter: state.accommodationOffersEvidenceList.itemList.filter ?? ""
  } as TablePaginationModel;
};

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.accommodationOffersEvidenceList.itemList.loaded;
};

export const getSelectedItems = (state: ApplicationState): Array<number> => {
  const selection = state.accommodationOffers.itemList.selection;
  return selection?.ids || [];
};

export const getState = (state: ApplicationState): AccommodationOffersEvidenceListState => {
  return state.accommodationOffersEvidenceList;
};

export const getSelectedIds = (state: ApplicationState): Array<number> => {
  return state.accommodationOffers.itemList.selection?.ids || [];
};