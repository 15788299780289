import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import VatDetail, { VatDetailStateProps, VatDetailDispatchProps, VatDetailProps } from './vatDetail';
import * as actions from './actions';
import { VatModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';

type VatEditContainerStateProps = {} & VatDetailStateProps;

type VatEditContainerDispatchProps = {} & VatDetailDispatchProps;

type VatEditContainerProps = {} & VatDetailProps & VatEditContainerStateProps & VatEditContainerDispatchProps;

const VatEditContainer: React.FunctionComponent<VatEditContainerProps> = memo(props => {
  console.debug('VatEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <VatDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): VatEditContainerStateProps => {
  return {
    initialValues: state.vats.itemList.byId[ownProps.match.params.id],
    errorMessage: state.vats.errorMessage,
    requestInProgress: state.vats.requestInProgress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): VatEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VatEditContainer);