import React, { RefObject } from 'react';

import { default as ConfirmDialog } from './confirmDialog';

export type ConfirmDialogContainerProps = {
  ref: RefObject<ConfirmDialogContainer>;
  approveAction: (event: React.MouseEvent<unknown>, id: number) => void;
  text: string;
};

type ConfirmDialogContainerState = {
  visible: boolean;
  id: number;
};

class ConfirmDialogContainer extends React.Component<ConfirmDialogContainerProps, ConfirmDialogContainerState> {
  state: ConfirmDialogContainerState = {
    visible: false,
    id: 0
  };

  open = (id: number): void => {
    console.log('open');
    this.setState({ visible: true, id: id });
  }

  onApprove = (event: React.MouseEvent<unknown>): void => {
    console.log('onApprove');
    this.props.approveAction(event, this.state.id);
    this.setState({ visible: false });
  }

  onClose = (event: React.MouseEvent<unknown>): void => {
    console.log('onClose');
    this.setState({ visible: false });
  }

  render(): React.ReactElement {
    console.debug('ConfirmDialogContainer.render');

    return <React.Fragment>
      {this.state.visible && 
      <ConfirmDialog
        visible={this.state.visible}
        approveAction={this.onApprove}
        closeAction={this.onClose}
        text={this.props.text}
      />}
    </React.Fragment>;
  }
}

export default ConfirmDialogContainer;
