import { all, put, takeEvery, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync, getAreAccommodationsLoaded } from '../accommodations';

import apiClient from '../../api/apiClientInstance';
import { PriceListItemModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest } from '../../core';
import { getItemsAsync as loadPriceListsAsync, getArePriceListsLoaded } from '../priceLists';
import { getItemsAsync as loadRoomTypesAsync, getAreRoomTypesLoaded } from '../roomTypes';
import { getItemsAsync as loadVatsAsync, getAreVatsLoaded } from '../vats';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getPriceListItemsRequest),
    yield takeEvery(newItemAsync.request, newPriceListItemRequest),
    yield takeEvery(saveItemAsync.request, savePriceListItemRequest),
    yield takeEvery(cancelItemAsync.request, cancelPriceListItemRequest),
    yield takeEvery(editItemAsync.request, editPriceListItemRequest),
    yield takeEvery(deleteItemAsync.request, deletePriceListItemRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type GetPriceListItemsRequestGeneratorType =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<PriceListItemModel[]>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getPriceListItemsRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetPriceListItemsRequestGeneratorType, void, boolean & BaseDataResponse<PriceListItemModel[]>> {
  try {
    console.debug('getPriceListItemsRequest');

    const payload = action.payload as GetAllRequestAsyncActionPayload;
    const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
    if (!isReloadRequired) {
      yield put(getItemsAsync.cancel());
      return;
    }

    const response: BaseDataResponse<PriceListItemModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.PriceListItems,
      apiCallFnName: 'getAllAsync'
    });

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type NewPriceListItemRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newPriceListItemRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewPriceListItemRequestGeneratorType, void, void> {
  try {
    console.debug('newPriceListItemRequest');

    yield put(navigateTo(ROUTES.SETTINGS_PRICE_LIST_ITEMS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditPriceListItemRequestGeneratorType =
  | Promise<BaseDataResponse<PriceListItemModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editPriceListItemRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditPriceListItemRequestGeneratorType, void, BaseDataResponse<PriceListItemModel>> {
  try {
    console.debug('editPriceListItemRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_PRICE_LIST_ITEMS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deletePriceListItemRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.PriceListItems,
  ROUTES.SETTINGS_PRICE_LIST_ITEMS
);

const savePriceListItemRequest = registerSaveEntityRequest<PriceListItemModel>(
  saveItemAsync.success,
  saveItemAsync.failure,
  apiClient.PriceListItems,
  ROUTES.SETTINGS_PRICE_LIST_ITEMS
);

const cancelPriceListItemRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_PRICE_LIST_ITEMS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadPriceListsAsync.request>>
  | PutEffect<ActionType<typeof loadVatsAsync.request>>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areAccommodationsLoaded = yield select(getAreAccommodationsLoaded);
    if (!areAccommodationsLoaded) {
      yield put(loadAccommodationsAsync.request());
    }

    const arePriceListsLoaded = yield select(getArePriceListsLoaded);
    if (!arePriceListsLoaded) {
      yield put(loadPriceListsAsync.request());
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
    }

    const areVatsLoaded = yield select(getAreVatsLoaded);
    if (!areVatsLoaded) {
      yield put(loadVatsAsync.request());
    }

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
