import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';

import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import ReservationRoomDetail, { ReservationRoomDetailProps, ReservationRoomDetailDispatchProps, ReservationRoomDetailStateProps } from './reservationRoomDetail';

import { selectGuestByCompanyByIdSelectItems } from '../guests';
import { getRoomModel, totalGuestsThatCanBeAdded } from './selectors';

type ReservationRoomEditContainerStateProps = {

} & ReservationRoomDetailStateProps;

type ReservationRoomEditContainerDispatchProps = {
  editRoom: typeof actions.editRoomAction;
} & ReservationRoomDetailDispatchProps;

type ReservationRoomEditContainerProps = {} & ReservationRoomDetailProps & ReservationRoomEditContainerStateProps & ReservationRoomEditContainerDispatchProps;

const ReservationRoomEditContainer: React.FunctionComponent<ReservationRoomEditContainerProps> = memo(props => {
  console.debug('ReservationRoomEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    editRoom,
    guestsSelectItems,
    maxGuests
  } = props;

  return (
    <React.Fragment>
      <ReservationRoomDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        editRoom={editRoom}
        guestsSelectItems={guestsSelectItems}
        maxGuests={maxGuests}
      />
    </React.Fragment>
  );
});


const mapStateToProps = (state: ApplicationState, ownProps: any): ReservationRoomEditContainerStateProps => {
  const roomModel = getRoomModel(state, ownProps.id);
  const maxGuests = totalGuestsThatCanBeAdded(state);

  return {
    initialValues: _.cloneDeep(roomModel),
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    guestsSelectItems: selectGuestByCompanyByIdSelectItems(state),
    maxGuests: maxGuests
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReservationRoomEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveRoomItemAsync.request,
        cancelActionRequest: actions.cancelRoomItemAsync.request,
        loadDependenciesRequest: actions.loadRoomDependenciesAsync.request,
        editRoom: actions.editRoomAction,
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationRoomEditContainer);
