import { all, ForkEffect, AllEffect } from 'redux-saga/effects';

import { virtualSaga } from '../components/virtualSelect';
import asyncActionsSaga from './asyncActionsSaga';
import { signInSaga } from '../features/signIn';
import { signOutSaga } from '../features/signOut';
import { authenticationSaga } from '../features/authentication';
import { downloadFileSaga } from '../features/downloadFile';
import { themeSaga } from '../features/themes';
import { errorsSaga } from '../features/errors';
import { appSaga } from '../features/app';
import { accommodationsSaga } from '../features/accommodations';
import { navigationSagas } from '../navigation';
import { timeLineSaga } from '../features/timeLine';
import { usersSaga } from '../features/users';
import { roomTypesSaga } from '../features/roomTypes';
import { roomsSaga } from '../features/rooms';
import { floorsSaga } from '../features/floors';
import { signUpSaga } from '../features/signUp';
import { guestsSaga } from '../features/guests';
import { statesSaga } from '../features/states';
import { companiesSaga } from '../features/companies';
import { priceListsSaga } from '../features/priceLists';
import { priceListItemsSaga } from '../features/priceListItems';
import { accommodationOffersSaga } from '../features/accommodationOffers';
import { accommodationOfferRoomTypesSaga } from '../features/accommodationOfferRoomTypes';
import { accommodationOfferCalculationsSaga } from '../features/accommodationOfferCalculations';
import { accommodationOfferPriceCalculationsSaga } from '../features/accommodationOfferPriceCalculations';
import { accommodationOfferFeesSaga } from '../features/accommodationOfferFees';
import { reservationsSaga } from '../features/reservations';
import { numberSeriesSaga } from '../features/numberSeries';
import { paymentsSaga } from '../features/payments';
import { vatsSaga } from '../features/vats';
import { myCompanySaga } from '../features/myCompany';
import { accommodationOffersEvidenceListSaga } from '../features/accommodationOffersEvidenceList';
import { accommodatedGuestsSaga } from '../features/accommodatedGuests';
import { logsSaga } from '../features/logs';

/*importSaga*/

export default function* rootSaga(): Generator<Generator<ForkEffect<never> | AllEffect<unknown>> | AllEffect<unknown>> {
  console.warn('rootSaga');

  yield all([
    errorsSaga(),
    asyncActionsSaga(),
    authenticationSaga(),
    signInSaga(),
    signOutSaga(),
    accommodationsSaga(),
    downloadFileSaga(),
    themeSaga(),
    appSaga(),
    navigationSagas(),
    timeLineSaga(),
    usersSaga(),
    roomTypesSaga(),
    roomsSaga(),
    floorsSaga(),
    signUpSaga(),
    guestsSaga(),
    statesSaga(),
    companiesSaga(),
    priceListsSaga(),
    priceListItemsSaga(),
    accommodationOffersSaga(),
    accommodationOfferCalculationsSaga(),
    accommodationOfferPriceCalculationsSaga(),
    accommodationOfferFeesSaga(),
    accommodationOfferRoomTypesSaga(),
    reservationsSaga(),
    numberSeriesSaga(),
    virtualSaga(),
    paymentsSaga(),
    vatsSaga(),
    myCompanySaga(),
    accommodationOffersEvidenceListSaga(),
    accommodatedGuestsSaga(),
    logsSaga()
    /*entitiesSaga*/
  ]);
}
