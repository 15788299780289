import React from 'react';
import { makeStyles, Theme, createStyles, Drawer, List } from '@material-ui/core';

import { VerticalMenuNavLinkItem } from '../../components';
import { SettingsMenuItemsConfiguration } from '../../configuration/mainMenu';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/rootReducer';
import { User } from '../authentication';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => {
  const spaceFromMainMenu = theme.spacing(2);

  return createStyles({
    root: {
      display: 'flex'
    },
    drawerRoot: {
      width: drawerWidth,
      flexShrink: 0,
      '& a': {
        textDecoration: 'none'
      },
      '& a:active': {
        textDecoration: 'none',
        color: theme.palette.text.primary
      }
    },
    drawerPaper: {
      width: drawerWidth,
      top: 'unset'
    },
    drawerPaperAnchorDockedLeft: {
      borderRightWidth: '1px',
      borderRightColor: theme.palette.primary.main,
      borderRightStyle: 'solid'
    },
    dwawerListRoot: {
      paddingTop: spaceFromMainMenu
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3)
    },
    sectionDesktop: {
      display: 'none',
      padding: '0px',
      [theme.breakpoints.up('md')]: {
        display: 'flex'
        /* width: 'fit-content',    */
      },
      '& > a': {
        textDecoration: 'none'
      }
    },
    rightSectionContent: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      paddingTop: spaceFromMainMenu,
      paddingLeft: spaceFromMainMenu,
      paddingRight: spaceFromMainMenu
    }
  });
});

type ContainerWithLeftMenuProps = {
  user: DeepReadonly<User | null>;
};

type ContainerWithLeftMenuDispatchProps = {
};

const ContainerWithLeftMenu: React.FunctionComponent<ContainerWithLeftMenuProps> = props => {
  console.debug('AppWithLeftMenu');
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <section>
          <Drawer
            variant="permanent"
            classes={{
              root: classes.drawerRoot,
              paper: classes.drawerPaper,
              paperAnchorDockedLeft: classes.drawerPaperAnchorDockedLeft
            }}
            anchor="left"
          >
            <List classes={{ root: classes.dwawerListRoot }}>
              {SettingsMenuItemsConfiguration.map((menuItem, index) => {
                if (menuItem.roleId > 0 && props.user) {
                  const roleIdsString = props.user?.roleIds;
                  const roleIdsList = roleIdsString.split(',');
                  const roleIds = roleIdsList.map(s => Number(s));
                  if (!roleIds.includes(menuItem.roleId)) {
                    return;
                  }
                }

                return <VerticalMenuNavLinkItem to={menuItem.to} iconName={menuItem.icon} text={menuItem.label} key={menuItem.to} />
              })}
            </List>
          </Drawer>
        </section>

        <section className={classes.rightSectionContent}>{props.children}</section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): ContainerWithLeftMenuProps => {
  return {
    user: state.authentication.user
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ContainerWithLeftMenuDispatchProps =>
  bindActionCreators({
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContainerWithLeftMenu);