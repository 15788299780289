import { createAsyncAction } from 'typesafe-actions';
import { GrantPasswordRequest } from './types';

export const signInRequestOptAsync = createAsyncAction(
  'signin/SIGN_IN_REQUEST_OTP_REQUEST',
  'signin/SIGN_IN_REQUEST_OTP_SUCCESS',
  'signin/SIGN_IN_REQUEST_OTP_FAILURE'
)<string, string, Error>();

export const signInValidateOptAsync = createAsyncAction(
  'signin/SIGN_IN_VALIDATE_OTP_REQUEST',
  'signin/SIGN_IN_VALIDATE_OTP_SUCCESS',
  'signin/SIGN_IN_VALIDATE_OTP_FAILURE'
)<string, void, Error>();

export const signInAsync = createAsyncAction(
  'signin/SIGN_IN_REQUEST',
  'signin/SIGN_IN_SUCCESS',
  'signin/SIGN_IN_FAILURE'
)<GrantPasswordRequest, void, Error>();

export const signInSilentAsync = createAsyncAction(
  'signin/SIGN_IN_SILENT_REQUEST_OTP_REQUEST',
  'signin/SIGN_IN_SILENT_REQUEST_OTP_SUCCESS',
  'signin/SIGN_IN_SILENT_REQUEST_OTP_FAILURE'
)<GrantPasswordRequest, void, Error>();

