import React from 'react';
import { Button, Dialog, Grid, StyleRules, Theme, Typography, withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const withStylesFunction = withStyles(
  (theme: Theme): StyleRules<ConfirmDialogClassKey> => ({
    root: {
      padding: "5px",
    },
    title: {
      backgroundColor: '#C6A530',
      textTransform: 'uppercase',
      color: '#fff',
      padding: '10px'
    },
    buttons: {
      textAlign: 'right',
      padding: '10px'
    },
    text: {
      padding: '20px'
    }
  }));

export type ConfirmDialogClassKey = | 'root' | 'title' | 'buttons' | 'text';

type ConfirmDialogProps = {
  visible: boolean;
  approveAction: (event: React.MouseEvent<unknown>) => void;
  closeAction: (event: React.MouseEvent<unknown>) => void;
  classes?: Partial<ClassNameMap<ConfirmDialogClassKey>>;
  text: string;
};

class ConfirmDialog extends React.PureComponent<ConfirmDialogProps> {
  render(): React.ReactElement {
    const { classes, text } = this.props;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        aria-labelledby="modal-dialog-title"
        open={true}
        style={{ visibility: this.props.visible ? 'visible' : 'hidden' }}
      >
        <Typography className={classes?.title}>Potvrzovací dialog</Typography>
        <Grid container className={classes?.root}>
          <Grid item xs={12} className={classes?.text}>
            <Typography>{text}</Typography>
          </Grid>
          <Grid item alignItems='center' xs={8}>
            &nbsp;
          </Grid>
          <Grid item className={classes?.buttons} xs={4}>
            <Button style={{ backgroundColor: '#B2942B', color: '#fff' }} variant="contained" title="Ok" onClick={this.props.approveAction}>
              OK
            </Button>
            &nbsp;
            <Button variant="contained" title="Zavřít" onClick={this.props.closeAction}>
              ZAVŘÍT
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withStylesFunction(ConfirmDialog);
