import { combineReducers } from 'redux';

import { VatsState } from './types';
import * as actions from './actions';
import { BaseActions, createBaseListReducer, createBaseErrorMessageReducer, createBaseRequestInProgressReducer } from '../../core';
import { VatModel } from '../../api/interfaces';

export const initialState: VatsState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false
  },
  errorMessage: '',
  requestInProgress: false
};

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync
} as BaseActions<VatModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<VatsState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer
});
