import React, { memo, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Chip, MenuItem, Select } from '@material-ui/core';

import { ApplicationState } from '../../store/rootReducer';
import { AccommodationOfferRoomTypeCalculationModel, PriceListItemModel, PriceListItemType } from '../../api/interfaces';
import { BaseTableColumnId } from '../../components/table';
import moment from 'moment';
import _ from 'lodash';

type RoomTypePriceContainerStateProps = {
  items: PriceListItemModel[];
  defaultValue: number;
  priceListId: number;
  type: PriceListItemType;
  from: Date;
  to: Date;
};

type RoomTypePriceContainerDispatchProps = {
};

type RoomTypePriceContainerProps = {
  roomTypeId: number;
  priceListItemPerBedAndNight: number;
  priceListItemPerRoomAndNight: number;
  onChange: (value: number, columnId: BaseTableColumnId) => {};
} & RoomTypePriceContainerStateProps &
  RoomTypePriceContainerDispatchProps;

const RoomTypePriceContainer: React.FunctionComponent<RoomTypePriceContainerProps> = memo(props => {
  const { items, priceListId, roomTypeId, defaultValue, onChange, type, from, to } = props;
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    console.debug('RoomTypePriceContainer.useEffect');
    setValue(defaultValue);
  }, [defaultValue]);


  const getDaysInMonth = (date: Date) => {
    return new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0).getDate();
  }

  const getItems = (): Array<PriceListItemModel> => {
    const i1 = items
      .filter(item => item.priceListId === priceListId && item.roomTypeId === roomTypeId && item.type === type);

    const i2 = items
      .filter(item => item.priceListId === priceListId && (item.roomTypeId === undefined || item.roomTypeId === null) && item.type === type);

    return i1.concat(i2);
  }

  const countPrice = (id: number): void => {
    setValue(id);

    const item = items.find(item => item.id === id);
    let price = item?.price ?? 0;
    if (item && item.isMonthlyPrice) {
      const mFrom = moment(from).startOf('day');
      const mTo = moment(to).startOf('day');

      if (moment(to).isAfter(mFrom, 'month')) {
        const fromDate = moment(from).startOf('month');
        const toDate = moment(to).endOf('month');

        const betweenMonths = [];
        let date = moment(fromDate).clone();
        while (date < toDate.endOf('month')) {
          betweenMonths.push({ month: date.month(), year: date.year() });
          date = date.clone().add(1, 'month');
        }
        let totalPrice = 0;
        let totalDays = 0;
        betweenMonths.forEach(date => {
          if (date.month === fromDate.month() && date.year === fromDate.year()) {
            const fromTotalDaysInMonth = getDaysInMonth(fromDate.toDate());
            const fromDays = (fromDate.endOf('month').diff(mFrom, 'days')) + 1;
            totalPrice += (price / fromTotalDaysInMonth) * fromDays;
            totalDays += fromDays;
          } else if (date.month === toDate.month() && date.year === toDate.year()) {
            const toTotalDaysInMonth = getDaysInMonth(toDate.toDate());
            const toDays = mTo.date() - 1;
            totalPrice += (price / toTotalDaysInMonth) * toDays;
            totalDays += toDays;
          } else {
            const allTotalDaysInMonth = getDaysInMonth(new Date(date.year, date.month));
            totalPrice += price;
            totalDays += allTotalDaysInMonth;
          }
        });

        price = _.round(totalPrice / totalDays, 2);
      } else {
        const pricePerDay = price / getDaysInMonth(moment(from).toDate());
        price = _.round(pricePerDay, 2);
      }
    }

    const columnId = type === PriceListItemType.PerBedAndNight ? 'pricePerBedAndNight' : 'pricePerRoomAndNight';
    const priceListColumnId = type === PriceListItemType.PerBedAndNight ? 'priceListItemPerBedAndNight' : 'priceListItemPerRoomAndNight';
    onChange(price, columnId as BaseTableColumnId);
    onChange(id, priceListColumnId as BaseTableColumnId);
  }

  return <Select
    value={value}
    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
      const id = event.target.value as number;
      countPrice(id);
    }}>
    <MenuItem value={0}>Nevybráno</MenuItem>
    {getItems().map(item => {
      return <MenuItem value={item.id}>{item.name} ({item.price})</MenuItem>
    })}
  </Select>
});

const mapStateToProps = (state: ApplicationState, ownProps: any): RoomTypePriceContainerStateProps => {
  const items = state.priceListItems.itemList.allIds.map<PriceListItemModel>(id => {
    return state.priceListItems.itemList.byId[id];
  });
  const { priceListId, payForRoomAndNight, from, to } = state.accommodationOfferCalculations.offerHeader;
  const type = payForRoomAndNight ? PriceListItemType.PerRoomAndNight : PriceListItemType.PerBedAndNight;
  const sortedItems = items.sort((a, b) => {
    return (a.isDefault === b.isDefault) ? 0 : (a.isDefault ? -1 : 1);
  });

  let defaultValue = payForRoomAndNight ? ownProps.priceListItemPerRoomAndNight : ownProps.priceListItemPerBedAndNight;
  if (defaultValue === 0) {
    defaultValue = sortedItems.find(item => item.priceListId === priceListId && item.roomTypeId === ownProps.roomTypeId && item.type === type)?.id ?? 0;
    if (defaultValue === 0) {
      defaultValue = items.find(item => item.priceListId === priceListId && item.type === type && item.isDefault)?.id ?? 0;
    }
  }

  return {
    priceListId: priceListId,
    from: from,
    to: to,
    type: type,
    items: items,
    defaultValue: defaultValue
  };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomTypePriceContainerDispatchProps => {
  return bindActionCreators(
    {
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypePriceContainer);