import React from 'react';
import { useField } from 'formik';
import { Theme } from '@material-ui/core';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { default as Checkbox, CheckboxClassKey } from './checkbox';

export type FormikCheckboxProps = {
    classes?: Partial<ClassNameMap<CheckboxClassKey>>;
    labelText: string;
    name: string;
    errorMessage?: string;
    CheckboxProps?: MuiCheckboxProps;
};

const FormikCheckbox: React.FunctionComponent<FormikCheckboxProps> = props => {
    const [field, meta] = useField(props.name);

    return (
        <Checkbox
            CheckboxProps={{ checked: field.value, onChange: field.onChange, name: props.name, color: 'primary' }}
            helperText={meta.touched ? meta.error : ''}
            labelText={props.labelText}
            error={Boolean(meta.touched) && (Boolean(meta.error) || Boolean(props.errorMessage))}
        />
    );
};

export default FormikCheckbox;
