import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import StateDetail, { StateDetailStateProps, StateDetailDispatchProps, StateDetailProps } from './statesDetail';
import * as actions from './actions';
import { StateModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';

type StateEditContainerStateProps = {} & StateDetailStateProps;

type StateEditContainerDispatchProps = {} & StateDetailDispatchProps;

type StateEditContainerProps = {} & StateDetailProps & StateEditContainerStateProps & StateEditContainerDispatchProps;

const StateEditContainer: React.FunctionComponent<StateEditContainerProps> = memo(props => {
  console.debug('StateEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <StateDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): StateEditContainerStateProps => {
  return {
    initialValues: state.states.itemList.byId[ownProps.match.params.id],
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress
  };
};

const mapDispatchToProps = (dispatch: Dispatch): StateEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StateEditContainer);
