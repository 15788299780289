import React, { memo } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  FormikDatePicker,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { numberSeriesValidationSchema } from './validations';
import { NumberSeriesModel, NumberSeriesDataModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';

export type NumberSeriesDetailStateProps = {
} & BaseDetailStateProps<NumberSeriesDataModel>;

export type NumberSeriesDetailDispatchProps = {
} & BaseDetailDispatchProps<NumberSeriesDataModel>;

export type NumberSeriesDetailProps = {}
  & BaseDetailProps<NumberSeriesDataModel>
  & NumberSeriesDetailStateProps & NumberSeriesDetailDispatchProps;

type RenderFormProps = {
} & BaseDetailContainerWrappedComponentProps<NumberSeriesDataModel>;

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues } = props;

  return (
    <Formik<NumberSeriesDataModel>
      initialValues={initialValues}
      onSubmit={(values: NumberSeriesDataModel, { setSubmitting }): void => {
        console.log('Submiting');
        setSubmitting(true);
        props.saveActionRequest(values);
      }}
      enableReinitialize={true}
      validationSchema={numberSeriesValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Typography variant="h5">Nabídky</Typography>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Prefix"
                    name={'accommodation.prefix'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'string'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Poslední číslo"
                    name={'accommodation.lastNumber'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'number'
                    }}
                  />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Typography variant="h5">Rezervace</Typography>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Prefix"
                    name={'reservation.prefix'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'string'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Poslední číslo"
                    name={'reservation.lastNumber'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'number'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Typography variant="h5">Fakturace</Typography>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Prefix"
                    name={'invoicing.prefix'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'string'

                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Poslední číslo"
                    name={'invoicing.lastNumber'}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true,
                      type: 'number'
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>
              </Grid>
            </Grid>

          </form>);
      }}
    </Formik>
  );
};

const NumberSeriesDetail: React.FunctionComponent<NumberSeriesDetailProps> = memo(props => {
  console.debug('NumberSeriesDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<NumberSeriesDataModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default NumberSeriesDetail;
