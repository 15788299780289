import React, { } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Box, Button, createStyles, makeStyles, StyleRules, Theme, withStyles } from '@material-ui/core';
import axios from 'axios';

import apiConfig from '../../configuration/api';
import { ApplicationState } from '../../store/rootReducer';
import {
  TableContainer,
  GenericColumn,
  BaseTableRowModel,
  AddButton,
  EditButton,
  withOpenModal,
  WithOpenModalProps,
  AlertsContainer
} from '../../components';
import { actionsColumn, createRelation, mergeWithIdColumn } from '../../components/table';
import { ReservationGuestCheckOutModel, RoomModel } from '../../api/interfaces';
import { ROUTES } from '../../navigation';
import {
  getCheckOutItemsAsync,
  deleteCheckOutItemAsync,
  ReservationGuestCheckOutCreateContainer,
  ReservationGuestCheckOutEditContainer,
  ReservationGuestCheckOutEditType,
  ReservationGuestStornoCreateContainer
} from '.';
import { getAllById as getRoomsById } from '../rooms';
import moment from 'moment';
import { NormalizedListById } from '../../core';
import { cancelCheckOutItemAsync } from './actions';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const withStylesFunction = withStyles(
  (theme: Theme): StyleRules<ReservationGuestsContainerClassKey> => ({
    gridButtonsRoot: {
      paddingTop: theme.spacing(3),
      '& > button:not(:first-of-type)': {
        marginLeft: theme.spacing(2)
      }
    }
  }));

type ReservationGuestsContainerClassKey = 'gridButtonsRoot';

type ReservationGuestsContainerStateProps = {
  isLogoutEnable: boolean;
  isLogonEnable: boolean;
  checkOuts: ReservationGuestCheckOutModel[];
  rooms: NormalizedListById<RoomModel>;
  id: number;
};

type ReservationGuestCheckOutContainerDispatchProps = {
  load: typeof getCheckOutItemsAsync.request;
  delete: typeof deleteCheckOutItemAsync.request;
  cancel: typeof cancelCheckOutItemAsync.request;
};

type ReservationGuestCheckOutContainerProps = {
  classes: Partial<ClassNameMap<ReservationGuestsContainerClassKey>>;
} & ReservationGuestsContainerStateProps
  & ReservationGuestCheckOutContainerDispatchProps
  & WithOpenModalProps;


const columns = (): GenericColumn<ReservationGuestCheckOutModel>[] => {
  return mergeWithIdColumn<ReservationGuestCheckOutModel>([
    {
      id: 'from', label: 'Odchod', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
      customComponent: (row: ReservationGuestCheckOutModel): any => {
        if (row.from)
          return moment(row.from).format('DD. MM. yyyy');
        else
          return <React.Fragment></React.Fragment>
      }
    },
    {
      id: 'to', label: 'Příchod', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
      customComponent: (row: ReservationGuestCheckOutModel): any => {
        if (row.to)
          return moment(row.to).format('DD. MM. yyyy');
        else
          return <React.Fragment></React.Fragment>
      }
    },
    {
      id: 'roomId',
      label: 'Pokoj',
      minWidth: 100,
      align: 'left',
      isVisible: true,
      isTableActions: false,
      relation: createRelation<ReservationGuestCheckOutModel, RoomModel>('roomId', 'name')
    },
    { id: 'guestName', label: 'Host', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
    {
      ...actionsColumn(), editComponent: (id: number): any => {
        return <EditButton createComponent={{
          component: ReservationGuestCheckOutEditContainer,
          route: ROUTES.RESERVATION_GUEST_CHECKOUTS_DETAIL,
          componentProps: { id: id }
        }}
          onClick={(): void => { console.log(`EDIT: ${id}`) }} />
      }
    }
  ])
};

class ReservationGuestCheckOutContainer extends React.PureComponent<ReservationGuestCheckOutContainerProps> {
  constructor(props: ReservationGuestCheckOutContainerProps) {
    super(props);

    props.load(props.id);
  }

  downloadFile(): void {
    const axiosInstance = axios.create({
      baseURL: apiConfig.baseUrl,
      timeout: 300000,
      withCredentials: true,
      headers: {

      },
      validateStatus: (status: number) => status >= 200 && status < 300
    });

    axiosInstance.get(`reservationguestcheckout/${this.props.id}/report`, { responseType: 'blob' })
      .then(response => {
        let fileName = response.headers["content-disposition"].split("filename=")[1].split(';')[0];
        fileName = fileName.replace(/^"|"$/g, '');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  }

  render(): React.ReactElement {
    console.debug('ReservationGuestCheckOutContainer.render');

    return (
      <Grid container direction="row" alignItems="center" style={{ padding: '20px' }}>
        <Grid item xs={12}>
          <Typography></Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <TableContainer
                rows={this.props.checkOuts}
                columns={columns() as GenericColumn<BaseTableRowModel>[]}
                dataSourcePath=""
                editAction={(id: number): void => {
                  this.props.openModal(ReservationGuestCheckOutEditContainer,
                    ROUTES.RESERVATION_GUEST_CHECKOUTS_DETAIL,
                    { id }
                  );
                  console.log('editAction');
                }}
                deleteAction={this.props.delete}
                relations={{ roomId: { items: this.props.rooms } }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box pt={3} width="100%" />
        <Grid container>
          <Grid item xs={10} classes={{ root: this.props.classes.gridButtonsRoot }}>
            {/* {this.props.isLogoutEnable &&  */}
            <AddButton
              createComponent={{
                component: ReservationGuestCheckOutCreateContainer,
                route: ROUTES.RESERVATION_GUEST_CHECKOUTS_CREATE,
                componentProps: { type: ReservationGuestCheckOutEditType.Logoff }
              }}
              text="Odhlásit z pokoje"
              onClick={(): void => {
                console.log('checkoutFromRoom');
              }}
            />
            &nbsp;
            {/* } */}
            {/* {this.props.isLogonEnable &&  */}
            <AddButton
              createComponent={{
                component: ReservationGuestCheckOutCreateContainer,
                route: ROUTES.RESERVATION_GUEST_CHECKOUTS_CREATE,
              }}
              text="Přihlásit do pokoje"
              onClick={(): void => {
                console.log('checkinToRoom');
              }}
            />
            {/* } */}
            &nbsp;
            <Button variant="outlined" color="inherit" onClick={(): void => { this.downloadFile(); }}>
              Tisk
            </Button>
            <AddButton
              createComponent={{
                component: ReservationGuestStornoCreateContainer,
                route: ROUTES.RESERVATION_GUEST_STORNO_CREATE,
                componentProps: { type: ReservationGuestCheckOutEditType.Logon }
              }}
              text="Storno"
              onClick={(): void => {
                console.log('addAccommodationOfferFeeAction');
              }}
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'right' }} classes={{ root: this.props.classes.gridButtonsRoot }}>
            <Button
              variant="contained"
              title="Zavřít"
              color="secondary"
              onClick={(): void => { this.props.cancel(); }}
            >
              Zavřít
            </Button>
          </Grid>
        </Grid>
      </Grid>);
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): ReservationGuestsContainerStateProps => {
  const { checkOuts } = state.reservations;
  let isLogoutEnable = false;
  let isLogonEnable = false;
  if (checkOuts) {
    let enable = false;
    const size = checkOuts.length;
    if (size > 0) {
      enable = true;
    }

    const checkOut = checkOuts[size - 1];
    if (checkOut === undefined || checkOut.from === null || checkOut.to !== null) {
      isLogoutEnable = true;
    } else if (checkOut?.to === null) {
      isLogonEnable = true && enable;
    }
  }

  return {
    isLogoutEnable: isLogoutEnable,
    isLogonEnable: isLogonEnable,
    checkOuts: checkOuts,
    rooms: getRoomsById(state),
    id: ownProps.id
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReservationGuestCheckOutContainerDispatchProps => {
  return bindActionCreators(
    {
      load: getCheckOutItemsAsync.request,
      delete: deleteCheckOutItemAsync.request,
      cancel: cancelCheckOutItemAsync.request
    }, dispatch);
};

export default withOpenModal(connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(ReservationGuestCheckOutContainer)));