import React from 'react';
import { Button, Grid, FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { signUpValidationSchema } from './validations';
import { useSignUpStyles } from './styles';
import { UserRegistrationRequest } from './types';

type SingUpViewProps = {
  errorMessage?: string;
  requestInProgress: boolean;
  inviteToken: string;

  signUp: (model: UserRegistrationRequest) => void;
};

type SingUp = Yup.InferType<typeof signUpValidationSchema>;

const nameof = nameofFactory<SingUp>();

const SingUpView: React.FunctionComponent<SingUpViewProps> = props => {
  const classes = useSignUpStyles(props);
  const { errorMessage, requestInProgress } = props;

  return (
    <Formik<SingUp>
      initialValues={{ firstName: '', lastName: '', password: '', passwordCheck: '', inviteToken: props.inviteToken }}
      onSubmit={(values: SingUp, { setSubmitting }): void => {
        setSubmitting(true);

        props.signUp(values);
      }}
      validationSchema={signUpValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { values, touched, errors, /* isSubmitting, */ handleChange, handleBlur, handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <Grid container className={classes.grid} spacing={3}>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <Input
                    iconName="id-card-alt"
                    labelText="Jméno"
                    helperText={touched.firstName ? errors.firstName : ''}
                    error={Boolean(touched.firstName) && (Boolean(errors.firstName) || Boolean(errorMessage))}
                    InputProps={{
                      name: nameof('firstName'),
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.firstName,
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    iconName="id-card-alt"
                    labelText="Přijmení"
                    helperText={touched.lastName ? errors.lastName : ''}
                    error={Boolean(touched.lastName) && (Boolean(errors.lastName) || Boolean(errorMessage))}
                    InputProps={{
                      name: nameof('lastName'),
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.lastName
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    iconName="id-card-alt"
                    labelText="Heslo"
                    helperText={touched.password ? errors.password : ''}
                    error={Boolean(touched.password) && (Boolean(errors.password) || Boolean(errorMessage))}
                    InputProps={{
                      name: nameof('password'),
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.password,
                      type: 'password'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    iconName="id-card-alt"
                    labelText="Heslo znovu"
                    helperText={touched.passwordCheck ? errors.passwordCheck : ''}
                    error={Boolean(touched.passwordCheck) && (Boolean(errors.passwordCheck) || Boolean(errorMessage))}
                    InputProps={{
                      name: nameof('passwordCheck'),
                      onChange: handleChange,
                      onBlur: handleBlur,
                      value: values.passwordCheck,
                      type: 'password'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="center" xs={12}>
                <Button variant="contained" title="Přihlásit se" color="primary" type="submit" disabled={requestInProgress}>
                  Registrovat se
                </Button>
              </Grid>
              <Grid item container justify="flex-end" xs={12}>
                <FormHelperText error={true}>{errorMessage}</FormHelperText>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default SingUpView;
