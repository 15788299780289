import React, { memo } from 'react';

import { BaseTableFilterComponentModel } from '../../components/table';
import { default as PaymentsFilter } from './paymentsFilter';

type PaymentsFilterContainerProps = {} & BaseTableFilterComponentModel;

const PaymentsFilterContainer: React.FunctionComponent<PaymentsFilterContainerProps> = memo(props => {
  console.debug('PaymentsFilterContainer');

  return <PaymentsFilter {...props} />;
});

export default PaymentsFilterContainer;
