import { all, put, takeEvery, PutEffect, SelectEffect, call, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { calculateAsyncAction, capacityIsNotLoadedAction, editOfferHeaderAction, editRoomTypeAction, setOfferHeaderAction, setWizardAction } from './actions';

import apiClient from '../../api/apiClientInstance';
import { AccommodationOfferCalculationModel, AccommodationOfferFloorModel, AccommodationOfferRoomCalculationModel, AccommodationOfferRoomTypeCalculationModel, AccommodationOfferStateEnum, ReservationRoomGuestModel, ReservationRoomModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { getCalculations, AccommodationOfferCalculationsState, getFreeRooms, getPartiallyOccupiedRooms, getReservedRooms, columnValueChangedAction } from '.';
import { addErrorAlert } from '../../components';
import { editRoomsAction } from '../reservations';
import { NormalizedListById } from '../../core';
import { calculatePriceAsyncAction } from '../accommodationOfferPriceCalculations';
import { BaseTableColumnId } from '../../components/table';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(calculateAsyncAction.request, calculateActionRequestHandler),
    yield takeEvery(capacityIsNotLoadedAction, capacityIsNotLoadedActionHandler),
    yield takeEvery(editRoomTypeAction, editRoomTypeActionHandler),
    yield takeEvery(setWizardAction, setWizardActionHandler),
    yield takeEvery(setOfferHeaderAction, setOfferHeaderActionHandler),
    yield takeEvery(columnValueChangedAction, columnValueChangedActionHandler),
  ]);
}

function* editRoomTypeActionHandler(action: ReturnType<typeof editRoomTypeAction>):
  Generator<
    SelectEffect
    | PutEffect
    | PutEffect<ActionType<typeof editRoomsAction>>,
    void,
    boolean
    & ReservationRoomModel[]
    & NormalizedListById<AccommodationOfferRoomTypeCalculationModel>
    & AccommodationOfferCalculationsState> {
  try {
    const calculations: AccommodationOfferCalculationsState = yield select(getCalculations);
    const _partiallyOccupiedRooms: NormalizedListById<AccommodationOfferRoomTypeCalculationModel> = yield select(getPartiallyOccupiedRooms);
    const partiallyOccupiedRooms = Object.entries(_partiallyOccupiedRooms).map((key, value) => {
      return key[1];
    });

    const _freeRoomTypes: NormalizedListById<AccommodationOfferRoomTypeCalculationModel> = yield select(getFreeRooms);
    const freeRoomTypes = Object.entries(_freeRoomTypes).map((key, value) => {
      return key[1];
    });

    const _reservedRoomTypes: NormalizedListById<AccommodationOfferRoomTypeCalculationModel> = yield select(getReservedRooms);
    const reservedRoomTypes = Object.entries(_reservedRoomTypes).map((key, value) => {
      return key[1];
    });

    const isOffer = calculations.offerHeader.state === AccommodationOfferStateEnum.Offer;
    const rooms = [] as ReservationRoomModel[];
    ([...freeRoomTypes, ...partiallyOccupiedRooms]).forEach(roomType => {
      roomType.rooms.forEach(room => {
        const occupiedBeds = roomType.roomCapacities?.find(rc => rc.id === room.roomId)?.countOfOccupiedBeds ?? 0;
        rooms.push(createRoom(room, roomType, occupiedBeds, false))
      })
    });

    [...reservedRoomTypes].forEach(roomType => {
      roomType.rooms.forEach(room => {
        const occupiedBeds = roomType.roomCapacities?.find(rc => rc.id === room.roomId)?.countOfOccupiedBeds ?? 0;
        rooms.push(createRoom(room, roomType, occupiedBeds, isOffer))
      })
    });

    yield put(editRoomsAction(rooms));
  } catch (error) {
    console.log(error);
  }

  function createRoom(
    room: AccommodationOfferRoomCalculationModel, 
    roomType: AccommodationOfferRoomTypeCalculationModel, 
    occupiedBeds: number, 
    deleted: boolean): ReservationRoomModel {
    return {
      id: room.roomId,
      countOfBedsByRoomType: Math.round(roomType.countOfBedsByRoomType / roomType.countOfAvailableRooms),
      countOfEmptyBeds: Math.round(roomType.countOfEmptyBeds / roomType.countOfAvailableRooms),
      countOfGuests: 0,
      occupiedBeds: occupiedBeds,
      _occupiedBeds: occupiedBeds,
      roomName: room.roomName,
      roomTypeName: roomType.roomTypeName,
      roomId: room.roomId,
      guests: [] as ReservationRoomGuestModel[],
      _guests: [] as ReservationRoomGuestModel[],
      _deleted: deleted
    } as ReservationRoomModel;
  }
}

function* capacityIsNotLoadedActionHandler(action: ReturnType<typeof capacityIsNotLoadedAction>): Generator<SelectEffect | PutEffect, void, void & boolean> {
  try {
    yield put(addErrorAlert('Nedostatečně zvolená kapacita pater.'));
  } catch (error) {
    console.log(error);
  }
}

type SetOfferHeaderActionRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof editOfferHeaderAction>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.failure>>;

function* setOfferHeaderActionHandler(
  action: ReturnType<typeof setOfferHeaderAction>
): Generator<SetOfferHeaderActionRequestGeneratorType, void, AccommodationOfferCalculationsState> {
  try {
    console.debug('setOfferHeaderActionHandler');
    const calculations: AccommodationOfferCalculationsState = yield select(getCalculations);

    // Take current priceList and compare with new values
    if (calculations.offerHeader.priceListId !== action.payload.priceListId) {
      yield put(calculatePriceAsyncAction.request());
    }

    yield put(editOfferHeaderAction(action.payload));
  } catch (error) {
    yield put(calculatePriceAsyncAction.failure(error));
  }
}

type SetWizardActionRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.failure>>;

function* setWizardActionHandler(
  action: ReturnType<typeof setWizardAction>
): Generator<SetWizardActionRequestGeneratorType, void, AccommodationOfferCalculationsState> {
  try {
    console.debug('setWizardActionHandler');

    if (action.payload.activeStep >= 1) {
      yield put(calculatePriceAsyncAction.request());
    }
  } catch (error) {
    yield put(calculatePriceAsyncAction.failure(error));
  }
}

type CalculateActionRequestGeneratorType =
  | Promise<BaseDataResponse<AccommodationOfferCalculationModel>>
  | SelectEffect
  | PutEffect<ActionType<typeof calculateAsyncAction.request>>
  | PutEffect<ActionType<typeof calculateAsyncAction.success>>
  | PutEffect<ActionType<typeof calculateAsyncAction.failure>>;

function* calculateActionRequestHandler(
  action: ReturnType<typeof calculateAsyncAction.request>
): Generator<CalculateActionRequestGeneratorType, void, BaseDataResponse<AccommodationOfferCalculationModel> & AccommodationOfferCalculationsState> {
  try {
    console.debug('calculateActionRequest');

    const model = { ...action.payload };
    const calculations: AccommodationOfferCalculationsState = yield select(getCalculations);
    const floors = calculations.floors.allIds.map((id) => {
      const { isSelected, number, floorId } = calculations.floors.byId[id];
      return { id, isSelected, number, floorId } as AccommodationOfferFloorModel & { isSelected: boolean };
    });
    model.floors = floors.filter(floor => floor.isSelected);
    const response: BaseDataResponse<AccommodationOfferCalculationModel> = yield apiClient.apiCallHandler(
      { context: apiClient.AccommodationOfferCalculations, apiCallFnName: 'calculateAsync' },
      model
    );

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(calculateAsyncAction.success(data));
    } else {
      yield put(calculateAsyncAction.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(calculateAsyncAction.failure(error));
  }
}

type ColumnValueChangedActionGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.failure>>;

function* columnValueChangedActionHandler(
  action: ReturnType<typeof columnValueChangedAction>
): Generator<ColumnValueChangedActionGeneratorType, void, AccommodationOfferCalculationsState> {
  try {
    console.debug('columnValueChangedActionHandler');
    const calculations: AccommodationOfferCalculationsState = yield select(getCalculations);

    const payload = action.payload;
    if (payload.columnId === ('pricePerBedAndNight' as BaseTableColumnId) || 
        payload.columnId === ('pricePerRoomAndNight' as BaseTableColumnId) ||
        payload.columnId === ('countOfOfferedBeds' as BaseTableColumnId)) {
      let forceCalculation = false;
      switch (payload.stateId) {
        case 'partiallyOccupiedRooms': 
            if (calculations.partiallyOccupiedRooms.byId[payload.rowId].rooms.length) {
              forceCalculation = true;
            }
          break;
        case 'freeRooms': 
          if (calculations.freeRooms.byId[payload.rowId].rooms.length) {
            forceCalculation = true;
          }
          break;
      }

      if (forceCalculation) {
        yield put(calculatePriceAsyncAction.request());
      }
    }
  } catch (error) {
    yield put(calculatePriceAsyncAction.failure(error));
  }
}