import { GenericColumn, ColumnRelation } from './types';

export const idColumn = <TModel>(): GenericColumn<TModel> => {
  return {
    id: 'id',
    label: 'Id',
    minWidth: 0,
    align: 'left',
    isVisible: false,
    isTableActions: false
  }
};

export const actionsColumn = <TModel>(): GenericColumn<TModel> => {
  return {
    id: 'tableActions',
    label: '',
    minWidth: 50,
    align: 'right',
    isVisible: true,
    isTableActions: true,
  }
};

const defaultColumns = <TModel>(): GenericColumn<TModel>[] => [idColumn(), actionsColumn()];

export const mergeWithDefaultColumns = <TModel>(columns: GenericColumn<TModel>[]): GenericColumn<TModel>[] => {
  if (!columns) {
    return defaultColumns<TModel>();
  }

  return columns.concat(defaultColumns<TModel>());
};

export const mergeWithIdColumn = <TModel>(columns: GenericColumn<TModel>[]): GenericColumn<TModel>[] => {
  if (!columns) {
    return defaultColumns<TModel>();
  }

  return columns.concat(idColumn<TModel>());
};

export const createRelation = <TModel, TRelatedModel>(key: keyof TModel, displayProperty: keyof TRelatedModel): ColumnRelation => {
  return { displayProperty: displayProperty as string, key: key as string };
};