import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { AccommodationOffersAction, AccommodationOffersState } from './types';
import * as actions from './actions';
import { BaseActions, createBaseErrorMessageReducer, createBaseRequestInProgressReducer, GetAllRequestAsyncActionPayload } from '../../core';
import { AccommodationOfferModel } from '../../api/interfaces';

export const initialState: AccommodationOffersState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false,
    page: 0,
    skip: 0,
    take: 100,
    filter: "",
    selection: { isOpen: false, ids: [], items: {} }
  },
  errorMessage: '',
  requestInProgress: false
};

const baseActions = {
  getItemAsync: actions.getItemAsync,
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync,
} as BaseActions<AccommodationOfferModel>;

const itemListReducer = createReducer<typeof initialState.itemList, AccommodationOffersAction>(initialState.itemList)
  .handleAction(actions.setPagination, (state, action) => {
    const newState = produce(state, draft => {
      draft.total = action.payload.total;
      draft.page = action.payload.page;
      draft.skip = action.payload.skip;
      draft.take = action.payload.take;
      draft.filter = action.payload.filter;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.allIds = action.payload.map(model => {
        draft.byId[model.id] = model;

        return model.id;
      });
      draft.loaded = true;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.request, (state, action) => {
    if (action.payload as GetAllRequestAsyncActionPayload) {
      return initialState.itemList;
    }

    return state;
  })
  .handleAction(actions.deleteItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      const index = draft.allIds.findIndex(id => id === action.payload)
      if (index !== -1) draft.allIds.splice(index, 1)
    });

    return newState;
  }).handleAction(actions.setSelection, (state, action) => {
    const newState = produce(state, draft => {
      draft.selection = action.payload;
    });

    return newState;
  });

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<AccommodationOffersState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer
});
