import { Reducer } from "redux";
import { getType } from "typesafe-actions";

import * as actions from "./actions";
import { AlertsState, AlertsAction } from "./types";

export const initialState: AlertsState = {
  alerts: [],
  containers: [],
};

const reducer: Reducer<AlertsState, AlertsAction> = (state: AlertsState = initialState, action: AlertsAction): AlertsState => {
  switch (action.type) {
    case getType(actions.addAlert): {
      return {
        ...state,
        alerts: [...state.alerts, action.payload]
      };
    }

    case getType(actions.removeAlert): {
      const i = state.alerts
        .map(a => {
          return a.id;
        })
        .indexOf(action.payload);
      return {
        ...state,
        alerts: [...state.alerts.slice(0, i), ...state.alerts.slice(i + 1)]
      };
    }

    case getType(actions.clearAlerts): {
      return {
        ...state,
        alerts: []
      };
    }

    case getType(actions.registerAlertContainer): {
      return {
        ...state,
        containers: [...state.containers, action.payload]
      }
    }

    case getType(actions.unRegisterAlertContainer): {
      const i = state.containers.indexOf(action.payload);
      return {
        ...state,
        containers: [...state.containers.slice(0, i), ...state.containers.slice(i + 1)]
      }
    }
    default:
      return state;
  }
};

export default reducer;
