import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import * as actions from './actions';

type SingOutContainerProps = {} & SingOutContainerDispatchProps;

type SingOutContainerDispatchProps = {
    signOutRequest: typeof actions.signOutAsync.request;
};

const SignOutContainer: React.FunctionComponent<SingOutContainerProps> = props => {
    console.debug('SignOutContainer');
    const signOutRequest = props.signOutRequest;

    useEffect(() => {
        console.debug('SignOutContainer.useEffect');

        signOutRequest();
    }, [signOutRequest]);

    return null;
};

const mapDispatchToProps = (dispatch: Dispatch): SingOutContainerDispatchProps =>
    bindActionCreators(
        {
            signOutRequest: actions.signOutAsync.request
        },
        dispatch
    );

export default connect(undefined, mapDispatchToProps)(SignOutContainer);
