import React, { memo } from 'react';

import { TableContainer, BaseTableProps } from '../../components';

type VatsTableProps = {} & BaseTableProps;

const VatsTable: React.FunctionComponent<VatsTableProps> = memo(props => {
    console.debug('VatsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default VatsTable;
