import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DeepReadonly } from 'utility-types';

import { RoomTypesState } from './types';
import * as actions from './actions';
import { BaseActions, createBaseListReducer, createBaseErrorMessageReducer, createBaseRequestInProgressReducer } from '../../core';
import { RoomTypeModel } from '../../api/interfaces';

export const initialState: RoomTypesState = {
    itemList: {
        allIds: [],
        byId: {},
        loaded: false
    },
    errorMessage: '',
    requestInProgress: false
};

const baseActions = {
    getItemsAsync: actions.getItemsAsync,
    saveItemAsync: actions.saveItemAsync,
    deleteItemAsync: actions.deleteItemAsync,
    editItemAsync: actions.editItemAsync
} as BaseActions<RoomTypeModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);
export default combineReducers<RoomTypesState>({
    itemList: itemListReducer,
    requestInProgress: requestInProgressReducer,
    errorMessage: errorMessageReducer
});
