import * as Yup from 'yup';

export const signInPersonalIdValidationSchema = Yup.object().shape({
    personalId: Yup.string().required('Osobní číslo je povinné')
});

export const signInPinCodeValidationSchema = Yup.object().shape({
    pinCode: Yup.string().required('Pin kód je povinný')
});

export const signInValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Uživatelské jméno je povinné'),
    password: Yup.string().required('Heslo je povinné')
});
