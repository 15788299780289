import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem, TablePaginationModel } from '../../components';
import { NormalizedListById } from '../../core';
import { GuestModel } from '../../api/interfaces';

export const getAreGuestsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.guests.itemList.loaded;
};

export const getGuestById = (state: ApplicationState): NormalizedListById<GuestModel> => {
  return state.guests.itemList.byId;
};

export const selectGuestByCompanyByIdSelectItems = (state: ApplicationState): DeepReadonly<RelationSelectItem[]> => {
  const guests = [] as GuestModel[];
  const { companyId } = state.accommodationOfferCalculations.offerHeader;
  if (companyId && companyId > 0) {
    Object.values(state.guests.itemList.byId).forEach(guest => {
      if (companyId === guest.companyId) {
        guests.push(guest);
      }
    });
  } else {
    Object.values(state.guests.itemList.byId).forEach(guest => {
      guests.push(guest);
    });
  }
  return guests.map(guest => {
    return { text: `${guest.name} ${guest.surname}`, value: guest.id } as RelationSelectItem;
  });
};

export const selectGuestsSelectItems = createSelector(
  [getGuestById],
  (guestsById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectGuestsSelectItems...');

    return Object.values(guestsById).map<RelationSelectItem>(guest => {
      return { text: `${guest.name} ${guest.surname}`, value: guest.id } as RelationSelectItem;
    });
  }
);

export const getGuestsPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.guests.itemList.page ?? 0,
    skip: state.guests.itemList.skip ?? 0,
    take: state.guests.itemList.take ?? 100,
    total: state.guests.itemList.total ?? 0,
    filter: state.guests.itemList.filter ?? ""
  } as TablePaginationModel;
};