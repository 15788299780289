import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DeepReadonly } from 'utility-types';

import { AccommodatedGuestAction, AccommodatedGuestsState } from './types';
import * as actions from './actions';
import {
  createBaseListReducer,
  BaseActions,
  createBaseErrorMessageReducer,
  createBaseRequestInProgressReducer,
  GetAllRequestAsyncActionPayload
} from '../../core';
import { AccommodatedGuestModel, AccommodationModel } from '../../api/interfaces';
import moment from 'moment';

export const initialState: AccommodatedGuestsState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false,
    page: 0,
    skip: 0,
    take: 100,
    filter: `group=${moment(new Date()).format('MM/YYYY')}`,
  },
  errorMessage: '',
  requestInProgress: false
};

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync,
  setPagination: actions.setPagination
} as BaseActions<AccommodatedGuestModel>;

// const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);
const itemListReducer = createReducer<typeof initialState.itemList, AccommodatedGuestAction>(initialState.itemList)
  .handleAction(actions.setPagination, (state, action) => {
    const newState = produce(state, draft => {
      draft.total = action.payload.total;
      draft.page = action.payload.page;
      draft.skip = action.payload.skip;
      draft.take = action.payload.take;
      draft.filter = action.payload.filter;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.allIds = action.payload.map(model => {
        draft.byId[model.id] = model;

        return model.id;
      });
      draft.loaded = true;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.request, (state, action) => {
    if (action.payload as GetAllRequestAsyncActionPayload) {
      
      return { ...initialState.itemList, filter: state.filter };
    }

    return state;
  });

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<AccommodatedGuestsState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer
});
