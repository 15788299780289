import { VatModel } from '../../api/interfaces';
import {
    createGetItemsAsyncAction,
    createNewItemAsyncAction,
    createEditItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createDeleteItemAsyncAction,
    createLoadDependenciesAsyncAction
} from '../../core';

const modelName = 'vats';

export const getItemsAsync = createGetItemsAsyncAction<VatModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<VatModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);
