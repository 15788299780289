import { all, put, takeEvery, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';

import apiClient from '../../api/apiClientInstance';
import { VatModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import {
  GetAllRequestAsyncActionPayload,
  isDataReloadRequired,
  registerSaveEntityRequest,
  registerCancelEntityRequest,
  registerDeleteEntityRequest
} from '../../core';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getVatsRequest),
    yield takeEvery(newItemAsync.request, newVatRequest),
    yield takeEvery(saveItemAsync.request, saveVatRequest),
    yield takeEvery(cancelItemAsync.request, cancelVatRequest),
    yield takeEvery(editItemAsync.request, editVatRequest),
    yield takeEvery(deleteItemAsync.request, deleteVatRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type GetVatsRequestGeneratorType =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<VatModel[]>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getVatsRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetVatsRequestGeneratorType, void, boolean & BaseDataResponse<VatModel[]>> {
  try {
    console.debug('getVatsRequest');

    const payload = action.payload as GetAllRequestAsyncActionPayload;
    const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
    if (!isReloadRequired) {
      yield put(getItemsAsync.cancel());
      return;
    }

    const response: BaseDataResponse<VatModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.Vats,
      apiCallFnName: 'getAllAsync'
    });

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type NewVatRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newVatRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewVatRequestGeneratorType, void, void> {
  try {
    console.debug('newVatRequest');

    yield put(navigateTo(ROUTES.SETTINGS_VATS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditVatRequestGeneratorType =
  | Promise<BaseDataResponse<VatModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editVatRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditVatRequestGeneratorType, void, BaseDataResponse<VatModel>> {
  try {
    console.debug('editVatRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_VATS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteVatRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.Vats,
  ROUTES.SETTINGS_VATS
);

const saveVatRequest = registerSaveEntityRequest<VatModel>(
  saveItemAsync.success,
  saveItemAsync.failure,
  apiClient.Vats,
  ROUTES.SETTINGS_VATS
);

const cancelVatRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_PRICE_LISTS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
