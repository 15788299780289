import { BaseModel } from './baseModel';

export enum PaymentMethodEnum {
  Cash = 1,
  Card = 2,
  Bank = 3,
  Invoice = 4
}

export type PaymentModel = {
  paymentMethod: PaymentMethodEnum;
  amount: number;
  dateOfPayment: Date;
  reservationId: number;
  reservationNumber: string;
  guestId: number;
  guestName: string;
  userName: string;
} & BaseModel;
