import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { FloorModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class FloorClient extends BaseAuthenticatedClient {
    constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
        super(authenticatedRequestHandler, errorHandler, axiosInstance, 'floor');
    }

    public async getAllAsync(): Promise<BaseDataResponse<FloorModel[]>> {
        return await this.getHttpAsync<BaseDataResponse<FloorModel[]>>('', '');
    }

    public async createAsync(model: FloorModel): Promise<BaseDataResponse<FloorModel>> {
        return await this.postHttpAsync<BaseDataResponse<FloorModel>, FloorModel>('', model);
    }

    public async updateAsync(model: FloorModel): Promise<BaseDataResponse<FloorModel>> {
        return await this.putHttpAsync<BaseDataResponse<FloorModel>, FloorModel>(`${model.id}`, model);
    }

    public async deleteAsync(id: number): Promise<BaseResponse> {
        return await this.deleteHttpAsync<BaseResponse>(`${id}`);
    }
}
