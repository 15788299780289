import { takeEvery, all, put, ForkEffect, AllEffect, PutEffect } from 'redux-saga/effects';
import { Action } from 'react-redux/node_modules/redux';
import { PayloadAction, ActionType } from 'typesafe-actions';

import { hideFullPageLoadingIndicator, showFullPageLoadingIndicator } from '../components/fullPageLoadingIndicator';
import { genericError, unauthorizedError } from '../features/errors';

function* handleAsyncActionRequest(): Generator<PutEffect<ActionType<typeof showFullPageLoadingIndicator>>> {
  yield put(showFullPageLoadingIndicator());
}

function* handleAsyncActionFailure(
  action: PayloadAction<string, Error>
): Generator<
 PutEffect<ActionType<typeof hideFullPageLoadingIndicator>> 
| PutEffect<ActionType<typeof genericError>>
| PutEffect<ActionType<typeof unauthorizedError>>> {
  yield put(hideFullPageLoadingIndicator());
  const error = JSON.parse(action.payload.message);

  if (error.code === -401) {
    yield put(unauthorizedError({ errorMessage: error.reason, errorCode: -401 }));
  } else {
    yield put(genericError(error.reason));
  }
}

function* handleAsyncActionSuccess(): Generator<PutEffect<ActionType<typeof hideFullPageLoadingIndicator>>> {
  yield put(hideFullPageLoadingIndicator());
}

function* handleAsyncActionCancel(): Generator<PutEffect<ActionType<typeof hideFullPageLoadingIndicator>>> {
  yield put(hideFullPageLoadingIndicator());
}

function* watchCancel(): Generator<ForkEffect<never>> {
  yield takeEvery((action: Action): ForkEffect<never> => {
    return action.type && action.type.toUpperCase().endsWith('CANCEL');
  }, handleAsyncActionCancel);
}

function* watchSuccess(): Generator<ForkEffect<never>> {
  yield takeEvery((action: Action): ForkEffect<never> => {
    return action.type && action.type.toUpperCase().endsWith('SUCCESS');
  }, handleAsyncActionSuccess);
}

function* watchFailure(): Generator<ForkEffect<never>> {
  yield takeEvery((action: Action) => {
    return action.type && action.type.toUpperCase().endsWith('FAILURE');
  }, handleAsyncActionFailure);
}

function* watchRequest(): Generator<ForkEffect<never>> {
  yield takeEvery((action: Action): ForkEffect<never> => {
    return action.type && action.type.toUpperCase().endsWith('REQUEST');
  }, handleAsyncActionRequest);
}

// use them in parallel
export default function* rootSaga(): Generator<AllEffect<Generator<ForkEffect<never>>>> {
  yield all([watchFailure(), watchRequest(), watchSuccess(), watchCancel()]);
}
