import React, { memo, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { isDisabledFactory } from '../../utils/isDisabledFactory';
import { roomTypeValidationSchema } from './validations';
import { RoomTypeModel } from '../../api/interfaces';
import { ReadonlyValues } from '../../core';
import { ROUTES } from '../../navigation';
import { AccommodationsCreateContainer } from '../accommodations';

export type RoomTypeDetailStateProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  readonlyValues?: Partial<ReadonlyValues<RoomTypeModel>>;
} & BaseDetailStateProps<RoomTypeModel>;

export type RoomTypeDetailDispatchProps = {} & BaseDetailDispatchProps<RoomTypeModel>;

export type RoomTypeDetailProps = {} & BaseDetailProps<RoomTypeModel> & RoomTypeDetailStateProps & RoomTypeDetailDispatchProps;

type RenderFormProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  readonlyValues?: Partial<ReadonlyValues<RoomTypeModel>>;
} & BaseDetailContainerWrappedComponentProps<RoomTypeModel>;

const nameof = nameofFactory<RoomTypeModel>();

const isDisabled = isDisabledFactory<RoomTypeModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues, accommodationsSelectItems, readonlyValues } = props;

  const handleActionCancel = (event: React.MouseEvent<HTMLButtonElement>): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<RoomTypeModel>
      initialValues={initialValues}
      onSubmit={(values: RoomTypeModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={roomTypeValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { /* isSubmitting, */ handleChange, handleBlur, handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Název"
                    name={nameof('name')}
                    errorMessage={errorMessage}
                    InputProps={
                      {
                        /* autoFocus: true */
                      }
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="sticky-note"
                    labelText="Popisek"
                    name={nameof('description')}
                    errorMessage={errorMessage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="user"
                    labelText="Počet osob"
                    name={nameof('personCount')}
                    errorMessage={errorMessage}
                    InputProps={{
                      type: 'number'
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Ubytovací zařízení"
                    name={nameof('accommodationId')}
                    errorMessage={errorMessage}
                    items={accommodationsSelectItems as RelationSelectItem[]}
                    disabled={isDisabled('accommodationId', readonlyValues)}
                    createComponent={{
                      component: AccommodationsCreateContainer,
                      route: ROUTES.SETTINGS_ACCOMMODATIONS_CREATE
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const RoomTypeDetail: React.FunctionComponent<RoomTypeDetailProps> = memo(props => {
  console.debug('RoomTypeDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    ...otherProps
  } = props;

  return (
    <BaseDetailContainer<RoomTypeModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      {...otherProps}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default RoomTypeDetail;
