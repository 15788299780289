import React, {  } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import { NumberSeriesModel } from '../../api/interfaces';
import * as actions from './actions';
import NumberSeriesDetail, { NumberSeriesDetailDispatchProps, NumberSeriesDetailProps, NumberSeriesDetailStateProps } from './numberSeriesDetail';

type NumberSeriesContainerStateProps = {
  initialValues: { accommodation: NumberSeriesModel; reservation: NumberSeriesModel };
} & NumberSeriesDetailStateProps;

type NumberSeriesContainerDispatchProps = {
  getNumberSeriesRequest: typeof actions.getNumberSeriesAsync.request;
  loadDependenciesRequest: typeof actions.loadNumberSeriesDependenciesAsync.request;
} & NumberSeriesDetailDispatchProps;

type NumberSeriesContainerProps = {} & NumberSeriesDetailProps & NumberSeriesContainerStateProps & NumberSeriesContainerDispatchProps;

class NumberSeriesContainer extends React.PureComponent<NumberSeriesContainerProps> {
  componentDidMount(): void {
    console.debug('NumberSeriesContainer.componentDidMount');
    this.props.getNumberSeriesRequest();
    this.props.loadDependenciesRequest();
  }

  render(): React.ReactNode {
    const { initialValues, 
      loadDependenciesRequest, 
      saveActionRequest, 
      cancelActionRequest,
      errorMessage,
      requestInProgress,
      modalDialog,
      modalDialogRoute
   } = this.props;
    return (
      <NumberSeriesDetail
        initialValues={initialValues}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): NumberSeriesContainerStateProps => {
  return {
    initialValues: state.numberSeries.current,
    errorMessage: state.numberSeries.errorMessage,
    requestInProgress: state.numberSeries.requestInProgress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): NumberSeriesContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        getNumberSeriesRequest: actions.getNumberSeriesAsync.request,
        loadDependenciesRequest: actions.loadNumberSeriesDependenciesAsync.request,
        saveActionRequest: actions.saveNumberSeriesAsync.request,
        cancelActionRequest: actions.cancelNumberSeriesAsync.request,
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NumberSeriesContainer);
