import React from 'react';
import { Container, Theme, makeStyles, createStyles } from '@material-ui/core';

import { default as UnauthenticatedAppOneColumnLayout } from './unauthenticatedAppOneColumnLayout';
import UnauthenticatedAppRouter from './unauthenticatedAppRouter';
import { SING_IN_BACKGROUNG } from '../../customization/assets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0px 0px 0 0px',
      backgroundColor: 'white',
      minHeight: '100vh',
      minWidth: '100vw',
      backgroundImage: `url(${SING_IN_BACKGROUNG})`,
      backgroundSize: '100% 100%',
      display: 'flex'
    }
  })
);

type UnauthenticatedAppProps = {};

const UnauthenticatedApp: React.FunctionComponent<UnauthenticatedAppProps> = props => {
  const content = <UnauthenticatedAppRouter />;
  const classes = useStyles();

  return (
    <Container classes={{ root: classes.root }}>
      <UnauthenticatedAppOneColumnLayout children={content} />
    </Container>
  );
};

export default UnauthenticatedApp;
