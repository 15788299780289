import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem, TablePaginationModel } from '../../components';
import { NormalizedListById } from '../../core';
import { CompanyModel } from '../../api/interfaces';

export const getAreCompaniesLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.companies.itemList.loaded;
};

export const getCompanyById = (state: ApplicationState): NormalizedListById<CompanyModel> => {
  return state.companies.itemList.byId;
};

export const selectCompaniesSelectItems = createSelector(
  [getCompanyById],
  (companiesById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectCompaniesSelectItems...');

    return Object.values(companiesById).map<RelationSelectItem>(company => {
      return { text: company.name, value: company.id } as RelationSelectItem;
    });
  }
);

export const getCompaniesPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.companies.itemList.page ?? 0,
    skip: state.companies.itemList.skip ?? 0,
    take: state.companies.itemList.take ?? 100,
    total: state.companies.itemList.total ?? 0,
    filter: state.companies.itemList.filter ?? ""
  } as TablePaginationModel;
};