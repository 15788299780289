import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { AuthenticatedApp } from './authenticatedApp';
import { default as UnauthenticatedApp } from './unauthenticatedApp';
import { AppProviderContainerProps, AppProviderContainerDispatchProps, AppProviderContainerStateProps } from './types';
import { ApplicationState } from '../../store/rootReducer';
import { FullPageLoadingIndicatorContainer } from '../../components';
import * as authenticationActions from '../authentication/actions';
import { afterAuthenticatedAppDidMount } from './actions';

export class AppProviderContainer extends React.Component<AppProviderContainerProps> {
  componentDidMount(): void {
    this.props.tryLoadAuthenticatedUserFormCredentials();
  }

  render(): React.ReactElement {
    const { user } = this.props;

    return (
      <React.Fragment>
        <FullPageLoadingIndicatorContainer size={90} />
        {user ? <AuthenticatedApp user={user} afterAuthenticatedAppDidMount={this.props.afterAuthenticatedAppDidMount}
        /> : <UnauthenticatedApp />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState): AppProviderContainerStateProps => {
  return {
    user: state.authentication.user
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AppProviderContainerDispatchProps =>
  bindActionCreators(
    {
      tryLoadAuthenticatedUserFormCredentials: authenticationActions.tryLoadAuthenticatedUserFormCredentials,
      afterAuthenticatedAppDidMount: afterAuthenticatedAppDidMount,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppProviderContainer);
