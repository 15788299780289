import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { AuthenticationState, AuthenticationAction, User } from './types';
import { loadAuthenticatedUserFormStorageAsync, setAuthenticatedUserFormCredentials } from './actions';
import { signInValidateOptAsync } from '../signIn';
import { signOutAsync } from '../signOut';

export const initialState: AuthenticationState = {
  user: null,
  isAuthenticated: false
};

type RootActions = AuthenticationAction | ActionType<typeof signInValidateOptAsync> | ActionType<typeof signOutAsync>;

const userReducer = createReducer<DeepReadonly<User | null>, RootActions>(initialState.user)
  .handleAction([loadAuthenticatedUserFormStorageAsync.request, loadAuthenticatedUserFormStorageAsync.failure], (state, action) => null)
  .handleAction(loadAuthenticatedUserFormStorageAsync.success, (state, action) => {
    return { ...action.payload };
  })
  .handleAction(setAuthenticatedUserFormCredentials, (state, action) => {
    return { ...action.payload };
  })
  .handleAction(signOutAsync.success, (state, action) => initialState.user);

const isAuthenticatedReducer = createReducer<DeepReadonly<boolean>, RootActions>(initialState.isAuthenticated)
  .handleAction([loadAuthenticatedUserFormStorageAsync.request, loadAuthenticatedUserFormStorageAsync.failure], (state, action) => false)
  .handleAction([loadAuthenticatedUserFormStorageAsync.success, setAuthenticatedUserFormCredentials], (state, action) => true)
  .handleAction(signOutAsync.success, (state, action) => initialState.isAuthenticated);

export default combineReducers<AuthenticationState, RootActions>({
  user: userReducer,
  isAuthenticated: isAuthenticatedReducer
});
