import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import { AppState, appInitialState, appReducer as featureAppReducer } from '../features/app';
import { AuthenticationState, authenticationReducer, authenticationInitialState } from '../features/authentication';
import { SignInState, signInReducer, signInInitialState } from '../features/signIn';
import { AccommodationsState, accommodationsReducer, accommodationsInitialState } from '../features/accommodations';

import { ThemeState, themeReducer, themeInitialState } from '../features/themes';
import {
  FullPageLoadingIndicatorState,
  fullPageLoadingIndicatorReducer,
  fullPageLoadingIndicatorInitialState
} from '../components/fullPageLoadingIndicator';
import { ErrorsState, errorsInitialState, errorsReducer } from '../features/errors';
import { AlertsState, alertsInitialState, alertsReducer } from '../components/alerts';
import { TimeLineState, timeLineInitialState, timeLineReducer } from '../features/timeLine';
import { UsersState, usersInitialState, usersReducer } from '../features/users';
import { SignUpState, signUpInitialState, signUpReducer } from '../features/signUp';
import { RoomTypesState, roomTypesInitialState, roomTypesReducer } from '../features/roomTypes';
import { RoomsState, roomsInitialState, roomsReducer } from '../features/rooms';
import { FloorsState, floorsInitialState, floorsReducer } from '../features/floors';
import { GuestsState, guestsInitialState, guestsReducer } from '../features/guests';
import { StatesState, statesInitialState, statesReducer } from '../features/states';
import { CompaniesState, companiesInitialState, companiesReducer } from '../features/companies';
import {
  ModalDialogManagerState,
  modalDialogManagerInitialState,
  modalDialogManagerReducer
} from '../components/modalDialogManager';
import { PriceListsState, priceListsInitialState, priceListsReducer } from '../features/priceLists';
import { PriceListItemsState, priceListItemsInitialState, priceListItemsReducer } from '../features/priceListItems';
import {
  AccommodationOffersState,
  accommodationOffersInitialState,
  accommodationOffersReducer
} from '../features/accommodationOffers';
import {
  AccommodationOfferCalculationsState,
  accommodationOfferCalculationsInitialState,
  accommodationOfferCalculationsReducer
} from '../features/accommodationOfferCalculations';
import {
  AccommodationOfferPriceCalculationsState,
  accommodationOfferPriceCalculationsInitialState,
  accommodationOfferPriceCalculationsReducer
} from '../features/accommodationOfferPriceCalculations';
import {
  ReservationsState,
  reservationsInitialState,
  reservationsReducer
} from '../features/reservations';

import {
  NumberSeriesState,
  numberSeriesInitialState,
  numberSeriesReducer,
} from '../features/numberSeries';
import { PaymentsState, paymentsInitialState, paymentsReducer } from '../features/payments';

import { VirtualState, virtualInitialState, virtualReducer } from '../components/virtualSelect';

import { VatsState, vatsInitialState, vatsReducer } from '../features/vats';

import {
  MyCompanyState,
  myCompanyInitialState,
  myCompanyReducer,
} from '../features/myCompany';

import {
  AccommodationOffersEvidenceListState,
  accommodationOffersEvidenceListInitialState,
  accommodationOffersEvidenceListReducer,
} from '../features/accommodationOffersEvidenceList';

import {
  FileUploadState,
  fileUploadReducer,
  fileUploadInitialState
} from '../components/fileUpload';


import {
  AccommodatedGuestsState,
  accommodatedGuestsReducer,
  accommodatedGuestsInitialState
} from '../features/accommodatedGuests';

import {
  LogsState,
  logsReducer,
  logsInitialState
} from '../features/logs';

import { StickyAlertsState, stickyAlertsInitialState, stickyAlertsReducer } from '../features/stickyAlerts';

/*importFeaure*/
export type ApplicationState = {
  authentication: AuthenticationState;
  signIn: SignInState;
  accommodations: AccommodationsState;
  theme: ThemeState;
  fullPageLoadingIndicator: FullPageLoadingIndicatorState;
  router: RouterState;
  errors: ErrorsState;
  alerts: AlertsState;
  timeLine: TimeLineState;
  users: UsersState;
  roomTypes: RoomTypesState;
  rooms: RoomsState;
  floors: FloorsState;
  signUp: SignUpState;
  guests: GuestsState;
  states: StatesState;
  companies: CompaniesState;
  modalDialogManager: ModalDialogManagerState;
  priceLists: PriceListsState;
  priceListItems: PriceListItemsState;
  accommodationOffers: AccommodationOffersState;
  accommodationOfferCalculations: AccommodationOfferCalculationsState;
  accommodationOfferPriceCalculations: AccommodationOfferPriceCalculationsState;
  app: AppState;
  reservations: ReservationsState;
  numberSeries: NumberSeriesState;
  virtual: VirtualState;
  payments: PaymentsState;
  vats: VatsState;
  myCompany: MyCompanyState;
  accommodationOffersEvidenceList: AccommodationOffersEvidenceListState;
  fileUpload: FileUploadState;
  accommodatedGuests: AccommodatedGuestsState;
  logs: LogsState;
  stickyAlerts: StickyAlertsState;
};

const initialState: ApplicationState = {
  authentication: authenticationInitialState,
  signIn: signInInitialState,
  accommodations: accommodationsInitialState,
  theme: themeInitialState,
  fullPageLoadingIndicator: fullPageLoadingIndicatorInitialState,
  router: {} as RouterState,
  errors: errorsInitialState,
  alerts: alertsInitialState,
  timeLine: timeLineInitialState,
  users: usersInitialState,
  roomTypes: roomTypesInitialState,
  rooms: roomsInitialState,
  floors: floorsInitialState,
  signUp: signUpInitialState,
  guests: guestsInitialState,
  states: statesInitialState,
  companies: companiesInitialState,
  modalDialogManager: modalDialogManagerInitialState,
  priceLists: priceListsInitialState,
  priceListItems: priceListItemsInitialState,
  accommodationOffers: accommodationOffersInitialState,
  accommodationOfferCalculations: accommodationOfferCalculationsInitialState,
  accommodationOfferPriceCalculations: accommodationOfferPriceCalculationsInitialState,
  app: appInitialState,
  reservations: reservationsInitialState,
  numberSeries: numberSeriesInitialState,
  virtual: virtualInitialState,
  payments: paymentsInitialState,
  vats: vatsInitialState,
  myCompany: myCompanyInitialState,
  accommodationOffersEvidenceList: accommodationOffersEvidenceListInitialState,
  fileUpload: fileUploadInitialState,
  accommodatedGuests: accommodatedGuestsInitialState,
  logs: logsInitialState,
  stickyAlerts: stickyAlertsInitialState
  /*initialApplicationState*/
};

const appReducer = (history: History) =>
  combineReducers({
    app: featureAppReducer,
    authentication: authenticationReducer,
    signIn: signInReducer,
    accommodations: accommodationsReducer,
    theme: themeReducer,
    fullPageLoadingIndicator: fullPageLoadingIndicatorReducer,
    router: connectRouter(history),
    errors: errorsReducer,
    alerts: alertsReducer,
    timeLine: timeLineReducer,
    users: usersReducer,
    roomTypes: roomTypesReducer,
    rooms: roomsReducer,
    floors: floorsReducer,
    signUp: signUpReducer,
    guests: guestsReducer,
    states: statesReducer,
    companies: companiesReducer,
    modalDialogManager: modalDialogManagerReducer,
    priceLists: priceListsReducer,
    priceListItems: priceListItemsReducer,
    accommodationOffers: accommodationOffersReducer,
    accommodationOfferCalculations: accommodationOfferCalculationsReducer,
    accommodationOfferPriceCalculations: accommodationOfferPriceCalculationsReducer,
    reservations: reservationsReducer,
    numberSeries: numberSeriesReducer,
    virtual: virtualReducer,
    payments: paymentsReducer,
    vats: vatsReducer,
    myCompany: myCompanyReducer,
    accommodationOffersEvidenceList: accommodationOffersEvidenceListReducer,
    fileUpload: fileUploadReducer,
    accommodatedGuests: accommodatedGuestsReducer,
    logs: logsReducer,
    stickyAlerts: stickyAlertsReducer
    /*entityReducer*/
  });

  // TODO: no any
const rootReducer = (history: History) => (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'signOut/SIGN_OUT_SUCCESS') {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state

    state = { ...initialState, router: state.router, theme: state.theme };
  }

  return appReducer(history)(state, action);
};

export default rootReducer;
