import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { AccommodationOfferPriceCalculationMutationModel, AccommodationOfferPriceCalculationModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class AccommodationOfferPriceCalculationClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'accommodationoffer');
  }

  public async calculatePriceAsync(model: AccommodationOfferPriceCalculationMutationModel): Promise<BaseDataResponse<AccommodationOfferPriceCalculationModel>> {
    return await this.postHttpAsync<BaseDataResponse<AccommodationOfferPriceCalculationModel>, AccommodationOfferPriceCalculationMutationModel>('calculatePrice', model);
  }
}
