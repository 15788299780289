import * as React from "react";
import { List, ListItem } from "@material-ui/core";

import { FileProgress } from "./fileProgress";

interface FileProps {
  file: File;
  uploadProgress: number;
  uploadError: string;
}

interface FileViewProps {
  selectedFiles: FileProps[];
}

export const FileView = (props: FileViewProps) => {
  const { selectedFiles } = props;

  return (
    <List>
      {selectedFiles?.map(({ file: { name, size }, uploadProgress }) => (
        <ListItem key={name}>
          <List>
            <ListItem><span className="primary">{name}</span><span style={{ paddingLeft: "5px" }}>({size})</span></ListItem>
            <ListItem><FileProgress {...{ progress: uploadProgress }} /></ListItem>
          </List>
        </ListItem>
      ))}
    </List>
  );
};

export default FileView;