import * as Yup from 'yup';

export const accommodationOfferFeeValidationSchema = Yup.object().shape({
  amount: Yup.number().required('Množství je povinné').min(0, 'Množství musí být nastaveno'),
  priceListId:  Yup.number()
  .required('Ceník musí být vybrán')
  .min(1, 'Ceník musí být vybrán'),
  priceListItemId:  Yup.number()
  .required('Položka ceníku musí být vybrána')
  .min(1, 'Položka ceníku musí být vybrána'),
});
