import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import VatDetail, { VatDetailStateProps, VatDetailDispatchProps, VatDetailProps } from './vatDetail';
import * as actions from './actions';
import { VatModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { relationConfiguration } from '../../configuration/relations';

type VatCreateContainerStateProps = {} & VatDetailStateProps;

type VatCreateContainerDispatchProps = {} & VatDetailDispatchProps;

type VatCreateContainerProps = {} & VatDetailProps & VatCreateContainerStateProps & VatCreateContainerDispatchProps;

const createInitialValues = { name: '', amount: 0 } as VatModel;

const VatCreateContainer: React.FunctionComponent<VatCreateContainerProps> = memo(props => {
  console.debug('VatCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <VatDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): VatCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.priceLists.errorMessage,
    requestInProgress: state.priceLists.requestInProgress,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): VatCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VatCreateContainer);
