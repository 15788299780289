import * as React from "react";
import { LinearProgress } from "@material-ui/core";

interface FileProgressProps {
  progress: number;
}

export const FileProgress = (props: FileProgressProps) => {
  return (
    <div style={{ width: '100%' }}>
      <LinearProgress variant="buffer" value={props.progress} />
    </div>
  );
};

export default FileProgress;