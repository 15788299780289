import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { CompanyModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';
import { ApiModelClient, ExtendedApiModelClient } from './baseClient';

export default class CompanyClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<CompanyModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'company');
  }

  public async getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<VirtualModel[]>>(`virtual`, `page=${page}&search=${search}&id=${id}&filter=${filter}`);
  }

  public async getAsync(id: number): Promise<BaseDataResponse<CompanyModel>> {
    return await this.getHttpAsync<BaseDataResponse<CompanyModel>>(`${id}`, '');
  }

  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<CompanyModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<CompanyModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAllAsync(): Promise<BaseDataResponse<CompanyModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<CompanyModel[]>>('', '');
  }

  public async createAsync(model: CompanyModel): Promise<BaseDataResponse<CompanyModel>> {
    return await this.postHttpAsync<BaseDataResponse<CompanyModel>, CompanyModel>('', model);
  }

  public async updateAsync(model: CompanyModel): Promise<BaseDataResponse<CompanyModel>> {
    return await this.putHttpAsync<BaseDataResponse<CompanyModel>, CompanyModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
