import { createCancelItemAsyncAction, createLoadDependenciesAsyncAction, createSaveItemAsyncAction } from '../../core';

import { 
  AccommodationOfferRoomModel, 
  AccommodationOfferRoomTypeModel } from '../../api/interfaces';
import { createAction } from 'typesafe-actions';

const modelName = 'accommodationOfferRoomType';

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const editRoomTypeAction = createAction(`${modelName}/EDIT_ROOM_TYPE`)<AccommodationOfferRoomTypeModel>();

export const cancelRoomTypeAsync = createCancelItemAsyncAction(modelName);

export const saveRoomTypeAsync = createSaveItemAsyncAction<AccommodationOfferRoomModel>(modelName);

