import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AccommodationOffersEvidenceListDetail, { AccommodationOffersEvidenceListDetailProps, AccommodationOffersEvidenceListDetailDispatchProps, AccommodationOffersEvidenceListDetailStateProps } from './accommodationOffersEvidenceListDetail';
import * as actions from './actions';
import { AccommodationOffersEvidenceListModel, AccommodationOffersEvidenceListPriceItemModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { BaseListStateProps } from '../../components';
import { getAccommodationOffersEvidenceListPagination } from './';
import { columnValueChangedAction } from '../accommodationOffers/actions';
import { action } from 'typesafe-actions';

type AccommodationOffersEvidenceListCreateContainerStateProps = {
} & AccommodationOffersEvidenceListDetailStateProps;

type AccommodationOffersEvidenceListCreateContainerDispatchProps = {
} & AccommodationOffersEvidenceListDetailDispatchProps;

type AccommodationOffersEvidenceListCreateContainerProps = {} & AccommodationOffersEvidenceListDetailProps & AccommodationOffersEvidenceListDetailDispatchProps & AccommodationOffersEvidenceListDetailDispatchProps;

const createInitialValues = {

} as AccommodationOffersEvidenceListModel;

const AccommodationOffersEvidenceListCreateContainer: React.FunctionComponent<AccommodationOffersEvidenceListCreateContainerProps> = memo(props => {
  console.debug('AccommodationOfferEvidenceListModel');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    pagination,
    items,
    getItemsRequest,
    newItemRequest,
    loadTableDependenciesRequest,
    columnValueChangedAction,
    deleteItemRequest,
    downloadAction
  } = props;

  return (
    <React.Fragment>
      <AccommodationOffersEvidenceListDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        pagination={pagination}
        items={items}
        getItemsRequest={getItemsRequest}
        newItemRequest={newItemRequest}
        loadTableDependenciesRequest={loadTableDependenciesRequest}
        columnValueChangedAction={columnValueChangedAction}
        deleteItemRequest={deleteItemRequest}
        downloadAction={downloadAction}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOffersEvidenceListCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    items: state.accommodationOffersEvidenceList.itemList.allIds.map<AccommodationOffersEvidenceListPriceItemModel>(id => {
      return state.accommodationOffersEvidenceList.itemList.byId[id];
    }),
    pagination: getAccommodationOffersEvidenceListPagination(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOffersEvidenceListCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        loadTableDependenciesRequest: actions.loadTableDependenciesAsync.request,
        getItemsRequest: actions.getItemsAsync.request,
        newItemRequest: actions.newItemAsync.request,
        newTableItemRequest: actions.newTableItemAsync.request,
        columnValueChangedAction: actions.columnValueChangedAction,
        deleteItemRequest: actions.deleteItemAsync.request,
        downloadAction: actions.downloadAction
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOffersEvidenceListCreateContainer);
