import React, { } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid, Toolbar, Divider, withStyles, createStyles, Theme } from '@material-ui/core';

import { AccommodatedGuestModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import {
  GenericColumn,
  BaseTableRowModel,
  BaseListContainer,
  BaseListContainerDispatchProps,
  BaseListStateProps,
  BaseListDispatchProps
} from '../../components';
import { IconName, Icon } from '../../icons';
import { mergeWithIdColumn, TablePaginationModel } from '../../components/table';
import AccommodatedGuestsTable from './accommodatedGuestsTable';
import * as actions from './actions';
import { getAccommodatedGuestsPagination } from './selectors';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import AccommodatedGuestsToolbarContainer from './accommodatedGuestsToolbarContainer';
import moment from 'moment';

type AccommodatedGuestsContainerClassKey = 'root' | 'toolBarRoot' | 'divider' | 'relationSelectTextRoot' | 'relationSelectRoot';

type AccommodatedGuestsContainerStateProps = {
  pagination: TablePaginationModel;
} & BaseListStateProps<AccommodatedGuestModel>;

type AccommodatedGuestsContainerDispatchProps = {
} & BaseListDispatchProps<AccommodatedGuestModel> & BaseListContainerDispatchProps<AccommodatedGuestModel>;

type AccommodatedGuestsContainerProps = {
  classes: Partial<ClassNameMap<AccommodatedGuestsContainerClassKey>>;
} & AccommodatedGuestsContainerStateProps &
  AccommodatedGuestsContainerDispatchProps;

const columns = mergeWithIdColumn<AccommodatedGuestModel>([
  { id: 'name', label: 'Host', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'reservationNumber', label: 'Rezervace', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'roomName', label: 'Pokoj č.', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'from', label: 'Od', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodatedGuestModel): any => { return moment(row.from).format('DD. MM. yyyy') }
  },
  {
    id: 'to', label: 'Do', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodatedGuestModel): any => { return moment(row.to).format('DD. MM. yyyy') }
  },
  {
    id: 'to', label: '', minWidth: 170, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: AccommodatedGuestModel): any => { 
      const color = "#000";
      return <Icon toolTip={'Odhlášený host'} iconName={'sign-out-alt'} IconProps={{ style: { color: color } }} />;
    }
  },
]);

const withStylesFunction = withStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: "1"
    },
    toolBarRoot: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      minHeight: theme.spacing(9),
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    divider: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.primary.main
    },
    relationSelectTextRoot: { marginLeft: theme.spacing(2), marginRight: theme.spacing(1) },
    relationSelectRoot: { width: '300px', marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }
  })
);

const AccommodatedGuestsContainer: React.FunctionComponent<AccommodatedGuestsContainerProps> = props => {
  console.debug('AccommodatedGuestsContainer.render');

  return (<div className={props.classes.root}>

    <Grid container direction="row" alignItems="center" className={props.classes.root}>
      <BaseListContainer
        getItemsRequest={props.getItemsRequest}
        newItemRequest={props.newItemRequest}
        loadDependenciesRequest={props.loadDependenciesRequest}
        disableNew={true}
      >
        <AccommodatedGuestsTable
          rows={props.items}
          rowNoClick={true}
          paging={true}
          pagination={props.pagination}
          columns={columns as GenericColumn<BaseTableRowModel>[]}
          editAction={props.editItemRequest}
          deleteAction={props.deleteItemRequest}
          loadAction={props.getItemsRequest}
          toolbar={{ component: AccommodatedGuestsToolbarContainer }}
        />
      </BaseListContainer>
    </Grid>
  </div>);
}

const mapStateToProps = (state: ApplicationState): AccommodatedGuestsContainerStateProps => {
  return {
    items: state.accommodatedGuests.itemList.allIds.map<AccommodatedGuestModel>(id => {
      return state.accommodatedGuests.itemList.byId[id];
    }),
    requestInProgress: state.accommodatedGuests.requestInProgress,
    pagination: getAccommodatedGuestsPagination(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodatedGuestsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request,
      newItemRequest: actions.newItemAsync.request,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(AccommodatedGuestsContainer));