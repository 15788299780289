import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync, getAreAccommodationsLoaded } from '../accommodations';

import apiClient from '../../api/apiClientInstance';
import { FloorModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest } from '../../core';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getFloorsRequest),
    yield takeEvery(newItemAsync.request, newFloorRequest),
    yield takeEvery(saveItemAsync.request, saveFloorRequest),
    yield takeEvery(cancelItemAsync.request, cancelFloorRequest),
    yield takeEvery(editItemAsync.request, editFloorRequest),
    yield takeEvery(deleteItemAsync.request, deleteFloorRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type GetFloorsRequestGeneratorType =
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<FloorModel[]>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getFloorsRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetFloorsRequestGeneratorType, void, boolean & BaseDataResponse<FloorModel[]>> {
  try {
    console.debug('getFloorsRequest');

    const payload = action.payload as GetAllRequestAsyncActionPayload;
    const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
    if (!isReloadRequired) {
      yield put(getItemsAsync.cancel());
      return;
    }

    const response: BaseDataResponse<FloorModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.Floors,
      apiCallFnName: 'getAllAsync'
    });

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type NewFloorRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newFloorRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewFloorRequestGeneratorType, void, void> {
  try {
    console.debug('newFloorRequest');

    yield put(navigateTo(ROUTES.SETTINGS_FLOORS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditFloorRequestGeneratorType =
  | Promise<BaseDataResponse<FloorModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editFloorRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditFloorRequestGeneratorType, void, BaseDataResponse<FloorModel>> {
  try {
    console.debug('editFloorRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_FLOORS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteFloorRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.Floors,
  ROUTES.SETTINGS_FLOORS
);

const saveFloorRequest = registerSaveEntityRequest<FloorModel>(saveItemAsync.success, saveItemAsync.failure, apiClient.Floors, ROUTES.SETTINGS_FLOORS);

const cancelFloorRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_FLOORS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
