import { all, put, takeEvery, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { addFeeAction, removeFeeAction, saveItemAsync, cancelItemAsync, loadDependenciesAsync, selectPriceListItemAction } from './actions';
import { navigateTo, ROUTES } from '../../navigation';
import { registerCancelEntityRequest } from '../../core';
import { getArePriceListsLoaded, getItemsAsync as loadPriceListsAsync } from '../priceLists';
import { getArePriceListItemsLoaded, getItemsAsync as loadPriceListItemsAsync } from '../priceListItems';
import { getIsSomeModalOpened, closeTopModal } from '../../components';
import { getPriceListItemsById } from '../priceListItems/selectors';
import { calculatePriceAsyncAction } from '../accommodationOfferPriceCalculations';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(saveItemAsync.request, saveAccommodationOfferFeeRequest),
    yield takeEvery(cancelItemAsync.request, cancelAccommodationOfferFeeRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(addFeeAction, addFeeActionHandler),
    yield takeEvery(removeFeeAction, removeFeeActionHandler),
    yield takeEvery(selectPriceListItemAction, selectPriceListItemActionHandler)
  ]);
}

function* selectPriceListItemActionHandler(action: ReturnType<typeof selectPriceListItemAction>): Generator<SelectEffect, void, void & boolean> {
  try {
    const priceListItems = yield select(getPriceListItemsById);
  } catch (error) {
    console.log(error);
  }
}

function* addFeeActionHandler(action: ReturnType<typeof addFeeAction>): Generator<
  SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  , void, void & boolean> {
  try {
    const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
    if (isSomeModalOpend) {
      yield put(closeTopModal());
    }

    yield put(calculatePriceAsyncAction.request());
  } catch (error) {
    console.log(error);
  }
}

function* removeFeeActionHandler(action: ReturnType<typeof removeFeeAction>): Generator<
  SelectEffect
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  , void, void & boolean> {
  try {
    yield put(calculatePriceAsyncAction.request());
  } catch (error) {
    console.log(error);
  }
}

type SaveAccommodationOfferFeeRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof saveItemAsync.success>>
  | PutEffect<ActionType<typeof saveItemAsync.failure>>
  | PutEffect<ActionType<typeof calculatePriceAsyncAction.request>>
  | SelectEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* saveAccommodationOfferFeeRequest(
  action: ReturnType<typeof saveItemAsync.request>
): Generator<SaveAccommodationOfferFeeRequestGeneratorType, void, void & boolean> {
  try {
    const isSomeModalOpend: boolean = yield select(getIsSomeModalOpened);
    if (isSomeModalOpend) {
      yield put(closeTopModal());
    }

    yield put(calculatePriceAsyncAction.request());
  } catch (error) {
    yield put(saveItemAsync.failure(error));
  }
}

const cancelAccommodationOfferFeeRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_ACCOMMODATIONS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadPriceListsAsync.request>>
  | PutEffect<ActionType<typeof loadPriceListItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const arePriceListsLoaded: boolean = yield select(getArePriceListsLoaded);
    if (!arePriceListsLoaded) {
      yield put(loadPriceListsAsync.request());
    }

    const arePriceListItemsLoaded: boolean = yield select(getArePriceListItemsLoaded);
    if (!arePriceListItemsLoaded) {
      yield put(loadPriceListItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}