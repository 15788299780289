import produce from 'immer';
import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';

import { VirtualModel, VirtualState } from './types';
import { setVirtualSearch, setVirtualScroll, setVirtualValue, getVirtualData } from './actions';

export const initialState: VirtualState = {
  virtuals: {}
};

const virtualsActions = {
  setVirtualSearch, setVirtualValue, getVirtualData, setVirtualScroll
}

const virtualsReducer = createReducer<typeof initialState.virtuals, ActionType<typeof virtualsActions>>(initialState.virtuals)
  .handleAction(virtualsActions.getVirtualData.success, (state, action) => {
    const nextState = produce(state, draft => {
      const index = Number(action.payload.type);
      draft[index] = {
        ...draft[index],
        items: action.payload.items,
        total: action.payload.total
      } as VirtualModel;
    });

    return nextState;
  })
  .handleAction(virtualsActions.setVirtualScroll, (state, action) => {
    const nextState = produce(state, draft => {
      const index = Number(action.payload.type);
      draft[index] = { ...draft[index], page: action.payload.page } as VirtualModel;
    });

    return nextState;
  })
  .handleAction(virtualsActions.setVirtualValue, (state, action) => {
    const nextState = produce(state, draft => {
      const index = Number(action.payload.type);
      draft[index] = { ...draft[index], value: action.payload.value } as VirtualModel;
    });

    return nextState;
  })
  .handleAction(virtualsActions.setVirtualSearch, (state, action) => {
    const nextState = produce(state, draft => {
      const index = Number(action.payload.type);
      draft[index] = { ...draft[index], search: action.payload.search } as VirtualModel;
    });

    return nextState;
  });


export default combineReducers<VirtualState>({
  virtuals: virtualsReducer
});
