import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { AresModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class AresClient extends BaseAuthenticatedClient {
    constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
        super(authenticatedRequestHandler, errorHandler, axiosInstance, 'ares');
    }

    public async getAsync(ico: string): Promise<BaseDataResponse<AresModel>> {
        return await this.getHttpAsync<BaseDataResponse<AresModel>>('getCompanyInfo', `companyId=${ico}`);
    }
}
