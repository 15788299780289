import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import RoomTypesTable from './roomTypesTable';
import * as actions from './actions';
import { RoomTypeModel, AccommodationModel } from '../../api/interfaces';
import { tableConfiguration } from '../../configuration/table';
import { NormalizedListById } from '../../core';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  BaseListContainer,
  createRelation
} from '../../components';
import { getAccommodationsById } from '../accommodations';

type RoomTypesContainerStateProps = { accommodations: NormalizedListById<AccommodationModel> } & BaseListStateProps<RoomTypeModel>;

type RoomTypesContainerDispatchProps = {} & BaseListDispatchProps<RoomTypeModel> & BaseListContainerDispatchProps<RoomTypeModel>;

type RoomTypesContainerProps = {} & RoomTypesContainerStateProps & RoomTypesContainerDispatchProps;

const columns = mergeWithDefaultColumns<RoomTypeModel>([
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  { id: 'description', label: 'Popis', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'personCount', label: 'Počet osob', minWidth: 120, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'accommodationId',
    label: 'Ubytovací zařízení',
    minWidth: tableConfiguration.MinColumnWidthForAccommodation,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<RoomTypeModel, AccommodationModel>('accommodationId', 'name')
  }
]);

const RoomTypesContainer: React.FunctionComponent<RoomTypesContainerProps> = props => {
  console.debug('RoomTypesContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <RoomTypesTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        relations={{ accommodationId: { items: props.accommodations } }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): RoomTypesContainerStateProps => {
  const items = state.roomTypes.itemList.allIds.map<RoomTypeModel>(id => {
    return state.roomTypes.itemList.byId[id];
  }).sort((a, b) => (b.accommodationId ?? 0) - (a.accommodationId ?? 0));
  return {
    items: items,
    requestInProgress: state.roomTypes.requestInProgress,
    accommodations: getAccommodationsById(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomTypesContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypesContainer);
