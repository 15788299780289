import React, { } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';

import { PaymentMethodEnum, PaymentModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import {
  GenericColumn,
  BaseTableRowModel,
  BaseListContainer,
  BaseListContainerDispatchProps,
  BaseListStateProps,
  BaseListDispatchProps,
  actionsColumn,
  DownloadButton
} from '../../components';
import { mergeWithIdColumn, TablePaginationModel } from '../../components/table';
import PaymentsTable from './paymentsTable';
import * as actions from './actions';
import { getPaymentMethodsSelectItems, getPaymentsPagination } from './selectors';
import moment from 'moment';
import PaymentsFilterContainer, { default as ds } from './paymentsFilterContainer';

type PaymentsContainerStateProps = {
  pagination: TablePaginationModel;
} & BaseListStateProps<PaymentModel>;

type PaymentsContainerDispatchProps = {
} & BaseListDispatchProps<PaymentModel> & BaseListContainerDispatchProps<PaymentModel>;

type PaymentsContainerProps = {} & PaymentsContainerStateProps &
  PaymentsContainerDispatchProps;


const columns = mergeWithIdColumn<PaymentModel>([
  { id: 'reservationNumber', label: 'Číslo rezervace', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'userName', label: 'Vytvořil', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  { id: 'guestName', label: 'Host', minWidth: 200, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'dateOfPayment', label: 'Datum platby', minWidth: 100, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: PaymentModel): any => { return moment(row.dateOfPayment).format('DD. MM. yyyy') }
  },
  {
    id: 'amount', label: 'Částka', minWidth: 200, align: 'right', isVisible: true, isTableActions: false, customComponent: (row: PaymentModel): any => {
      return row.amount.toFixed(2);
    }
  },
  {
    id: 'paymentMethod', label: 'Způsob platby', minWidth: 200, align: 'left', isVisible: true, isTableActions: false,
    customComponent: (row: PaymentModel): any => {
      let result = "Nenastaveno";
      const translations = Object.fromEntries(getPaymentMethodsSelectItems().map(t => [t.value, t.text]));

      switch (row.paymentMethod) {
        case PaymentMethodEnum.Cash:
          result = translations[PaymentMethodEnum.Cash];
          break;
        case PaymentMethodEnum.Invoice:
          result = translations[PaymentMethodEnum.Invoice];
          break;
        case PaymentMethodEnum.Bank:
          result = translations[PaymentMethodEnum.Bank];
          break;
        case PaymentMethodEnum.Card:
          result = translations[PaymentMethodEnum.Card];
          break;
      }

      return result;
    }
  },
  {
    ...actionsColumn(), isEditable: false, actionComponent: (id: number, row: PaymentModel, classes): any => {
      return <React.Fragment>
        <DownloadButton
          url={`payment/invoice/${row.id}/report`}
          onClick={(): void => { console.debug('Download file') }}
        />
      </React.Fragment>
    }
  }
]);

const PaymentsContainer: React.FunctionComponent<PaymentsContainerProps> = props => {
  console.debug('PaymentsContainer.render');

  return (<Grid container direction="row" alignItems="center">
    <Typography>

    </Typography>
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
      disableNew={true}
    >
      <PaymentsTable
        rows={props.items}
        rowNoClick={true}
        paging={true}
        pagination={props.pagination}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        loadAction={props.getItemsRequest}
        filter={{ component: PaymentsFilterContainer }}
      />
    </BaseListContainer>
  </Grid>);
}

const mapStateToProps = (state: ApplicationState): PaymentsContainerStateProps => {
  return {
    items: state.payments.itemList.allIds.map<PaymentModel>(id => {
      return state.payments.itemList.byId[id];
    }),
    requestInProgress: state.payments.requestInProgress,
    pagination: getPaymentsPagination(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PaymentsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getAllItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      loadDependenciesRequest: actions.loadTableDependenciesAsync.request,
      newItemRequest: actions.newItemAsync.request,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(PaymentsContainer);