import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import produce from 'immer';

import { AccommodationOfferCalculationsState } from './types';
import {
  calculateAsyncAction,
  clearAction,
  editRoomTypeAction,
  columnValueChangedAction,
  setWizardAction,
  setCompanyFilterAction,
  setOfferHeaderAction,
  editOfferHeaderAction
} from './actions';
import { BaseActions } from '../../core';

import { addFeeAction, removeFeeAction } from '../accommodationOfferFees/actions';
import { toggleFloor } from '../accommodationOfferFloors/actions';
import { getItemAsync as getAccommodationOfferAsync } from '../accommodationOffers/actions';

import {
  AccommodationOfferCalculationModel, AccommodationOfferCalculationHeaderModel,
  AccommodationOfferRoomTypeCalculationModel, AccommodationOfferFloorCalculationModel, AccommodationOfferRoomTypeModel
} from '../../api/interfaces';

export const initialState: AccommodationOfferCalculationsState = {
  errorMessage: '',
  requestInProgress: false,
  offerHeader: { companyId: 0, numberOfPersons: 0, numberOfNights: 0 } as AccommodationOfferCalculationHeaderModel,
  freeRooms: { byId: {}, allIds: [] },
  partiallyOccupiedRooms: { byId: {}, allIds: [] },
  reservedRooms: { byId: {}, allIds: [] },
  fees: { byId: {}, allIds: [] },
  floors: { byId: {}, allIds: [] },
  wizard: {
    activeStep: 0,
    completed: [false, false, false, false, false],
    isValidated: [false, false, false, false, false],
  }
};

const errorMessageReducer = createReducer<typeof initialState.errorMessage, ActionType<BaseActions<AccommodationOfferCalculationModel>>>(
  initialState.errorMessage
);

const requestInProgressReducer = createReducer<typeof initialState.requestInProgress, ActionType<BaseActions<AccommodationOfferCalculationModel>>>(
  initialState.requestInProgress
);

const columnValueChangedReducer = (
  state: { byId: { [itemId: number]: AccommodationOfferRoomTypeCalculationModel }; allIds: number[] },
  action: ReturnType<typeof columnValueChangedAction>,
): { byId: { [itemId: number]: AccommodationOfferRoomTypeCalculationModel }; allIds: number[] } => {
  const newState = produce(state, draft => {
    let value = Number.parseInt(action.payload.newValue);
    if (action.payload.newValue.includes('.')){
      value = Number.parseFloat(action.payload.newValue);
    } 
    if (draft.byId[action.payload.rowId]) draft.byId[action.payload.rowId][action.payload.columnId] = value;
  });

  return newState;
};

const extendedActions = {
  calculateAsyncAction,
  columnValueChangedAction,
  clearAction,
  getAccommodationOfferAsync,
  setCompanyFilterAction,
  setOfferHeaderAction,
  editRoomTypeAction,
  editOfferHeaderAction
}

const offerHeaderReducer = createReducer<typeof initialState.offerHeader, ActionType<typeof extendedActions>>(initialState.offerHeader)
.handleAction(extendedActions.calculateAsyncAction.request, (state, action) => {
    const newState = produce(state, draft => {
      draft = action.payload;

      return draft;
    });

    return newState;
  }
).handleAction(extendedActions.editOfferHeaderAction, (state, action) => {
  const newState = produce(initialState.offerHeader, draft => {
    draft = action.payload;

    return draft;
  });

  return newState;
});

const freeRoomsReducer = createReducer<typeof initialState.freeRooms, ActionType<typeof extendedActions>>(initialState.freeRooms)
  .handleAction(extendedActions.calculateAsyncAction.success, (state, action) => {
    const newState = produce(initialState.freeRooms, draft => {
      draft.allIds = action.payload.freeRooms.map((model: AccommodationOfferRoomTypeCalculationModel) => {
        draft.byId[model.id] = model;
        return model.id;
      });
    });

    return newState;
  })
  .handleAction(extendedActions.columnValueChangedAction, (state, action) => {
    if (action.payload.stateId !== "freeRooms") {
      return state;
    }

    return columnValueChangedReducer(state, action);
  })
  .handleAction(extendedActions.clearAction, (state, action) => {
    return initialState.freeRooms;
  })
  .handleAction(extendedActions.editRoomTypeAction, (state, action) => {
    if (action.payload.type !== 0) {
      return state;
    }

    const newState = produce(state, draft => {
      const { id } = action.payload;
      const values = draft.byId[id];
      draft.byId[id] = { ...values, rooms: action.payload.rooms }
      return draft;
    });

    return newState;
  });

const partiallyOccupiedRoomsReducer = createReducer<typeof initialState.partiallyOccupiedRooms, ActionType<typeof extendedActions>>(
  initialState.partiallyOccupiedRooms
)
  .handleAction(extendedActions.calculateAsyncAction.success, (state, action) => {
    const newState = produce(initialState.partiallyOccupiedRooms, draft => {
      draft.allIds = action.payload.partiallyOccupiedRooms.map((model: AccommodationOfferRoomTypeCalculationModel) => {
        draft.byId[model.id] = model;
        return model.id;
      });
    });

    return newState;
  })
  .handleAction(extendedActions.columnValueChangedAction, (state, action) => {
    if (action.payload.stateId !== "partiallyOccupiedRooms") {
      return state;
    }

    return columnValueChangedReducer(state, action);
  })
  .handleAction(extendedActions.clearAction, (state, action) => {
    return initialState.partiallyOccupiedRooms;
  })
  .handleAction(extendedActions.editRoomTypeAction, (state, action) => {
    if (action.payload.type !== 1) {
      return state;
    }
    
    const newState = produce(state, draft => {
      const { id } = action.payload;
      const values = draft.byId[id];
      draft.byId[id] = { ...values, rooms: action.payload.rooms }
      return draft;
    });

    return newState;
  });

const reservedRoomsReducer = createReducer<typeof initialState.reservedRooms, ActionType<typeof extendedActions>>(initialState.reservedRooms)
  .handleAction(extendedActions.getAccommodationOfferAsync.success, (state, action) => {
    const newState = produce(initialState.reservedRooms, draft => {
      draft.allIds = action.payload.roomTypes.map((model: AccommodationOfferRoomTypeModel) => {
        draft.byId[model.id] = { ...model, roomIds: model.rooms.map(room => room.roomId) } as AccommodationOfferRoomTypeCalculationModel;
        return model.id;
      });

      return draft;
    });

    return newState;
  })
  .handleAction(extendedActions.columnValueChangedAction, (state, action) => {
    if (action.payload.stateId !== "reservedRooms") {
      return state;
    }

    return columnValueChangedReducer(state, action);
  })
  .handleAction(extendedActions.clearAction, (state, action) => {
    return initialState.reservedRooms;
  })
  .handleAction(extendedActions.editRoomTypeAction, (state, action) => {
    if (action.payload.type !== 2) {
      return state;
    }
    
    const newState = produce(state, draft => {
      const { id } = action.payload;
      const values = draft.byId[id];
      draft.byId[id] = { ...values, rooms: action.payload.rooms }
      return draft;
    });

    return newState;
  });


const floorsActions = {
  ...extendedActions,
  toggleFloor
};

const floorsReducer = createReducer<typeof initialState.floors, ActionType<typeof floorsActions>>(initialState.floors)
  .handleAction(floorsActions.calculateAsyncAction.success, (state, action) => {
    const newState = produce(initialState.floors, draft => {
      draft.allIds = action.payload.floors.map((model: AccommodationOfferFloorCalculationModel) => {
        const fixModel = { ...model };
        if (state.byId[model.id]) {
          fixModel.isSelected = state.byId[model.id].isSelected;
        }

        draft.byId[model.id] = fixModel;

        return model.id;
      });
    });
    return newState;
  })
  .handleAction(floorsActions.toggleFloor, (state, action) => {
    const newState = produce(state, draft => {
      if (draft.byId[action.payload.floorId]) draft.byId[action.payload.floorId] = { ...draft.byId[action.payload.floorId], isSelected: action.payload.checked };
    });
    return newState;
  })
  .handleAction(floorsActions.getAccommodationOfferAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      if (action.payload.floors) {
        draft.allIds = draft.allIds.map(id => {
          const index = action.payload.floors.findIndex(f => f.number === id);
          if (index !== -1) {
            const model = action.payload.floors[index];
            draft.byId[model.number] = { ...draft.byId[model.number], isSelected: true };
          }

          return id;
        });
      }
    });

    return newState;
  })
  .handleAction(floorsActions.clearAction, (state, action) => {
    return initialState.floors;
  });

const feesActions = {
  ...extendedActions,
  addFeeAction,
  removeFeeAction,
};

const feesReducer = createReducer<typeof initialState.fees, ActionType<typeof feesActions>>(initialState.fees)
  .handleAction(feesActions.addFeeAction, (state, action) => {
    let key = -1;
    if (!action.payload.id) {
      state.allIds.forEach(id => {
        if (id <= key) {
          key = id - 1;
        }
      });
    } else {
      key = action.payload.id;
    }

    const newState = produce(state, draft => {
      const index = draft.allIds.findIndex(id => id === key);
      if (index === -1) draft.allIds.push(key);

      draft.byId[key] = { ...action.payload, id: key };
    });

    return newState;
  })
  .handleAction(feesActions.removeFeeAction, (state, action) => {
    const newState = produce(state, draft => {
      const index = draft.allIds.findIndex(id => id === action.payload);
      if (index !== -1) draft.allIds.splice(index, 1);

      delete draft.byId[action.payload];
    });

    return newState;
  })
  .handleAction(feesActions.getAccommodationOfferAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      if (action.payload.fees) {
        draft.allIds = action.payload.fees.map(model => {
          draft.byId[model.id] = model;

          return model.id;
        });
      }
    });

    return newState;
  })
  .handleAction(feesActions.clearAction, (state, action) => {
    return initialState.fees;
  });

const wizardActions = {
  clearAction,
  setWizardAction
}

const wizardReducer = createReducer<typeof initialState.wizard, ActionType<typeof wizardActions>>(initialState.wizard)
  .handleAction(wizardActions.setWizardAction, (state, action) => {
    const newState = produce(state, draft => {
      draft.activeStep = action.payload.activeStep;
      draft.completed = action.payload.completed;
      draft.isValidated = action.payload.isValidated;
    });
    return newState;
  })
  .handleAction(wizardActions.clearAction, (state, action) => {
    return initialState.wizard;
  });

export default combineReducers<AccommodationOfferCalculationsState>({
  offerHeader: offerHeaderReducer,
  freeRooms: freeRoomsReducer,
  reservedRooms: reservedRoomsReducer,
  partiallyOccupiedRooms: partiallyOccupiedRoomsReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer,
  fees: feesReducer,
  floors: floorsReducer,
  wizard: wizardReducer
});
