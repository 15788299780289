import React, { memo } from 'react';
import { Checkbox, createStyles, makeStyles, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import * as actions from './actions';
import { Icon } from '../../icons';
import produce from 'immer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
    },
  })
);

type SelectionButtonModel = {
};

type SelectionButtonStateProps = {
  ids: Array<number>;
  items: { [itemId: number]: { id: number; name: string } };
};

type SelectionButtonDispatchProps = {
  setSelection: typeof actions.setSelection;
};

type SelectionButtonProps = {
  id: number;
  name: string;
} & SelectionButtonStateProps & SelectionButtonDispatchProps;

const SelectionButton: React.FunctionComponent<SelectionButtonProps> = memo(props => {
  console.debug('SelectionButton');
  const classes = useStyles();
  const isSelected = props.ids.includes(props.id);

  return (
    <React.Fragment>
      {isSelected && <Icon
        iconName={'layer-minus'}
        IconProps={{
          color: 'secondary',
          title: 'Odebrat',
          style: { cursor: 'pointer' },
          onClick: (event: React.MouseEvent<HTMLSpanElement>): void => {
            let ids = props.ids;
            if (ids.includes(props.id)) {
              ids = [...ids.filter(id => id !== props.id)];
            }

            const newItems = { ...props.items };
            delete newItems[props.id];

            props.setSelection({ ids: ids, isOpen: false, items: newItems });
          }
        }}
      />
      }
      {!isSelected && <Icon
        iconName={'layer-plus'}
        IconProps={{
          color: 'secondary',
          title: 'Přidat',
          style: { cursor: 'pointer' },
          onClick: (event: React.MouseEvent<HTMLSpanElement>): void => {
            let ids = props.ids;
            if (!ids.includes(props.id)) {
              ids = [...ids, props.id];
            }

            props.setSelection({
              ids: ids, isOpen: false, items: {
                ...props.items, [props.id]: {
                  id: props.id,
                  name: props.name
                }
              }
            });
          }
        }} />}
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState): SelectionButtonStateProps => {
  return {
    ids: state.accommodationOffers.itemList.selection?.ids || [],
    items: state.accommodationOffers.itemList.selection?.items || {}
  };
};

const mapDispatchToProps = (dispatch: Dispatch): SelectionButtonDispatchProps =>
  bindActionCreators({
    setSelection: actions.setSelection
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SelectionButton);
