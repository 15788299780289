import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';

import { nameofFactory } from '../../utils/nameofFactory';
import { accommodationValidationSchema } from './validations';
import { AccommodationModel } from '../../api/interfaces';
import {
  FormikInput,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps
} from '../../components';

export type AccommodationDetailStateProps = {} & BaseDetailStateProps<AccommodationModel>;

export type AccommodationDetailDispatchProps = {} & BaseDetailDispatchProps<AccommodationModel>;

export type AccommodationDetailProps = {} & BaseDetailProps<AccommodationModel> & AccommodationDetailStateProps & AccommodationDetailDispatchProps;

const nameof = nameofFactory<AccommodationModel>();

const renderForm = (props: BaseDetailContainerWrappedComponentProps<AccommodationModel>): React.ReactElement => {
  const { classes, errorMessage, requestInProgress, initialValues } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<AccommodationModel>
      initialValues={initialValues}
      onSubmit={(values: AccommodationModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={accommodationValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="hotel"
                    labelText="Název"
                    name={nameof('name')}
                    errorMessage={errorMessage}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="sticky-note"
                    labelText="Popisek"
                    name={nameof('description')}
                    errorMessage={errorMessage}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const AccommodationDetail: React.FunctionComponent<AccommodationDetailProps> = memo(props => {
  console.debug('AccommodationDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest
  } = props;

  return (
    <BaseDetailContainer<AccommodationModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
    >
      {renderForm}
    </BaseDetailContainer>
  );
});

export default AccommodationDetail;
