import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse, BaseResponse } from '../responses';
import { RoomTypeModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class RoomTypeClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'roomtype');
  }

  public async getAllAsync(): Promise<BaseDataResponse<RoomTypeModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<RoomTypeModel[]>>('', '');
  }

  public async createAsync(model: RoomTypeModel): Promise<BaseDataResponse<RoomTypeModel>> {
    return await this.postHttpAsync<BaseDataResponse<RoomTypeModel>, RoomTypeModel>('', model);
  }

  public async updateAsync(model: RoomTypeModel): Promise<BaseDataResponse<RoomTypeModel>> {
    return await this.putHttpAsync<BaseDataResponse<RoomTypeModel>, RoomTypeModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
