import { createReducer } from 'typesafe-actions';

import { FileProps, FileUploadState, FileUploadAction } from './types';
import * as actions from './actions';

import { Stack } from '../../utils/stack';

export const initialState: FileUploadState = {
  progress: 0
};

const fileUploadReducer = createReducer<FileUploadState, FileUploadAction>(initialState)
  .handleAction(actions.setProgress, (state, action) => {
     return { progress: action.payload };
  });

export default fileUploadReducer;
