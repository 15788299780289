import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Formik } from 'formik';
import { DeepReadonly } from 'utility-types';
import _ from 'lodash';
import * as Yup from 'yup';

import {
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikRelationSelect,
  RelationSelectItem,
  FormikDatePicker
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import {
  reservationCheckOutValidationSchema,
  reservationCheckOutLogOnValidationSchema,
  reservationCheckOutLogOffValidationSchema
} from './validations';
import {
  ReservationGuestStornoModel, ReservationRoomModel,
} from '../../api/interfaces';
import { ReservationGuestCheckOutEditType, selectRoomsSelectItemsByGuestId } from '.';

export type ReservationGuestStornoDetailStateProps = {
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  roomsSelectItems: DeepReadonly<RelationSelectItem[]>;
  rooms: { [itemId: number]: DeepReadonly<ReservationRoomModel> };
  type: ReservationGuestCheckOutEditType;
  info: string;
} & BaseDetailStateProps<ReservationGuestStornoModel>;

export type ReservationGuestStornoDetailDispatchProps = {
  selectRoomsSelectItemsByGuestId: typeof selectRoomsSelectItemsByGuestId;
} & BaseDetailDispatchProps<ReservationGuestStornoModel>;

export type ReservationGuestStornoDetailProps = {
} & BaseDetailProps<ReservationGuestStornoModel> & ReservationGuestStornoDetailStateProps & ReservationGuestStornoDetailDispatchProps;

type RenderFormProps = {
  roomsSelectItems: DeepReadonly<RelationSelectItem[]>;
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  rooms: { [itemId: number]: DeepReadonly<ReservationRoomModel> };
  type: ReservationGuestCheckOutEditType;
  info: string;
} & BaseDetailContainerWrappedComponentProps<ReservationGuestStornoModel>;

const nameof = nameofFactory<ReservationGuestStornoModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    guestsSelectItems,
    roomsSelectItems,
    rooms,
    cancelActionRequest,
    type,
    info
  } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    cancelActionRequest();
  };

  return (
    <Formik<ReservationGuestStornoModel>
      initialValues={initialValues}
      onSubmit={(values: ReservationGuestStornoModel, { setSubmitting }): void => {
        setSubmitting(true);
        props.saveActionRequest(values);
      }}
      validationSchema={(): any => Yup.lazy(() => {
        if (type === ReservationGuestCheckOutEditType.Logoff) {
          return reservationCheckOutLogOffValidationSchema;
        } else if (type === ReservationGuestCheckOutEditType.Logon) {
          return reservationCheckOutLogOnValidationSchema;
        }

        return reservationCheckOutValidationSchema;
      })}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, setFieldValue, values } = formProps;
        const filteredRoomsSelectItems = values.guestId > 0 ? selectRoomsSelectItemsByGuestId(values.guestId, rooms) : roomsSelectItems;
        return (
          <form onSubmit={handleSubmit} autoComplete="off" style={{ margin: '12px' }}>
            <Grid container className={classes.grid} spacing={3}>
             {info && <Grid item xs={12}>{info}</Grid>}
                <Grid item xs={12}>
                  <FormikDatePicker
                    iconName="address-card"
                    labelText="Storno k datu"
                    InputLabelProps={{
                      shrink: true
                    }}
                    name={nameof('to')}
                    showErrorWhenNotTouched={true}
                  />
                </Grid>
              <Grid item xs={12}>
                <FormikRelationSelect
                  iconName="hotel"
                  labelText="Host"
                  name={nameof("guestId")}
                  showErrorWhenNotTouched={false}
                  errorMessage={errorMessage}
                  items={guestsSelectItems as RelationSelectItem[]}
                  onChange={(newValue: number): void => {
                    // props.getInfo({ ...values, guestId: newValue });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikRelationSelect
                  iconName="hotel"
                  labelText="Pokoj"
                  name="roomId"
                  showErrorWhenNotTouched={false}
                  errorMessage={errorMessage}
                  items={filteredRoomsSelectItems as RelationSelectItem[]}
                  onChange={(value: number): void => {
                    const room = filteredRoomsSelectItems.find(room => room.value === value);
                    setFieldValue('roomName', room?.text);
                  }}
                />
              </Grid>
              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const ReservationGuestStornoDetail: React.FunctionComponent<ReservationGuestStornoDetailProps> = memo(props => {
  console.debug('ReservationGuestStornoDetail');
  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    guestsSelectItems,
    roomsSelectItems,
    rooms,
    type,
    info
  } = props;

  return (
    <BaseDetailContainer<ReservationGuestStornoModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        guestsSelectItems: guestsSelectItems,
        roomsSelectItems: roomsSelectItems,
        rooms: rooms,
        type: type,
        info: info
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default ReservationGuestStornoDetail;
