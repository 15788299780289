import { createTheme, Theme } from '@material-ui/core/styles';

import { default as DefaultThemeOptions } from './defaultThemeOptions';
import ColorPalettes, { PaletteProvider, DefaultColorPaletteKey } from './colorPalettes';

class ThemeService {
  createTheme = (themeName: string): Theme => {
    const tennantPalette = PaletteProvider.getPallete(themeName);
    const finalThemeOptions = { ...DefaultThemeOptions, palette: { ...tennantPalette, ...DefaultThemeOptions.palette } };

    return createTheme(finalThemeOptions);
  };

  getThemeName = (tenantKey: string): string => {
    for (const themeKey in ColorPalettes) {
      if (themeKey === tenantKey) {
        return themeKey;
      }
    }

    return DefaultColorPaletteKey;
  };
}

export default new ThemeService();
