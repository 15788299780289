import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import produce from 'immer';

import { AccommodationOffersEvidenceListState, AccommodationOffersEvidenceListTypesAction } from './types';
import * as actions from './actions';
import { BaseActions, createBaseErrorMessageReducer, createBaseRequestInProgressReducer, GetAllRequestAsyncActionPayload } from '../../core';
import { AccommodationOffersEvidenceListModel, AccommodationOffersEvidenceListPriceItemModel } from '../../api/interfaces';

export const initialState: AccommodationOffersEvidenceListState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false,
    page: 0,
    skip: 0,
    take: 100,
    filter: "",
    selection: { isOpen: false, ids: [], items: {} }
  },
  id: 0,
  errorMessage: '',
  requestInProgress: false
};

const columnValueChangedReducer = (
  state: { byId: { [itemId: number]: AccommodationOffersEvidenceListPriceItemModel }; allIds: number[]; loaded: boolean },
  action: ReturnType<typeof actions.columnValueChangedAction>,
): { byId: { [itemId: number]: AccommodationOffersEvidenceListPriceItemModel }; allIds: number[]; loaded: boolean } => {
  const newState = produce(state, draft => {
    if (draft.byId[action.payload.rowId]) draft.byId[action.payload.rowId][action.payload.columnId] = action.payload.newValue;
  });

  return newState;
};

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync,
} as BaseActions<AccommodationOffersEvidenceListPriceItemModel>;

const itemListReducer = createReducer<typeof initialState.itemList, AccommodationOffersEvidenceListTypesAction>(initialState.itemList)
  .handleAction(actions.setPagination, (state, action) => {
    const newState = produce(state, draft => {
      draft.total = action.payload.total;
      draft.page = action.payload.page;
      draft.skip = action.payload.skip;
      draft.take = action.payload.take;
      draft.filter = action.payload.filter;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft.allIds = action.payload.map(model => {
        draft.byId[model.id] = model;

        return model.id;
      });
      draft.loaded = true;
    });

    return newState;
  })
  .handleAction(actions.getItemsAsync.request, (state, action) => {
    if (action.payload as GetAllRequestAsyncActionPayload) {
      return initialState.itemList;
    }

    return state;
  }).handleAction(actions.columnValueChangedAction, (state, action) => {
    return columnValueChangedReducer(state, action);
  }).handleAction(actions.deleteItemAsync.request, (state, action) => {
    const newState = produce(state, draft => {
      const index = draft.allIds.findIndex(i => action.payload === i);
      delete draft.allIds[index];
      delete draft.byId[action.payload]
    });

    return newState;
  }).handleAction(actions.newItemAsync.request, (state, action) => {
    const newState = produce(state, draft => {
      const minId = Math.min(...draft.allIds);
      let newMinId = minId - 1;
      if (minId > 0)
        newMinId = 0;
      draft.allIds = [...draft.allIds, newMinId]
      
      draft.byId[newMinId] = {
        id: newMinId,
        amount: 0,
        name: '',
        price: 0,
        reservationNumber: '',
        totalPrice: 0
      } as AccommodationOffersEvidenceListPriceItemModel;
    });

    return newState;
  });

const idActions = {
} as BaseActions<AccommodationOffersEvidenceListModel>;


const idReducer = createReducer<typeof initialState.id, ActionType<typeof idActions>>(initialState.id);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<AccommodationOffersEvidenceListState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer,
  id: idReducer
});
