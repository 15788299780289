import { createAction, createAsyncAction, PayloadActionCreator } from 'typesafe-actions';
import { TablePaginationModel } from '../components';
import { TablePageChangeModel, TableSelectionModel } from '../components/table';

import { GetAllRequestAsyncActionPayload } from './types';

export type CustomAsyncAction<TRequestPayloadType, TSuccessPayloadType> = {
  request: PayloadActionCreator<string, TRequestPayloadType>;
  success: PayloadActionCreator<string, TSuccessPayloadType>;
  failure: PayloadActionCreator<string, Error>;
};

export type CustomCancelableAsyncAction<TRequestPayloadType, TSuccessPayloadType> = {
  cancel: PayloadActionCreator<string, void>;
} & CustomAsyncAction<TRequestPayloadType, TSuccessPayloadType>;


export const createGetItemAsyncAction = <TModelType>(modelName: string) /* : CustomAsyncAction<GetAllAsyncActionPayload | void, TModelType[]>  */ =>
  createAsyncAction<'GET_REQUEST', 'GET_SUCCESS', 'GET_FAILURE', 'GET_CANCEL'>(
    `${modelName}/GET_REQUEST` as 'GET_REQUEST',
    `${modelName}/GET_SUCCESS` as 'GET_SUCCESS',
    `${modelName}/GET_FAILURE` as 'GET_FAILURE',
    `${modelName}/GET_CANCEL` as 'GET_CANCEL'
  )<number, TModelType, Error, void>();

export const createGetItemsAsyncAction = <TModelType>(modelName: string) /* : CustomAsyncAction<GetAllAsyncActionPayload | void, TModelType[]>  */ =>
  createAsyncAction<'GET_ITEMS_REQUEST', 'GET_ITEMS_SUCCESS', 'GET_ITEMS_FAILURE', 'GET_ITEMS_CANCEL'>(
    `${modelName}/GET_ITEMS_REQUEST` as 'GET_ITEMS_REQUEST',
    `${modelName}/GET_ITEMS_SUCCESS` as 'GET_ITEMS_SUCCESS',
    `${modelName}/GET_ITEMS_FAILURE` as 'GET_ITEMS_FAILURE',
    `${modelName}/GET_ITEMS_CANCEL` as 'GET_ITEMS_CANCEL'
  )<GetAllRequestAsyncActionPayload | TablePageChangeModel | void, TModelType[], Error, void>();

export const createNewItemAsyncAction = (modelName: string) /* : CustomAsyncAction<void, void> */ =>
  createAsyncAction<'NEW_REQUEST', 'NEW_SUCCESS', 'NEW_FAILURE'>(
    `${modelName}/NEW_REQUEST` as 'NEW_REQUEST',
    `${modelName}/NEW_SUCCESS` as 'NEW_SUCCESS',
    `${modelName}NEW_FAILURE` as 'NEW_FAILURE'
  )<void, void, Error>();

export const createEditItemAsyncAction = (modelName: string) /* : CustomAsyncAction<number, void> */ =>
  createAsyncAction<'EDIT_REQUEST', 'EDIT_SUCCESS', 'EDIT_FAILURE'>(
    `${modelName}/EDIT_REQUEST` as 'EDIT_REQUEST',
    `${modelName}/EDIT_SUCCESS` as 'EDIT_SUCCESS',
    `${modelName}/EDIT_FAILURE` as 'EDIT_FAILURE'
  )<number, void, Error>();

export const createDeleteItemAsyncAction = (modelName: string) /* : CustomAsyncAction<number, void> */ =>
  createAsyncAction<'DELETE_REQUEST', 'DELETE_SUCCESS', 'DELETE_FAILURE'>(
    `${modelName}/DELETE_REQUEST` as 'DELETE_REQUEST',
    `${modelName}/DELETE_SUCCESS` as 'DELETE_SUCCESS',
    `${modelName}/DELETE_FAILURE` as 'DELETE_FAILURE'
  )<number, number, Error>();

export const createSaveItemAsyncAction = <TModelType>(modelName: string) /* : CustomAsyncAction<TModelType, TModelType>  */ =>
  createAsyncAction<'SAVE_REQUEST', 'SAVE_SUCCESS', 'SAVE_FAILURE'>(
    `${modelName}/SAVE_REQUEST` as 'SAVE_REQUEST',
    `${modelName}/SAVE_SUCCESS` as 'SAVE_SUCCESS',
    `${modelName}/SAVE_FAILURE` as 'SAVE_FAILURE'
  )<TModelType, TModelType, Error>();

export const createCancelItemAsyncAction = (modelName: string) /* : CustomAsyncAction<void, void> */ =>
  createAsyncAction<'CANCEL_REQUEST', 'CANCEL_SUCCESS', 'CANCEL_FAILURE'>(
    `${modelName}/CANCEL_REQUEST` as 'CANCEL_REQUEST',
    `${modelName}/CANCEL_SUCCESS` as 'CANCEL_SUCCESS',
    `${modelName}/CANCEL_FAILURE` as 'CANCEL_FAILURE'
  )<void, void, Error>();

export const createLoadDependenciesAsyncAction = (modelName: string) /* : CustomAsyncAction<void, void> */ =>
  createAsyncAction<'LOAD_DEPENDENCIES_REQUEST', 'LOAD_DEPENDENCIES_SUCCESS', 'LOAD_DEPENDENCIES_FAILURE'>(
    `${modelName}/LOAD_DEPENDENCIES_REQUEST` as 'LOAD_DEPENDENCIES_REQUEST',
    `${modelName}/LOAD_DEPENDENCIES_SUCCESS` as 'LOAD_DEPENDENCIES_SUCCESS',
    `${modelName}/LOAD_DEPENDENCIES_FAILURE` as 'LOAD_DEPENDENCIES_FAILURE'
  )<void, void, Error>();

export const createCopyItemAsyncAction = (modelName: string) /* : CustomAsyncAction<number, void> */ =>
  createAsyncAction<'COPY_REQUEST', 'COPY_SUCCESS', 'COPY_FAILURE'>(
    `${modelName}/COPY_REQUEST` as 'COPY_REQUEST',
    `${modelName}/COPY_SUCCESS` as 'COPY_SUCCESS',
    `${modelName}/COPY_FAILURE` as 'COPY_FAILURE'
  )<number, void, Error>();

export const createPagination = (modelName: string) => createAction(`${modelName}/TABLE_PAGINATION`)<TablePaginationModel>();

export const createSelection = (modelName: string) => createAction(`${modelName}/TABLLE_SELECTION`)<TableSelectionModel>();

export const createGetAllItemsAsyncAction = <TModelType>(modelName: string) /* : CustomAsyncAction<GetAllAsyncActionPayload | void, TModelType[]>  */ =>
  createAsyncAction<'GET_ALL_ITEMS_REQUEST', 'GET_ALL_ITEMS_SUCCESS', 'GET_ALL_ITEMS_FAILURE', 'GET_ALL_ITEMS_CANCEL'>(
    `${modelName}/GET_ALL_ITEMS_REQUEST` as 'GET_ALL_ITEMS_REQUEST',
    `${modelName}/GET_ALL_ITEMS_SUCCESS` as 'GET_ALL_ITEMS_SUCCESS',
    `${modelName}/GET_ALL_ITEMS_FAILURE` as 'GET_ALL_ITEMS_FAILURE',
    `${modelName}/GET_ALL_ITEMS_CANCEL` as 'GET_ALL_ITEMS_CANCEL'
  )<GetAllRequestAsyncActionPayload | void, TModelType[], Error, void>();
