import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { NormalizedListById } from '../../core';
import { StateModel } from '../../api/interfaces';

export const getAreStatesLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
    return state.states.itemList.loaded;
};

export const getStateById = (state: ApplicationState): NormalizedListById<StateModel> => {
    return state.states.itemList.byId;
};

export const selectStatesSelectItems = createSelector(
    [getStateById],
    (statesById): DeepReadonly<RelationSelectItem[]> => {
        console.debug('Calling selectStatesSelectItems...');

        return Object.values(statesById).map<RelationSelectItem>(state => {
            return { text: state.name, value: state.id } as RelationSelectItem;
        });
    }
);