import React from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import { FormikInput } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import * as actions from './actions';
import { TablePageChangeModel } from '../../components/table';

const nameof = nameofFactory<CompaniesFilterModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '30px'
    }
  })
);

type CompaniesFilterModel = {
  name: string;
  ico: string;
  vat: string;
  city: string;
};

type CompaniesFilterStateProps = {
};

type CompaniesFilterDispatchProps = {
  getItems: typeof actions.getItemsAsync.request;
};

type CompaniesFilterProps = {
  onClose(): void;
} & CompaniesFilterStateProps & CompaniesFilterDispatchProps;

const CompaniesFilter: React.FunctionComponent<CompaniesFilterProps> = props => {
  console.debug('CompaniesFilter');
  const classes = useStyles();

  const { getItems } = props;
  return (
    <React.Fragment>
      <Formik<CompaniesFilterModel>
        initialValues={{ name: '', ico: '', vat: '', city: '', }}
        onSubmit={(values: CompaniesFilterModel): void => {
          console.debug(values);
          let filter = "";
          if (values.name.length > 0) {
            filter += `name=${values.name}%26`
          }
          if (values.ico.length > 0) {
            filter += `ico=${values.ico}%26`
          }
          if (values.vat.length > 0) {
            filter += `vat=${values.vat}%26`
          }
          if (values.city.length > 0) {
            filter += `city=${values.city}%26`
          }

          getItems({ page: 0, filter: filter, skip: 0 } as TablePageChangeModel);
          props.onClose();
        }}
      >
        {(formProps): React.ReactElement => {
          const { handleSubmit, resetForm, submitForm } = formProps;
          return (
            <form style={{ width: '500px' }} onSubmit={handleSubmit} autoComplete="off">
              <Grid container className={classes.grid} spacing={3}>
                <Grid container item>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="Název"
                      name={nameof('name')}
                      InputProps={{
                      }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="IČO"
                      name={nameof('ico')}
                      InputProps={{
                      }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="DIČ"
                      name={nameof('vat')}
                      InputProps={{
                      }} />
                  </Grid>
                  <Grid container item xs={12}>
                    <FormikInput
                      iconName="address-card"
                      labelText="Město"
                      name={nameof('city')}
                      InputProps={{
                      }} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={(): void => {
                      resetForm();
                      submitForm();
                    }}>
                      Odstranit vše
                    </Button>&nbsp;
                    <Button color="primary" type="submit" variant="contained">
                      Hledat
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>);
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): CompaniesFilterStateProps => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch): CompaniesFilterDispatchProps =>
  bindActionCreators({
    getItems: actions.getItemsAsync.request
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesFilter);
