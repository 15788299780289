import * as Yup from 'yup';

import { accommodationIdValidationSchema, nameValidationSchema } from '../validations';

export const roomValidationSchema = Yup.object().shape({
  name: nameValidationSchema,
  floorId: Yup.number()
    .required('Patro je povinné')
    .min(1, 'Patro musí být nastaveno'),
  accommodationId: accommodationIdValidationSchema,
  roomTypeId: Yup.number()
    .required('Typ pokoje musí být vybrán')
    .min(1, 'Typ pokoje musí být vybrán')
});
