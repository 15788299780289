import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient, ExtendedApiModelClient } from '.';
import { BaseDataResponse, BasePageDataResponse, BaseResponse } from '../responses';
import { GuestModel, VirtualModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class GuestClient extends BaseAuthenticatedClient implements ExtendedApiModelClient<GuestModel> {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'guest');
  }

  public async getByVirtualPageAsync(page: number, search: string, id: number, filter: string): Promise<BasePageDataResponse<VirtualModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<VirtualModel[]>>(`virtual`, `page=${page}&search=${search}&id=${id}&filter=${filter}`);
  }

  public async getAsync(id: number): Promise<BaseDataResponse<GuestModel>> {
    return await this.getHttpAsync<BaseDataResponse<GuestModel>>(`${id}`, '');
  }

  public async getByPageAsync(skip: number, take: number, filter: string): Promise<BasePageDataResponse<GuestModel[]>> {
    return await this.getHttpAsync<BasePageDataResponse<GuestModel[]>>(``, `skip=${skip}&take=${take}&filter=${filter}`);
  }

  public async getAllAsync(): Promise<BaseDataResponse<GuestModel[]>> {
    return await this.getHttpAsync<BaseDataResponse<GuestModel[]>>('', '');
  }

  public async createAsync(model: GuestModel): Promise<BaseDataResponse<GuestModel>> {
    return await this.postHttpAsync<BaseDataResponse<GuestModel>, GuestModel>('', model);
  }

  public async updateAsync(model: GuestModel): Promise<BaseDataResponse<GuestModel>> {
    return await this.putHttpAsync<BaseDataResponse<GuestModel>, GuestModel>(`${model.id}`, model);
  }

  public async deleteAsync(id: number): Promise<BaseResponse> {
    return await this.deleteHttpAsync<BaseResponse>(`${id}`);
  }
}
