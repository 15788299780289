import { all, put, takeEvery, select, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync, setPagination, getAllItemsAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync } from '../accommodations';
import { getItemsAsync as loadStatesAsync } from '../states';

import apiClient from '../../api/apiClientInstance';
import { CompanyModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded, getCompaniesPagination } from './selectors';
import { registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest, registerGetEntitiesByPageRequest } from '../../core';
import { getAreItemsLoaded as getAreStatesLoaded } from '../states/selectors';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getCompaniesRequest),
    yield takeEvery(getAllItemsAsync.request, getAllCompaniesRequest),
    yield takeEvery(newItemAsync.request, newCompanyRequest),
    yield takeEvery(saveItemAsync.request, saveCompanyRequest),
    yield takeEvery(cancelItemAsync.request, cancelCompanyRequest),
    yield takeEvery(editItemAsync.request, editCompanyRequest),
    yield takeEvery(deleteItemAsync.request, deleteCompanyRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type GetAllCompaniesRequestGeneratorType =
  | SelectEffect
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<CompanyModel[]>>
  | PutEffect<ActionType<typeof getAllItemsAsync.success>>
  | PutEffect<ActionType<typeof getAllItemsAsync.failure>>;

function* getAllCompaniesRequest(
  action: ReturnType<typeof getAllItemsAsync.request>
): Generator<GetAllCompaniesRequestGeneratorType, void, boolean & BaseDataResponse<CompanyModel[]>> {
  try {
    console.debug('getAllCompaniesRequest');

    const response: BaseDataResponse<CompanyModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.Companies,
      apiCallFnName: 'getAllAsync'
    });

    if (response.resultCode === 0) {
      yield put(getAllItemsAsync.success(response.data));
    } else {
      yield put(getAllItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getAllItemsAsync.failure(error));
  }
}

const getCompaniesRequest = registerGetEntitiesByPageRequest<CompanyModel>(
  getItemsAsync.success,
  getItemsAsync.failure,
  getItemsAsync.cancel,
  apiClient.Companies,
  setPagination,
  getCompaniesPagination
);

type NewCompanyRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newCompanyRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewCompanyRequestGeneratorType, void, void> {
  try {
    console.debug('newCompanyRequest');

    yield put(navigateTo(ROUTES.SETTINGS_COMPANIES_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditCompanyRequestGeneratorType =
  | Promise<BaseDataResponse<CompanyModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editCompanyRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditCompanyRequestGeneratorType, void, BaseDataResponse<CompanyModel>> {
  try {
    console.debug('editCompanyRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_COMPANIES_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteCompanyRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.Companies,
  ROUTES.SETTINGS_COMPANIES
);

const saveCompanyRequest = registerSaveEntityRequest<CompanyModel>(
  saveItemAsync.success,
  saveItemAsync.failure,
  apiClient.Companies,
  ROUTES.SETTINGS_COMPANIES
);

const cancelCompanyRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_COMPANIES);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadStatesAsync.request>>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areStatesLoaded = yield select(getAreStatesLoaded);
    if (!areStatesLoaded) {
      yield put(loadStatesAsync.request());
    }

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
