import React from 'react';
import { Dialog } from '@material-ui/core';

import * as actions from './actions';
import { ModalDialogModel } from './types';

type ModalDialogFormProps = { modal: ModalDialogModel; visible: boolean; close: typeof actions.closeModal };

class ModalDialogForm extends React.PureComponent<ModalDialogFormProps> {
  static defaultProps = {};

  handleOnClose = () => {
    this.props.close(this.props.modal.id);
  };

  render(): React.ReactElement {
    return (
      <Dialog
        fullWidth={this.props.modal.fullWidth ?? true}
        maxWidth="lg"
        onClose={this.handleOnClose}
        aria-labelledby="modal-dialog-title"
        open={true}
        // style={Object.assign({ visibility: this.props.visible ? 'visible' : 'hidden' }, this.props.modal.componentProps?.style)}
        style={{ visibility: this.props.visible ? 'visible' : 'hidden' }}
      >
        <this.props.modal.component modalDialog={true} modalDialogRoute={this.props.modal.route} {...this.props.modal.componentProps} />
      </Dialog>
    );
  }
}

export default ModalDialogForm;
