import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import CompanyDetail, { CompanyDetailStateProps, CompanyDetailDispatchProps, CompanyDetailProps } from './companiesDetail';
import * as actions from './actions';
import { CompanyModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectStatesSelectItems } from '../states/selectors';
import { getCompanyInfoAsync } from '../ares';

type CompanyCreateContainerStateProps = {} & CompanyDetailStateProps;

type CompanyCreateContainerDispatchProps = {} & CompanyDetailDispatchProps;

type CompanyCreateContainerProps = {} & CompanyDetailProps & CompanyCreateContainerStateProps & CompanyCreateContainerDispatchProps;

const CompanyCreateContainer: React.FunctionComponent<CompanyCreateContainerProps> = memo(props => {
  console.debug('CompanyCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    stateSelectItems,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    getCompanyInfoRequest
  } = props;

  return (
    <CompanyDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      stateSelectItems={stateSelectItems}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      getCompanyInfoRequest={getCompanyInfoRequest}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): CompanyCreateContainerStateProps => {
  return {
    initialValues: state.companies.current,
    errorMessage: state.companies.errorMessage,
    requestInProgress: state.companies.requestInProgress,
    stateSelectItems: selectStatesSelectItems(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): CompanyCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        getCompanyInfoRequest: getCompanyInfoAsync.request
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreateContainer);
