import {
    createGetItemsAsyncAction,
    createNewItemAsyncAction,
    createEditItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createDeleteItemAsyncAction,
    createLoadDependenciesAsyncAction
} from '../../core';

import { RoomTypeModel } from '../../api/interfaces';

const modelName = 'roomTypes';

export const getItemsAsync = createGetItemsAsyncAction<RoomTypeModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<RoomTypeModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);
