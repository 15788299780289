import React from 'react';
import { Collapse } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { StickyAlertModel } from './types';
import { alertAutoCloseConfig } from '../../configuration/alerts';
import { ROUTES } from '../../navigation';

import { SignInMinimalContainer } from '../signIn';
import { AddButton } from '../../components';

const initialState = { isAlertVisible: true };

type StickyAlertState = Readonly<typeof initialState>;

type StickyAlertProps = {
  alert: StickyAlertModel;
  autoCloseConfig: typeof alertAutoCloseConfig;
  canClose: boolean;
  onClose(id: string): void;
};

class StickyAlert extends React.Component<StickyAlertProps, StickyAlertState> {
  timer: NodeJS.Timeout | undefined;

  static defaultProps = {
    autoCloseConfig: alertAutoCloseConfig,
    canClose: true
  };

  readonly state: StickyAlertState = initialState;

  constructor(props: StickyAlertProps) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount(): void {
    if (
      (this.props.alert.type === 'error' && this.props.autoCloseConfig.autoCloseError) ||
      (this.props.alert.type === 'success' && this.props.autoCloseConfig.autoCloseSuccess) ||
      (this.props.alert.type === 'info' && this.props.autoCloseConfig.autoCloseInfo) ||
      (this.props.alert.type === 'warning' && this.props.autoCloseConfig.autoCloseWarning)
    ) {
      this.timer = setTimeout(this.onHide, this.props.autoCloseConfig.timeout);
    }
  }

  onHide(): void {
    this.setState({ isAlertVisible: false });
  }

  onClose(): void {
    this.props.onClose(this.props.alert.id);
  }

  render(): React.ReactElement {
    const alert = this.props.alert;

    return (
      <Collapse className="sticky-alert-collapse" in={this.state.isAlertVisible} unmountOnExit onExited={this.onClose}>
        <MuiAlert className="sticky-alert" variant="outlined" severity={alert.type} onClose={this.onHide}>
          {this.props.alert.message}
          {this.props.alert.errorType === 'unauthorized' &&
            <span>&nbsp;<AddButton createComponent={{
              component: SignInMinimalContainer,
              route: ROUTES.SINGIN
            }} onClick={function (): void {
              console.debug('Unauthorized');
            } } text={'Přihlásit se.'} /></span>}
        </MuiAlert>
      </Collapse>
    );
  }
}

export default StickyAlert;
