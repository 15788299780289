import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import AccommodationOfferDetail, {
  AccommodationOfferDetailStateProps,
  AccommodationOfferDetailDispatchProps,
  AccommodationOfferDetailProps
} from './accommodationOffersDetail';
import * as actions from './actions';
import { calculateAsyncAction, setCompanyFilterAction, setOfferHeaderAction } from '../accommodationOfferCalculations';
import { calculatePriceAsyncAction } from '../accommodationOfferPriceCalculations';
import { AccommodationOfferFloorCalculationModel, AccommodationOfferModel, ReservationModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectAccommodationsSelectItems } from '../accommodations';
import { relationConfiguration } from '../../configuration/relations';
import { selectCompaniesSelectItems } from '../companies';
import { selectPriceListsSelectItems } from '../priceLists';
import { selectPriceListItemsSelectPricePerBedItems, selectPriceListItemsSelectPricePerRoomItems } from '../priceListItems';
import { selectGuestByCompanyByIdSelectItems } from '../guests';

type AccommodationOfferCreateContainerStateProps = {} & AccommodationOfferDetailStateProps;

type AccommodationOfferCreateContainerDispatchProps = {} & AccommodationOfferDetailDispatchProps;

type AccommodationOfferCreateContainerProps = {} & AccommodationOfferDetailProps &
  AccommodationOfferCreateContainerStateProps &
  AccommodationOfferCreateContainerDispatchProps;

const createInitialValues = {
  numberOfPersons: 0,
  numberOfNights: 0,
  accommodationId: relationConfiguration.relationNotSetId,
  validationType: 0,
  offerNumber: '',
  reservation: {
    reservationNumber: ''
  } as ReservationModel,
} as AccommodationOfferModel;

const AccommodationOfferCreateContainer: React.FunctionComponent<AccommodationOfferCreateContainerProps> = memo(props => {
  console.debug('AccommodationOfferCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    accommodationsSelectItems,
    guestsSelectItems,
    companiesSelectItems,
    priceListsSelectItems,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    calculateActionRequest,
    calculatePriceActionRequest,
    setCompanyFilterAction,
    setOfferHeaderAction,
    priceListItemsSelectItemsPerBed,
    priceListItemsSelectItemsPerRoom,
    activeStep
  } = props;

  return (
    <AccommodationOfferDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      accommodationsSelectItems={accommodationsSelectItems}
      guestsSelectItems={guestsSelectItems}
      companiesSelectItems={companiesSelectItems}
      priceListsSelectItems={priceListsSelectItems}
      calculateActionRequest={calculateActionRequest}
      calculatePriceActionRequest={calculatePriceActionRequest}
      setCompanyFilterAction={setCompanyFilterAction}
      setOfferHeaderAction={setOfferHeaderAction}
      priceListItemsSelectItemsPerBed={priceListItemsSelectItemsPerBed}
      priceListItemsSelectItemsPerRoom={priceListItemsSelectItemsPerRoom}
      activeStep={activeStep}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferCreateContainerStateProps => {
  const floors = state.accommodationOfferCalculations.floors.allIds.map<AccommodationOfferFloorCalculationModel>(id => {
    return state.accommodationOfferCalculations.floors.byId[id];
  });
  createInitialValues.floors = floors;
  createInitialValues.offerNumber = state.numberSeries.uniqueOfferNumber;
  createInitialValues.reservation = { reservationNumber: state.numberSeries.uniqueReservationNumber } as ReservationModel;
  return {
    initialValues: createInitialValues,
    errorMessage: state.accommodationOffers.errorMessage,
    requestInProgress: state.accommodationOffers.requestInProgress,
    accommodationsSelectItems: selectAccommodationsSelectItems(state),
    guestsSelectItems: selectGuestByCompanyByIdSelectItems(state),
    companiesSelectItems: selectCompaniesSelectItems(state),
    priceListsSelectItems: selectPriceListsSelectItems(state),
    priceListItemsSelectItemsPerBed: selectPriceListItemsSelectPricePerBedItems(state),
    priceListItemsSelectItemsPerRoom: selectPriceListItemsSelectPricePerRoomItems(state),
    activeStep: state.accommodationOfferCalculations.wizard.activeStep
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        calculateActionRequest: calculateAsyncAction.request,
        calculatePriceActionRequest: calculatePriceAsyncAction.request,
        setCompanyFilterAction: setCompanyFilterAction,
        setOfferHeaderAction: setOfferHeaderAction
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferCreateContainer);
