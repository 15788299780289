import { ActionType } from 'typesafe-actions';
import uuidV4 from 'uuid/v4';

import * as actions from './actions';

export type StickyAlertType = 'info' | 'success' | 'warning' | 'error';

export type StickyErrorType = 'unauthorized' | undefined;

export class StickyAlertMessage {
  type: StickyAlertType;
  message: string;
  errorType?: StickyErrorType;

  constructor(init: StickyAlertMessage) {
    this.type = 'info';
    this.message = '';
    this.errorType = undefined;
    
    Object.assign(this, init);
  }
}

export class StickyAlertModel extends StickyAlertMessage {
  id: string;

  constructor(init: StickyAlertMessage) {
    super(init);

    this.id = uuidV4();
  }
}

export type StickyAlertsState = {
  stickyAlerts: StickyAlertModel[];
};

export type StickyAlertsAction = ActionType<typeof actions>;
