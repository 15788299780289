import React, { RefObject } from 'react';
import {
  Theme,
  Button
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

import { IconName } from '../../../icons';
import { BaseComponentWithModalSupport } from '../../baseDetail';
import { withOpenModal, WithOpenModalProps } from '../../modalDialogManager';
import { ROUTES } from '../../../navigation';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({

}));

export type AddButtonProps = {
  createComponent?: {
    iconName?: IconName;
    component: React.ComponentType<BaseComponentWithModalSupport>;
    componentProps?: any;
    route: ROUTES;
  };
  text: string;
  onClick: () => void;
} & WithOpenModalProps;


type AddButtonState = {
  open: boolean;
};

class AddButton extends React.PureComponent<AddButtonProps, AddButtonState> {
  selectRef: RefObject<any>;

  constructor(props: AddButtonProps) {
    super(props);

    this.state = { open: false };
    this.selectRef = React.createRef<any>();
  }

  handleClick = (event: React.MouseEvent<HTMLSpanElement>): void => {
    this.props.onClick();
    event.preventDefault();
    if (this.props.createComponent) {
      this.props.openModal(this.props.createComponent.component, this.props.createComponent.route, this.props.createComponent.componentProps);
    }
  };

  render(): React.ReactElement {
    console.debug('AddButton.render');

    return (
      <React.Fragment>
        <Button
          variant="outlined"
          title={this.props.text}
          color="inherit"
          onClick={this.handleClick}>{this.props.text}</Button>
      </React.Fragment>
    );
  }
}

export default withOpenModal(withStylesFunction(AddButton));
