import { Reducer } from "redux";
import { ActionType, getType } from "typesafe-actions";

import * as actions from "./actions";
import { StickyAlertsState, StickyAlertsAction } from "./types";
import { signInSilentAsync } from "../signIn";

export const initialState: StickyAlertsState = {
  stickyAlerts: [],
};
const stickyActions = {
  addStickyAlert: actions.addStickyAlert,
  removeStickyAlert: actions.removeStickyAlert,
  clearStickyAlerts: actions.clearStickyAlerts,
  signInAsyncSuccess: signInSilentAsync.success
}

const reducer: Reducer<StickyAlertsState, ActionType<typeof stickyActions>> = (state: StickyAlertsState = initialState, 
  action: ActionType<typeof stickyActions>): StickyAlertsState => {
  switch (action.type) {
    case getType(actions.addStickyAlert): {
      return {
        ...state,
        stickyAlerts: [...state.stickyAlerts, action.payload]
      };
    }
    case getType(actions.removeStickyAlert): {
      const i = state.stickyAlerts
        .map(a => {
          return a.id;
        })
        .indexOf(action.payload);
      return {
        ...state,
        stickyAlerts: [...state.stickyAlerts.slice(0, i), ...state.stickyAlerts.slice(i + 1)]
      };
    }
    case getType(actions.clearStickyAlerts): {
      return {
        ...state,
        stickyAlerts: []
      };
    }
    case getType(stickyActions.signInAsyncSuccess): {
      return {
        ...state,
        stickyAlerts: state.stickyAlerts.filter(a => a.errorType !== 'unauthorized')
      }
    }
    default:
      return state;
  }
};

export default reducer;
