import { all, put, takeEvery, PutEffect, SelectEffect, CallEffect, TakeEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { VirtualModel as ApiVirtualModel } from '../../api/interfaces';
import { BasePageDataResponse } from '../../api/responses';
import {
  getVirtualData,
  loadDependenciesAsync,
  setVirtualScroll,
  setVirtualSearch
} from './actions';
import apiClient from '../../api/apiClientInstance';
import { VirtualComponentTypeEnum, VirtualModel, VirtualQueryModel, VirtualSelectItem } from './types';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(setVirtualSearch, setVirtualSearchHandler),
    yield takeEvery(setVirtualScroll, setVirtualScrollHandler),
    yield takeEvery(getVirtualData.request, getVirtualDataRequest)
  ]);
}

type GetVirtualDataGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getVirtualData.request>>
  | PutEffect<ActionType<typeof getVirtualData.failure>>
  | PutEffect<ActionType<typeof getVirtualData.success>>
  | Promise<BasePageDataResponse<ApiVirtualModel[]>>;

function* getVirtualDataRequest(action: ReturnType<typeof getVirtualData.request>):
  Generator<GetVirtualDataGeneratorType, void,
    void
    & boolean
    & BasePageDataResponse<ApiVirtualModel[]>> {
  try {
    let typedApiClient: any;
    switch (action.payload.type) {
      case VirtualComponentTypeEnum.Guests:
        typedApiClient = apiClient.Guests;
        break;
      case VirtualComponentTypeEnum.Companies:
        typedApiClient = apiClient.Companies;
        break;
    }

    const response: BasePageDataResponse<ApiVirtualModel[]> = yield apiClient.apiCallHandler({
      context: typedApiClient,
      apiCallFnName: 'getByVirtualPageAsync'
    }, action.payload.page, action.payload.search ?? '', action.payload.id ?? 0, action.payload.filter ?? '');
    if (response.resultCode === 0) {
      const items = response.data.map(item => { return { text: item.text, value: item.value } as VirtualSelectItem });
      yield put(getVirtualData.success({ items: items, type: action.payload.type, total: response.total } as VirtualModel));
    }
  } catch (error) {
    yield put(getVirtualData.failure(error));
  }
}


type SetVirtualScrollGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getVirtualData.success>>
  | PutEffect<ActionType<typeof getVirtualData.request>>
  | PutEffect<ActionType<typeof getVirtualData.failure>>;

function* setVirtualScrollHandler(action: ReturnType<typeof setVirtualScroll>):
  Generator<SetVirtualScrollGeneratorType, void,
    void
    & boolean> {
  yield put(getVirtualData.request({ ...action.payload, page: action.payload.page } as VirtualQueryModel));
}

type SetVirtualSearchGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getVirtualData.success>>
  | PutEffect<ActionType<typeof getVirtualData.request>>
  | PutEffect<ActionType<typeof getVirtualData.failure>>;

function* setVirtualSearchHandler(action: ReturnType<typeof setVirtualSearch>):
  Generator<SetVirtualSearchGeneratorType, void,
    void
    & boolean> {
  yield put(getVirtualData.request({ ...action.payload, page: 0 } as VirtualQueryModel));
}

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
