import { all, put, takeEvery, select, ForkEffect, AllEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { getItemsAsync, newItemAsync, saveItemAsync, cancelItemAsync, editItemAsync, deleteItemAsync, loadDependenciesAsync } from './actions';
import { getItemsAsync as loadAccommodationsAsync, getAreAccommodationsLoaded } from '../accommodations';
import { getItemsAsync as loadRoomTypesAsync } from '../roomTypes';
import { getItemsAsync as loadFloorsAsync, getAreFloorsLoaded } from '../floors';

import apiClient from '../../api/apiClientInstance';
import { RoomModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, ROUTES } from '../../navigation';
import { getAreItemsLoaded } from './selectors';
import { getAreRoomTypesLoaded } from '../roomTypes/selectors';
import { GetAllRequestAsyncActionPayload, isDataReloadRequired, registerSaveEntityRequest, registerCancelEntityRequest, registerDeleteEntityRequest } from '../../core';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getRoomsRequest),
    yield takeEvery(newItemAsync.request, newRoomRequest),
    yield takeEvery(saveItemAsync.request, saveRoomRequest),
    yield takeEvery(cancelItemAsync.request, cancelRoomRequest),
    yield takeEvery(editItemAsync.request, editRoomRequest),
    yield takeEvery(deleteItemAsync.request, deleteRoomRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest)
  ]);
}

type GetRoomsRequestGeneratorType =
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<RoomModel[]>>
  | PutEffect<ActionType<typeof getItemsAsync.cancel>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>;

function* getRoomsRequest(
  action: ReturnType<typeof getItemsAsync.request>
): Generator<GetRoomsRequestGeneratorType, void, boolean & BaseDataResponse<RoomModel[]>> {
  try {
    console.debug('getRoomsRequest');

    const payload = action.payload as GetAllRequestAsyncActionPayload;
    const isReloadRequired = yield isDataReloadRequired(getAreItemsLoaded, payload);
    if (!isReloadRequired) {
      yield put(getItemsAsync.cancel());
      return;
    }

    const response: BaseDataResponse<RoomModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.Rooms,
      apiCallFnName: 'getAllAsync'
    });

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type NewRoomRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newRoomRequest(action: ReturnType<typeof newItemAsync.request>): Generator<NewRoomRequestGeneratorType, void, void> {
  try {
    console.debug('newRoomRequest');

    yield put(navigateTo(ROUTES.SETTINGS_ROOMS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditRoomRequestGeneratorType =
  | Promise<BaseDataResponse<RoomModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editRoomRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditRoomRequestGeneratorType, void, BaseDataResponse<RoomModel>> {
  try {
    console.debug('editRoomRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.SETTINGS_ROOMS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteRoomRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.Rooms,
  ROUTES.SETTINGS_ROOMS
);

const saveRoomRequest = registerSaveEntityRequest<RoomModel>(saveItemAsync.success, saveItemAsync.failure, apiClient.Rooms, ROUTES.SETTINGS_ROOMS);

const cancelRoomRequest = registerCancelEntityRequest(cancelItemAsync.success, cancelItemAsync.failure, ROUTES.SETTINGS_ROOMS);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean> {
  try {
    console.debug('loadDependenciesRequest');

    const areAcoommodationsLoaded = yield select(getAreAccommodationsLoaded);
    if (!areAcoommodationsLoaded) {
      yield put(loadAccommodationsAsync.request());
    }

    const areRoomTypesLoaded = yield select(getAreRoomTypesLoaded);
    if (!areRoomTypesLoaded) {
      yield put(loadRoomTypesAsync.request());
    }

    const areFloorsLoaded = yield select(getAreFloorsLoaded);
    if (!areFloorsLoaded) {
      yield put(loadFloorsAsync.request());
    }

    const areItemsLoaded: boolean = yield select(getAreItemsLoaded);
    if (!areItemsLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}
