import { AxiosInstance } from 'axios';

import { BaseAuthenticatedClient } from '.';
import { BaseDataResponse } from '../responses';
import { NumberSeriesDataModel } from '../interfaces';
import { IAuthenticatedRequestHandler, IErrorHandler } from '../abstractions';

export default class NumberSeriesDataClient extends BaseAuthenticatedClient {
  constructor(authenticatedRequestHandler: IAuthenticatedRequestHandler, errorHandler: IErrorHandler, axiosInstance: AxiosInstance) {
    super(authenticatedRequestHandler, errorHandler, axiosInstance, 'numberseriesdata');
  }

  public async getAsync(): Promise<BaseDataResponse<NumberSeriesDataModel>> {
    return await this.getHttpAsync<BaseDataResponse<NumberSeriesDataModel>>('', '');
  }

  public async updateAsync(model: NumberSeriesDataModel): Promise<BaseDataResponse<NumberSeriesDataModel>> {
    return await this.putHttpAsync<BaseDataResponse<NumberSeriesDataModel>, NumberSeriesDataModel>(``, model);
  }
}
