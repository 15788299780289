import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import GuestsTable from './guestsTable';
import * as actions from './actions';

import { GuestModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListContainer,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  TablePaginationModel
} from '../../components';
import { getGuestsPagination } from './selectors';
import GuestsFilterContainer from './guestsFilterContainer';

type GuestsContainerStateProps = {
  pagination: TablePaginationModel;
} & BaseListStateProps<GuestModel>;

type GuestsContainerDispatchProps = {} & BaseListDispatchProps<GuestModel> & BaseListContainerDispatchProps<GuestModel>;

type GuestsContainerProps = {} & GuestsContainerStateProps & GuestsContainerDispatchProps;

const columns = mergeWithDefaultColumns<GuestModel>([
  { id: 'name', label: 'Jméno', minWidth: 120, align: 'left', isVisible: true, isTableActions: false },
  { id: 'surname', label: 'Příjmení', minWidth: 120, align: 'left', isVisible: true, isTableActions: false },
  { id: 'street', label: 'Ulice', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'city', label: 'Město', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'email', label: 'E-mail', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'phone', label: 'Telefon', minWidth: 120, align: 'left', isVisible: true, isTableActions: false }
]);

const GuestsContainer: React.FunctionComponent<GuestsContainerProps> = props => {
  console.debug('GuestsContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <GuestsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        paging={true}
        pagination={props.pagination}
        loadAction={props.getItemsRequest}
        filter={{ component: GuestsFilterContainer }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): GuestsContainerStateProps => {
  return {
    items: state.guests.itemList.allIds.map<GuestModel>(id => {
      return state.guests.itemList.byId[id];
    }),
    requestInProgress: state.guests.requestInProgress,
    pagination: getGuestsPagination(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): GuestsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GuestsContainer);
