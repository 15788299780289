import { all, put, take, takeEvery, select, TakeEffect, PutEffect, SelectEffect, call, CallEffect } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import {
  saveNumberSeriesAsync,
  getNumberSeriesAsync,
  loadNumberSeriesDependenciesAsync,
} from './actions';
import apiClient from '../../api/apiClientInstance';
import { NumberSeriesDataModel } from '../../api/interfaces';
import { BaseDataResponse } from '../../api/responses';
import { addSuccessAlert } from '../../components';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(loadNumberSeriesDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(getNumberSeriesAsync.request, getNumberSeriesRequest),
    yield takeEvery(saveNumberSeriesAsync.request, saveNumberSeriesRequest),
  ]);
}

type SaveEntityRequestGeneratorType<NumberSeriesDataModel> =
  | Promise<BaseDataResponse<NumberSeriesDataModel>>
  | PutEffect<ActionType<typeof saveNumberSeriesAsync.success>>
  | PutEffect<ActionType<typeof saveNumberSeriesAsync.failure>>
  | SelectEffect
  | CallEffect
  | PutEffect<ActionType<typeof addSuccessAlert>>;

function* saveNumberSeriesRequest(
  action: ActionType<typeof saveNumberSeriesAsync.request>
): Generator<SaveEntityRequestGeneratorType<NumberSeriesDataModel>, void, 
  BaseDataResponse<NumberSeriesDataModel>
  & NumberSeriesDataModel & boolean> {

  try {
    console.debug('saveNumberSeriesRequest');

    const model = action.payload;
    const response: BaseDataResponse<NumberSeriesDataModel> = yield apiClient.apiCallHandler(
      { context: apiClient.NumberSeriesData, apiCallFnName: 'updateAsync' },
      model
    );

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(saveNumberSeriesAsync.success(data));
      yield put(addSuccessAlert('Řady byly úspěšně uloženy.'));
    } else {
      yield put(saveNumberSeriesAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(saveNumberSeriesAsync.failure(error));
  }
}

type GetNumberSeriesRequestGeneratorType =
  | SelectEffect
  | Promise<BaseDataResponse<NumberSeriesDataModel>>
  | PutEffect<ActionType<typeof getNumberSeriesAsync.request>>
  | PutEffect<ActionType<typeof getNumberSeriesAsync.success>>
  | PutEffect<ActionType<typeof getNumberSeriesAsync.failure>>;

function* getNumberSeriesRequest(
  action: ReturnType<typeof getNumberSeriesAsync.request>
): Generator<GetNumberSeriesRequestGeneratorType, void, NumberSeriesDataModel & BaseDataResponse<NumberSeriesDataModel>> {
  try {
    console.debug('getNumberSeriesRequest');

    const response: BaseDataResponse<NumberSeriesDataModel> = yield apiClient.NumberSeriesData.getAsync();

    if (response.resultCode === 0) {
      yield put(getNumberSeriesAsync.success(response.data));
    } else {
      yield put(getNumberSeriesAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getNumberSeriesAsync.failure(error));
  }
}

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | PutEffect<ActionType<typeof loadNumberSeriesDependenciesAsync.request>>
  | PutEffect<ActionType<typeof loadNumberSeriesDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadNumberSeriesDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadNumberSeriesDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean & void> {
  try {
    console.debug('loadDependenciesRequest');
    yield put(loadNumberSeriesDependenciesAsync.success());

  } catch (error) {
    yield put(loadNumberSeriesDependenciesAsync.failure(error));
  }
}