import React from 'react';

import { default as SettingsRouter } from './settingsRouter';
import { default as ContainerWithLeftMenu } from '../app/containerWithLeftMenu';

type SettingsProps = {};

const Settings: React.FunctionComponent<SettingsProps> = props => {
  console.debug('Settings');

  return (
    <ContainerWithLeftMenu>
      <SettingsRouter />
    </ContainerWithLeftMenu>
  );
};

export default Settings;
