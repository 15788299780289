import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type GuestsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const GuestsTable: React.FunctionComponent<GuestsTableProps> = memo(props => {
    console.debug('GuestsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default GuestsTable;
