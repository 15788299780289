import { put, all, takeEvery, PutEffect, ForkEffect, AllEffect } from 'redux-saga/effects';

import apiClient from '../../api/apiClientInstance';
import { BaseResponse } from '../../api/responses';

import { signOutAsync } from './actions';
import { ActionType } from 'typesafe-actions';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<never> | AllEffect<unknown>> {
    yield all([yield takeEvery(signOutAsync.request, signOutRequest)]);
}

type SignOutRequestGeneratorType = Promise<BaseResponse> | PutEffect<ActionType<typeof signOutAsync.success>>;

function* signOutRequest(action: ReturnType<typeof signOutAsync.request>): Generator<SignOutRequestGeneratorType, void, BaseResponse> {
    // TODO: AsyncGenerator {
    try {
        console.debug('signOutRequest');

        const response: BaseResponse = yield apiClient.Authentication.signOut();
        yield put(signOutAsync.success());
    } catch (error) {
        //TODO: obecna chyba
        console.error(error);
        yield put(signOutAsync.success());
    }
}
