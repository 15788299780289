import { Reducer } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import produce from 'immer';

import { GetAllRequestAsyncActionPayload, ListItemState, NormalizedListState, BaseActions } from './types';

export const createBaseListReducer = <TModel extends ListItemState>(
  initialState: NormalizedListState<TModel>,
  actions: BaseActions<TModel>
): Reducer<NormalizedListState<TModel>, ActionType<BaseActions<TModel>>> => {
  let listReducer = createReducer<typeof initialState, ActionType<BaseActions<TModel>>>(initialState)
    .handleAction(actions.getItemsAsync.request, (state, action) => {
      if (action.payload as GetAllRequestAsyncActionPayload) {
        return initialState;
      }

      return state;
    })
    .handleAction(actions.getItemsAsync.success, (state, action) => {
      const newState = produce(state, (draft: NormalizedListState<TModel>) => {
        draft.allIds = action.payload.map((model: TModel) => {
          draft.byId[model.id] = model;

          return model.id;
        });
        draft.loaded = true;
      });

      return newState;
    })
    .handleAction(actions.saveItemAsync.success, (state, action) => {
      const newState = produce(state, (draft: NormalizedListState<TModel>) => {
        if (!state.allIds.find(itemId => itemId === action.payload.id)) {
          draft.allIds.push(action.payload.id);
        }
        draft.byId[action.payload.id] = action.payload;
      });

      return newState;
    })
    .handleAction(actions.deleteItemAsync.success, (state, action) => {
      const newState = produce(state, (draft: NormalizedListState<TModel>) => {
        draft.allIds = draft.allIds.filter(id => id !== action.payload);

        delete draft.byId[action.payload];
        return draft;
      });

      return newState;
    });

  if (actions.setPagination !== undefined) {
    listReducer = listReducer.handleAction(actions.setPagination, (state, action) => {
      const newState = produce(state, draft => {
        draft.total = action.payload.total;
        draft.page = action.payload.page;
        draft.skip = action.payload.skip;
        draft.take = action.payload.take;
        draft.filter = action.payload.filter;
      });

      return newState;
    });
  }

  if (actions.setSelection !== undefined) {
    listReducer = listReducer.handleAction(actions.setSelection, (state, action) => {
      const newState = produce(state, draft => {
        draft.selection = action.payload;
      });

      return newState;
    })
  }

  if (actions.getItemAsync) {
    listReducer = listReducer.handleAction(actions.getItemAsync.success, (state, action) => {
      const newState = produce(state, (draft: NormalizedListState<TModel>) => {
        if (!state.allIds.find(itemId => itemId === action.payload.id)) {
          draft.allIds.push(action.payload.id);
        }
        draft.byId[action.payload.id] = action.payload;
      });

      return newState;
    });
  }

  if (actions.getAllItemsAsync) {
    listReducer = listReducer.handleAction(actions.getAllItemsAsync.success, (state, action) => {
      const newState = produce(state, (draft: NormalizedListState<TModel>) => {
        draft.allIds = action.payload.map((model: TModel) => {
          draft.byId[model.id] = model;

          return model.id;
        });
        draft.loaded = true;
      });

      return newState;
    })
  }

  return listReducer;
};

export const createBaseErrorMessageReducer = <TModel extends ListItemState>(
  initialState: string,
  actions: BaseActions<TModel>
): Reducer<string, ActionType<BaseActions<TModel>>> => {
  const errorMessageReducer = createReducer<typeof initialState, ActionType<BaseActions<TModel>>>(initialState)
    .handleAction(
      [
        actions.getItemsAsync.request,
        actions.getItemsAsync.success,
        actions.editItemAsync.request,
        actions.editItemAsync.success,
        actions.deleteItemAsync.request,
        actions.deleteItemAsync.success
      ],
      (state, action) => initialState
    )
    .handleAction(
      [actions.getItemsAsync.failure, actions.editItemAsync.failure, actions.deleteItemAsync.failure],
      (state, action) => action.payload.message
    );
  return errorMessageReducer;
};

export const createBaseRequestInProgressReducer = <TModel extends ListItemState>(
  initialState: boolean,
  actions: BaseActions<TModel>
): Reducer<boolean, ActionType<BaseActions<TModel>>> => {
  const requestInProgressReducer = createReducer<typeof initialState, ActionType<BaseActions<TModel>>>(initialState)
    .handleAction([actions.getItemsAsync.request, actions.editItemAsync.request, actions.deleteItemAsync.request], (state, action) => true)
    .handleAction(
      [
        actions.getItemsAsync.success,
        actions.editItemAsync.success,
        actions.deleteItemAsync.success,
        actions.getItemsAsync.failure,
        actions.editItemAsync.failure,
        actions.deleteItemAsync.failure,
        actions.getItemsAsync.cancel
      ],
      (state, action) => initialState
    );
  return requestInProgressReducer;
};
