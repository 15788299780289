import { createAction, createAsyncAction } from 'typesafe-actions';

import { UserModel, UserInvitationModel } from '../../api/interfaces';
import {
    createGetItemsAsyncAction,
    createNewItemAsyncAction,
    createEditItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createDeleteItemAsyncAction,
    createLoadDependenciesAsyncAction
} from '../../core';

const modelName = 'users';

export const getItemsAsync = createGetItemsAsyncAction<UserModel>(modelName);

export const sendUserInvitationAsync = createAsyncAction(
    'users/SEND_INVITATION_REQUEST',
    'users/SEND_INVITATION_SUCCESS',
    'users/SEND_INVITATION_FAILURE'
)<UserInvitationModel, void, Error>();

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<UserModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);
