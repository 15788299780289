import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter, RouterRootState } from 'connected-react-router';

import './utils/array';
import './utils/string';
import './utils/date';
import './icons/library';
import './configuration/dates';

import { default as browserHistory } from './browserHistory';
import configureStore from './store/configureStore';
import { ApplicationState } from './store/rootReducer';
import ThemedAppContainer from './features/themes/themedAppContainer';
import './index.scss';
import './fonts/fontello/css/hotelios.css';

// Create browser history to use in the Redux store
// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const history = createBrowserHistory({ basename: baseUrl });
const history = browserHistory;
const store = configureStore(history, {} as ApplicationState & RouterRootState);

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemedAppContainer />
        </ConnectedRouter>
    </Provider>,
    rootElement
);
