import React, { memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import PriceListItemDetail, {
  PriceListItemDetailStateProps,
  PriceListItemDetailDispatchProps,
  PriceListItemDetailProps
} from './priceListItemsDetail';
import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import { priceListItemTypeAsSelectOptions } from './enums';
import { selectPriceListsSelectItems } from '../priceLists';
import { selectRoomTypesSelectItemsByAccomodationId } from '../roomTypes';
import { selectVatsSelectItems } from '../vats';
import { getAccommodationIdByPriceListId } from '../priceLists';

type PriceListItemEditContainerStateProps = {} & PriceListItemDetailStateProps;

type PriceListItemEditContainerDispatchProps = {} & PriceListItemDetailDispatchProps;

type PriceListItemEditContainerProps = {} & PriceListItemDetailProps & PriceListItemEditContainerStateProps & PriceListItemEditContainerDispatchProps;

const PriceListItemEditContainer: React.FunctionComponent<PriceListItemEditContainerProps> = memo(props => {
  console.debug('PriceListItemEditContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    priceTypeSelectItems,
    priceListsSelectItems,
    vatsSelectItems,
    priceLists,
    roomTypes,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    getAccommodationIdByPriceListId,
    selectRoomTypesSelectItemsByAccomodationId
  } = props;

  return (
    <PriceListItemDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      priceTypeSelectItems={priceTypeSelectItems}
      priceListsSelectItems={priceListsSelectItems}
      vatsSelectItems={vatsSelectItems}
      priceLists={priceLists}
      getAccommodationIdByPriceListId={getAccommodationIdByPriceListId}
      roomTypes={roomTypes}
      selectRoomTypesSelectItemsByAccomodationId={selectRoomTypesSelectItemsByAccomodationId}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): PriceListItemEditContainerStateProps => {
  return {
    initialValues: state.priceListItems.itemList.byId[ownProps.match.params.id],
    errorMessage: state.priceListItems.errorMessage,
    requestInProgress: state.priceListItems.requestInProgress,
    priceTypeSelectItems: priceListItemTypeAsSelectOptions,
    priceListsSelectItems: selectPriceListsSelectItems(state),
    vatsSelectItems: selectVatsSelectItems(state),
    priceLists: state.priceLists.itemList.byId,
    roomTypes: state.roomTypes.itemList.byId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PriceListItemEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    ),
    getAccommodationIdByPriceListId,
    selectRoomTypesSelectItemsByAccomodationId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceListItemEditContainer);
