import { ReadonlyValues } from "../core";

export const isDisabledFactory = <TModel>() => (
  key: keyof Partial<ReadonlyValues<TModel>>,
  readonlyValues?: Partial<ReadonlyValues<TModel>>
): boolean => {
  if (!readonlyValues) {
    return false;
  }

  return key in readonlyValues ? (readonlyValues[key] as boolean) : false;
};
