import React, { memo } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { nameofFactory } from '../../utils/nameofFactory';
import { accommodationOfferCreateValidationSchema, accommodationOfferUpdateValidationSchema, accommodationOfferCalculateValidationSchema } from './validations';
import { AccommodationOfferCalculationHeaderModel, AccommodationOfferModel, AccommodationOfferStateEnum, AccommodationOfferSubmitTypeEnum } from '../../api/interfaces';

import {
  FormikInput,
  FormikRelationSelect,
  FormikVirtualSelect,
  RelationSelectItem,
  FormikCheckbox,
  FormikDatePicker,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  RelationFilterSelectItem,
  FormikObserver
} from '../../components';
import { default as AccommodationOffersWizard } from './accommodationOffersWizard';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CompaniesCreateContainer } from '../companies';
import { GuestsCreateContainer } from '../guests';
import { ROUTES } from '../../navigation';
import { calculateAsyncAction, setCompanyFilterAction, setOfferHeaderAction } from '../accommodationOfferCalculations';
import { calculatePriceAsyncAction } from '../accommodationOfferPriceCalculations';

import { getAccommodationOfferStateSelectItems } from '.';
import { VirtualComponentTypeEnum } from '../../components/virtualSelect/';

export type AccommodationOfferDetailStateProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  companiesSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListItemsSelectItemsPerBed: DeepReadonly<RelationFilterSelectItem[]>;
  priceListItemsSelectItemsPerRoom: DeepReadonly<RelationFilterSelectItem[]>;
  activeStep: number;
} & BaseDetailStateProps<AccommodationOfferModel>;

export type AccommodationOfferDetailDispatchProps = {
  calculateActionRequest: typeof calculateAsyncAction.request;
  calculatePriceActionRequest: typeof calculatePriceAsyncAction.request;
  setCompanyFilterAction: typeof setCompanyFilterAction;
  setOfferHeaderAction: typeof setOfferHeaderAction;
} & BaseDetailDispatchProps<AccommodationOfferModel>;

export type AccommodationOfferDetailProps = {} & BaseDetailProps<AccommodationOfferModel> &
  AccommodationOfferDetailStateProps &
  AccommodationOfferDetailDispatchProps;

type RenderFormProps = {
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  guestsSelectItems: DeepReadonly<RelationSelectItem[]>;
  companiesSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListItemsSelectItemsPerBed: DeepReadonly<RelationFilterSelectItem[]>;
  priceListItemsSelectItemsPerRoom: DeepReadonly<RelationFilterSelectItem[]>;
  handleTabValueChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  selectedTabValue: number;
  handleAccomodationChanged: (event: React.MouseEvent<HTMLInputElement>) => void;
  calculateActionRequest: typeof calculateAsyncAction.request;
  calculatePriceActionRequest: typeof calculatePriceAsyncAction.request;
  setCompanyFilterAction: typeof setCompanyFilterAction;
  setOfferHeaderAction: typeof setOfferHeaderAction;
  filterId: number;
  setFilterId: any;
  activeStep: number;
} & BaseDetailContainerWrappedComponentProps<AccommodationOfferModel>;

const nameof = nameofFactory<AccommodationOfferModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    requestInProgress,
    initialValues,
    accommodationsSelectItems,
    companiesSelectItems,
    priceListsSelectItems,
    priceListItemsSelectItemsPerBed,
    priceListItemsSelectItemsPerRoom,
    filterId,
    setFilterId,
    errorMessage,
    activeStep
  } = props;
  const accommodationOfferStateSelectItems = getAccommodationOfferStateSelectItems();

  return (
    <Formik<AccommodationOfferModel>
      initialValues={initialValues}
      onSubmit={(values: any, { setSubmitting }): void => {
        setSubmitting(true);
        console.log(values);
        if (values.submitType === AccommodationOfferSubmitTypeEnum.Save) {
          props.saveActionRequest(values);
        } else if (values.submitType === AccommodationOfferSubmitTypeEnum.Calculate) {
          props.calculateActionRequest(values);
        }
      }}

      validationSchema={(): any => Yup.lazy((values: any) => {
        if (values.validationType === 1) {
          return accommodationOfferCalculateValidationSchema;
        }

        if (values.id > 0) {
          return accommodationOfferUpdateValidationSchema
        } else {
          return accommodationOfferCreateValidationSchema;
        }
      })}
    >
      {(formProps): React.ReactElement => {
        const { values, handleSubmit, setFieldValue, isValid, submitForm, validateForm, touched } = formProps;
        const isDisabled = values.id > 0 || activeStep > 0;
        const editMode = values.id > 0;

        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormikObserver<AccommodationOfferCalculationHeaderModel> values={formProps.values} onChange={(values, prevValues): void => {
              props.setOfferHeaderAction(values);

              if (values.payForRoomAndNight !== prevValues.payForRoomAndNight) {
                props.calculatePriceActionRequest();
              }

              if (values.companyId !== prevValues.companyId) {
                console.debug(values.companyId);
              }
            }} />
            <Grid container className={classes.grid}>
              <Grid container item spacing={2}>
                {/*Prvni radek*/}
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={3}>
                    <FormikRelationSelect
                      iconName="info"
                      labelText="Stav"
                      name={nameof('state')}
                      items={accommodationOfferStateSelectItems as RelationSelectItem[]}
                      showErrorWhenNotTouched={true}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {values.state === AccommodationOfferStateEnum.Reservation && <FormikInput
                      iconName="bed"
                      labelText="Unikátní číslo"
                      name={'reservation.reservationNumber'}
                      InputProps={{
                        autoFocus: true,
                        type: 'string',
                        disabled: true
                      }}
                      errorMessage={errorMessage}
                    />}
                    {values.state === AccommodationOfferStateEnum.Offer && <FormikInput
                      iconName="bed"
                      labelText="Unikátní číslo"
                      name={nameof('offerNumber')}
                      InputProps={{
                        autoFocus: true,
                        type: 'string',
                        disabled: true
                      }}
                      errorMessage={errorMessage}
                    />}

                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={3}>
                    {/* <FormikCheckbox
                      labelText="Započítat do ceny volné lůžka na obsazeném pokoji"
                      name={nameof('payForFreeBeds')}
                    /> */}
                  </Grid>
                </Grid>
                {/*Druhy radek*/}
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={3}>
                    <FormikRelationSelect
                      iconName="hotel"
                      labelText="Ubytovací zařízení"
                      name={nameof('accommodationId')}
                      items={accommodationsSelectItems as RelationSelectItem[]}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikVirtualSelect
                      iconName="list-alt"
                      labelText="Firma"
                      name={nameof('companyId')}
                      filter={undefined}
                      showErrorWhenNotTouched={true}
                      type={VirtualComponentTypeEnum.Companies}
                      createComponent={{ component: CompaniesCreateContainer, route: ROUTES.SETTINGS_COMPANIES_CREATE }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikVirtualSelect
                      iconName="list-alt"
                      labelText="Kontaktní osoba"
                      name={nameof('guestId')}
                      filter={`${values.companyId}`}
                      showErrorWhenNotTouched={true}
                      type={VirtualComponentTypeEnum.Guests}
                      createComponent={{ component: GuestsCreateContainer, route: ROUTES.SETTINGS_GUESTS_CREATE }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikCheckbox
                      labelText="Počítat cenu za pokoj/noc"
                      name={nameof('payForRoomAndNight')}
                    />
                  </Grid>
                </Grid>
                {/*Treti radek*/}
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={3}>
                    <FormikDatePicker
                      iconName="calendar-week"
                      labelText="Datum příjezd"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: isDisabled
                      }}
                      onChange={(date: MaterialUiPickersDate): void => {
                        if (formProps.values.to) {
                          const fromDate = moment(date);
                          const toDate = moment(formProps.values.to);

                          setFieldValue('numberOfNights', toDate.diff(fromDate, 'days'));
                        }
                      }}
                      name={nameof('from')}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikDatePicker
                      iconName="calendar-week"
                      labelText="Datum odjezd"
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: isDisabled
                      }}
                      onChange={(date: MaterialUiPickersDate): void => {
                        if (formProps.values.from) {
                          const fromDate = moment(formProps.values.from);
                          const toDate = moment(date);

                          setFieldValue('numberOfNights', toDate.diff(fromDate, 'days'));
                        }
                      }}
                      name={nameof('to')}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikInput
                      iconName="bed"
                      labelText="Počet nocí"
                      name={nameof('numberOfNights')}
                      InputProps={{
                        type: 'number',
                        disabled: isDisabled
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormikInput
                      iconName="user"
                      labelText="Počet osob"
                      name={nameof('numberOfPersons')}
                      InputProps={{
                        type: 'number',
                        disabled: isDisabled
                      }}
                      errorMessage={errorMessage}
                    />
                  </Grid>

                </Grid>
                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={3}>
                    <FormikRelationSelect
                      iconName="list-alt"
                      labelText="Ceník"
                      name={nameof('priceListId')}
                      items={priceListsSelectItems as RelationSelectItem[]}
                      showErrorWhenNotTouched={true}
                      onChange={(value): void => {
                        setFilterId(value);

                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress} onClick={(): void => {
                      setFieldValue('submitType', AccommodationOfferSubmitTypeEnum.Calculate, false);
                      setFieldValue('validationType', 0, false);
                    }}>
                      Ukaž volné kapacity
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box component="span" p={4}>
                  <AccommodationOffersWizard
                    values={formProps.values}
                    setFieldValue={setFieldValue}
                    isValid={isValid}
                    submitForm={submitForm}
                    validateForm={validateForm}
                    touched={touched} />
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const AccommodationOfferDetail: React.FunctionComponent<AccommodationOfferDetailProps> = memo(props => {

  console.debug('AccommodationOfferDetail');

  const [selectedTabValue, setValue] = React.useState(0);
  const [filterId, setFilterId] = React.useState(0);

  const handleTabValueChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    setValue(newValue);
  };

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    accommodationsSelectItems,
    companiesSelectItems,
    priceListsSelectItems,
    priceListItemsSelectItemsPerBed,
    priceListItemsSelectItemsPerRoom,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest,
    getItemAsync,
    calculateActionRequest,
    calculatePriceActionRequest,
    setCompanyFilterAction,
    setOfferHeaderAction,
    activeStep
  } = props;

  return (
    <BaseDetailContainer<AccommodationOfferModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      getItemAsync={getItemAsync}
      {...{
        accommodationsSelectItems: accommodationsSelectItems,
        companiesSelectItems: companiesSelectItems,
        priceListsSelectItems: priceListsSelectItems,
        priceListItemsSelectItemsPerBed: priceListItemsSelectItemsPerBed,
        priceListItemsSelectItemsPerRoom: priceListItemsSelectItemsPerRoom,
        handleTabValueChange: handleTabValueChange,
        selectedTabValue: selectedTabValue,
        calculateActionRequest: calculateActionRequest,
        calculatePriceActionRequest: calculatePriceActionRequest,
        setCompanyFilterAction: setCompanyFilterAction,
        setOfferHeaderAction: setOfferHeaderAction,
        filterId: filterId,
        setFilterId: setFilterId,
        activeStep: activeStep
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default AccommodationOfferDetail;
