import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import produce from 'immer';

import { CompaniesState, CompaniesAction } from './types';
import * as actions from './actions';
import { BaseActions, createBaseListReducer, createBaseErrorMessageReducer, createBaseRequestInProgressReducer, GetAllRequestAsyncActionPayload } from '../../core';
import { CompanyModel } from '../../api/interfaces';
import { getCompanyInfoAsync } from '../ares';

export const currentInitialState: CompanyModel = {
  city: '',
  email: '',
  ico: '',
  name: '',
  note: '',
  phone: '',
  street: '',
  vat: '',
  zip: '',
  stateId: 0,
  id: 0,
  allowPayByInvoice: false
} as CompanyModel;

export const initialState: CompaniesState = {
  itemList: {
    allIds: [],
    byId: {},
    loaded: false
  },
  current: currentInitialState,
  errorMessage: '',
  requestInProgress: false
};

const baseActions = {
  getItemsAsync: actions.getItemsAsync,
  saveItemAsync: actions.saveItemAsync,
  deleteItemAsync: actions.deleteItemAsync,
  editItemAsync: actions.editItemAsync,
  setPagination: actions.setPagination,
  getAllItemsAsync: actions.getAllItemsAsync
} as BaseActions<CompanyModel>;

const extendedActions = {
  loadDependenciesAsync: actions.loadDependenciesAsync,
  saveItemAsync: actions.saveItemAsync,
  getItemAsync: actions.getItemsAsync,
  getCompanyInfoAsync
}

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

const companyReducer = createReducer<typeof initialState.current, ActionType<typeof extendedActions>>(initialState.current)
  .handleAction(actions.loadDependenciesAsync.request, (state, action) => {
    return currentInitialState;
  })
  .handleAction(actions.saveItemAsync.success, (state, action) => {
    return currentInitialState;
  })
  .handleAction(getCompanyInfoAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      if (action.payload?.records?.length === 1) {
        const payload = action.payload.records[0];

        draft.name = payload.companyName;
        draft.ico = payload.companyIdentificationNumber;
        draft.vat = payload.vatNumber;
        draft.city = payload.city;
        draft.street = payload.street;
        draft.zip = payload.postalCode;
      }

      return draft;
    });

    return newState;
  });

export default combineReducers<CompaniesState>({
  itemList: itemListReducer,
  requestInProgress: requestInProgressReducer,
  errorMessage: errorMessageReducer,
  current: companyReducer
});
