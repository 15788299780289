/* eslint-disable @typescript-eslint/camelcase */
import moment from 'moment';

import { TimeLineItemModel, TimeLineGroupModel } from '../../api/interfaces';
import { TimeLineItem, TimeLineGroup } from '../../components/timeLine';

export const timeLineItemModelsToTimeLineItemMapper = (items: TimeLineItemModel[]): TimeLineItem[] => {
  return items.map(item => {
    return {
      id: item.id,
      title: item.title,
      group: item.groupId,
      end_time: moment(item.endDate),
      start_time: moment(item.startDate),
      refId: item.refId,
      type: item.type
    } as TimeLineItem;
  });
};

export const timeLineGroupModelsToTimeLineGroupMapper = (groups: TimeLineGroupModel[]): TimeLineGroup[] => {
  return groups.map(group => {
    return {
      id: group.id,
      title: group.title
    } as TimeLineGroup;
  });
};
