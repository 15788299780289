import React, { memo } from 'react';

import { TableContainer, BaseTableProps /* BaseGenericTableProps */ } from '../../components';

type PriceListItemsTableProps = {} & BaseTableProps /* & BaseGenericTableProps<AccommodationModel> */;

const PriceListItemsTable: React.FunctionComponent<PriceListItemsTableProps> = memo(props => {
    console.debug('PriceListItemsTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default PriceListItemsTable;
