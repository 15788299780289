import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ActionType } from "typesafe-actions";

import { MyCompanyState } from './types';
import { saveItemAsync, getItemAsync } from './actions';
import { getCompanyInfoAsync } from '../ares';
import { MyCompanyModel } from '../../api/interfaces';
import produce from 'immer';
import _ from 'lodash';

export const initialState: MyCompanyState = {
  errorMessage: '',
  requestInProgress: false,
  loaded: false,
  current: {
    ico: ' ',
    name: ' ',
    vat: ' ',
    street: ' ',
    city: ' ',
    zip: ' ',
    stateId: 0,
    email: ' ',
    phone: ' ',
    note: ' ',
    bankAccountNumber: ' ',
    bankCode: ' ',
    constantSymbol: ' ',
    specificSymbol: ' ',
    id: 0,
    numberOfDueDays: 0
  } as MyCompanyModel
};

const extendedActions = {
  saveItemAsync,
  getItemAsync,
  getCompanyInfoAsync
}

const myCompanyReducer = createReducer<typeof initialState.current, ActionType<typeof extendedActions>>(initialState.current)
  .handleAction(saveItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft = action.payload;
      return draft;
    });

    return newState;
  }).handleAction(getItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      const payload = _.cloneDeep(action.payload) as any;
      Object.keys(payload).map(function (key: any, index) {
          if (payload[key as keyof MyCompanyModel] === null) {
            payload[key] = '';
          }
      });
      
      draft = payload as MyCompanyModel;

      return draft;
    });

    return newState;
  })
  .handleAction(getCompanyInfoAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      if (action.payload?.records?.length === 1) {
        const payload = action.payload.records[0];

        draft.name = payload.companyName;
        draft.ico = payload.companyIdentificationNumber;
        draft.vat = payload.vatNumber;
        draft.city = payload.city;
        draft.street = payload.street;
        draft.zip = payload.postalCode;
      }

      return draft;
    });

    return newState;
  });

const errorMessageReducer = createReducer<typeof initialState.errorMessage, ActionType<typeof saveItemAsync>>(initialState.errorMessage)
  .handleAction(saveItemAsync.success, (state, action) => {
    return initialState.errorMessage;
  });

const requestInProgressReducer = createReducer<typeof initialState.requestInProgress, ActionType<typeof saveItemAsync>>(initialState.requestInProgress)
  .handleAction(saveItemAsync.request, (state, action) => true)
  .handleAction(saveItemAsync.success, (state, action) => {
    return initialState.requestInProgress;
  });

const loadedReducer = createReducer<typeof initialState.loaded, ActionType<typeof extendedActions>>(initialState.loaded)
  .handleAction(saveItemAsync.success, (state, action) => {
    return initialState.loaded;
  })
  .handleAction(getItemAsync.success, (state, action) => {
    return true;
  });

export default combineReducers<MyCompanyState>({
  current: myCompanyReducer,
  errorMessage: errorMessageReducer,
  requestInProgress: requestInProgressReducer,
  loaded: loadedReducer
});