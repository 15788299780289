import React, { memo, useState } from 'react';
import { Grid, Button, Box, Typography, createStyles, Tab, Tabs, Theme, withStyles } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  FormikDatePicker,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikCheckbox
} from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { guestValidationSchema } from './validations';
import { GuestModel } from '../../api/interfaces';
import { StatesCreateContainer } from '../states';
import { CompaniesCreateContainer } from '../companies';
import { ROUTES } from '../../navigation';
import AccommodationOffersContainer from '../accommodationOffers/accommodationOffersContainer';


const StyledTabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.light
    }
  })
)(Tabs);

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    selected: {
      fontWeight: 'bold'
    }
  })
)(Tab);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps): any {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number): any {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  };
}

export type GuestDetailStateProps = {
  stateSelectItems: DeepReadonly<RelationSelectItem[]>;
  companySelectItems: DeepReadonly<RelationSelectItem[]>;
} & BaseDetailStateProps<GuestModel>;

export type GuestDetailDispatchProps = {
} & BaseDetailDispatchProps<GuestModel>;

export type GuestDetailProps = {} & BaseDetailProps<GuestModel> & GuestDetailStateProps & GuestDetailDispatchProps;

type RenderFormProps = {
  stateSelectItems: DeepReadonly<RelationSelectItem[]>;
  companySelectItems: DeepReadonly<RelationSelectItem[]>;
  tab: number;
  setTab: (index: number) => void;
} & BaseDetailContainerWrappedComponentProps<GuestModel>;

const nameof = nameofFactory<GuestModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const { tab, setTab, classes, errorMessage, requestInProgress, initialValues, stateSelectItems, companySelectItems } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<GuestModel>
      initialValues={initialValues}
      onSubmit={(values: GuestModel, { setSubmitting }): void => {
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={guestValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, values } = formProps;
        return (
          <Box>
            <StyledTabs value={tab}
              aria-label="offer tabs"
              indicatorColor="primary">
              <StyledTab label="Detail" {...a11yProps(0)} onClick={() => setTab(0)} />
              <StyledTab label="Seznam rezervací" {...a11yProps(1)} onClick={() => setTab(1)} />
            </StyledTabs>
            <TabPanel value={tab} index={0}>
              <form onSubmit={handleSubmit} autoComplete="off">
                <Grid container className={classes.grid}>
                  <Grid container item spacing={2}>
                    <Grid item xs={2}>
                      <FormikInput
                        iconName="sticky-note"
                        labelText="Titul před"
                        name={nameof('titleBefore')}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikInput
                        iconName="hotel"
                        labelText="Jméno"
                        name={nameof('name')}
                        errorMessage={errorMessage}
                        InputProps={{
                          autoFocus: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormikInput iconName="sticky-note" labelText="Příjmení" name={nameof('surname')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={2}>
                      <FormikInput
                        iconName="sticky-note"
                        labelText="Titul za"
                        name={nameof('titleAfter')}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikRelationSelect
                        iconName="hotel"
                        labelText="Občanství"
                        name={nameof('citizenshipId')}
                        errorMessage={errorMessage}
                        items={stateSelectItems as RelationSelectItem[]}
                        createComponent={{ component: StatesCreateContainer, route: ROUTES.SETTINGS_STATES_CREATE }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikInput iconName="address-card" labelText="Ulice" name={nameof('street')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikInput iconName="address-card" labelText="Město" name={nameof('city')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikInput iconName="address-card" labelText="PSČ" name={nameof('zip')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikRelationSelect
                        iconName="hotel"
                        labelText="Stát"
                        name={nameof('stateId')}
                        errorMessage={errorMessage}
                        items={stateSelectItems as RelationSelectItem[]}
                        createComponent={{ component: StatesCreateContainer, route: ROUTES.SETTINGS_STATES_CREATE }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikDatePicker
                        iconName="address-card"
                        labelText="Datum narození"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={nameof('birthdate')}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikInput iconName="address-card" labelText="E-mail" name={nameof('email')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikInput iconName="address-card" labelText="Telefon" name={nameof('phone')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikInput iconName="address-card" labelText="Poznámka" name={nameof('note')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikRelationSelect
                        iconName="hotel"
                        labelText="Firma"
                        name={nameof('companyId')}
                        errorMessage={errorMessage}
                        items={companySelectItems as RelationSelectItem[]}
                        createComponent={{ component: CompaniesCreateContainer, route: ROUTES.SETTINGS_COMPANIES_CREATE }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikInput iconName="address-card" labelText="Číslo občanského průkazu" name={nameof('identityCardNumber')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikDatePicker
                        iconName="address-card"
                        labelText="Datum expirace občanského průkazu"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={nameof('dateOfIdentityCardExpiration')}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikInput iconName="address-card" labelText="Číslo pasu" name={nameof('passportNumber')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={6}>
                      <FormikDatePicker
                        iconName="address-card"
                        labelText="Datum expirace pasu"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name={nameof('dateOfPassportExpiration')}
                        errorMessage={errorMessage}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikInput iconName="address-card" labelText="Místo pobytu" name={nameof('placeOfStay')} errorMessage={errorMessage} />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikCheckbox labelText="Blokovat hosta v rezervaci" name={nameof('isBlocked')} errorMessage={errorMessage} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                    <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                      Uložit
                    </Button>

                    <Button
                      variant="contained"
                      title="Zavřít"
                      color="secondary"
                      disabled={requestInProgress}
                      onClick={handleActionCancel}
                    >
                      Zavřít
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <AccommodationOffersContainer hideMenu={true} filter={`guestId=${values.id}`} />
            </TabPanel>
          </Box>
        );
      }}
    </Formik>
  );
};

const GuestDetail: React.FunctionComponent<GuestDetailProps> = memo(props => {
  const [tab, setTab] = useState(0);

  console.debug('GuestDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    stateSelectItems,
    companySelectItems,
    classes,
    modalDialog,
    modalDialogRoute,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<GuestModel>
      loadDependenciesRequest={loadDependenciesRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      {...{
        stateSelectItems: stateSelectItems,
        companySelectItems: companySelectItems,
        tab: tab,
        setTab: (index: number) => { setTab(index); },
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default GuestDetail;
