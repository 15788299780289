import React, { } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles, Theme, createStyles, Divider, Typography, Grid, Box, Chip, Input } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { AccommodationOfferRoomTypeCalculationModel, AccommodationOfferStateEnum } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { TableContainer, GenericColumn, BaseTableRowModel, EditButton, LinearProgressWithLabel, MenuItemModel } from '../../components';
import { BaseTableColumnId, EditableColumn, mergeWithIdColumn } from '../../components/table';
import * as calcActions from '../accommodationOfferCalculations/actions';
import { ROUTES } from '../../navigation';
import { AccommodationOfferRoomTypeEditContainer } from '../accommodationOfferRoomTypes';
import { getSelectedRoomCapacity } from '../accommodationOfferCalculations';
import _ from 'lodash';
import RoomTypePriceContainer from './roomTypePriceContainer';
import RoomTypeTotalPriceContainer from './roomTypeTotalPriceContainer';

type AccommodationOfferRoomTypesClassKey = 'body' | 'root';

type AccommodationOfferRoomTypesContainerStateProps = {
  accommodationOfferState: AccommodationOfferStateEnum;
  freeRooms: AccommodationOfferRoomTypeCalculationModel[];
  partiallyOccupiedRooms: AccommodationOfferRoomTypeCalculationModel[];
  reservedRooms: AccommodationOfferRoomTypeCalculationModel[];
  progress: number;
  total: number;
  selected: number;
  payForRoomAndNight: boolean;
};

type AccommodationOfferRoomTypesContainerDispatchProps = {
  columnValueChangedAction: typeof calcActions.columnValueChangedAction;
};

type AccommodationOfferRoomTypesContainerProps = {
  classes: Partial<ClassNameMap<AccommodationOfferRoomTypesClassKey>>;
  showProgress: boolean;
} & AccommodationOfferRoomTypesContainerStateProps &
  AccommodationOfferRoomTypesContainerDispatchProps;

const withStylesFunction = withStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    body: {
      backgroundColor: '#ebebeb'
    },
  })
);

const sharedColumns = (payForRoomAndNight: boolean): GenericColumn<AccommodationOfferRoomTypeCalculationModel>[] => [
  { id: 'id', label: '', minWidth: 25, align: 'left', isVisible: true, isTableActions: false },
  { id: 'roomTypeName', label: 'Typ pokoje', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'floorNumber', label: 'Patro', minWidth: 150, align: 'center', isVisible: true, isTableActions: false },
  { id: 'countOfAvailableRooms', label: 'Dostupné pokoje', minWidth: 150, align: 'center', isVisible: true, isTableActions: false },
  { id: 'countOfBedsByRoomType', label: 'Lůžka', minWidth: 150, align: 'center', isVisible: true, isTableActions: false },
  { id: 'countOfEmptyBeds', label: 'Volná lůžka', minWidth: 150, align: 'center', isVisible: true, isTableActions: false },
  { id: 'pricePerBedAndNight', label: 'Cena za lůžko / noc', minWidth: 150, align: 'center', isVisible: !payForRoomAndNight, isTableActions: false, editable: { type: 'number' } as EditableColumn, textAlign: 'right' },
  { id: 'pricePerRoomAndNight', label: 'Cena za pokoj / noc', minWidth: 100, align: 'center', isVisible: payForRoomAndNight, isTableActions: false, editable: { type: 'number' } as EditableColumn, textAlign: 'right' },
  {
    id: 'countOfOfferedBeds', label: 'Nabídnutá lůžka', minWidth: 100, align: 'center', isVisible: true, isTableActions: false,
    customComponent: (row, onColumnValueChanged, columnId, stateId) => {
      return <Input type="number" inputProps={{ min: 0, max: row.countOfEmptyBeds, style: { textAlign: 'right' } }}
        onChange={(event) => {
          const value = Number.parseInt(event.target.value);
          if (value <= row.countOfEmptyBeds) {
            onColumnValueChanged({
              newValue: `${value}`,
              rowId: row.id,
              columnId: columnId,
              stateId: stateId
            });
          } else {
            event.preventDefault();
          }
        }} defaultValue={0} />
    }
  },
  {
    id: 'roomTypeId', label: 'Ceník', minWidth: 200, align: 'right', isVisible: true, isTableActions: false,
    customComponent: (row, onColumnValueChanged, _columnId, stateId) => {
      return <RoomTypePriceContainer
        roomTypeId={row.roomTypeId}
        priceListItemPerBedAndNight={row.priceListItemPerBedAndNight}
        priceListItemPerRoomAndNight={row.priceListItemPerRoomAndNight}
        onChange={(value: number, columnId: BaseTableColumnId) => {
          onColumnValueChanged({
            newValue: `${value}`,
            rowId: row.id,
            columnId: columnId,
            stateId: stateId
          });
        }}
      />
    }
  },
  {
    id: 'id', label: 'Cena celkem', minWidth: 100, align: 'center', isVisible: payForRoomAndNight, isTableActions: false,
    customComponent: (row, onColumnValueChanged, _columnId, stateId) => {
      return <RoomTypeTotalPriceContainer
        roomTypeId={row.roomTypeId}
        pricePerBedAndNight={row.pricePerBedAndNight}
        pricePerRoomAndNight={row.pricePerRoomAndNight}
        priceListItemPerBedAndNight={row.priceListItemPerBedAndNight}
        priceListItemPerRoomAndNight={row.priceListItemPerRoomAndNight}
        onChange={(value: number, columnId: BaseTableColumnId) => {
          onColumnValueChanged({
            newValue: `${_.round(value, 4)}`,
            rowId: row.id,
            columnId: columnId,
            stateId: stateId
          });
        }}
      />
    }
  },
  {
    id: 'rooms', label: 'Pokoje', minWidth: 100, align: 'center', isVisible: true, isTableActions: false, customComponent: (row): any => {
      if (row.rooms) {
        const rooms = row.rooms.map(room => {
          return <Chip
            label={room.roomName}
            color="secondary"
          />
        });
        if (rooms.length > 0) {
          return rooms;
        } else {
          return null;
        }
      }
    }
  }];

const freeRoomColumns = (payForRoomAndNight: boolean): GenericColumn<AccommodationOfferRoomTypeCalculationModel>[] => mergeWithIdColumn<AccommodationOfferRoomTypeCalculationModel>([
  ...sharedColumns(payForRoomAndNight),
  {
    id: 'tableActions', label: '', align: 'left', isVisible: true, isTableActions: true, deletable: false, editComponent: (id: number, row: AccommodationOfferRoomTypeCalculationModel): any => {
      return <EditButton createComponent={{
        component: AccommodationOfferRoomTypeEditContainer,
        route: ROUTES.ACCOMMODATION_OFFER_ROOMS,
        componentProps: { id: row.id, roomTypeId: row.roomTypeId, floorNumber: row.floorNumber, type: 0 },
        fullWidth: false
      }}
        onClick={(): void => { console.log(`EDIT: ${id}`) }} />
    }
  }
]);

const partiallyOccupiedRoomColumns = (payForRoomAndNight: boolean): GenericColumn<AccommodationOfferRoomTypeCalculationModel>[] => mergeWithIdColumn<AccommodationOfferRoomTypeCalculationModel>([
  ...sharedColumns(payForRoomAndNight),
  {
    id: 'tableActions', label: '', align: 'left', isVisible: true, isTableActions: true, deletable: false, editComponent: (id: number, row: AccommodationOfferRoomTypeCalculationModel): any => {
      return <EditButton createComponent={{
        component: AccommodationOfferRoomTypeEditContainer,
        route: ROUTES.ACCOMMODATION_OFFER_ROOMS,
        componentProps: { id: row.id, roomTypeId: row.roomTypeId, floorNumber: row.floorNumber, type: 1 },
        fullWidth: false
      }}
        onClick={(): void => { console.log(`EDIT: ${id}`) }} />
    }
  }
]);

const reservedRoomColumns = (state: AccommodationOfferStateEnum, payForRoomAndNight: boolean): GenericColumn<AccommodationOfferRoomTypeCalculationModel>[] => mergeWithIdColumn<AccommodationOfferRoomTypeCalculationModel>([
  { id: 'id', label: '', minWidth: 25, align: 'left', isVisible: true, isTableActions: false },
  { id: 'roomTypeName', label: 'Typ pokoje', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'floorNumber', label: 'Patro', minWidth: 150, align: 'left', isVisible: true, isTableActions: false },
  { id: 'pricePerBedAndNight', label: 'Cena za lůžko / noc', minWidth: 150, align: 'left', isVisible: !payForRoomAndNight, isTableActions: false },
  { id: 'pricePerRoomAndNight', label: 'Cena za pokoj / noc', minWidth: 100, align: 'left', isVisible: payForRoomAndNight, isTableActions: false },
  { id: 'countOfOfferedBeds', label: 'Nabídnutá lůžka', minWidth: 100, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'rooms', label: 'Pokoje', minWidth: 100, align: 'left', isVisible: true, isTableActions: false, customComponent: (row): any => {
      if (row.rooms) {
        const rooms = row.rooms.map(room => {
          return <Chip
            label={room.roomName}
            color="secondary"
          />
        });
        if (rooms.length > 0) {
          return rooms;
        } else {
          return null;
        }
      }
    }
  },
  {
    id: 'tableActions', label: '', align: 'left', isVisible: state === AccommodationOfferStateEnum.Reservation, isTableActions: true, deletable: false, editComponent: (id: number, row: AccommodationOfferRoomTypeCalculationModel): any => {
      return <EditButton createComponent={{
        component: AccommodationOfferRoomTypeEditContainer,
        route: ROUTES.ACCOMMODATION_OFFER_ROOMS,
        componentProps: { id: row.id, roomTypeId: row.roomTypeId, floorNumber: row.floorNumber, type: 2 },
        fullWidth: false
      }}
        onClick={(): void => { console.log(`EDIT: ${id}`) }} />
    }
  }
]);

class AccommodationOfferRoomTypesContainer extends React.PureComponent<AccommodationOfferRoomTypesContainerProps> {
  render(): React.ReactElement {
    console.debug('AccommodationOfferRoomTypesContainer.render');
    const { progress, total, selected } = this.props;

    return (<Grid container direction="row" alignItems="center">
      {this.props.showProgress && <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <LinearProgressWithLabel value={progress} label={`${selected} z ${total}`} />
        </Grid>
      </Grid>}
      <Typography>Volné typy pokoje</Typography>
      <Box display="blok" flexGrow="1" flexShrink="1" flexBasis="auto">
        <Divider />
      </Box>
      <Grid container direction="row" alignItems="center">
        <TableContainer
          rows={this.props.freeRooms}
          rowNoClick={true}
          stateId={'freeRooms'}
          columns={freeRoomColumns(this.props.payForRoomAndNight) as GenericColumn<BaseTableRowModel>[]}
          dataSourcePath=""
          editAction={() => {
            console.log('editAction');
          }}
          deleteAction={(id) => {
            console.log('deleteAction')
          }}
          columnValueChangedAction={this.props.columnValueChangedAction}
        />
      </Grid>

      <Box pt={3} width="100%" />
      <Typography>Částečně obsazené typy pokoje</Typography>
      <Box display="blok" flexGrow="1" flexShrink="1" flexBasis="auto">
        <Divider />
      </Box>

      <Grid container direction="row" alignItems="center">
        <TableContainer
          rows={this.props.partiallyOccupiedRooms}
          columns={partiallyOccupiedRoomColumns(this.props.payForRoomAndNight) as GenericColumn<BaseTableRowModel>[]}
          rowNoClick={true}
          stateId={'partiallyOccupiedRooms'}
          dataSourcePath=""
          editAction={() => {
            console.log('editAction');
          }}
          deleteAction={() => {
            console.log('deleteAction');
          }}
          columnValueChangedAction={this.props.columnValueChangedAction}
        />
      </Grid>
      {this.props.reservedRooms && this.props.reservedRooms.length > 0 &&
        <React.Fragment>
          <Box pt={3} width="100%" />
          <Typography>Nabídnuté typy pokojů</Typography>
          <Box display="blok" flexGrow="1" flexShrink="1" flexBasis="auto">
            <Divider />
          </Box>
          <Grid container direction="row" alignItems="center">
            <TableContainer
              rows={this.props.reservedRooms}
              columns={reservedRoomColumns(this.props.accommodationOfferState, this.props.payForRoomAndNight) as GenericColumn<BaseTableRowModel>[]}
              rowNoClick={true}
              stateId={'reservedRooms'}
              dataSourcePath=""
              editAction={() => {
                console.log('editAction');
              }}
              deleteAction={() => {
                console.log('deleteAction');
              }}
              columnValueChangedAction={this.props.columnValueChangedAction}
              customCellClasses={this.props.classes}
            />
          </Grid>
        </React.Fragment>}
    </Grid>);
  }
}

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferRoomTypesContainerStateProps => {
  const selected = getSelectedRoomCapacity(state);
  let total = state.accommodationOfferCalculations.offerHeader.numberOfPersons;
  if (total <= 0) {
    total = 1;
  }
  const onePercent = 100 / total;
  let progress = selected > 0 ? onePercent * selected : 0;
  if (progress > 100) {
    progress = 100;
  }

  const freeRooms = state.accommodationOfferCalculations.freeRooms.allIds.map<AccommodationOfferRoomTypeCalculationModel>(id => {
    return state.accommodationOfferCalculations.freeRooms.byId[id];
  });
  const partiallyOccupiedRooms = state.accommodationOfferCalculations.partiallyOccupiedRooms.allIds.map<AccommodationOfferRoomTypeCalculationModel>(id => {
    return state.accommodationOfferCalculations.partiallyOccupiedRooms.byId[id];
  });
  const reservedRooms = state.accommodationOfferCalculations.reservedRooms.allIds.map<AccommodationOfferRoomTypeCalculationModel>(id => {
    return state.accommodationOfferCalculations.reservedRooms.byId[id];
  });

  const accommodationOfferState = state.accommodationOfferCalculations.offerHeader.state;
  const payForRoomAndNight = state.accommodationOfferCalculations.offerHeader.payForRoomAndNight;

  return {
    accommodationOfferState: accommodationOfferState,
    freeRooms: freeRooms,
    partiallyOccupiedRooms: partiallyOccupiedRooms,
    reservedRooms: reservedRooms,
    progress: progress,
    total: state.accommodationOfferCalculations.offerHeader.numberOfPersons,
    selected: selected,
    payForRoomAndNight: payForRoomAndNight
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferRoomTypesContainerDispatchProps => {
  return bindActionCreators(
    {
      columnValueChangedAction: calcActions.columnValueChangedAction
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(AccommodationOfferRoomTypesContainer));