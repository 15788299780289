import React, { RefObject } from 'react';
import {
  Theme
} from '@material-ui/core';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { Icon, IconName } from '../../../icons';
import { BaseComponentWithModalSupport } from '../../baseDetail';
import { withOpenModal, WithOpenModalProps } from '../../modalDialogManager';
import { ROUTES } from '../../../navigation';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  root: {},
  actionIconRoot: {
    '&:not(:last-of-type)': {
      marginRight: spacing(1)
    }
  },
  actionIcon: {
    fontSize: '1.2rem'
  }
}));

type EditButtonClassKey = 'actionIconRoot' | 'actionIcon';

export type EditButtonProps = {
  createComponent?: {
    iconName?: IconName;
    component: React.ComponentType<BaseComponentWithModalSupport>;
    componentProps?: any;
    fullWidth?: boolean;
    route: ROUTES;
  };
  onClick: () => void;
  classes: Partial<ClassNameMap<EditButtonClassKey>>;
} & WithOpenModalProps;


type EditButtonState = {
  open: boolean;
};

class EditButton extends React.PureComponent<EditButtonProps, EditButtonState> {
  selectRef: RefObject<any>;

  constructor(props: EditButtonProps) {
    super(props);

    this.state = { open: false };
    this.selectRef = React.createRef<any>();
  }

  openModal = (event: React.MouseEvent<HTMLSpanElement>): void => {
    event.preventDefault();

    if (this.props.createComponent) {
      this.props.openModal(
        this.props.createComponent.component, 
        this.props.createComponent.route, 
        this.props.createComponent.componentProps, 
        this.props.createComponent.fullWidth);
    }
  };

  render(): React.ReactElement {
    console.debug('EditButton.render');

    return (
      <React.Fragment>
        <Icon
          iconName="edit"
          className={this.props.classes.actionIcon}
          IconProps={{
            classes: { root: this.props.classes.actionIconRoot },
            color: 'secondary',
            title: 'Editovat',
            style: { cursor: 'pointer' },
            onClick: (event: React.MouseEvent<HTMLSpanElement>): void => {
              this.props.onClick();
              this.openModal(event);
            }
          }} />
      </React.Fragment>
    );
  }
}

export default withOpenModal(withStylesFunction(EditButton));
