import React, { memo } from 'react';

import { BaseTableToolbarComponentModel } from '../../components/table';
import { default as AccommodatedGuestsToolbar } from './accommodatedGuestsToolbar';

type AccommodatedGuestsToolbarContainerProps = {} & BaseTableToolbarComponentModel;

const AccommodatedGuestsToolbarContainer: React.FunctionComponent<AccommodatedGuestsToolbarContainerProps> = memo(props => {
    console.debug('AccommodatedGuestsToolbarContainer');

    return <AccommodatedGuestsToolbar {...props} />;
});

export default AccommodatedGuestsToolbarContainer;
