import { IconName } from "../../icons";
import { ROUTES } from "../../navigation";

export class MenuItemModel {
  to: ROUTES;
  label: string;
  icon: IconName;
  roleId: number;

  constructor(to: ROUTES, label: string, icon: IconName, roleId?: number) {
    this.to = to;
    this.label = label;
    this.icon = icon;
    this.roleId = roleId ?? 0;
  }
}
