import React, { RefObject } from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios';

import apiConfig from '../../configuration/api';
import { FileView } from './fileView';
import { FileProps, AcceptedFileType } from './types';

export type FileUploadProps = {
  fileType?: string | AcceptedFileType[];
  path: string;
  ref: RefObject<FileUpload>;
};

export type FileUploadStateProps = {
  selectedFiles: FileProps[];
};

class FileUpload extends React.Component<FileUploadProps, FileUploadStateProps> {
  constructor(props: FileUploadProps) {
    super(props);
    this.state = { selectedFiles: [] as FileProps[] };
  }
  
  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelected: File[] = Array.prototype.slice.call(
      event?.target?.files
    );

    const modified = currentSelected.map((file) => {
      return {
        file,
        uploadError: "",
        uploadProgress: 0
      };
    });

    this.setState({ selectedFiles: modified});
  };

  uploadFile = () => {
    const { selectedFiles } = this.state;

    const formData = new FormData();

    const axiosInstance = axios.create({
      baseURL: apiConfig.baseUrl,
      timeout: 300000,
      withCredentials: true,
      headers: {

      },
      validateStatus: (status: number) => status >= 200 && status < 300
    });

    const fileToUpload = selectedFiles !== null && selectedFiles !== undefined && selectedFiles.length > 0 ? selectedFiles[0] : null;

    if (fileToUpload !== null) {
      formData.append("file", fileToUpload?.file);

      axiosInstance.post(this.props.path, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          const newState = this.state.selectedFiles.map((file) => {
            if (file.file.name === fileToUpload.file.name) {
              return {
                ...file,
                uploadProgress: Math.round((100 * data.loaded) / data.total),
              }
            }

            return file;
          });

          this.setState({ selectedFiles: newState });
        },
      });
    }
  }

  acceptedFormats = (): string | undefined => {
    const { fileType } = this.props;

    return typeof fileType === "string"
      ? fileType
      : Array.isArray(fileType)
        ? fileType?.join(",")
        : Object.values(AcceptedFileType).toString();
  };

  render(): React.ReactNode {
    const { selectedFiles } = this.state;

    return (
      <div>
        <Button
          variant="contained"
          component="label"
          style={{ textTransform: "none" }}
        >
          <input
            hidden
            type="file"
            multiple={false}
            accept={this.acceptedFormats()}
            onChange={this.handleFileSelect}
          />

          {!selectedFiles?.length && <span> Vyberte Logo vaší firmy.</span>}
          {!!selectedFiles?.length && <FileView selectedFiles={selectedFiles} />}
        </Button>
        {!!selectedFiles?.length && (
          <div>
            {/* <Button>Smazat</Button> */}
          </div>
        )}
      </div>
    );
  }
}

export default FileUpload;