import { createAction, createAsyncAction } from 'typesafe-actions';
import { AccommodatedGuestModel } from '../../api/interfaces';
import {
    createGetItemsAsyncAction,
    createNewItemAsyncAction,
    createEditItemAsyncAction,
    createDeleteItemAsyncAction,
    createSaveItemAsyncAction,
    createCancelItemAsyncAction,
    createLoadDependenciesAsyncAction,
    createPagination
} from '../../core';

const modelName = 'accommodatedGuests';

export const getItemsAsync = createGetItemsAsyncAction<AccommodatedGuestModel>(modelName);

export const newItemAsync = createNewItemAsyncAction(modelName);

export const editItemAsync = createEditItemAsyncAction(modelName);

export const deleteItemAsync = createDeleteItemAsyncAction(modelName);

export const saveItemAsync = createSaveItemAsyncAction<AccommodatedGuestModel>(modelName);

export const cancelItemAsync = createCancelItemAsyncAction(modelName);

export const loadDependenciesAsync = createLoadDependenciesAsyncAction(modelName);

export const setPagination = createPagination(modelName);
