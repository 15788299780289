import React, { memo } from 'react';

import { BaseTableFilterComponentModel } from '../../components/table';
import { default as AccommodationOffersFilter } from './accommodationOffersFilter';

type AccommodationOffersFilterContainerProps = {} & BaseTableFilterComponentModel;

const AccommodationOffersFilterContainer: React.FunctionComponent<AccommodationOffersFilterContainerProps> = memo(props => {
    console.debug('AccommodationOffersFilterContainer');

    return <AccommodationOffersFilter {...props} />;
});

export default AccommodationOffersFilterContainer;
