import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { DeepReadonly } from 'utility-types';

import { AccommodationsState } from './types';
import * as actions from './actions';
import {
    createBaseListReducer,
    BaseActions,
    createBaseErrorMessageReducer,
    createBaseRequestInProgressReducer
} from '../../core';
import { AccommodationModel } from '../../api/interfaces';

export const initialState: AccommodationsState = {
    itemList: {
        allIds: [],
        byId: {},
        loaded: false
    },
    errorMessage: '',
    requestInProgress: false
};

const baseActions = {
    getItemsAsync: actions.getItemsAsync,
    saveItemAsync: actions.saveItemAsync,
    deleteItemAsync: actions.deleteItemAsync,
    editItemAsync: actions.editItemAsync
} as BaseActions<AccommodationModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<AccommodationsState>({
    itemList: itemListReducer,
    requestInProgress: requestInProgressReducer,
    errorMessage: errorMessageReducer
});
