import { all, put, takeEvery, PutEffect, ForkEffect, AllEffect } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { tenantProvider } from '../tenant';
import { themeService } from '../themes';

// use them in parallel
export default function* rootSaga(): Generator<ForkEffect<unknown> | AllEffect<unknown>> {
  yield all([yield takeEvery(actions.detectThemeName, detectThemeName)]);
}

function* detectThemeName(
  action: ReturnType<typeof actions.detectThemeName>
): Generator<PutEffect<ActionType<typeof actions.selectThemeName>>, void> {
  try {
    const tenantKey = tenantProvider.getTenant();
    const themeName = themeService.getThemeName(tenantKey);
    yield put(actions.selectThemeName(themeName));
  } catch (error) {
    console.error(error);
  }
}
