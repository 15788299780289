import React, { PureComponent } from 'react';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';

import Condition from './condition';

type FormikConditionProps<TValue, TKey> = {
  expectedValue: TValue | TValue[];
  propertyName: keyof TKey;
};

class FormikCondition<TValue, TKey> extends PureComponent<FormikConditionProps<TValue, TKey>> {
  is(value: TValue): boolean {
    if (Array.isArray(this.props.expectedValue)) {
      return (this.props.expectedValue as TValue[]).find(ev => ev === value) !== undefined;
    } else {
      return value === (this.props.expectedValue as TValue);
    }
  }

  render(): React.ReactNode {
    return (
      <Field name={this.props.propertyName}>
        {({ field }: FieldProps<TValue>): React.ReactNode => <Condition {...this.props} is={this.is(field.value)} />}
      </Field>
    );
  }
}

export default FormikCondition;
