import axios, { AxiosInstance } from 'axios';

import {
  AuthenticationClient,
  AccommodationClient,
  TimeLineClient,
  UserClient,
  RoomTypeClient,
  RoomClient,
  FloorClient,
  GuestClient,
  StateClient,
  CompanyClient,
  PriceListClient,
  PriceListItemClient,
  AccommodationOfferClient,
  AccommodationOfferRoomClient,
  AccommodationOfferCalculationClient,
  AccommodationOfferPriceCalculationClient,
  NumberSeriesClient,
  NumberSeriesDataClient,
  ReservationGuestCheckOutClient,
  PaymentClient,
  VatClient,
  MyCompanyClient,
  ReservationGuestStornoClient,
  AresClient,
  AccommodationOffersEvidenceListClient,
  AccommodationOffersEvidenceListPriceItemClient,
  AccommodatedGuestsClient,
  LogsClient
  /*importClient*/
} from './clients';
import { AuthenticatedRequestHandler, ErrorHandler } from './handlers';
import { tenantInterceptor } from './interceptors';
import { apiCallHandler } from './sagas';

export class ApiClient {
  private axiosInstance: AxiosInstance;

  constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      timeout: 300000,
      withCredentials: true,
      headers: {
        
      },
      validateStatus: (status: number) => status >= 200 && status < 300
    });

    this.registerInterceptors();

    const authenticatedRequestHandler = new AuthenticatedRequestHandler();
    const errorHandler = new ErrorHandler();

    this.Authentication = new AuthenticationClient(errorHandler, this.axiosInstance);
    this.Accommodations = new AccommodationClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.TimeLine = new TimeLineClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Users = new UserClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.RoomTypes = new RoomTypeClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Rooms = new RoomClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Floors = new FloorClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Guests = new GuestClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.States = new StateClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Companies = new CompanyClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.PriceLists = new PriceListClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.PriceListItems = new PriceListItemClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodationOffers = new AccommodationOfferClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodationOfferRooms = new AccommodationOfferRoomClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodationOfferCalculations = new AccommodationOfferCalculationClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodationOfferPriceCalculations = new AccommodationOfferPriceCalculationClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.NumberSeries = new NumberSeriesClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.NumberSeriesData = new NumberSeriesDataClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.ReservationGuestCheckOut = new ReservationGuestCheckOutClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Payments = new PaymentClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.Vats = new VatClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.MyCompanyClient = new MyCompanyClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.ReservationGuestStorno = new ReservationGuestStornoClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AresClient = new AresClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodationOffersEvidenceListClient = new AccommodationOffersEvidenceListClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodationOffersEvidenceListPriceItemClient = new AccommodationOffersEvidenceListPriceItemClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.AccommodatedGuestsClient = new AccommodatedGuestsClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    this.LogsClient = new LogsClient(authenticatedRequestHandler, errorHandler, this.axiosInstance);
    
    /*clientInstance*/

    this.apiCallHandler = apiCallHandler;
  }

  public Authentication: AuthenticationClient;

  public Users: UserClient;

  public TimeLine: TimeLineClient;

  public Accommodations: AccommodationClient;

  public RoomTypes: RoomTypeClient;

  public Rooms: RoomClient;

  public Floors: FloorClient;

  public Guests: GuestClient;

  public States: StateClient;

  public Companies: CompanyClient;

  public PriceLists: PriceListClient;

  public PriceListItems: PriceListItemClient;

  public AccommodationOffers: AccommodationOfferClient;

  public AccommodationOfferRooms: AccommodationOfferRoomClient;

  public AccommodationOfferCalculations: AccommodationOfferCalculationClient;

  public AccommodationOfferPriceCalculations: AccommodationOfferPriceCalculationClient;

  public NumberSeries: NumberSeriesClient;

  public NumberSeriesData: NumberSeriesDataClient;

  public ReservationGuestCheckOut: ReservationGuestCheckOutClient;

  public Payments: PaymentClient;

  public Vats: VatClient;

  public MyCompanyClient: MyCompanyClient;

  public ReservationGuestStorno: ReservationGuestStornoClient;

  public AresClient: AresClient;

  public AccommodationOffersEvidenceListClient: AccommodationOffersEvidenceListClient;

  public AccommodationOffersEvidenceListPriceItemClient: AccommodationOffersEvidenceListPriceItemClient;

  public AccommodatedGuestsClient: AccommodatedGuestsClient;

  public LogsClient: LogsClient;

  /*clientProperty*/

  public apiCallHandler: typeof apiCallHandler;

  private registerInterceptors(): void {
    this.axiosInstance.interceptors.request.use(tenantInterceptor, error => {
      return Promise.reject(error);
    });
  }
}
