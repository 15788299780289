import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import produce from 'immer';

import { AccommodationOfferPriceCalculationsState } from './types';
import { calculatePriceAsyncAction } from './actions';
import { BaseActions } from '../../core';

export const initialState: AccommodationOfferPriceCalculationsState = {
  prices: {
    totalVat: 0,
    totalWithVat: 0,
    totalWithoutVat: 0,
    realTotalVat: 0,
    realTotalWithVat: 0,
    realTotalWithoutVat: 0
  }
};

const extendedActions = {
  calculatePriceAsyncAction,
}

const pricesReducer = createReducer<typeof initialState.prices, ActionType<typeof extendedActions>>(initialState.prices).handleAction(
  extendedActions.calculatePriceAsyncAction.success,
  (state, action) => {
    const newState = produce(state, draft => {
      draft = action.payload;

      return draft;
    });

    return newState;
  }
);

export default combineReducers<AccommodationOfferPriceCalculationsState>({
  prices: pricesReducer
});
