import { combineReducers } from 'redux';

import { FloorsState } from './types';
import * as actions from './actions';
import { createBaseListReducer, createBaseErrorMessageReducer, createBaseRequestInProgressReducer, BaseActions } from '../../core';
import { FloorModel } from '../../api/interfaces';

export const initialState: FloorsState = {
    itemList: {
        allIds: [],
        byId: {},
        loaded: false
    },
    errorMessage: '',
    requestInProgress: false
};

const baseActions = {
    getItemsAsync: actions.getItemsAsync,
    saveItemAsync: actions.saveItemAsync,
    deleteItemAsync: actions.deleteItemAsync,
    editItemAsync: actions.editItemAsync
} as BaseActions<FloorModel>;

const itemListReducer = createBaseListReducer(initialState.itemList, baseActions);

const errorMessageReducer = createBaseErrorMessageReducer(initialState.errorMessage, baseActions);

const requestInProgressReducer = createBaseRequestInProgressReducer(initialState.requestInProgress, baseActions);

export default combineReducers<FloorsState>({
    itemList: itemListReducer,
    requestInProgress: requestInProgressReducer,
    errorMessage: errorMessageReducer
});
