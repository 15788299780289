import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import { ActionType } from "typesafe-actions";

import { ReservationsState } from './types';
import {
  createOrUpdateRoomAction,
  removeRoomAction,
  clearAction,
  editRoomsAction,
  setReservationAction,
  getCheckOutItemsAsync,
  deleteCheckOutItemAsync,
  saveCheckOutItemAsync,
  getCheckOutInfoAsync,
  clearCheckOutInfoAction
} from './actions';
import { AccommodationOfferStateEnum, ReservationModel, ReservationRoomModel } from '../../api/interfaces';
import { getItemAsync as getAccommodationOfferAsync } from '../accommodationOffers/actions';
import _ from 'lodash';
import { info } from 'console';

export const initialState: ReservationsState = {
  current: { rooms: [] as ReservationRoomModel[] } as ReservationModel,
  checkOuts: [],
  info: ''
};

const checkOutsActions = {
  getCheckOutItemsAsync,
  deleteCheckOutItemAsync,
  saveCheckOutItemAsync
};

const checkOutsReducer = createReducer<typeof initialState.checkOuts, ActionType<typeof checkOutsActions>>(initialState.checkOuts)
  .handleAction(checkOutsActions.getCheckOutItemsAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      draft = action.payload;
      return draft;
    });

    return newState;
  })
  .handleAction(checkOutsActions.saveCheckOutItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      const index = draft.findIndex(checkOut => checkOut.id === action.payload.id);
      if (index !== -1) {
        draft[index] = action.payload;
      } else {
        draft.push(action.payload);
      }

      return draft;
    });

    return newState;
  })
  .handleAction(checkOutsActions.deleteCheckOutItemAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      return draft.filter(x => x.id !== action.payload);
    });

    return newState;
  });

const currentActions = {
  clearAction,
  createOrUpdateRoomAction,
  removeRoomAction,
  getAccommodationOfferAsync,
  editRoomsAction,
  setReservationAction
};

const currentReducer = createReducer<typeof initialState.current, ActionType<typeof currentActions>>(initialState.current)
  .handleAction(currentActions.getAccommodationOfferAsync.success, (state, action) => {
    const newState = produce(state, draft => {
      const model = _.cloneDeep(action.payload.reservation) as ReservationModel;
      const isOffer = action.payload.state === AccommodationOfferStateEnum.Offer;
      model.rooms.forEach(room => {
        room._occupiedBeds = room.occupiedBeds;
        room._guests = room.guests;
        room._deleted = isOffer;
      });

      return model;
    });

    return newState;
  })
  .handleAction(currentActions.setReservationAction, (state, action) => {
    const newState = produce(state, draft => {
      const model = _.cloneDeep(action.payload) as ReservationModel;
      const isOffer = action.payload.state === AccommodationOfferStateEnum.Offer;
      model.rooms.forEach(room => {
        room._occupiedBeds = room.occupiedBeds;
        room._guests = room.guests;
        room._deleted = isOffer;
      });
      return model;
    });

    return newState;
  })
  .handleAction(currentActions.removeRoomAction, (state, action) => {
    const newState = produce(state, draft => {
      draft.rooms = draft.rooms.filter(room => room.id !== action.payload);
      return draft;
    });
    return newState;
  })
  .handleAction(currentActions.editRoomsAction, (state, action) => {
    const newState = produce(state, draft => {
      const values = action.payload;
      const rooms = [] as ReservationRoomModel[];
      state.rooms.forEach(room => {
        if (values.map(value => value.roomId).includes(room.roomId)) {
          const toAdd = values.find(v => v.roomId === room.roomId) as ReservationRoomModel;
          if (toAdd) {
            rooms.push(toAdd);
          }
        }
      });
      values.filter(value => !rooms.map(r => r.roomId).includes(value.roomId)).forEach(value => rooms.push(value));
      draft.rooms = rooms;
      return draft;
    });

    return newState;
  })
  .handleAction(currentActions.createOrUpdateRoomAction, (state, action) => {
    const newState = produce(state, draft => {
      const index = state.rooms.findIndex(r => r.roomId === action.payload.roomId);
      if (index !== -1) {
        draft.rooms[index].guests = [];
        action.payload.guests.forEach(guest => {
          draft.rooms[index].guests.push(guest);
        });
        const occupiedBeds = (state.rooms[index]._occupiedBeds - state.rooms[index]._guests.length) + action.payload.guests.length;
        draft.rooms[index].occupiedBeds = occupiedBeds;
        draft.rooms[index].countOfEmptyBeds = action.payload.countOfBedsByRoomType - occupiedBeds;
        draft.rooms[index].countOfGuests = occupiedBeds;
        draft.rooms[index].countOfBedsByRoomType = action.payload.countOfBedsByRoomType;
      }
    });

    return newState;
  })
  .handleAction(currentActions.clearAction, (state, action) => {
    return initialState.current;
  });

const infoActions = {
  getCheckOutInfoAsync,
  clearCheckOutInfoAction
};

const infoReducer = createReducer<typeof initialState.info, ActionType<typeof infoActions>>(initialState.info)
  .handleAction(infoActions.getCheckOutInfoAsync.success, (state, action) => {
    return action.payload;
  }).handleAction(infoActions.clearCheckOutInfoAction, (state, action) => {
    return initialState.info;
  });

export default combineReducers<ReservationsState>({
  current: currentReducer,
  checkOuts: checkOutsReducer,
  info: infoReducer
});
