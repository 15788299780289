import { all, put, take, takeEvery, select, TakeEffect, PutEffect, SelectEffect, call, CallEffect, takeMaybe } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import {
  getItemsAsync,
  getItemAsync,
  newItemAsync,
  saveItemAsync,
  cancelItemAsync,
  editItemAsync,
  deleteItemAsync,
  loadDependenciesAsync,
  loadTableDependenciesAsync,
  copyItemAsync,
  setDefaults
} from './actions';
import { calculateAsyncAction, clearCalculationAction, getCalculations, getCurrentOfferHeader } from '../accommodationOfferCalculations';

import apiClient from '../../api/apiClientInstance';
import { AccommodationOfferFeeModel, AccommodationOfferRoomTypeModel, AccommodationOfferModel, ReservationModel, AccommodationOfferFloorModel, GuestModel, AccommodationOfferStateEnum, AccommodationOfferSaveAsEnum, PriceListItemModel, PriceListItemType, AccommodationOfferCalculationHeaderModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import { navigateTo, navigateToDetail, NavigateToDetail, navigateBack, ROUTES } from '../../navigation';
import { getAreItemsLoaded, getAccommodationOffersPagination } from './selectors';
import {
  registerSaveEntityRequest,
  registerCancelEntityRequest,
  registerDeleteEntityRequest,
  registerGetEntitiesByPageRequest,
  NormalizedListById
} from '../../core';
import { getArePriceListsLoaded, getItemsAsync as loadPriceListsAsync } from '../priceLists';
import { getArePriceListItemsLoaded, getPriceListItemsById, getItemsAsync as loadPriceListItemsAsync } from '../priceListItems';
import { getAreCompaniesLoaded, getItemsAsync as loadCompaniesAsync, saveItemAsync as saveCompanyAsync } from '../companies';
import { getAreGuestsLoaded, getItemsAsync as loadGuestsAsync, saveItemAsync as saveGuestAsync } from '../guests';
import { getAreAccommodationsLoaded, getItemsAsync as loadAccommodationsAsync, } from '../accommodations';

import { closeTopModal, getVirtualData, VirtualComponentTypeEnum, VirtualQueryModel } from '../../components';

import { clearReservationAction, getCurrentReservation } from '../reservations';
import { getAccommodationOfferFloors } from '../accommodationOfferFloors';
import { addFeeAction, getAccommodationOfferFees } from '../accommodationOfferFees';
import {
  getAccommodationOfferFreeRoomTypes,
  getAccommodationOfferPartiallyOccupiedRoomTypes,
  getAccommodationOfferReservedRoomTypes
} from '../accommodationOfferRoomTypes';

import { setUniqueReservationNumberAction, setUniqueOfferNumberAction } from '../numberSeries';
import _ from 'lodash';
import { setPagination, setSelection } from './actions';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getAccommodationOffersRequest),
    yield takeEvery(newItemAsync.request, newAccommodationOfferRequest),
    yield takeEvery(saveItemAsync.request, saveAccommodationOfferRequest),
    yield takeEvery(cancelItemAsync.request, cancelAccommodationOfferRequest),
    yield takeEvery(editItemAsync.request, editAccommodationOfferRequest),
    yield takeEvery(deleteItemAsync.request, deleteAccommodationOfferRequest),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesRequest),
    yield takeEvery(loadTableDependenciesAsync.request, loadTableDependenciesRequest),
    yield takeEvery(getItemAsync.request, getAccommodationOfferRequest),
    yield takeEvery(saveGuestAsync.success, saveGuestAsyncSuccess),
    yield takeEvery(saveCompanyAsync.success, saveCompanyAsyncSuccess),
    yield takeEvery(copyItemAsync.request, copyAccommodationOfferRequest),
    yield takeEvery(setDefaults, setDefaultsAction)
  ]);
}
type SetDefaultsActionGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | PutEffect<ActionType<typeof addFeeAction>>
  | PutEffect<ActionType<typeof loadPriceListsAsync.request>>
  | PutEffect<ActionType<typeof loadPriceListItemsAsync.request>>
  | PutEffect<ActionType<typeof setDefaults>>;

function* setDefaultsAction(
  action: ReturnType<typeof setDefaults>
): Generator<SetDefaultsActionGeneratorType, void,
  & AccommodationOfferCalculationHeaderModel
  & NormalizedListById<AccommodationOfferFeeModel>
  & NormalizedListById<PriceListItemModel>> {
  try {
    const arePriceListsLoaded = yield select(getArePriceListsLoaded);
    if (!arePriceListsLoaded) {
      yield put(loadPriceListsAsync.request());
      yield take([getType(loadPriceListsAsync.success)]);
    }

    const arePriceListItemsLoaded = yield select(getArePriceListItemsLoaded);
    if (!arePriceListItemsLoaded) {
      yield put(loadPriceListItemsAsync.request());
      yield take([getType(loadPriceListItemsAsync.success)]);
    }

    const fees: NormalizedListById<AccommodationOfferFeeModel> = yield select(getAccommodationOfferFees);
    const offerHeader: AccommodationOfferCalculationHeaderModel = yield select(getCurrentOfferHeader);
    const priceListItems: NormalizedListById<PriceListItemModel> = yield select(getPriceListItemsById);

    const _fees = Object.entries(fees).map((key, value) => {
      return key[1];
    });

    const _priceListItems = Object.entries(priceListItems).map((key, value) => {
      return key[1];
    }).sort((a, b) => {
      return (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1;
    });

    const reservationFeeItem = _priceListItems.find(p => p.type === PriceListItemType.Fee);
    if (reservationFeeItem && offerHeader) {
      if (_fees.findIndex(f => f.priceListItemId === reservationFeeItem.id) === -1) {
        const { price } = reservationFeeItem;
        const { numberOfNights, numberOfPersons } = offerHeader;
        yield put(addFeeAction({
          price: price,
          totalPrice: price * (numberOfNights * numberOfPersons),
          amount: numberOfNights * numberOfPersons,
          priceListItemId: reservationFeeItem.id,
          priceListId: reservationFeeItem.priceListId,
          name: reservationFeeItem.name
        } as AccommodationOfferFeeModel));
      }
    }
  } catch (error) {
    console.error(error);
  }
}


type SaveCompanyAsyncSuccessRequestGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getVirtualData.request>>;

function* saveCompanyAsyncSuccess(
  action: ReturnType<typeof saveGuestAsync.success>
): Generator<SaveCompanyAsyncSuccessRequestGeneratorType, void, boolean> {
  yield put(getVirtualData.request({ page: 0, type: VirtualComponentTypeEnum.Companies } as VirtualQueryModel));
}

type SaveGuestAsyncSuccessRequestGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getVirtualData.request>>;

function* saveGuestAsyncSuccess(
  action: ReturnType<typeof saveGuestAsync.success>
): Generator<SaveGuestAsyncSuccessRequestGeneratorType, void, boolean> {
  yield put(getVirtualData.request({ page: 0, type: VirtualComponentTypeEnum.Guests } as VirtualQueryModel));
}

type GetAccommodationOfferRequestGeneratorType =
  | SelectEffect
  | TakeEffect
  | Generator<TakeEffect, boolean, boolean>
  | Generator<SelectEffect, boolean, boolean>
  | Promise<BaseDataResponse<AccommodationOfferModel>>
  | PutEffect<ActionType<typeof getItemAsync.success>>
  | PutEffect<ActionType<typeof getItemAsync.cancel>>
  | PutEffect<ActionType<typeof getItemAsync.failure>>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof calculateAsyncAction.request>>
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadCompaniesAsync.request>>
  | PutEffect<ActionType<typeof loadPriceListsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* getAccommodationOfferRequest(
  action: ReturnType<typeof getItemAsync.request>
): Generator<GetAccommodationOfferRequestGeneratorType, void, boolean & BaseDataResponse<AccommodationOfferModel>> {
  try {
    console.debug('getEntityRequest');
    const response: BaseDataResponse<AccommodationOfferModel> = yield apiClient.apiCallHandler({
      context: apiClient.AccommodationOffers,
      apiCallFnName: 'getAsync',
    }, action.payload);

    if (response.resultCode === 0) {
      const payload = response.data;
      yield put(calculateAsyncAction.request({ ...payload }));
      yield take([getType(calculateAsyncAction.success)]);

      const areAccommodationsLoaded = yield select(getAreAccommodationsLoaded);
      if (!areAccommodationsLoaded) {
        yield put(loadAccommodationsAsync.request());
      }

      const areGuestsLoaded = yield select(getAreGuestsLoaded);
      if (!areGuestsLoaded) {
        yield put(loadGuestsAsync.request());
      }

      const areCompaniesLoaded = yield select(getAreCompaniesLoaded);
      if (!areCompaniesLoaded) {
        yield put(loadCompaniesAsync.request());
      }

      const arePriceListsLoaded = yield select(getArePriceListsLoaded);
      if (!arePriceListsLoaded) {
        yield put(loadPriceListsAsync.request());
      }

      yield put(getItemAsync.success(payload));
    } else {
      yield put(getItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemAsync.failure(error));
  }
}

const getAccommodationOffersRequest = registerGetEntitiesByPageRequest<AccommodationOfferModel>(
  getItemsAsync.success,
  getItemsAsync.failure,
  getItemsAsync.cancel,
  apiClient.AccommodationOffers,
  setPagination,
  getAccommodationOffersPagination
);

type NewAccommodationOfferRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof newItemAsync.success>>
  | PutEffect<ActionType<typeof newItemAsync.failure>>;

function* newAccommodationOfferRequest(
  action: ReturnType<typeof newItemAsync.request>
): Generator<NewAccommodationOfferRequestGeneratorType, void, void> {
  try {
    console.debug('newAccommodationOfferRequest');

    yield put(navigateTo(ROUTES.ACCOMMODATION_OFFERS_CREATE));
    yield put(newItemAsync.success());
  } catch (error) {
    yield put(newItemAsync.failure(error));
  }
}

type EditAccommodationOfferRequestGeneratorType =
  | Promise<BaseDataResponse<AccommodationOfferModel>>
  | PutEffect<ActionType<typeof editItemAsync.success>>
  | PutEffect<ActionType<typeof editItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* editAccommodationOfferRequest(
  action: ReturnType<typeof editItemAsync.request>
): Generator<EditAccommodationOfferRequestGeneratorType, void, BaseDataResponse<AccommodationOfferModel>> {
  try {
    console.debug('editAccommodationOfferRequest');
    const itemToEditId = action.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.ACCOMMODATION_OFFERS_DETAIL, detailId: itemToEditId } as NavigateToDetail));
    yield put(editItemAsync.success());
  } catch (error) {
    yield put(editItemAsync.failure(error));
  }
}

const deleteAccommodationOfferRequest = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.AccommodationOffers,
  ROUTES.ACCOMMODATION_OFFERS
);

type SaveEntityRequestGeneratorType<AccommodationOfferModel> =
  | Promise<BaseDataResponse<AccommodationOfferModel>>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof saveItemAsync.success>>
  | PutEffect<ActionType<typeof saveItemAsync.failure>>
  | SelectEffect
  | CallEffect
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* saveAccommodationOfferRequest(
  action: ActionType<typeof saveItemAsync.request>
): Generator<SaveEntityRequestGeneratorType<AccommodationOfferModel>, void,
  BaseDataResponse<AccommodationOfferModel>
  & ReservationModel
  & NormalizedListById<AccommodationOfferFeeModel>
  & NormalizedListById<AccommodationOfferRoomTypeModel>
  & NormalizedListById<AccommodationOfferFloorModel>
  & boolean> {
  const fees: NormalizedListById<AccommodationOfferFeeModel> = yield select(getAccommodationOfferFees);
  const freeRoomTypes: NormalizedListById<AccommodationOfferRoomTypeModel> = yield select(getAccommodationOfferFreeRoomTypes);
  const occupiedRoomTypes: NormalizedListById<AccommodationOfferRoomTypeModel> = yield select(getAccommodationOfferPartiallyOccupiedRoomTypes);
  const reserverdRoomTypes: NormalizedListById<AccommodationOfferRoomTypeModel> = yield select(getAccommodationOfferReservedRoomTypes);
  const reservation: ReservationModel = yield select(getCurrentReservation);
  const floors: NormalizedListById<AccommodationOfferFloorModel> = yield select(getAccommodationOfferFloors);

  const _action = _.cloneDeep(action);
  _action.payload.fees = Object.entries(fees).map((key, value) => {
    return key[1];
  });

  const _freeRoomTypes = Object.entries(freeRoomTypes).map((key, value) => {
    return key[1];
  });
  const _occupiedRoomTypes = Object.entries(occupiedRoomTypes).map((key, value) => {
    return key[1];
  });

  let _reservedRoomTypes = Object.entries(reserverdRoomTypes).map((key, value) => {
    return key[1];
  });

  // If changing state from offer to reservation clear 
  if (action.payload.saveAs === AccommodationOfferSaveAsEnum.Reservation
    && action.payload.state === AccommodationOfferStateEnum.Offer) {
    _reservedRoomTypes = [] as AccommodationOfferRoomTypeModel[];
  }

  // If has some changes in offer clear reserved rooms and create with newly added
  if (action.payload.saveAs === AccommodationOfferSaveAsEnum.Offer &&
    action.payload.state === AccommodationOfferStateEnum.Offer) {
    if (([..._freeRoomTypes, ..._occupiedRoomTypes]).filter(roomType => roomType.rooms.length > 0).length > 0) {
      _reservedRoomTypes = [] as AccommodationOfferRoomTypeModel[];
      _action.payload.saveAs = AccommodationOfferSaveAsEnum.OfferWithRoomTypesUpdate;
    } else {
      _action.payload.saveAs = AccommodationOfferSaveAsEnum.OfferWithoutRoomTypesUpdate;
    }
  }

  _action.payload.floors = Object.entries(floors).map((key, value) => {
    return key[1];
  }).filter(floor => floor.isSelected);
  _action.payload.roomTypes = ([..._freeRoomTypes, ..._occupiedRoomTypes, ..._reservedRoomTypes]).filter(roomType => roomType.rooms.length > 0);
  _action.payload.reservation = { ..._action.payload.reservation, rooms: reservation?.rooms.filter(room => !room._deleted) };

  yield call(baseSaveAccommodationOfferRequest, _action);

  yield put(getItemsAsync.request());
}

const baseSaveAccommodationOfferRequest = registerSaveEntityRequest<AccommodationOfferModel>(
  saveItemAsync.success,
  saveItemAsync.failure,
  apiClient.AccommodationOffers,
  ROUTES.ACCOMMODATION_OFFERS
);

const cancelAccommodationOfferRequest = registerCancelEntityRequest(
  cancelItemAsync.success,
  cancelItemAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

type LoadDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof clearCalculationAction>>
  | PutEffect<ActionType<typeof clearReservationAction>>
  | PutEffect<ActionType<typeof setUniqueReservationNumberAction>>
  | PutEffect<ActionType<typeof setUniqueOfferNumberAction>>
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadCompaniesAsync.request>>
  | PutEffect<ActionType<typeof loadGuestsAsync.request>>
  | PutEffect<ActionType<typeof loadPriceListsAsync.request>>
  | PutEffect<ActionType<typeof loadPriceListItemsAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>;

function* loadDependenciesRequest(
  action: ReturnType<typeof loadDependenciesAsync.request>
): Generator<LoadDependenciesRequestGeneratorType, void, boolean & BaseDataResponse<string>> {
  try {
    console.debug('loadDependenciesRequest');

    yield put(clearCalculationAction());

    yield put(clearReservationAction());

    const areAccommodationsLoaded = yield select(getAreAccommodationsLoaded);
    if (!areAccommodationsLoaded) {
      yield put(loadAccommodationsAsync.request());
    }

    const areGuestsLoaded = yield select(getAreGuestsLoaded);
    if (!areGuestsLoaded) {
      yield put(loadGuestsAsync.request());
      yield take([getType(loadGuestsAsync.success)]);
    }

    const areCompaniesLoaded = yield select(getAreCompaniesLoaded);
    if (!areCompaniesLoaded) {
      yield put(loadCompaniesAsync.request());
      yield take([getType(loadCompaniesAsync.success)]);
    }

    const arePriceListsLoaded = yield select(getArePriceListsLoaded);
    if (!arePriceListsLoaded) {
      yield put(loadPriceListsAsync.request());
      yield take([getType(loadPriceListsAsync.success)]);
    }

    const arePriceListItemsLoaded = yield select(getArePriceListItemsLoaded);
    if (!arePriceListItemsLoaded) {
      yield put(loadPriceListItemsAsync.request());
      yield take([getType(loadPriceListItemsAsync.success)]);
    }

    const response0: BaseDataResponse<string> = yield apiClient.apiCallHandler({
      context: apiClient.NumberSeries,
      apiCallFnName: 'getUniqueReservationNumberAsync'
    });

    yield put(setUniqueReservationNumberAction(response0.data));

    const response1: BaseDataResponse<string> = yield apiClient.apiCallHandler({
      context: apiClient.NumberSeries,
      apiCallFnName: 'getUniqueOfferNumberAsync'
    });

    yield put(setUniqueOfferNumberAction(response1.data));

    yield put(loadDependenciesAsync.success());
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}

type LoadTableDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | TakeEffect
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof setSelection>>
  | PutEffect<ActionType<typeof loadAccommodationsAsync.request>>
  | PutEffect<ActionType<typeof loadCompaniesAsync.request>>
  | PutEffect<ActionType<typeof loadTableDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadTableDependenciesAsync.failure>>;

function* loadTableDependenciesRequest(
  action: ReturnType<typeof loadTableDependenciesAsync.request>
): Generator<LoadTableDependenciesRequestGeneratorType, void, boolean & BaseDataResponse<string>> {
  try {
    console.debug('loadTableDependenciesRequest');

    yield put(setSelection({
      ids: [],
      isOpen: false,
      items: {}
    }));

    const areCompaniesLoaded = yield select(getAreCompaniesLoaded);
    if (!areCompaniesLoaded) {
      yield put(loadCompaniesAsync.request());
      yield take([getType(loadCompaniesAsync.success)]);
    }

    const areAccommodationsLoaded = yield select(getAreAccommodationsLoaded);
    if (!areAccommodationsLoaded) {
      yield put(loadAccommodationsAsync.request());
    }

    const areAccommodationOffersLoaded = yield select(getAreItemsLoaded);
    if (!areAccommodationOffersLoaded) {
      yield put(getItemsAsync.request());
    }

    yield put(loadTableDependenciesAsync.success());
  } catch (error) {
    yield put(loadTableDependenciesAsync.failure(error));
  }
}


type CopyAccommodationOfferRequestGeneratorType =
  | Promise<BaseDataResponse<AccommodationOfferModel>>
  | PutEffect<ActionType<typeof copyItemAsync.success>>
  | PutEffect<ActionType<typeof copyItemAsync.failure>>
  | PutEffect<ActionType<typeof navigateToDetail>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* copyAccommodationOfferRequest(
  action?: ReturnType<typeof editItemAsync.request>
): Generator<CopyAccommodationOfferRequestGeneratorType, void, BaseDataResponse<AccommodationOfferModel>> {
  try {
    console.debug('copyAccommodationOfferRequest');
    const itemToCopyId = action?.payload;

    yield put(navigateToDetail({ masterPage: ROUTES.ACCOMMODATION_OFFERS_COPY, detailId: itemToCopyId } as NavigateToDetail));
    yield put(copyItemAsync.success());
  } catch (error) {
    yield put(copyItemAsync.failure(error));
  }
}