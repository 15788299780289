import { all, take, put, takeEvery, select, TakeEffect, PutEffect, SelectEffect, CallEffect } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { AccommodationOfferModel, PaymentModel } from '../../api/interfaces';
import { BaseDataResponse, BaseResponse } from '../../api/responses';
import apiClient from '../../api/apiClientInstance';
import { navigateTo, ROUTES } from '../../navigation';
import {
  getItemsAsync,
  saveItemAsync,
  cancelItemAsync,
  deleteItemAsync,
  loadDependenciesAsync,
  calculateAsync,
  loadTableDependenciesAsync,
  getAllItemsAsync,
  setPagination
} from './actions';
import { NormalizedListById, registerCancelEntityRequest, registerGetEntitiesByPageRequest } from '../../core';
import { getItemsAsync as loadAccommodationOffersAsync, getAccommodationOffersById } from '../accommodationOffers';
import { setReservationAction } from '../reservations';
import { getIsSomeModalOpened, closeTopModal } from '../../components';
import { PaymentCalculationModel } from '../../api/interfaces';
import { getAreItemsLoaded, getPaymentsPagination } from './selectors';
import { getAreCompaniesLoaded, getItemsAsync as loadCompaniesAsync } from '../companies';

export default function* rootSaga(): any {
  yield all([
    yield takeEvery(getItemsAsync.request, getItemsAsyncHandler),
    yield takeEvery(cancelItemAsync.request, cancelItemRequestHandler),
    yield takeEvery(saveItemAsync.request, saveItemRequestHandler),
    yield takeEvery(deleteItemAsync.request, deleteItemRequestHandler),
    yield takeEvery(loadDependenciesAsync.request, loadDependenciesAsyncHandler),
    yield takeEvery(calculateAsync.request, calculateAsyncHandler),
    yield takeEvery(loadTableDependenciesAsync.request, loadTableDependenciesRequest),
    yield takeEvery(getAllItemsAsync.request, getAllItemsAsyncHandler),
  ]);
}

type CalculateActionGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof calculateAsync.request>>
  | PutEffect<ActionType<typeof calculateAsync.success>>
  | PutEffect<ActionType<typeof calculateAsync.failure>>
  | Promise<BaseDataResponse<PaymentCalculationModel>>;

function* calculateAsyncHandler(action: ReturnType<typeof calculateAsync.request>):
  Generator<CalculateActionGeneratorType, void,
    void
    & boolean
    & BaseDataResponse<PaymentModel[]>> {
  try {

    const response: BaseDataResponse<PaymentCalculationModel> = yield apiClient.apiCallHandler({
      context: apiClient.Payments,
      apiCallFnName: 'calculateAsync'
    }, action.payload);

    if (response.resultCode === 0) {
      yield put(calculateAsync.success(response.data));
    } else {
      yield put(calculateAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }

  } catch (error) {
    yield put(calculateAsync.failure(error));
  }
}

type DeleteEntityRequestGeneratorType =
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof loadAccommodationOffersAsync.request>>
  | PutEffect<ActionType<typeof deleteItemAsync.success>>
  | PutEffect<ActionType<typeof deleteItemAsync.failure>>;

function* deleteItemRequestHandler(
  action: ActionType<typeof deleteItemAsync.request>
): Generator<DeleteEntityRequestGeneratorType, void, BaseResponse> {
  try {
    console.debug('deleteAccommodationOfferRequest');
    const itemToDeleteId = action.payload;
    const response: BaseResponse = yield apiClient.apiCallHandler(
      { context: apiClient.Payments, apiCallFnName: 'deleteAsync' },
      itemToDeleteId
    );

    if (response.resultCode === 0) {
      yield put(deleteItemAsync.success(itemToDeleteId));
      yield put(loadAccommodationOffersAsync.request());
      yield put(navigateTo(ROUTES.PAYMENTS));
    } else {
      yield put(deleteItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(deleteItemAsync.failure(error));
  }
}

/*const deleteItemRequestHandler = registerDeleteEntityRequest(
  deleteItemAsync.success,
  deleteItemAsync.failure,
  apiClient.Payments,
  ROUTES.ACCOMMODATION_OFFERS
);*/

const cancelItemRequestHandler = registerCancelEntityRequest(
  cancelItemAsync.success,
  cancelItemAsync.failure,
  ROUTES.ACCOMMODATION_OFFERS
);

type GetItemsActionGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof getItemsAsync.success>>
  | PutEffect<ActionType<typeof getItemsAsync.failure>>
  | Promise<BaseDataResponse<PaymentModel[]>>;

function* getItemsAsyncHandler(action: ReturnType<typeof getItemsAsync.request>):
  Generator<GetItemsActionGeneratorType, void,
    void
    & boolean
    & NormalizedListById<AccommodationOfferModel>
    & BaseDataResponse<PaymentModel[]>> {
  try {

    const response: BaseDataResponse<PaymentModel[]> = yield apiClient.apiCallHandler({
      context: apiClient.Payments,
      apiCallFnName: 'getAllByReservationAsync'
    }, action.payload);

    if (response.resultCode === 0) {
      yield put(getItemsAsync.success(response.data));
    } else {
      yield put(getItemsAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }
  } catch (error) {
    yield put(getItemsAsync.failure(error));
  }
}

type LoadDependenciesActionGeneratorType = SelectEffect
  | TakeEffect
  | CallEffect
  | Generator<TakeEffect, boolean, boolean>
  | PutEffect<ActionType<typeof setReservationAction>>
  | PutEffect<ActionType<typeof getItemsAsync.request>>
  | PutEffect<ActionType<typeof calculateAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.request>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadDependenciesAsync.failure>>
  | PutEffect<ActionType<typeof loadCompaniesAsync.request>>
  | Promise<BaseDataResponse<PaymentCalculationModel>>;

function* loadDependenciesAsyncHandler(action: ReturnType<typeof loadDependenciesAsync.request>):
  Generator<LoadDependenciesActionGeneratorType, void,
    void
    & boolean
    & NormalizedListById<AccommodationOfferModel>
    & BaseDataResponse<PaymentModel[]>> {
  try {
    const accommodationOffers: NormalizedListById<AccommodationOfferModel> = yield select(getAccommodationOffersById);
    const accommodationOffer = accommodationOffers[action.payload];

    yield put(getItemsAsync.request(accommodationOffer.reservation.id));
    yield take([getType(getItemsAsync.success)]);

    yield put(calculateAsync.request(accommodationOffer.reservation.id));
    yield take([getType(calculateAsync.success)]);

    const areCompaniesLoaded = yield select(getAreCompaniesLoaded);
    if (!areCompaniesLoaded) {
      yield put(loadCompaniesAsync.request());
      yield take([getType(loadCompaniesAsync.success)]);
    }

    yield put(loadDependenciesAsync.success());
    yield put(setReservationAction(accommodationOffer.reservation));
  } catch (error) {
    yield put(loadDependenciesAsync.failure(error));
  }
}

type SaveItemRequestGeneratorType =
  | PutEffect<ActionType<typeof navigateTo>>
  | PutEffect<ActionType<typeof loadAccommodationOffersAsync.request>>
  | PutEffect<ActionType<typeof saveItemAsync.success>>
  | PutEffect<ActionType<typeof saveItemAsync.failure>>
  | SelectEffect
  | Promise<BaseDataResponse<PaymentModel>>
  | PutEffect<ActionType<typeof closeTopModal>>
  | PutEffect<ActionType<typeof navigateTo>>;

function* saveItemRequestHandler(
  action: ReturnType<typeof saveItemAsync.request>
): Generator<SaveItemRequestGeneratorType, void,
  void
  & boolean
  & BaseDataResponse<PaymentModel>> {
  try {
    const model = action.payload;
    const method: keyof typeof apiClient.Payments = model.id > 0 ? 'updateAsync' : 'createAsync';
    const response: BaseDataResponse<PaymentModel> = yield apiClient.apiCallHandler(
      {
        context: apiClient.Payments,
        apiCallFnName: method
      },
      model);

    if (response.resultCode === 0) {
      const data = response.data;
      yield put(saveItemAsync.success(data));

      const isSomeModalOpened: boolean = yield select(getIsSomeModalOpened);
      if (isSomeModalOpened) {
        yield put(closeTopModal());

        yield put(loadAccommodationOffersAsync.request());
      }
    } else {
      yield put(saveItemAsync.failure(new Error(JSON.stringify({ reason: response.resultReason, code: response.resultCode }))));
    }

  } catch (error) {
    yield put(saveItemAsync.failure(error));
  }
}

type LoadTableDependenciesRequestGeneratorType =
  | SelectEffect
  | CallEffect
  | Promise<BaseResponse>
  | PutEffect<ActionType<typeof getAllItemsAsync.request>>
  | PutEffect<ActionType<typeof loadTableDependenciesAsync.success>>
  | PutEffect<ActionType<typeof loadTableDependenciesAsync.failure>>;

function* loadTableDependenciesRequest(
  action: ReturnType<typeof loadTableDependenciesAsync.request>
): Generator<LoadTableDependenciesRequestGeneratorType, void, boolean & BaseDataResponse<string>> {
  try {
    console.debug('loadTableDependenciesRequest');

    const arePaymentsLoaded = yield select(getAreItemsLoaded);
    if (!arePaymentsLoaded) {
      yield put(getAllItemsAsync.request());
    }

    yield put(loadTableDependenciesAsync.success());
  } catch (error) {
    yield put(loadTableDependenciesAsync.failure(error));
  }
}

const getAllItemsAsyncHandler = registerGetEntitiesByPageRequest<PaymentModel>(
  getAllItemsAsync.success,
  getAllItemsAsync.failure,
  getAllItemsAsync.cancel,
  apiClient.Payments,
  setPagination,
  getPaymentsPagination
);