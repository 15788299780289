import { isString } from 'lodash';
import * as Yup from 'yup';

export const reservationValidationSchema = Yup.object().shape({
});

export const reservationCheckOutLogOffValidationSchema = Yup.object().shape({
  guestId: Yup.number()
    .required('Host musí být vybrána')
    .min(1, 'Host musí být vybrána'),
  roomId: Yup.number()
    .required('Pokoj musí být vybrána')
    .min(1, 'Pokoj musí být vybrána'),
  from: Yup.mixed().typeError('Odchod musí být datum ve formátu den.měsíc.rok').required('Od musí být vyplněno'),
  to: Yup.mixed().notRequired()
});

export const reservationCheckOutLogOnValidationSchema = Yup.object().shape({
  guestId: Yup.number()
    .required('Host musí být vybrán')
    .min(1, 'Host musí být vybrán'),
  roomId: Yup.number()
    .required('Pokoj musí být vybrán')
    .min(1, 'Pokoj musí být vybrán'),
  to: Yup.mixed().typeError('Odchod musí být datum ve formátu den.měsíc.rok').required('Do musí být vyplněno'),
  from: Yup.mixed().notRequired()
});

export const reservationCheckOutValidationSchema = Yup.object().shape({
  guestId: Yup.number()
    .required('Host musí být vybrán')
    .min(1, 'Host musí být vybrán'),
  roomId: Yup.number()
    .required('Pokoj musí být vybrán')
    .min(1, 'Pokoj musí být vybrán'),
  from: Yup.mixed().typeError('Odchod musí být datum ve formátu den.měsíc.rok').required('Od musí být vyplněno'),
  to: Yup.mixed().typeError('Příchod musí být validní datum').when(['from'],
    (from: Date) => {
      if (from) {
        let _from = from;
        if(isString(from)) {
          _from = new Date(_from);
        }
        const day = new Date(_from.getTime());
        return Yup.date()
                        .min(day, 'Datum příchodu musí být vetší jak datum odchodu')
      }

      return Yup.date();
    })
});
