import React from 'react';
import { Typography, Button, Grid, FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { DeepReadonly } from 'utility-types';

import { FormikInput } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { signInValidationSchema } from './validations';
import { useSignInStyles } from './styles';
import { GrantPasswordRequest } from './types';

type SingInUserNamePasswordViewProps = {
  errorMessage?: string;
  requestInProgress: boolean;

  signIn: (request: GrantPasswordRequest) => void;
};

type SingIn = Yup.InferType<typeof signInValidationSchema>;

const nameof = nameofFactory<SingIn>();

const SingInUserNamePasswordView: React.FunctionComponent<SingInUserNamePasswordViewProps> = props => {
  const classes = useSignInStyles(props);
  const { errorMessage, requestInProgress } = props;

  return (
    <Formik<SingIn>
      initialValues={{ userName: '', password: '' }}
      onSubmit={(values: SingIn, { setSubmitting }): void => {
        setSubmitting(true);

        props.signIn(values);
      }}
      validationSchema={signInValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { values, touched, errors, /* isSubmitting, */ handleChange, handleBlur, handleSubmit } = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <Grid container className={classes.grid} spacing={3}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="id-card-alt"
                    labelText="Uživatelské jméno"
                    name={nameof('userName')}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="id-card-alt"
                    labelText="Heslo"
                    name={nameof('password')}
                    InputProps={{
                      type: 'password'
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="center" xs={12}>
                <Button variant="contained" title="Přihlásit se" color="primary" type="submit" disabled={requestInProgress}>
                  Přihlásit se
                </Button>
              </Grid>
              <Grid item container justify="flex-end" xs={12}>
                <FormHelperText error={true}>{errorMessage}</FormHelperText>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default SingInUserNamePasswordView;
