import * as Yup from 'yup';
import { nameValidationSchema } from '../validations';

export const guestValidationSchema = Yup.object().shape({
  name: Yup.string().required('Jméno'),
  surname: Yup.string().required('Příjmení'),
  stateId: Yup.number()
    .required('Stát musí být vybrán')
    .min(1, 'Stát musí být vybrán'),
});
