import React from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { getPaymentMethodsSelectItems } from '.';
import { ApplicationState } from '../../store/rootReducer';
import { FormikDatePicker, FormikInput, FormikRelationSelect, RelationSelectItem, TablePaginationModel } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { AccommodationOfferStateEnum } from '../../api/interfaces';
import * as actions from './actions';
import { TablePageChangeModel } from '../../components/table';

const nameof = nameofFactory<PaymentsFilterModel>();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '30px'
    }
  })
);

type PaymentsFilterModel = {
  paymentMethod: AccommodationOfferStateEnum;
  dateOfPaymentFrom?: Date;
  dateOfPaymentTo?: Date;
  amountFrom?: number;
  amountTo?: number;
};

type PaymentsFilterStateProps = {
};

type PaymentsFilterDispatchProps = {
  getItems: typeof actions.getAllItemsAsync.request;
};

type PaymentsFilterProps = {
  onClose(): void;
} & PaymentsFilterStateProps & PaymentsFilterDispatchProps;

const PaymentsFilter: React.FunctionComponent<PaymentsFilterProps> = props => {
  console.debug('PaymentsFilter');
  const classes = useStyles();
  const paymentMethodsSelectItems = getPaymentMethodsSelectItems();

  const { getItems } = props;
  return (
    <React.Fragment>
      <Formik<PaymentsFilterModel>
        initialValues={{ paymentMethod: 0, dateOfPaymentFrom: undefined, dateOfPaymentTo: undefined, amountFrom: undefined, amountTo: undefined }}
        onSubmit={(values: any): void => {
          console.debug(values);
          let filter = "";
          if (values.paymentMethod > 0) {
            filter += `paymentMethod=${values.paymentMethod}%26`
          }
          if (values.amountFrom >= 0) {
            filter += `amountFrom=${values.amountFrom}%26`
          }
          if (values.amountTo >= 0) {
            filter += `amountTo=${values.amountTo}%26`
          }
          if (values.dateOfPaymentFrom !== undefined) {
            filter += `dateOfPaymentFrom=${values.dateOfPaymentFrom.toISOString()}%26`
          }
          if (values.dateOfPaymentTo !== undefined) {
            filter += `dateOfPaymentTo=${values.dateOfPaymentTo.toISOString()}`
          }

          getItems({ page: 0, filter: filter, skip: 0 } as TablePageChangeModel);
          props.onClose();
        }}
      >
        {(formProps): React.ReactElement => {
          const { handleSubmit, resetForm, submitForm } = formProps;
          return (
            <form style={{ width: '500px' }} onSubmit={handleSubmit} autoComplete="off">
              <Grid container className={classes.grid} spacing={3}>
                <Grid container item>
                  <Grid container item xs={6}>
                    <FormikInput
                      iconName="money-bill"
                      labelText="Částka od"
                      name={nameof('amountFrom')}
                      InputProps={{
                        type: 'number',
                      }} />
                  </Grid>
                  <Grid container item xs={6}>
                    <FormikInput
                      iconName="money-bill"
                      labelText="Částka do"
                      name={nameof('amountTo')}
                      InputProps={{
                        type: 'number',
                      }} />
                  </Grid>
                </Grid>
                <Grid container item>
                  <FormikRelationSelect
                    iconName="info"
                    labelText="Způsob platby"
                    name={nameof('paymentMethod')}
                    items={paymentMethodsSelectItems as RelationSelectItem[]}
                    showErrorWhenNotTouched={true}
                  />
                </Grid>
                <Grid container item>
                  <Grid container item xs={6}>
                    <FormikDatePicker
                      iconName="address-card"
                      labelText="Od"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={nameof('dateOfPaymentFrom')}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                  <Grid container item xs={6}>
                    <FormikDatePicker
                      iconName="address-card"
                      labelText="Do"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name={nameof('dateOfPaymentTo')}
                      showErrorWhenNotTouched={true}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={(): void => {
                      resetForm();
                      submitForm();
                    }}>
                      Odstranit vše
                    </Button>&nbsp;
                    <Button color="primary" type="submit" variant="contained">
                      Hledat
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>);
        }}
      </Formik>
    </React.Fragment>
  );
};

const mapStateToProps = (state: ApplicationState): PaymentsFilterStateProps => {
  return {
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PaymentsFilterDispatchProps =>
  bindActionCreators({
    getItems: actions.getAllItemsAsync.request
  },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsFilter);
