import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withStyles, Theme, createStyles, Typography, Toolbar, Divider } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import 'react-day-picker/dist/style.css';

import {
  AlertsContainer,
  TimeLine,
  TimeLineItem,
  TimeLineGroup,
  DynamicToolbarButton,
  RelationSelect,
  RelationSelectItem,
  LoadingIndicator,
  withOpenModal,
  WithOpenModalProps,
} from '../../components';
import { ApplicationState } from '../../store/rootReducer';
import { selectAccommodationsSelectItems, getAreAccommodationsLoaded } from '../accommodations';
import { getAreFloorsLoaded } from '../floors';
import * as actions from './actions';
import { transformRoomsAsTimeLineGroupItems } from './selectors';
import { ROUTES, navigateTo } from '../../navigation';
import { selectFloorsSelectItems } from '../floors/selectors';
import _ from 'lodash';
import { TimeLineRange } from './types';
import DateRangePicker from '../../components/dateRangePicker/dateRangePicker';

const withStylesFunction = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // flex: "1"
    },
    toolBarRoot: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.text.primary,
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      minHeight: theme.spacing(9),
      borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    divider: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.primary.main
    },
    relationSelectTextRoot: { marginLeft: theme.spacing(2), marginRight: theme.spacing(1) },
    relationSelectRoot: { width: '300px', marginLeft: theme.spacing(1), marginRight: theme.spacing(1) }
  })
);

type TimeLineContainerClassKey = 'root' | 'toolBarRoot' | 'divider' | 'relationSelectTextRoot' | 'relationSelectRoot';

type TimeLineContainerStateProps = Readonly<{
  items: Readonly<TimeLineItem[]>;
  groups: Readonly<TimeLineGroup[]>;
  accommodationsSelectItems: Readonly<RelationSelectItem[]>;
  floorsSelectItems: Readonly<RelationSelectItem[]>;
  accommodationsLoaded: boolean;
  floorsLoaded: boolean;
  defaultDataStartDate: moment.Moment;
  defaultDataEndDate: moment.Moment;
  visibleDataStartDate: moment.Moment;
  visibleDataEndDate: moment.Moment;
  selectedAccommodation: number;
  selectedFloor: number;
  daysToVisible: number;
}>;

type TimeLineContainerDispatchProps = {
  getTimeLineRequest: typeof actions.getTimeLineAsync.request;
  timeScopeChanged: typeof actions.timeScopeChanged;
  loadDependenciesRequest: typeof actions.loadDependenciesAsync.request;
  filterByAccommodation: typeof actions.filterByAccommodation;
  filterByFloor: typeof actions.filterByFloor;
  chipSelected: typeof actions.chipSelected;
  navigateTo: typeof navigateTo;
  visibleRangeChanged: typeof actions.visibleRangeChanged;
};

type TimeLineContainerProps = { classes: Partial<ClassNameMap<TimeLineContainerClassKey>> } & TimeLineContainerStateProps &
  TimeLineContainerDispatchProps &
  WithOpenModalProps;

class TimeLineContainer extends React.Component<TimeLineContainerProps, { selected: Date; dateRange: boolean }> {
  timeScopeChangedDebounced: _.DebouncedFunc<(e: TimeLineRange) => typeof actions.timeScopeChanged>;

  constructor(props: TimeLineContainerProps) {
    super(props);

    this.timeScopeChangedDebounced = _.debounce((e: TimeLineRange) => {
      this.props.timeScopeChanged(e);
    }, 500);

    this.state = { selected: new Date(), dateRange: false };
  }

  componentDidMount(): void {
    console.debug('TimeLineContainer.componentDidMount');
    this.props.getTimeLineRequest();
    this.props.loadDependenciesRequest();
  }

  onNewButtonClickHandler = (): void => {
    this.props.navigateTo(ROUTES.ACCOMMODATION_OFFERS_CREATE);
  };

  onRefreshButtonClickHandler = (): void => {
    this.props.getTimeLineRequest();
  };

  onChangeAccommodationHandler = (value: number): void => {
    console.debug('TimeLineContainer.onChangeAccommodationHandler');

    this.props.filterByAccommodation(value);
  };

  onChangeFloorHandler = (value: number): void => {
    console.debug('TimeLineContainer.onChangeFloorHandler');

    this.props.filterByFloor(value);
  };

  render(): React.ReactNode {
    console.debug('TimeLineContainer.render');

    if (!this.props.accommodationsLoaded) {
      return <LoadingIndicator />;
    }

    let selectedAccommodationValue = this.props.accommodationsSelectItems.find(item => item.value === this.props.selectedAccommodation);
    if (selectedAccommodationValue === undefined) {
      selectedAccommodationValue = this.props.accommodationsSelectItems[0];
    }

    let selectedFloorValue = this.props.floorsSelectItems.find(item => item.value === this.props.selectedFloor);
    if (selectedFloorValue === undefined) {
      selectedFloorValue = this.props.floorsSelectItems[0];
    }

    return (
      <div className={this.props.classes.root}>
        <Toolbar className={this.props.classes.toolBarRoot}>
          <Typography variant="h4">REZERVACE</Typography>
          <Divider orientation="vertical" flexItem className={this.props.classes.divider} />

          <DynamicToolbarButton icon="sync-alt" ariaLabel="refresh list action" onClick={this.onRefreshButtonClickHandler} />
          <DynamicToolbarButton icon="plus" ariaLabel="add action" onClick={this.onNewButtonClickHandler} />
          {/* <DynamicToolbarButton icon="check" ariaLabel="add action" onClick={this.onNewButtonClickHandler} /> */}

          <Typography variant="h6" classes={{ root: this.props.classes.relationSelectTextRoot }} color="primary">
            Objekty
          </Typography>
          <RelationSelect
            items={this.props.accommodationsSelectItems as RelationSelectItem[]}
            classes={{ root: this.props.classes.relationSelectRoot }}
            variant="outlined"
            hideErrorComponent={true}
            disableUnselect={true}
            onChange={this.onChangeAccommodationHandler}
            value={selectedAccommodationValue}
          />
          <Typography variant="h6" classes={{ root: this.props.classes.relationSelectTextRoot }} color="primary">
            Patra
          </Typography>
          <RelationSelect
            items={this.props.floorsSelectItems as RelationSelectItem[]}
            classes={{ root: this.props.classes.relationSelectRoot }}
            variant="outlined"
            hideErrorComponent={true}
            disableUnselect={true}
            onChange={this.onChangeFloorHandler}
            value={selectedFloorValue}
          />
          &nbsp;
          <DateRangePicker onChange={(from: Date, to: Date) => {
              this.setState({ dateRange: true }, () => {
                this.props.visibleRangeChanged({
                  start: moment(from),
                  end: moment(to)
                });
  
                this.props.getTimeLineRequest();
              });
            }} 
            reset={() => {
              this.setState({ dateRange: false });
            }}
          />
        </Toolbar>
        <TimeLine
          canResize={false}
          items={this.props.items as TimeLineItem[]}
          groups={this.props.groups as TimeLineGroup[]}
          defaultTimeStart={this.props.defaultDataStartDate as moment.Moment}
          defaultTimeEnd={this.props.defaultDataEndDate as moment.Moment}
          visibleTimeStart={this.props.visibleDataStartDate as moment.Moment}
          visibleTimeEnd={this.props.visibleDataEndDate as moment.Moment}
          daysToVisible={this.props.daysToVisible}
          onTimeChanged={this.timeScopeChangedDebounced}
          onClick={this.props.chipSelected}
          dateRange={this.state.dateRange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): TimeLineContainerStateProps => {
  let floorSelectListItems = selectFloorsSelectItems(state);
  floorSelectListItems = [{ value: -1, text: 'Vše' }].concat(floorSelectListItems);

  return {
    items: state.timeLine.items,
    groups: transformRoomsAsTimeLineGroupItems(state),
    accommodationsSelectItems: selectAccommodationsSelectItems(state),
    floorsSelectItems: floorSelectListItems,
    accommodationsLoaded: getAreAccommodationsLoaded(state),
    floorsLoaded: getAreFloorsLoaded(state),
    daysToVisible: state.timeLine.countOfDaysToShowAfterToday + state.timeLine.countOfDaysToShowBeforeToday,
    selectedAccommodation: state.timeLine.selectedAccommodation,
    defaultDataStartDate: state.timeLine.defaultDataStartDate,
    defaultDataEndDate: state.timeLine.defaultDataEndDate,
    visibleDataStartDate: state.timeLine.visibleDataStartDate,
    visibleDataEndDate: state.timeLine.visibleDataEndDate,
    selectedFloor: state.timeLine.selectedFloor
  };
};

const mapDispatchToProps = (dispatch: Dispatch): TimeLineContainerDispatchProps => {
  return bindActionCreators(
    {
      getTimeLineRequest: actions.getTimeLineAsync.request,
      timeScopeChanged: actions.timeScopeChanged,
      loadDependenciesRequest: actions.loadDependenciesAsync.request,
      filterByAccommodation: actions.filterByAccommodation,
      filterByFloor: actions.filterByFloor,
      navigateTo: navigateTo,
      chipSelected: actions.chipSelected,
      visibleRangeChanged: actions.visibleRangeChanged
    },
    dispatch
  );
}

export default withOpenModal(connect(mapStateToProps, mapDispatchToProps)(withStylesFunction(TimeLineContainer)));
