import React, { ReactNode } from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import { Theme, FormControl, InputAdornment, FormHelperText } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import withStyles, { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { InputProps as MuiInputProps } from '@material-ui/core/Input';
import { InputLabelProps as MuiInputLabelProps } from '@material-ui/core/InputLabel';

import { IconName, Icon } from '../../icons';

const withStylesFunction = withStyles(({ palette, spacing }: Theme) => ({
  labelFormControl: {
    transform: 'translate(44px, 24px) scale(1)'
  },
  iconRoot: {
    width: '1.3rem',
    color: palette.text.secondary
  },
  inputInput: {
    fontWeight: 'bold'
  },
  inputRoot: {
    /* paddingBottom: `calc(${spacing(2)}px - ${paddings.MuiInputBaseInput.Bottom}px)` */
  },
  inputStartAdornment: {
    marginRight: spacing(2)
  },
  keyboardIconRoot: {
    fontSize: '1rem'
  }
}));

export type DatePickerClassKey =
  | 'labelFormControl'
  | 'iconRoot'
  | 'inputInput'
  | 'inputRoot'
  | 'inputStartAdornment'
  | 'keyboardIconRoot'
  | 'selectMenu';

export type DatePickerProps = {
  classes: Partial<ClassNameMap<DatePickerClassKey>>;
  iconName: IconName;
  labelText: string;
  helperText: ReactNode;
  error?: boolean;
  name: string;
  value: Date | null;
  format?: string;
  InputProps?: MuiInputProps;
  InputLabelProps?: MuiInputLabelProps;
  onChange: (date: MaterialUiPickersDate | null, value?: string | null) => void;
};

const defaultFormat = 'DD.MM.yyyy';

const DatePicker = (props: DatePickerProps): React.ReactElement => {
  const shrink = props.InputLabelProps ? props.InputLabelProps.shrink : false;
  const format = props.format ? props.format : defaultFormat;
  const dateValue = props.value && props.value instanceof Date ? props.value : null;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        fullWidth
        variant="inline"
        format={format}
        margin="none"
        name={props.name}
        id="date-picker-inline"
        label={props.labelText}
        value={dateValue}
        onChange={props.onChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          classes: { root: props.classes.keyboardIconRoot }
        }}
        keyboardIcon={<Icon iconName="calendar-alt" IconProps={{ className: props.classes.iconRoot }} fixedWidth />}
        InputProps={{
          classes: {
            root: props.classes.inputRoot,
            input: props.classes.inputInput
          },
          startAdornment: (
            <InputAdornment position="start" classes={{ root: props.classes.inputStartAdornment }}>
              <Icon iconName={props.iconName} IconProps={{ className: props.classes.iconRoot }} fixedWidth />
            </InputAdornment>
          ),
          ...{ ...props.InputProps, error: props.error }
        }}
        InputLabelProps={{
          classes: {
            formControl: !shrink ? props.classes.labelFormControl : undefined
          }
        }}
      />
      <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
    </MuiPickersUtilsProvider>
  );
};

export default withStylesFunction(DatePicker);
