import React, { memo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';
import { Formik } from 'formik';

import { nameofFactory } from '../../utils/nameofFactory';
import { priceListItemValidationSchema } from './validations';
import { PriceListItemModel, PriceListItemType, PriceListModel, RoomTypeModel } from '../../api/interfaces';
import {
  FormikInput,
  FormikRelationSelect,
  RelationSelectItem,
  BaseDetailContainer,
  BaseDetailDispatchProps,
  BaseDetailStateProps,
  BaseDetailContainerWrappedComponentProps,
  BaseDetailProps,
  FormikCondition,
  FormikCheckbox
} from '../../components';
import { getAccommodationIdByPriceListId } from '../priceLists/selectors';
import { selectRoomTypesSelectItemsByAccomodationId } from '../roomTypes';

export type PriceListItemDetailStateProps = {
  priceTypeSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListsSelectItems: DeepReadonly<RelationSelectItem[]>;
  vatsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceLists: { [itemId: number]: DeepReadonly<PriceListModel> };
  roomTypes: { [itemId: number]: DeepReadonly<RoomTypeModel> };
} & BaseDetailStateProps<PriceListItemModel>;

export type PriceListItemDetailDispatchProps = {
  getAccommodationIdByPriceListId: typeof getAccommodationIdByPriceListId;
  selectRoomTypesSelectItemsByAccomodationId: typeof selectRoomTypesSelectItemsByAccomodationId;
} & BaseDetailDispatchProps<PriceListItemModel>;

export type PriceListItemDetailProps = {} & BaseDetailProps<PriceListItemModel> & PriceListItemDetailStateProps & PriceListItemDetailDispatchProps;

type RenderFormProps = {
  priceTypeSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceListsSelectItems: DeepReadonly<RelationSelectItem[]>;
  accommodationsSelectItems: DeepReadonly<RelationSelectItem[]>;
  vatsSelectItems: DeepReadonly<RelationSelectItem[]>;
  priceLists: { [itemId: number]: DeepReadonly<PriceListModel> };
  roomTypes: { [itemId: number]: DeepReadonly<RoomTypeModel> };
} & BaseDetailContainerWrappedComponentProps<PriceListItemModel>;

const nameof = nameofFactory<PriceListItemModel>();

const RenderFormComponent = (props: RenderFormProps): React.ReactElement => {
  const {
    classes,
    errorMessage,
    requestInProgress,
    initialValues,
    priceTypeSelectItems,
    priceListsSelectItems,
    priceLists,
    accommodationsSelectItems,
    vatsSelectItems,
    roomTypes
  } = props;

  const handleActionCancel = (): void => {
    console.debug('handleActionCancel');

    props.cancelActionRequest();
  };

  return (
    <Formik<PriceListItemModel>
      initialValues={initialValues}
      onSubmit={(values: PriceListItemModel, { setSubmitting }): void => {
        console.log('Submiting');
        setSubmitting(true);

        props.saveActionRequest(values);
      }}
      validationSchema={priceListItemValidationSchema}
    >
      {(formProps): React.ReactElement => {
        const { handleSubmit, values } = formProps;

        const accommodationId =
          values.priceListId > 0
            ? getAccommodationIdByPriceListId(values.priceListId, priceLists)
            : 0;
        const roomTypesByAccommodation = selectRoomTypesSelectItemsByAccomodationId(accommodationId, roomTypes) ?? roomTypes;
        return (
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container className={classes.grid} spacing={3}>
              <Grid container item spacing={2}>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="usd-circle"
                    labelText="Ceník"
                    name={nameof('priceListId')}
                    errorMessage={errorMessage}
                    items={priceListsSelectItems as RelationSelectItem[]}
                    InputProps={{
                      autoFocus: true
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput iconName="usd-circle" labelText="Název" name={nameof('name')} errorMessage={errorMessage} />
                </Grid>
                <Grid item xs={12}>
                  <FormikInput
                    iconName="sticky-note"
                    labelText="Popisek"
                    name={nameof('description')}
                    errorMessage={errorMessage}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Typ ceny"
                    name={nameof('type')}
                    errorMessage={errorMessage}
                    items={priceTypeSelectItems as RelationSelectItem[]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormikCheckbox
                    labelText="Výchozí položka"
                    name={nameof('isDefault')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikRelationSelect
                    iconName="hotel"
                    labelText="Sazba DPH"
                    name={nameof('vatId')}
                    errorMessage={errorMessage}
                    items={vatsSelectItems as RelationSelectItem[]}
                    showErrorWhenNotTouched={true}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormikInput
                    iconName="usd-circle"
                    labelText="Cena"
                    name={nameof('price')}
                    errorMessage={errorMessage}
                    InputProps={{
                      type: 'number'
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormikCheckbox
                    labelText="Měsíční sazba"
                    name={nameof('isMonthlyPrice')}
                  />
                </Grid>
                <Grid item xs={12}>
                <FormikCondition<PriceListItemType, PriceListItemModel>
                    propertyName="type"
                    expectedValue={[PriceListItemType.PerBedAndNight, PriceListItemType.PerRoomAndNight]}
                  >
                    <FormikRelationSelect
                      iconName="hotel"
                      labelText="Typ pokoje"
                      name={nameof('roomTypeId')}
                      errorMessage={errorMessage}
                      items={roomTypesByAccommodation as RelationSelectItem[]}
                      showErrorWhenNotTouched={true}
                    />
                  </FormikCondition>
                </Grid>
         
                
              </Grid>

              <Grid item xs={12} classes={{ root: classes.gridButtonsRoot }}>
                <Button variant="contained" title="Uložit" color="primary" type="submit" disabled={requestInProgress}>
                  Uložit
                </Button>

                <Button
                  variant="contained"
                  title="Zavřít"
                  color="secondary"
                  disabled={requestInProgress}
                  onClick={handleActionCancel}
                >
                  Zavřít
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

const PriceListItemDetail: React.FunctionComponent<PriceListItemDetailProps> = memo(props => {
  console.debug('PriceListItemDetail');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    classes,
    modalDialog,
    modalDialogRoute,
    priceTypeSelectItems,
    priceListsSelectItems,
    vatsSelectItems,
    priceLists,
    roomTypes,
    loadDependenciesRequest,
    saveActionRequest,
    cancelActionRequest
  } = props;

  return (
    <BaseDetailContainer<PriceListItemModel>
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      initialValues={initialValues}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      classes={classes}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      {...{
        priceTypeSelectItems: priceTypeSelectItems,
        priceListsSelectItems: priceListsSelectItems,
        vatsSelectItems: vatsSelectItems,
        priceLists: priceLists,
        roomTypes: roomTypes
      }}
    >
      {RenderFormComponent}
    </BaseDetailContainer>
  );
});

export default PriceListItemDetail;
