import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApplicationState } from '../../store/rootReducer';
import PriceListsTable from './priceListsTable';
import * as actions from './actions';

import { PriceListModel, RoomTypeModel, AccommodationModel } from '../../api/interfaces';
import {
  GenericColumn,
  BaseTableRowModel,
  mergeWithDefaultColumns,
  BaseListStateProps,
  BaseListDispatchProps,
  BaseListContainerDispatchProps,
  BaseListContainer,
  createRelation
} from '../../components';
import { tableConfiguration } from '../../configuration/table';
import { getAccommodationsById } from '../accommodations';
import { NormalizedListById } from '../../core';

type PriceListsContainerStateProps = { accommodations: NormalizedListById<AccommodationModel> } & BaseListStateProps<PriceListModel>;

type PriceListsContainerDispatchProps = {} & BaseListDispatchProps<PriceListModel> & BaseListContainerDispatchProps<PriceListModel>;

type PriceListsContainerProps = {} & PriceListsContainerStateProps & PriceListsContainerDispatchProps;

const columns = mergeWithDefaultColumns<PriceListModel>([
  { id: 'name', label: 'Název', minWidth: 170, align: 'left', isVisible: true, isTableActions: false },
  {
    id: 'accommodationId',
    label: 'Ubytovací zařízení',
    minWidth: tableConfiguration.MinColumnWidthForAccommodation,
    align: 'left',
    isVisible: true,
    isTableActions: false,
    relation: createRelation<PriceListModel, AccommodationModel>('accommodationId', 'name')
  }
]);

const PriceListsContainer: React.FunctionComponent<PriceListsContainerProps> = props => {
  console.debug('PriceListsContainer');

  return (
    <BaseListContainer
      getItemsRequest={props.getItemsRequest}
      newItemRequest={props.newItemRequest}
      loadDependenciesRequest={props.loadDependenciesRequest}
    >
      <PriceListsTable
        rows={props.items}
        columns={columns as GenericColumn<BaseTableRowModel>[]}
        editAction={props.editItemRequest}
        deleteAction={props.deleteItemRequest}
        relations={{ accommodationId: { items: props.accommodations } }}
      />
    </BaseListContainer>
  );
};

const mapStateToProps = (state: ApplicationState): PriceListsContainerStateProps => {
  return {
    items: state.priceLists.itemList.allIds.map<PriceListModel>(id => {
      return state.priceLists.itemList.byId[id];
    }),
    requestInProgress: state.priceLists.requestInProgress,
    accommodations: getAccommodationsById(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch): PriceListsContainerDispatchProps =>
  bindActionCreators(
    {
      getItemsRequest: actions.getItemsAsync.request,
      deleteItemRequest: actions.deleteItemAsync.request,
      editItemRequest: actions.editItemAsync.request,
      newItemRequest: actions.newItemAsync.request,
      loadDependenciesRequest: actions.loadDependenciesAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PriceListsContainer);
