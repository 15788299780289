import * as Yup from 'yup';

import { PriceListItemType } from '../../api/interfaces';
import { nameValidationSchema } from '../validations';

export const priceListItemValidationSchema = Yup.object().shape({
    name: nameValidationSchema,
    price: Yup.number()
        .required('Cena je povinná')
        .min(0, 'Cena musí být nastavena'),
    priceListId: Yup.number()
        .required('Ceník musí být vybrán')
        .min(1, 'Ceník musí být vybrán'),
    type: Yup.number()
        .required('Typ ceny musí být vybrán')
        .min(0, 'Typ ceny musí být vybrán'),
    accommodationId: Yup.number().when('type', (type: PriceListItemType, schema: Yup.NumberSchema) => {
        return type === PriceListItemType.Default
            ? schema
                  .required('Pro tento typ ceny, musí být vybráno ubytovací zařízení.')
                  .min(1, 'Pro tento typ ceny, musí být vybráno ubytovací zařízení.')
            : schema;
    })
});
