import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from './actions';
import { ApplicationState } from '../../store/rootReducer';
import { default as ModalDialogForm } from './modalDialogForm';
import { ModalDialogModel } from './types';

export type ModalDialogManagerProps = {} & ModalDialogManagerStateProps & ModalDialogManagerDispatchProps;

export type ModalDialogManagerStateProps = {
  modals: ModalDialogModel[];
};

export type ModalDialogManagerDispatchProps = {
  close: typeof actions.closeModal;
};

class ModalDialogManagerContainer extends React.PureComponent<ModalDialogManagerProps> {
  static defaultProps = {};

  render(): React.ReactElement {
    return (
      <React.Fragment>
        {this.props.modals.map((modal, index) => {
          const isLast = this.props.modals.length === index + 1;
          return <ModalDialogForm key={modal.id} modal={modal} close={this.props.close} visible={isLast} />;
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ modalDialogManager }: ApplicationState): ModalDialogManagerStateProps => {
  return {
    modals: modalDialogManager.modals
  };
};

const mapDispatchToProps = (dispatch: any): ModalDialogManagerDispatchProps => {
  return bindActionCreators(
    {
      close: actions.closeModal
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalDialogManagerContainer);
