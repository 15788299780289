import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actions from './actions';
import { AccommodationOfferRoomTypeModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { selectRoomSelectItems } from './selectors';
import AccommodationOfferRoomTypeDetail, { AccommodationOfferRoomTypeDetailDispatchProps, AccommodationOfferRoomTypeDetailProps, AccommodationOfferRoomTypeDetailStateProps } from './accommodationOfferRoomTypeDetail';
import { RelationSelectItem } from '../../components';

type AccommodationOfferRoomEditContainerStateProps = {

} & AccommodationOfferRoomTypeDetailStateProps;

type AccommodationOfferRoomEditContainerDispatchProps = {
  editRoomType: typeof actions.editRoomTypeAction;
} & AccommodationOfferRoomTypeDetailDispatchProps;

type AccommodationOfferRoomEditContainerProps = {} & AccommodationOfferRoomTypeDetailProps & AccommodationOfferRoomEditContainerStateProps & AccommodationOfferRoomEditContainerDispatchProps;

const AccommodationOfferRoomEditContainer: React.FunctionComponent<AccommodationOfferRoomEditContainerProps> = memo(props => {
  console.debug('AccommodationOfferRoomEditContainer');

  const [_roomsSelectItems, _setRoomsSelectItems] = useState<RelationSelectItem[]>([]);

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    editRoomType,
    roomsSelectItems,
    rooms
  } = props;

  return (
    <React.Fragment>
      <AccommodationOfferRoomTypeDetail
        initialValues={initialValues}
        errorMessage={errorMessage}
        requestInProgress={requestInProgress}
        modalDialog={modalDialog}
        modalDialogRoute={modalDialogRoute}
        loadDependenciesRequest={loadDependenciesRequest}
        saveActionRequest={saveActionRequest}
        cancelActionRequest={cancelActionRequest}
        editRoomType={editRoomType}
        roomsSelectItems={roomsSelectItems}
        rooms={rooms}
        _roomsSelectItems={_roomsSelectItems}
        _setRoomsSelectItems={_setRoomsSelectItems}
      />
    </React.Fragment>
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferRoomEditContainerStateProps => {
  let initialValues: AccommodationOfferRoomTypeModel;
  switch (ownProps.type) {
    case 0: 
      initialValues = state.accommodationOfferCalculations.freeRooms.byId[ownProps.id];
      break;
    case 1: 
      initialValues = state.accommodationOfferCalculations.partiallyOccupiedRooms.byId[ownProps.id];
      break;
    case 2: 
      initialValues = state.accommodationOfferCalculations.reservedRooms.byId[ownProps.id];
      break;
    default:
      initialValues = { } as AccommodationOfferRoomTypeModel;
      break;
  }

  return {
    initialValues: { ...initialValues, type: ownProps.type },
    errorMessage: state.states.errorMessage,
    requestInProgress: state.states.requestInProgress,
    roomsSelectItems: selectRoomSelectItems(state, ownProps.roomTypeId, ownProps.floorNumber, initialValues.roomIds),
    rooms: state.rooms.itemList.byId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferRoomEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveRoomTypeAsync.request,
        cancelActionRequest: actions.cancelRoomTypeAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        editRoomType: actions.editRoomTypeAction
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferRoomEditContainer);
