import { applyMiddleware, /*  combineReducers, */ compose, createStore } from 'redux';
import {
    /* connectRouter, */ routerMiddleware as createRouterMiddleware,
  RouterRootState /* , LocationChangeAction */
} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';

import rootReducer, { ApplicationState /* , ApplicationAction */ } from './rootReducer';
import rootSaga from '../saga/rootSaga';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IExtendWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

type RootApplicationState = ApplicationState & RouterRootState;

export default function configureStore(history: History, initialState: RootApplicationState) {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware(history);
  const middleware = [sagaMiddleware, routerMiddleware];

  // In development, use the browser's Redux dev tools extension if installed
  const isDevelopment = process.env.NODE_ENV === 'development';

  const composeEnhancers =
    isDevelopment &&
      typeof window !== 'undefined' &&
      typeof window === 'object' &&
      ((window as unknown) as IExtendWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? ((window as unknown) as IExtendWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        trace: true
      })
      : compose;

  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware)
      // other store enhancers if any
    )
  );
  console.log(store.getState());

  sagaMiddleware.run(rootSaga);

  return store;
}
