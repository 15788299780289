import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { NormalizedListById } from '../../core';
import { AccommodationOfferRoomTypeModel, RoomModel } from '../../api/interfaces';

export const getAccommodationOfferFreeRoomTypes = (state: ApplicationState): NormalizedListById<AccommodationOfferRoomTypeModel> => {
  return Object.assign({}, state.accommodationOfferCalculations.freeRooms.byId);
};

export const getAccommodationOfferPartiallyOccupiedRoomTypes = (state: ApplicationState): NormalizedListById<AccommodationOfferRoomTypeModel> => {
  return Object.assign({}, state.accommodationOfferCalculations.partiallyOccupiedRooms.byId);
};

export const getAccommodationOfferReservedRoomTypes = (state: ApplicationState): NormalizedListById<AccommodationOfferRoomTypeModel> => {
  return Object.assign({}, state.accommodationOfferCalculations.reservedRooms.byId);
};

export const selectRooms = (state: ApplicationState, items: DeepReadonly<RelationSelectItem[]>): DeepReadonly<RelationSelectItem[]> => {
  const ids = state.reservations.current.rooms.map(room => room.roomId);
  return items.filter(item => {
    return !ids.includes(item.value);
  });
}

export const selectRoomSelectItems = (state: ApplicationState, roomTypeId: number, floorNumber: number, ids: number[]): DeepReadonly<RelationSelectItem[]> => {
  const rooms = [] as RoomModel[];
  Object.values(state.rooms.itemList.byId).forEach(room => {
    if (roomTypeId === room.roomTypeId 
      && room.floorNumber === floorNumber
      && ids.includes(room.id)) {
      rooms.push(room);
    }
  });

  return rooms.map(room => {
    return { text: room.name, value: room.id } as RelationSelectItem;
  });
};