import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { makeStyles, createStyles, Theme, Container, Grid } from '@material-ui/core';

import * as actions from './actions';
import SignInUserNamePasswordView from './signInUserNamePasswordView';
import { ApplicationState } from '../../store/rootReducer';
import { Logo } from '../../components';
import { HOTELIOS_LOGO_GOLD } from '../../customization/assets';
import AlertsContainer from '../../components/alerts/alertsContainer';

type SignInMinimalContainerProps = {} & SignInMinimalContainerStateProps & SignInMinimalContainerDispatchProps;

type SignInMinimalContainerStateProps = {
  errorMessage: string;
  requestInProgress: boolean;
};

type SignInMinimalContainerDispatchProps = {
  signInRequest: typeof actions.signInSilentAsync.request;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing(4)}px`,
      minHeight: '100%'
    },
    itemRoot: {
      backgroundColor: 'white',
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
      borderStyle: 'solid',
      margin: 0,
      padding: theme.spacing(4)
    },
    loginText: {
      color: theme.palette.primary.main
    },
    headerRoot: {
    },
    image: {
      height: 90,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  })
);

const SignInMinimalContainer: React.FunctionComponent<SignInMinimalContainerProps> = props => {
  const classes = useStyles(props);

  return (
    <Container maxWidth="sm" classes={{ root: classes.root }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item classes={{ root: classes.itemRoot }}>
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <header className={classes.headerRoot}>
                <Logo imageSrc={HOTELIOS_LOGO_GOLD} classes={{ root: classes.image }} alt="logo" />
              </header>
            </Grid>
            <Grid item>
              <SignInUserNamePasswordView
                signIn={props.signInRequest}
                errorMessage={props.errorMessage}
                requestInProgress={props.requestInProgress}
              />
              <AlertsContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: ApplicationState): SignInMinimalContainerStateProps => ({
  errorMessage: state.signIn.errorMessage,
  requestInProgress: state.signIn.requestInProgress
});

const mapDispatchToProps = (dispatch: Dispatch): SignInMinimalContainerDispatchProps =>
  bindActionCreators(
    {
      signInRequest: actions.signInSilentAsync.request
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignInMinimalContainer);
