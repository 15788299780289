import React, { memo } from 'react';

import { TableContainer, BaseTableProps } from '../../components';

type UsersTableProps = {} & BaseTableProps;

const UsersTable: React.FunctionComponent<UsersTableProps> = memo(props => {
    console.debug('UsersTable');

    return <TableContainer {...props} columns={props.columns} dataSourcePath="" />;
});

export default UsersTable;
