import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import RoomDetail, { RoomDetailStateProps, RoomDetailDispatchProps, RoomDetailProps } from './roomsDetail';
import * as actions from './actions';
import { RoomModel, RoomTypeModel } from '../../api/interfaces';
import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem } from '../../components';
import { selectAccommodationsSelectItems } from '../accommodations';
import { selectFloorsSelectItems } from '../floors/selectors';
import { selectRoomTypesSelectItemsByAccomodationId } from '../roomTypes/selectors';

type RoomCreateContainerStateProps = {} & RoomDetailStateProps;

type RoomCreateContainerDispatchProps = {} & RoomDetailDispatchProps;

type RoomCreateContainerProps = {} & RoomDetailProps & RoomCreateContainerStateProps & RoomCreateContainerDispatchProps;

const createInitialValues = {
  description: '',
  name: '',
  accommodationId: 0,
  floorId: 0,
  roomTypeId: 0
} as RoomModel;

const RoomCreateContainer: React.FunctionComponent<RoomCreateContainerProps> = memo(props => {
  console.debug('RoomCreateContainer');

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    roomTypes,
    accommodationsSelectItems,
    floorsSelectItems,
    modalDialog,
    modalDialogRoute,
    saveActionRequest,
    cancelActionRequest,
    selectRoomTypesSelectItemsByAccomodationId,
    loadDependenciesRequest
  } = props;

  return (
    <RoomDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      accommodationsSelectItems={accommodationsSelectItems}
      floorsSelectItems={floorsSelectItems}
      roomTypes={roomTypes}
      selectRoomTypesSelectItemsByAccomodationId={selectRoomTypesSelectItemsByAccomodationId}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
    />
  );
});

const mapStateToProps = (state: ApplicationState): RoomCreateContainerStateProps => {
  return {
    initialValues: createInitialValues,
    errorMessage: state.rooms.errorMessage,
    requestInProgress: state.rooms.requestInProgress,
    accommodationsSelectItems: selectAccommodationsSelectItems(state),
    floorsSelectItems: selectFloorsSelectItems(state),
    roomTypes: state.roomTypes.itemList.byId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): RoomCreateContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request
      },
      dispatch
    ),
    selectRoomTypesSelectItemsByAccomodationId: selectRoomTypesSelectItemsByAccomodationId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomCreateContainer);
