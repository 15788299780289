import { createReducer } from 'typesafe-actions';

import { FullPageLoadingIndicatorState, FullPageLoadingIndicatorAction } from './types';
import * as actions from './actions';

import { Stack } from '../../utils/stack';

export const initialState: FullPageLoadingIndicatorState = {
  showIndicator: false,
  indicatorStack: new Stack<boolean>()
};

const fullPageLoadingIndicatorReducer = createReducer<FullPageLoadingIndicatorState, FullPageLoadingIndicatorAction>(initialState)
  .handleAction(actions.showFullPageLoadingIndicator, (state, action) => {
    const newStack = state.indicatorStack.clone();
    newStack.push(true);

    return { showIndicator: !newStack.isEmpty(), indicatorStack: newStack };
  })
  .handleAction(actions.hideFullPageLoadingIndicator, (state, action) => {
    const newStack = state.indicatorStack.clone();
    newStack.pop();

    return { showIndicator: !newStack.isEmpty(), indicatorStack: newStack };
  });

export default fullPageLoadingIndicatorReducer;
