import { ApplicationState } from '../../store/rootReducer';
import { DeepReadonly } from 'utility-types';
import { retry } from 'redux-saga/effects';
import { RelationSelectItem, TablePaginationModel } from '../../components';
import { PaymentMethodEnum } from '../../api/interfaces';

export const getPaymentMethodsSelectItems = (): RelationSelectItem[] => {
  return [
    { text: 'Hotově', value: PaymentMethodEnum.Cash },
    { text: 'Kartou', value: PaymentMethodEnum.Card },
    { text: 'Převodem', value: PaymentMethodEnum.Bank },
    { text: 'Fakturou', value: PaymentMethodEnum.Invoice }
  ] as RelationSelectItem[];
};

export const getPaymentsPagination = (state: ApplicationState): TablePaginationModel => {
  return {
    page: state.payments.itemList.page ?? 0,
    skip: state.payments.itemList.skip ?? 0,
    take: state.payments.itemList.take ?? 100,
    total: state.payments.itemList.total ?? 0,
    filter: state.payments.itemList.filter ?? ""
  } as TablePaginationModel;
};

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.payments.itemList.loaded;
};