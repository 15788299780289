import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import { RelationSelectItem, RelationFilterSelectItem } from '../../components';
import { NormalizedListById } from '../../core';
import { PriceListItemModel, PriceListItemType, RoomTypeModel } from '../../api/interfaces';

export const getAreItemsLoaded = (state: ApplicationState): boolean => {
  return state.priceListItems.itemList.loaded;
};

export const getArePriceListItemsLoaded = (state: ApplicationState): boolean => getAreItemsLoaded(state);

export const getPriceListItemsById = (state: ApplicationState): NormalizedListById<PriceListItemModel> => {
  return state.priceListItems.itemList.byId;
};

export const selectPriceListItemsSelectItems = createSelector(
  [getPriceListItemsById],
  (priceListItemsById): DeepReadonly<RelationSelectItem[]> => {
    console.debug('Calling selectPriceListItemsSelectItems...');

    return Object.values(priceListItemsById).map<RelationSelectItem>(priceListItem => {
      return { text: priceListItem.name, value: priceListItem.id, filterId: priceListItem.priceListId, isDefault: priceListItem.isDefault } as RelationFilterSelectItem;
    });
  }
);

export const selectPriceListItemsSelectPricePerBedItems = createSelector(
  [getPriceListItemsById],
  (priceListItemsById): DeepReadonly<RelationFilterSelectItem[]> => {
    console.debug('Calling selectPriceListItemsSelectItems...');

    return Object.values(priceListItemsById).filter(p => p.type === PriceListItemType.PerBedAndNight).map<RelationFilterSelectItem>(priceListItem => {
      return { text: priceListItem.name, value: priceListItem.id, filterId: priceListItem.priceListId, isDefault: priceListItem.isDefault } as RelationFilterSelectItem;
    });
  }
);

export const selectPriceListItemsSelectPricePerRoomItems = createSelector(
  [getPriceListItemsById],
  (priceListItemsById): DeepReadonly<RelationFilterSelectItem[]> => {
    console.debug('Calling selectPriceListItemsSelectItems...');

    return Object.values(priceListItemsById).filter(p => p.type === PriceListItemType.PerRoomAndNight).map<RelationFilterSelectItem>(priceListItem => {
      return { text: priceListItem.name, value: priceListItem.id, filterId: priceListItem.priceListId, isDefault: priceListItem.isDefault } as RelationFilterSelectItem;
    });
  }
);

export const selectPriceListItemsSelectFeesItems = createSelector(
  [getPriceListItemsById],
  (priceListItemsById): DeepReadonly<RelationFilterSelectItem[]> => {
    console.debug('Calling selectPriceListItemsSelectItems...');

    return Object.values(priceListItemsById).filter(p => p.type === PriceListItemType.Fee).map<RelationFilterSelectItem>(priceListItem => {
      return { text: priceListItem.name, value: priceListItem.id, filterId: priceListItem.priceListId, isDefault: priceListItem.isDefault } as RelationFilterSelectItem;
    });
  }
);

export const selectRoomTypesSelectItemsByAccomodationId = (
  accommodationId: number,
  roomTypesById: { [itemId: number]: DeepReadonly<RoomTypeModel> }
): RelationSelectItem[] => {
  console.debug('Calling selectRoomTypesSelectItemsByAccomodationId...');

  return Object.values(roomTypesById)
    .filter(roomType => {
      return roomType.accommodationId === accommodationId;
    })
    .map<RelationSelectItem>(roomType => {
      return { text: roomType.name, value: roomType.id } as RelationSelectItem;
    });
};
