import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AccommodationOfferDetail, {
  AccommodationOfferDetailStateProps,
  AccommodationOfferDetailDispatchProps,
  AccommodationOfferDetailProps
} from './accommodationOffersDetail';
import * as actions from './actions';
import { calculateAsyncAction, setCompanyFilterAction, setOfferHeaderAction } from '../accommodationOfferCalculations/actions';
import { calculatePriceAsyncAction } from '../accommodationOfferPriceCalculations';
import { ApplicationState } from '../../store/rootReducer';
import { selectAccommodationsSelectItems } from '../accommodations';
import { selectCompaniesSelectItems } from '../companies';
import { selectPriceListsSelectItems } from '../priceLists';
import { selectGuestsSelectItems } from '../guests';
import { selectPriceListItemsSelectPricePerBedItems, selectPriceListItemsSelectPricePerRoomItems } from '../priceListItems';

type AccommodationOfferEditContainerStateProps = { } & AccommodationOfferDetailStateProps;

type AccommodationOfferEditContainerDispatchProps = { } & AccommodationOfferDetailDispatchProps;

type AccommodationOfferEditContainerProps = { } & AccommodationOfferDetailProps &
  AccommodationOfferEditContainerStateProps &
  AccommodationOfferEditContainerDispatchProps;

const AccommodationOfferEditContainer: React.FunctionComponent<AccommodationOfferEditContainerProps> = memo(props => {
  console.debug('AccommodationOfferEditContainer');
  useEffect(() => {
    console.warn("AccommodationOfferEditContainer.didMount");
  }, []);

  const {
    errorMessage,
    requestInProgress,
    initialValues,
    modalDialog,
    modalDialogRoute,
    accommodationsSelectItems,
    guestsSelectItems,
    companiesSelectItems,
    priceListsSelectItems,
    saveActionRequest,
    cancelActionRequest,
    loadDependenciesRequest,
    getItemAsync,
    calculateActionRequest,
    calculatePriceActionRequest,
    setCompanyFilterAction,
    setOfferHeaderAction,
    priceListItemsSelectItemsPerBed,
    priceListItemsSelectItemsPerRoom,
    activeStep
  } = props;

  return (
    <AccommodationOfferDetail
      initialValues={initialValues}
      loadDependenciesRequest={loadDependenciesRequest}
      saveActionRequest={saveActionRequest}
      cancelActionRequest={cancelActionRequest}
      errorMessage={errorMessage}
      requestInProgress={requestInProgress}
      modalDialog={modalDialog}
      modalDialogRoute={modalDialogRoute}
      accommodationsSelectItems={accommodationsSelectItems}
      guestsSelectItems={guestsSelectItems}
      companiesSelectItems={companiesSelectItems}
      priceListsSelectItems={priceListsSelectItems}
      getItemAsync={getItemAsync}
      calculateActionRequest={calculateActionRequest}
      calculatePriceActionRequest={calculatePriceActionRequest}
      setCompanyFilterAction={setCompanyFilterAction}
      setOfferHeaderAction={setOfferHeaderAction}
      priceListItemsSelectItemsPerBed={priceListItemsSelectItemsPerBed}
      priceListItemsSelectItemsPerRoom={priceListItemsSelectItemsPerRoom}
      activeStep={activeStep}
    />
  );
});

const mapStateToProps = (state: ApplicationState, ownProps: any): AccommodationOfferEditContainerStateProps => {
  return {
    initialValues: state.accommodationOffers.itemList.byId[ownProps.match.params.id],
    errorMessage: state.accommodationOffers.errorMessage,
    requestInProgress: state.accommodationOffers.requestInProgress,
    accommodationsSelectItems: selectAccommodationsSelectItems(state),
    guestsSelectItems: selectGuestsSelectItems(state),
    companiesSelectItems: selectCompaniesSelectItems(state),
    priceListsSelectItems: selectPriceListsSelectItems(state),
    priceListItemsSelectItemsPerBed: selectPriceListItemsSelectPricePerBedItems(state),
    priceListItemsSelectItemsPerRoom: selectPriceListItemsSelectPricePerRoomItems(state),
    activeStep: state.accommodationOfferCalculations.wizard.activeStep
  };
};

const mapDispatchToProps = (dispatch: Dispatch): AccommodationOfferEditContainerDispatchProps => {
  return {
    ...bindActionCreators(
      {
        saveActionRequest: actions.saveItemAsync.request,
        cancelActionRequest: actions.cancelItemAsync.request,
        loadDependenciesRequest: actions.loadDependenciesAsync.request,
        getItemAsync: actions.getItemAsync.request,
        calculateActionRequest: calculateAsyncAction.request,
        calculatePriceActionRequest: calculatePriceAsyncAction.request,
        setCompanyFilterAction: setCompanyFilterAction,
        setOfferHeaderAction: setOfferHeaderAction
      },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationOfferEditContainer);
